import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useCallback,
  useReducer,
  useEffect,
} from "react";
import { NODE_TYPE, WORKFLOW_STATUS } from "utils/constants";
import { updateFlowInstance, restartWorkflowInstance } from "api";
import { getStateByPath, injectValue as _injectValue } from "utils";
import { NetworkConnectionErrorModal } from "components/common/NetworkConnectionErrorModal";

const FlowContext = createContext();

const reducer = (state, [type, payload]) => {
  switch (type) {
    case "updateState": {
      return {
        ...state,
        ...payload,
      };
    }
    case "updateFlowState": {
      return {
        ...state,
        flowState: payload,
      };
    }
    case "updateInstanceState": {
      return {
        ...state,
        instanceState: payload,
      };
    }
    case "addReferencedWorkflow": {
      return {
        ...state,
        referencedWorkflows: {
          ...state.referencedWorkflows,
          [payload.id]: { ...payload },
        },
      };
    }
    case "iterate": {
      return {
        ...state,
        iterate: payload,
      };
    }
    default:
      throw Error("Unknown action: " + type);
  }
};

function FlowProvider({ dryRun = false, children }) {
  const [accountNumber, setAccountNumber] = useState(null);
  const [iwTicketNumber, setIwTicketNumber] = useState(null);
  const [instanceData, setInstanceData] = useState({});
  const [sequence, setSequence] = useState(1);
  const [debugMode, setDebugMode] = useState(false);
  const [lastInstanceData, setLastInstanceData] = useState(null);
  const [workflow, setWorkflow] = useState(null);
  const [workflowInstanceId, setWorkflowInstanceId] = useState(null);
  const [apiRunning, setApiRunning] = useState(false);
  const [techInfo, setTechInfo] = useState({});
  const [state, dispatch] = useReducer(reducer, {
    flowConfig: {},
    flowState: {},
    status: WORKFLOW_STATUS.IN_PROGRESS,
    flowPath: [],
    workflowNodes: {},
    workflow: {},
    referencedWorkflows: {},
    slackChannels: [],
    instanceState: {},
    currentNode: "start-1",
    error: null,
    user: null,
    instanceData,
    dryRun,
    path: [],
    iterate: null,
  });

  const injectValue = useCallback(
    (string) =>
      _injectValue(
        string,
        state.instanceState,
        state.path,
        workflow?.form?.form_schema,
      ),
    [state.instanceState, state.path, workflow?.form],
  );

  const referenceFlow = useMemo(
    () => state.path.findLast(({ type }) => type === NODE_TYPE.REFERENCE_FLOW),
    [state.path],
  );

  const getReferenceFlowNodes = useCallback(
    (referenceFlowId) => state?.workflowNodes?.references?.[referenceFlowId],
    [state?.workflowNodes?.references],
  );

  const stepState = useMemo(
    () =>
      getReferenceFlowNodes(referenceFlow?.reference_flow)?.[
        state.currentNode
      ] ?? state?.workflowNodes?.[state.currentNode],
    [
      getReferenceFlowNodes,
      referenceFlow,
      state.currentNode,
      state?.workflowNodes,
    ],
  );

  const isInLoop = useCallback(
    (path) => {
      if (path.length === 0) return false;
      if (path[path.length - 1].type === NODE_TYPE.LOOP) return true;
      if (
        path.length > 1 &&
        stepState.type === NODE_TYPE.END &&
        path[path.length - 1].type === NODE_TYPE.REFERENCE_FLOW &&
        path[path.length - 2].type === NODE_TYPE.LOOP
      )
        return true;
      return false;
    },
    [stepState],
  );

  const getInstanceState = useCallback(
    ({ state: prevState, path: customPath } = {}) => {
      let instanceState = prevState ?? state.instanceState;
      const _path = customPath ?? state.path;
      return getStateByPath(instanceState, _path);
    },
    [state.path, state.instanceState],
  );

  const updateFlowStateStepData = (stepData, status, timestamp) => {
    if (status) {
      const newState = {
        ...state,
        flowState: {
          ...state.flowState,
          [sequence]: {
            ...state.flowState?.[sequence],
            ...stepData,
          },
        },
      };
      upsertData(status, newState, timestamp);
    }
    const path = structuredClone(state.path);
    switch (stepData.nodeType) {
      case NODE_TYPE.LOOP:
        path.push({
          id: stepData.nodeId,
          type: stepData.nodeType,
        });
        break;
      case NODE_TYPE.REFERENCE_FLOW:
        path.push({
          id: stepData.nodeId,
          type: stepData.nodeType,
          reference_flow: stepData.data.reference_flow,
        });
        break;
      default:
        break;
    }
    dispatch([
      "updateFlowState",
      {
        ...state.flowState,
        [sequence]: {
          ...state.flowState?.[sequence],
          ...stepData,
          path,
        },
      },
    ]);
    dispatch(["updateInstanceState", formatStepOutput(stepData)]);
    if (path.length !== state.path.length) dispatch(["updateState", { path }]);
  };

  const formatStepOutput = (stepData) => {
    const { instanceState: prevInstanceState } = state;
    let formattedStepState = {};
    let key = stepData.nodeId;
    switch (stepData.nodeType) {
      case NODE_TYPE.END:
        if (!referenceFlow) return prevInstanceState;
        key = "success";
        formattedStepState = stepData.status === "Success";
        break;
      case NODE_TYPE.API:
        formattedStepState = {
          data: stepData.state?.data?.data || {},
          response: stepData.state?.data?.text || "",
          success: stepData.state?.data?.success,
          status_code: stepData.state?.status,
        };
        break;
      case NODE_TYPE.RADIO:
      case NODE_TYPE.CHECKBOX:
      case NODE_TYPE.ESCALATE:
        formattedStepState = { answer: stepData.selected };
        break;
      case NODE_TYPE.INPUT:
      case NODE_TYPE.LOOP:
        formattedStepState = { ...stepData.state };
        break;
      case NODE_TYPE.START_SPECIFIC:
        return { ...prevInstanceState, ...stepData.state };
      case NODE_TYPE.REFERENCE_FLOW:
        const defaultColumns = [
          "reference_flow",
          "reference_flow_label",
          "notifications_config",
          "images",
        ];
        const columns = Object.keys(stepData.data).filter(
          (x) => !defaultColumns.includes(x),
        );
        formattedStepState = Object.entries(stepData.data).reduce(
          (acc, [key, value]) => {
            if (columns.includes(key)) {
              return {
                ...acc,
                [key]: _injectValue(value, prevInstanceState, state.path) || "",
              };
            } else {
              return acc;
            }
          },
          {},
        );
        break;
      default:
        return prevInstanceState;
    }

    const { instanceState } = structuredClone(state);
    const reference = getInstanceState({ state: instanceState });
    reference[key] = formattedStepState;
    return instanceState;
  };

  const upsertWorkflowState = async (status, workflowState, timestamp) => {
    if (state.dryRun) {
      return;
    }
    let data = {
      workflow_id: state.workflow.id,
      status,
      current_state: workflowState
        ? {
            flowPath: workflowState.flowPath,
            flowState: workflowState.flowState,
            instanceState: workflowState.instanceState,
          }
        : {
            flowPath: state.flowPath,
            flowState: state.flowState,
            instanceState: state.instanceState,
          },
      account_number: accountNumber,
      iw_ticket: iwTicketNumber,
      instance_data: instanceData,
    };
    if (timestamp) {
      const input = { ...data, timestamp };
      return updateFlowInstance(workflowInstanceId, input);
    }
    return updateFlowInstance(workflowInstanceId, data);
  };

  const upsertData = async (status, workflowState = null, timestamp = null) => {
    if (state.dryRun || !state.instanceState["account_number"]) return;
    await upsertWorkflowState(status, workflowState, timestamp);
  };

  const restartFlow = async (data) => {
    if (state.dryRun) {
      setWorkflowInstanceId(data.id);
      return;
    }
    const {
      data: { data: response },
    } = await restartWorkflowInstance(data.id);
    setWorkflowInstanceId(response?.id);
  };

  const onPrevious = async () => {
    const SKIP_PREVIOUS = [
      NODE_TYPE.IF,
      NODE_TYPE.END,
      NODE_TYPE.REFERENCE_FLOW,
      NODE_TYPE.LOOP,
    ];
    let instanceState = {};

    // check if current node is in a loop
    if (isInLoop(state.path)) {
      const { id } = state.path[state.path.length - 1];
      const currentState =
        referenceFlow?.reference_flow && stepState.type !== NODE_TYPE.END
          ? getReferenceFlowNodes(referenceFlow.reference_flow)
          : state?.workflowNodes;
      const {
        next: { iteration },
      } = currentState[id];

      // if current node is the starting node of the loop decrement item index
      if (iteration === state.currentNode) {
        const reference = getInstanceState({
          path: state.path.slice(0, state.path.length - 1),
        });
        const { index, source } = reference[id];
        if (index > 0)
          reference[id] = {
            ...reference[id],
            index: index - 1,
            item: source[index - 1],
          };
      }
    }

    if (
      SKIP_PREVIOUS.includes(
        state.flowState[state.flowPath[state.flowPath.length - 1] || 1]
          ?.nodeType,
      )
    ) {
      let j = 2;
      for (let i = state.flowPath.length - 1; i >= 1; i--) {
        if (
          SKIP_PREVIOUS.includes(
            state.flowState[state.flowPath[state.flowPath.length - j]]
              ?.nodeType,
          )
        ) {
          j++;
          continue;
        } else {
          const flowPath = state.flowPath.slice(0, state.flowPath.length - j);
          dispatch([
            "updateState",
            {
              flowState: Object.keys(state.flowState)
                .filter((key) => flowPath.includes(Number(key)))
                .reduce((obj, key) => {
                  obj[key] = state.flowState[key];
                  return obj;
                }, {}),
              flowPath,
              currentNode:
                state.flowState[state.flowPath[state.flowPath.length - j]]
                  ?.nodeId,
              instanceState: { ...state.instanceState, ...instanceState },
              path: state.flowState[sequence - j].path,
            },
          ]);

          setSequence(sequence - j);
        }
      }
    } else {
      const flowPath = state.flowPath.slice(0, state.flowPath.length - 1);
      dispatch([
        "updateState",
        {
          flowState: Object.keys(state.flowState)
            .filter((key) => flowPath.includes(Number(key)))
            .reduce((obj, key) => {
              obj[key] = state.flowState[key];
              return obj;
            }, {}),
          flowPath,
          currentNode:
            state.flowState[state.flowPath[state.flowPath.length - 1] || 1]
              ?.nodeId,
          instanceState: { ...state.instanceState, ...instanceState },
          path: state.flowState[sequence - 1].path,
        },
      ]);
      setSequence(sequence - 1);
    }
  };

  useEffect(() => {
    upsertData(state.status);
  }, [sequence, state.status]);

  const handleStartStep = () => {
    if (state.currentNode !== "start-1") return;
    const accountNumber = Object.values(state.flowState).find(
      (x) => x.nodeId === "start-1",
    )?.state?.["account"];
    if (accountNumber) {
      setAccountNumber(accountNumber);
    }
  };

  const handleInputStep = () => {
    if (stepState?.type !== NODE_TYPE.INPUT) return;

    const inputData = Object.values(state.flowState).find(
      (x) => x.nodeId === state.currentNode,
    )?.state;

    setInstanceData({
      ...instanceData,
      input_data: {
        ...instanceData?.input_data,
        ...inputData,
      },
    });
  };

  const handleApiStep = () => {
    if (stepState?.type !== NODE_TYPE.API) return;

    const apiData = Object.values(state.flowState).find(
      (x) => x.nodeId === state.currentNode,
    )?.state?.data;
    if (apiData?.data) {
      setInstanceData({
        ...instanceData,
        ...apiData.data,
      });
    }
  };

  const determineNextStep = (options = {}) => {
    let next = null;
    let _path = state.path;
    let reference_flow = referenceFlow?.reference_flow;
    let nextIteration = null;
    switch (stepState.type) {
      case NODE_TYPE.API:
        const handles = stepState.results;
        const { apiResponse } = options;
        if (apiResponse.status !== 200) {
          const handle = handles.find(
            (handle) => handle.status === apiResponse.status ?? 500,
          );
          if (handle) {
            next = handle.next;
          }
        } else {
          //status === 200
          const filtered = handles
            .filter(
              (handle) =>
                handle.success === apiResponse.data.success &&
                handle.status === apiResponse.status,
            )
            .sort((a, b) => (a.response > b.response ? -1 : 1));

          for (let index = 0; index < filtered.length; index++) {
            const result = filtered[index];
            const { response, next: nextStep } = result;
            if (response) {
              // check to see if backend sent back nothing, if not continue
              // Object.keys will error out on a return of nothing
              if (!apiResponse.data?.text) {
                next = nextStep;
                continue;
              }
              if (
                // updated to compare as object. String is no longer sent
                response.toString().toLowerCase() ===
                Object.keys(apiResponse.data?.text)[0]?.toString().toLowerCase()
              ) {
                next = nextStep;
                break;
              }
            } else {
              next = nextStep;
              break;
            }
          }
        }
        break;
      case NODE_TYPE.INPUT:
      case NODE_TYPE.START_SPECIFIC:
        const { isValid } = options;
        if (!isValid) return null;
        next = stepState?.next ?? null;
        break;
      case NODE_TYPE.END:
        if (referenceFlow) {
          _path = _path.slice(0, _path.length - 1);
          reference_flow = _path.findLast(
            ({ type }) => type === NODE_TYPE.REFERENCE_FLOW,
          )?.reference_flow;
          const workflowNodes = reference_flow
            ? state.workflowNodes?.references[reference_flow]
            : state.workflowNodes;
          next =
            stepState?.status === "Success"
              ? workflowNodes[referenceFlow.id]?.next?.success
              : workflowNodes[referenceFlow.id]?.next?.failure;
        }
        break;
      case NODE_TYPE.RADIO:
        const { answer } = options;
        if (answer) next = answer.next;
        break;
      case NODE_TYPE.CHECKBOX:
        const areEqual = (arrayOne, arrayTwo) => {
          return (
            arrayOne.length === arrayTwo.length &&
            arrayOne.filter((currVal, idx) => currVal !== arrayTwo[idx])
              .length === 0
          );
        };
        const { selected = [] } = options;
        const { results = [] } = stepState;
        const sortedSelection = selected.sort();
        let i = 0;
        while (i < results.length && next === null) {
          const sortedHandles = results[i].handle.sort();
          if (areEqual(sortedSelection, sortedHandles)) {
            next = results[i].next;
          }
          i++;
        }
        break;
      case NODE_TYPE.LOOP:
        next = stepState.next.iteration;
        _path = [..._path, { id: stepState.id, type: stepState.type }];
        break;
      case NODE_TYPE.IF:
        const { check } = options;
        const handle = stepState.next.find(
          ({ source, sourceHandle }) =>
            sourceHandle.split(`${source}_`)[1] === (check ? "0" : "1"),
        );
        if (handle) {
          next = handle.target;
        }
        break;
      case NODE_TYPE.REFERENCE_FLOW:
        const startNode = getReferenceFlowNodes(stepState.data.reference_flow)[
          "start-1"
        ];
        next = startNode?.next;
        _path = [
          ..._path,
          {
            id: stepState.id,
            type: stepState.type,
            reference_flow: stepState.data.reference_flow,
          },
        ];
        break;
      case NODE_TYPE.ESCALATE:
        const { selectedValue } = options;
        if (!stepState?.omitResolved) {
          if (!selectedValue) return null;
        }
        next =
          stepState.answers.find(
            ({ text, next }) =>
              (stepState.omitResolved && next) || text === selectedValue,
          )?.next ?? null;
        break;
      default:
        next = stepState?.next ?? null;
        break;
    }
    if (isInLoop(_path)) {
      const workflowNodes = reference_flow
        ? getReferenceFlowNodes(reference_flow)
        : state?.workflowNodes;
      // if item targets a node outside the loop break out of loop
      if (next) {
        const { parentNode } = workflowNodes[next];
        while (
          _path[_path.length - 1]?.type === NODE_TYPE.LOOP &&
          parentNode !== _path[_path.length - 1].id
        ) {
          nextIteration = {
            type: "end",
            id: _path[_path.length - 1].id,
          };
          _path = _path.slice(0, _path.length - 1);
        }
      }
      // if item has no next node specified (end is reached)
      // start next iteration or move to success step when all iterations complete
      else {
        nextIteration = {
          type: "end",
        };
        const handleLoopIteration = () => {
          if (!isInLoop(_path)) return;
          const { id } = _path[_path.length - 1];
          nextIteration.id = id;
          const state = getInstanceState({ path: _path });
          
          // Check if state is defined and contains the key 'id'
          if (state && state.hasOwnProperty(id)) {
            const { index, source } = state[id];
            if (index === source?.length - 1) {
              next = workflowNodes[id].next.success;
              if (!next) handleLoopIteration();
            } else {
              next = workflowNodes[id].next.iteration;
              nextIteration.type = "next";
            }
          } else {
            // Handle case where state[id] is undefined
            // Possibly log an error or handle this condition appropriately
            // For example, throw an error, return, or set a default value for `index` and `source`
            console.error(`State for id ${id} is undefined or does not exist.`);
          }
        };
        handleLoopIteration();
      }
    }
    dispatch(["iterate", nextIteration]);

    return next;
  };

  const handleNextStep = (status) => {
    const nextNode = state.flowState[sequence]?.next;
    const { flowPath, instanceState } = structuredClone(state);
    let _path = state.path;

    // handle reference flow
    if (referenceFlow && stepState?.type === NODE_TYPE.END) {
      _path = _path.slice(0, _path.length - 1);
    }

    // handle loop
    if (state.iterate) {
      switch (state.iterate.type) {
        case "end":
          _path = _path.slice(
            0,
            _path.findLastIndex(({ id }) => state.iterate.id === id),
          );
          break;
        case "next":
          _path = _path.slice(
            0,
            _path.findLastIndex(({ id }) => state.iterate.id === id) + 1,
          );
          const reference = getInstanceState({
            state: instanceState,
            path: _path.slice(0, _path.length - 1),
          });
          const { id } = _path[_path.length - 1];
          const { index, source } = reference[id];
          reference[id] = {
            ...reference[id],
            index: index + 1,
            item: source[index + 1],
          };
          break;
        default:
          break;
      }
    }

    const updatedState = {
      flowPath: [...flowPath, sequence],
      currentNode: nextNode,
      instanceData: instanceData,
      instanceState,
      iterate: null,
      status,
    };
    if (state.path.length !== _path.length) updatedState.path = _path;
    dispatch(["updateState", updatedState]);
  };

  const onNext = async (status = WORKFLOW_STATUS.IN_PROGRESS) => {
    setSequence(sequence + 1);
    //if current step is start, then get account number information
    handleStartStep();
    handleInputStep();
    handleApiStep();
    handleNextStep(status);
  };

  return (
    <FlowContext.Provider
      value={{
        state: { ...state },
        onPrevious,
        onNext,
        dispatch,
        restartFlow,
        sequence,
        setSequence,
        updateFlowStateStepData,
        debugMode,
        setDebugMode,
        accountNumber,
        setAccountNumber,
        iwTicketNumber,
        setIwTicketNumber,
        setInstanceData,
        lastInstanceData,
        setLastInstanceData,
        workflow,
        setWorkflow,
        workflowInstanceId,
        setWorkflowInstanceId,
        apiRunning,
        setApiRunning,
        getInstanceState,
        determineNextStep,
        referenceFlow,
        stepState,
        injectValue,
        techInfo,
        setTechInfo
      }}
    >
      <NetworkConnectionErrorModal />
      {children}
    </FlowContext.Provider>
  );
}

function useFlowState() {
  return useContext(FlowContext);
}

export { FlowProvider, useFlowState };
