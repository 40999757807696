import React, {useState, useEffect} from 'react';
import html2canvas from 'html2canvas';
import { postFrontendErrorLogs, sendFlowState, sendScreenShot, updateWorkflowInstanceV1 } from 'api';
import { useFlowState } from 'contexts/flow';

const ScreenShot = ({iwTicket, workflowInstanceId}) => {
    const [currentNodeLocal, setCurrentNodeLocal] = useState('');

    const {state, referenceFlow} = useFlowState();
    const {currentNode, workflow } = state;
    
    const updateWorkflowInstanceStep = async() => {
        try{
          await updateWorkflowInstanceV1(workflowInstanceId, {
            node: currentNode,
            workflow: referenceFlow?.id || workflow?.id,
            wid: workflowInstanceId
          });
        }catch(error){
          console.log(error)
          return
        }
    }

    async function sendScreenShotLocal(payload){
        try{
            await sendScreenShot(payload?.workflowInstanceId, payload);
        }catch(error){
            postFrontendErrorLogs(error)
        }
    }

    async function captureScreenShot(){
        return new Promise((resolve) => {
            html2canvas(document.getElementById('root')).then((canvas) => {
                const screenShotData = canvas.toDataURL('image/png');
                resolve(screenShotData)
            })
        })
    }


    async function captureAndSendScreenShot(){
        try{
            if(currentNodeLocal !== currentNode){
                const screenShotData = await captureScreenShot();
                await sendScreenShotLocal({
                    imgData: screenShotData,
                    workflowInstanceId,
                    iwTicket
                });
                setCurrentNodeLocal(state?.currentNode)
            }   

            }catch(error){
                postFrontendErrorLogs(error)
                return false
        }
    }

    async function sendFlowData(){
        try{
            // await sendFlowState(state)
        }catch(error){
            postFrontendErrorLogs(error)
            return false
        }
    }

    useEffect(() => {
        captureAndSendScreenShot();
        sendFlowData();
        updateWorkflowInstanceStep();
    }, [currentNode])

    return <></>
}

export default ScreenShot