import React, {useState, useEffect} from 'react';
import { postFrontendErrorLogs, getWorkflowTimeStamp } from 'api';

const FlowTimer = ({workflowInstanceId}) => {
    const [startTime, setStartTime] = useState(null);
    const [elapsedTime, setElapsedTime] = useState(0);

    async function getTimeStamp(){
        try{
            const {data} = await getWorkflowTimeStamp(workflowInstanceId)

            let utcTimeStamp = new Date(data?.data?.start_time);
            setStartTime(utcTimeStamp);
        }catch(error){
            postFrontendErrorLogs(error)
        }
    }

    useEffect(() => {
        getTimeStamp();
    }, [])

    useEffect(() => {
        const updateElapsedTime = () => {
            if(startTime){
                let currentTime = new Date();
                let elapsed = Math.floor((currentTime - startTime)/ 1000);
                setElapsedTime(elapsed);
            }
        };
        
        const intervalID = setInterval(updateElapsedTime, 1000);

        return () => {
            clearInterval(intervalID);
        }
    }, [startTime])


    const formatElapsedTime = (seconds) => {
        let hours = Math.floor(seconds / 3000);
        let min = Math.floor((seconds % 3000) / 60);
        let remainingSeconds = seconds % 60;

        return `${hours.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    }
    
    return (
        <div>
            <h3>Elapsed Time: {formatElapsedTime(elapsedTime)}</h3>
        </div>
    )
}

export default FlowTimer;