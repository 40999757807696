import { Fragment } from "react";
import { FILE_TYPES, NODE_TYPE } from "./constants";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const formatErrorMessage = (error) => {
  return error?.message ?? error;
};

export function formatWorkflow(
  workflow,
  initialGraph = {},
  referenceFlowId = null,
) {
  const { nodes, edges } = workflow;
  const graph = structuredClone(initialGraph);
  nodes.forEach((node) => {
    const { data } = node;
    let edge = null;
    let base = graph;
    if (referenceFlowId) {
      if (!graph.references?.[referenceFlowId])
        graph.references = {
          ...(graph.references || {}),
          [referenceFlowId]: {},
        };
      base = graph.references[referenceFlowId];
    }
    switch (node.type) {
      case NODE_TYPE.LOOP:
        let startingNode = node.data?.starting_node;
        edge = edges.find(
          (e) => e.source === node.id && e.sourceHandle === `${node.id}_0`,
        );
        if (!startingNode) {
          const children = nodes.filter((n) => n.parentNode === node.id);
          startingNode = children.find(
            (child) => !edges.some((edge) => edge.target === child.id),
          )?.id;
        }
        base[node.id] = {
          id: node.id,
          type: node.type,
          data: node.data,
          next: {
            iteration: startingNode ?? null,
            success: edge ? edge.target : null,
          },
        };
        break;
      case NODE_TYPE.REFERENCE_FLOW:
        const successEdge = edges.find(
          (e) => e.source === node.id && e.sourceHandle === `${node.id}_0`,
        );
        const failureEdge = edges.find(
          (e) => e.source === node.id && e.sourceHandle === `${node.id}_1`,
        );

        base[node.id] = {
          id: node.id,
          type: node.type,
          data: node.data,
          next: {
            success: successEdge?.target,
            failure: failureEdge?.target,
          },
        };
        break;
      case NODE_TYPE.API:
        base[node.id] = {
          id: node.id,
          type: node.type,
          apis: [
            {
              name: data.name,
              endpoint: data.endpoint,
              arguments: data.arguments,
              parameters: data.parameters,
            },
          ],
          results: data.results.map((result, idx) => {
            const edge = edges.find(
              (e) =>
                e.source === node.id && e.sourceHandle === `${node.id}_${idx}`,
            );
            return {
              ...result,
              status: Number(result.status),
              success: result.success === "true",
              next: edge ? edge.target : null,
            };
          }),
        };
        break;

      case NODE_TYPE.CHECKBOX:
        base[node.id] = {
          id: node.id,
          question: data.question,
          type: node.type,
          answers: data.answers,
          results: data.handles.map(({ handle }, idx) => {
            const edge = edges.find(
              (e) =>
                e.source === node.id && e.sourceHandle === `${node.id}_${idx}`,
            );
            return {
              handle,
              next: edge ? edge.target : null,
            };
          }),
          images: data.images,
        };
        break;
      case NODE_TYPE.ESCALATE:
        base[node.id] = {
          id: node.id,
          type: node.type,
          failure: `${data?.failureGroup} - ${data?.failure}`,
          answers: [
            { text: "Yes", next: null },
            { text: "No", next: null },
          ].map((answer, idx) => {
            const edge = edges.find(
              (e) =>
                e.source === node.id && e.sourceHandle === `${node.id}_${idx}`,
            );
            if (edge) {
              return {
                ...answer,
                next: edge.target,
              };
            }
            return answer;
          }),
          omitResolved: data?.omitResolved,
        };
        break;
      case NODE_TYPE.END:
        base[node.id] = {
          id: node.id,
          type: node.type,
          status: data.status,
          message: data.message,
        };
        break;
      case NODE_TYPE.IF:
        edge = edges.filter((e) => e.source === node.id);
        base[node.id] = {
          id: node.id,
          type: node.type,
          statements: data.conditions,
          next: edge,
        };
        break;
      case NODE_TYPE.INPUT:
        edge = edges.find(
          (e) => e.source === node.id && e.sourceHandle === `${node.id}_0`,
        );
        base[node.id] = {
          id: node.id,
          type: node.type,
          heading: data.heading,
          fields: data.fields,
          next: edge ? edge.target : null,
          images: data.images,
        };
        break;
      case NODE_TYPE.INSTRUCTIONAL:
        edge = edges.find(
          (e) => e.source === node.id && e.sourceHandle === `${node.id}_0`,
        );
        base[node.id] = {
          id: node.id,
          type: node.type,
          heading: data.heading,
          instruction: data.instruction,
          next: edge ? edge.target : null,
          images: data.images,
        };
        break;
      case NODE_TYPE.RADIO:
        base[node.id] = {
          id: node.id,
          question: data.question,
          type: node.type,
          answers: data.answers.map((answer, idx) => {
            const edge = edges.find(
              (e) =>
                e.source === node.id && e.sourceHandle === `${node.id}_${idx}`,
            );
            if (edge) {
              return {
                ...answer,
                next: edge.target,
              };
            }
            return answer;
          }),
          images: data.images,
        };
        break;
      case NODE_TYPE.START:
        edge = edges.find((e) => e.source === node.id);
        base[node.id] = {
          id: node.id,
          type: node.type,
          fields: [data],
          next: edge ? edge.target : null,
        };
        break;
      case NODE_TYPE.WAIT:
        edge = edges.find((e) => e.source === node.id);
        base[node.id] = {
          id: node.id,
          type: node.type,
          delay: Number(data.timer) || 0,
          heading: data.heading,
          next: edge ? edge.target : null,
          images: data.images,
        };
        break;
      default:
        base[node.id] = {
          id: node.id,
          type: node.type,
        };
        break;
    }
    base[node.id].info = data?.info;
    if (node.parentNode) base[node.id].parentNode = node.parentNode;
  });

  return graph;
}

export function convertArrayToObject(
  arr,
  keyField = "key",
  valueField = "value",
) {
  return arr.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item[keyField]]: (item[valueField] || "").toString(),
      }),
    {},
  );
}

export const getStateByPath = (state, path) => {
  let reference = state;
  if (path?.length > 0) {
    path.forEach(({ id, type }) => {
      switch (type) {
        case NODE_TYPE.REFERENCE_FLOW:
          reference = reference[id];
          break;
        case NODE_TYPE.LOOP:
          const { index, data } = reference[id];
          if (!data[index]) data[index] = {};
          if(typeof(data[index]) === 'object' && !Object.keys(data[index]).length){
            reference = reference
            break
          }
          reference = data[index];
          break;
        default:
          break;
      }
    });
  }
  return reference;
};

const getValue = (string, obj) =>
  string.split(".").reduce(function (a, b) {
    return a ? a[b] : undefined;
  }, obj);

  export const injectValue = (str, obj, path = [], form_schema) => {
    try{
    if (!str || !obj) return str;
    const data = getStateByPath(obj, path);
    return str
      .replace(/\[\d\]/g, (match) => "." + match.slice(1, match.length - 1))
      .replace(/\${(.*?)}/g, function (x, g) {
        const value = getValue(g, data) ?? getValue(g, obj);
        if (typeof value === "object") {
          if (value?.constructor == Array) {
            return JSON.stringify(value);
          }
          if (/^api-\d+.response/.test(g)) {
            const keys = Object.keys(value);
            if (keys.length === 1 && /^(success|failure)(\d+)?$/.test(keys[0])) {
              return value[keys[0]];
            }
          }
          if (!form_schema?.[g]) {
            return JSON.stringify(value);
          }
  
          const { fields } = form_schema[g];
          const keys = Object.keys(fields);
          let result = "";
  
          keys.forEach((key, index) => {
            const isLastIndex = index === keys.length - 1;
  
            const field = fields[key];
            const label = field.label;
            let selectOptionsLabels = "";
            if (field.selectOptions) {
              selectOptionsLabels = field.selectOptions
                .map((selectOpt) => selectOpt.label)
                .join(", ");
            }
  
            if (selectOptionsLabels.length > 0 && field.selectOptions) {
              result += `${label}: ${selectOptionsLabels} `;
            } else {
              result += `${label} ${isLastIndex ? " " : ", "}`;
            }
          });
  
          return result;
        }
  
        if (typeof value !== "undefined")
          return /\${(.*?)}/.test(value)
            ? injectValue(value, obj, path, form_schema)
            : value;
        // inject values in loop nodes for current item
        // eg: for ${loop-1.radio-1.answer}
        // loopId = "loop-1", rest = "radio-1.answer"
        // this will return the value of data[loopId].data[index].radio-1.answer
        let { loopId, rest } =
          g?.match(/^(?<loopId>loop-\w+)\.(?<rest>.*)$/)?.groups ?? {};
        if (
          g.startsWith("item") &&
          path?.length > 0 &&
          path[path.length - 1].type === NODE_TYPE.LOOP
        ) {
          loopId = path[path.length - 1].id;
          rest = g;
        }
        if (loopId) {
          const pathIndex = path.findLastIndex(({ id }) => id === loopId);
          if (pathIndex === -1) return x;
          const _data = getStateByPath(obj, path.slice(0, pathIndex));
          const { data: loopData, index, item } = _data[loopId];
          let loopValue = str;
          if (rest.startsWith("item")) {
            if (rest.split(/^item\./).length === 1) return item;
            const str = "${" + rest.split(/^item\./)[1] + "}";
            loopValue = injectValue(str, item);
          } else {
            const str = "${" + rest + "}";
            loopValue = injectValue(str, loopData[index]);
          }
          return loopValue;
        }
        // root based variables eg: ${account_number}
        if (path.length > 0) {
          return injectValue(str, obj);
        }
        return x;
      });
    }catch(error){
      console.log(error)
      return;
    }
  };

/**
 * Adds line breaks for each \n
 * @param {string} title
 */
export const formatTitle = (title) => {
  return title.split("\\n").map((str, idx) => (
    <Fragment key={idx}>
      {str}
      <br />
    </Fragment>
  ));
};

/**
 *  Remove the \n's from the string passed in
 * @param {string} title
 *
 * @returns string with \n's removed
 */
export const cleanTitle = (title) => {
  return title?.replace(/\\n/g, " ");
};

const USPS_REGEX = [
  "(94|93|92|94|95)[0-9]{20}",
  "(94|93|92|94|95)[0-9]{22}",
  "(70|14|23|03)[0-9]{14}",
  "(M0|82)[0-9]{8}",
  "([A-Z]{2})[0-9]{9}([A-Z]{2})",
  "[0-9]{30}",
];

const UPS_REGEX = [
  "(1Z)[0-9A-Z]{16}",
  "(T)+[0-9A-Z]{10}",
  "[0-9]{9}",
  "[0-9]{26}",
];

const FEDEX_REGEX = ["[0-9]{15}", "[0-9]{12}", "[9]{11}"];

const TRACKING_REGEX = [...USPS_REGEX, ...UPS_REGEX, ...FEDEX_REGEX];

export const USPS_PATTERN = USPS_REGEX.map((r) => new RegExp(`^${r}$`, "g"));
export const UPS_PATTERN = UPS_REGEX.map((r) => new RegExp(`^${r}$`, "g"));
export const FEDEX_PATTERN = FEDEX_REGEX.map((r) => new RegExp(`^${r}$`, "g"));
export const TRACKING_PATTERN = new RegExp(TRACKING_REGEX.join("|"), "g");

export const imageAbsoluteS3Path = (relativePath) =>
  `https://grteng-greenix-images.s3.amazonaws.com/${relativePath}`;

export const idGenerator = (len) => {
  let id = "";
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < len; i++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return id;
};

export const excelDateToDateTime = (serial) => {
  if (!serial) return null;
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(
    date_info.getUTCFullYear(),
    date_info.getUTCMonth(),
    date_info.getUTCDate(),
    hours,
    minutes,
    seconds,
  );
};

export const parseTime = (date) => {
  if (!date) return "";
  return `${date.getHours().toString().padStart(2, "0")}:${date
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;
};

export const parseDate = (date) => {
  if (!date) return "";
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
};

export const getESTDateTimeObj = () => {
  const eastern = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  return new Date(eastern);
};

export const getDayOfWeek = (dateString) => {
  var daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var date = new Date(dateString);
  var dayOfWeek = date.getDay();
  return daysOfWeek[dayOfWeek];
};

const fromPairs = (arr) => {
  let i = 0,
    len = arr.length,
    pair,
    obj = {};
  while (i < len) {
    pair = arr[i];
    obj[pair[0]] = pair[1];
    i += 1;
  }
  return obj;
};

export const toObject = (arr = [], getKey) =>
  fromPairs(arr.map((x) => [getKey(x), x]));

export const getAttachmentType = (attachment) => {
  if (typeof attachment === "string") {
    const { groups: { extension } = {} } =
      attachment.match(/^.*\.(?<extension>.*)$/) || {};
    return Object.entries(FILE_TYPES).find(([_, types]) =>
      types.includes(extension),
    )?.[0];
  } else if (attachment instanceof File) return attachment.type.split("/")[0];
  return undefined;
};

export const getAttachmentSrc = (attachment) =>
  typeof attachment === "string"
    ? imageAbsoluteS3Path(attachment)
    : attachment instanceof File
    ? URL.createObjectURL(attachment)
    : undefined;

/**
 * Formats the workflow if nodes to use new grouping data structure
 * ```json
 * {
    "type": "if",
    "name": "<node_name>",
    "conditions": [
        {
            "variable": "<variable_1>",
            "op": "EQUAL",
            "value": "<value_1>",
            "logicalOp": "AND"
        },
        {
            "variable": "<variable_2>",
            "op": "EQUAL",
            "value": "<value_2>",
            "logicalOp": "AND"
        },
        {
            "variable": "<variable_3>",
            "op": "EQUAL",
            "value": "<value_3>",
            "logicalOp": "OR"
        }
    ]
  }
  converts to:
  {
      "type": "if",
      "name": "<node_name>",
      "conditions": {
          "logicalOp": "OR",
          "rules": [
              {
                  "variable": "<variable_3>",
                  "op": "EQUAL",
                  "value": "<value_3>"
              },
              {
                  "logicalOp": "AND",
                  "rules": [
                      {
                          "variable": "<variable_1>",
                          "op": "EQUAL",
                          "value": "<value_1>"
                      },
                      {
                          "variable": "<variable_2>",
                          "op": "EQUAL",
                          "value": "<value_2>"
                      }
                  ]
              }
          ]
      }
  }
 * ```
 */
// TODO: remove formatWorkflowIfNodes once if nodes are migrated to new setup
export const formatWorkflowIfNodes = (workflow) => ({
  ...workflow,
  flow_tree: {
    ...workflow.flow_tree,
    nodes: workflow.flow_tree?.nodes.map((node) => {
      if (node.type !== NODE_TYPE.IF || !Array.isArray(node.data.conditions))
        return node;

      const hasOR = node.data.conditions.filter(
        ({ logicalOp }) => logicalOp === "OR",
      ).length;
      if (!hasOR)
        return {
          ...node,
          data: {
            ...node.data,
            conditions: {
              logicalOp: "AND",
              rules: node.data.conditions.map(({ variable, op, value }) => ({
                variable,
                op,
                value,
              })),
            },
          },
        };
      const ruleGroups = node.data.conditions.reduce((acc, condition) => {
        const { variable, op, value, logicalOp } = condition;
        const rule = { variable, op, value };
        if (logicalOp === "AND") {
          if (!Array.isArray(acc[acc.length - 1])) acc.push([rule]);
          else acc[acc.length - 1].push(rule);
        } else acc.push(rule);
        return acc;
      }, []);
      return {
        ...node,
        data: {
          ...node.data,
          conditions: {
            logicalOp: "OR",
            rules: ruleGroups.map((ruleGroup) => {
              if (Array.isArray(ruleGroup) && ruleGroup.length > 1)
                return {
                  logicalOp: "AND",
                  rules: ruleGroup,
                };
              else return ruleGroup;
            }),
          },
        },
      };
    }),
  },
});
