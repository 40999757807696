import withFlowState from "components/hoc/withFlowState";
import React, { useState, useEffect } from "react";
import { NODE_TYPE } from "utils/constants";
import { checkIfStatements } from "utils/helpers";
import FlowStep from "../FlowStep";

const IfElseStep = (props) => {
  const {
    state,
    updateFlowStateStepData,
    onNext,
    determineNextStep,
    stepState,
  } = props;
  const { currentNode, instanceState, path } = state;
  const [nodeId, setNodeId] = useState(null);

  useEffect(() => {
    const check = checkIfStatements(stepState.statements, instanceState, path);
    setNodeId(currentNode);
    stepState.next.sort((a, b) => {
      const nameA = a.sourceHandle.toUpperCase();
      const nameB = b.sourceHandle.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: NODE_TYPE.IF,
      next: determineNextStep({ check }),
      state: check,
    });
  }, [currentNode]);

  useEffect(() => {
    if (
      nodeId &&
      Object.values(state.flowState).find((x) => x.nodeId === nodeId)
    ) {
      onNext();
    }
  }, [state.flowState, nodeId]);

  return <FlowStep data={stepState} />;
};

export default withFlowState(IfElseStep);
