import React, { useEffect } from "react";
import withFlowState from "components/hoc/withFlowState";
import FlowStep from "../FlowStep";
import { Button } from "components/common";
import { NODE_TYPE, WORKFLOW_STATUS } from "utils/constants";

const StartStep = (props) => {
  const {
    state,
    updateFlowStateStepData,
    onNext,
    restartFlow,
    lastInstanceData,
    accountNumber,
    iwTicketNumber,
    setAccountNumber,
    setSequence,
    setInstanceData,
    dispatch,
    determineNextStep,
    stepState,
  } = props;
  const { workflow, flowState,instanceState, dryRun } = state;

  useEffect(() => {
    if (Object.keys(flowState).length > 0) {
      onNext();
    }
  }, [flowState]);

  const continueFlow = () => {
    let newCurrentNode = null;
    if (lastInstanceData) {
      if (lastInstanceData?.current_state?.flowState) {
        const values = Object.values(
          lastInstanceData?.current_state?.flowState,
        );
        newCurrentNode =
          values[values.length - 1].next || values[values.length - 1].nodeId;
        const keys = Object.keys(lastInstanceData.current_state.flowState);
        setSequence(Number(keys[keys.length - 1]) + 1);
      } else {
        const startNode = lastInstanceData.flow_tree.edges.find(
          (element) => element.source === "start-1",
        );
        newCurrentNode = startNode["target"];
        setSequence(1);
      }
      setAccountNumber(lastInstanceData.account_number);
      setInstanceData({ ...lastInstanceData?.instance_data });

      let path = [];
      const keys = Object.keys(
        lastInstanceData?.current_state?.flowState ?? {},
      );

      if (!!keys.length) {
        path =
          lastInstanceData?.current_state?.flowState[keys[keys.length - 1]]
            ?.path ?? [];
      }

      dispatch([
        "updateState",
        {
          instanceState: {
            ...lastInstanceData?.form_data,
            ...(lastInstanceData?.current_state?.instanceState || {}),
            ...state.instanceState,
          },
          instanceData: lastInstanceData
            ? {
                ...lastInstanceData?.instance_data,
              }
            : state.instanceData,
          flowPath: lastInstanceData
            ? Object.keys(lastInstanceData?.current_state?.flowState || {}).map(
                (key) => Number(key),
              )
            : state.flowPath,
          flowState: lastInstanceData
            ? { ...(lastInstanceData.current_state?.flowState || {}) }
            : state.flowState,
          currentNode: newCurrentNode || state.currentNode,
          path,
        },
      ]);
    }
  };

  const onRestart = async () => {
    await restartFlow(lastInstanceData);
    updateFlowStateStepData(
      {
        nodeId: NODE_TYPE.START,
        nodeType: NODE_TYPE.START,
        next: determineNextStep(),
        state: { account: instanceState["account_number"] },
      },
      WORKFLOW_STATUS.IN_PROGRESS,
      new Date().toUTCString(),
    );
  };

  const startFlow = () => {
    updateFlowStateStepData(
      {
        nodeId: NODE_TYPE.START,
        nodeType: NODE_TYPE.START,
        next: determineNextStep(),
        state: { account: instanceState["account_number"] },
      },
      WORKFLOW_STATUS.IN_PROGRESS,
      new Date().toUTCString(),
    );
  };


  return (
    <>
      <FlowStep data={stepState}>
        <h1 className="mb-6 min-h-[6rem] sm:mb-8 sm:min-h-0 sm:text-lg">
          {lastInstanceData?.status !== "Scheduled" &&
          Object.keys(lastInstanceData).length > 0 ? (
            <span>
              A workflow for Field Service Ticket Number{" "}
              <span className="font-bold">{iwTicketNumber}</span> and account
              number <span className="font-bold">{accountNumber}</span> is
              already in progress. Please click "Restart" to start from the
              beginning, or "Continue" to proceed with the turn up in progress.
            </span>
          ) : (
            <span>
              {`You're about to start a turn up flow for ${workflow?.name}. Please click "Start"
            to begin.`}
            </span>
          )}
        </h1>
      </FlowStep>
      <div className="flex flex-row items-center py-4 sm:py-8">
        {lastInstanceData?.status !== "Scheduled" &&
        Object.keys(lastInstanceData).length > 0 &&
        !dryRun ? (
          <>
            {/* mobile */}
            <div className="flex m-1 mt-0 justify-between w-full gap-5 text-secondary-text sm:hidden">
              {/* <Button
                variant="secondary"
                className="flex-grow cursor-default !rounded-full dark:bg-transparent !border-blue-600"
                onClick={onRestart}
              >
                <span>Restart</span>
              </Button> */}
              <Button
                variant="primary"
                className="flex-grow cursor-default !rounded-full !bg-[#3B82F6] text-white dark:bg-transparent dark:text-white"
                onClick={continueFlow}
              >
                <span>Resume where you left off</span>
              </Button>
            </div>
            {/* desktop */}
            <div className="hidden sm:flex">
              {/* <Button
                type="button"
                variant="secondary"
                className="p-2"
                onClick={onRestart}
              >
                <span>Restart</span>
              </Button> */}
              <Button
                type="button"
                variant="primary"
                className="ml-3 p-2"
                onClick={continueFlow}
              >
                <span>Resume where you left off</span>
              </Button>
            </div>
          </>
        ) : (
          <Button
            type="button"
            variant="primary"
            className="p-2"
            onClick={startFlow}
          >
            <span>Start</span>
          </Button>
        )}
      </div>
    </>
  );
};

export default withFlowState(StartStep);
