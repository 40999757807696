import React, { useEffect, useState } from "react";
import { useFieldArray,useWatch } from "react-hook-form";
import { TrashIcon } from "@heroicons/react/24/outline";
import FormInput from "../FormInput";
import Label from "../Label";
import Button from "components/common/Button";
import { classNames } from "utils";

function vmailRuleHunt(line, huntInfo){
  if(line?.serviceName === 'voice' && !line?.call_forward_number){
    return false;
  }

  if(line?.serviceName === 'hunt' && !line?.call_forward_number){
    return line?.hunt_order === 'A' ? false : huntInfo[line?.hunt_group]
  }

  return true
}

const InputArray = ({
  name,
  label,
  error,
  register,
  onReset,
  control,
  watch,
  isValidating,
  fields = {},
  dynamic = true,
  dynamicOptions,
  options = {},
  query,
  ...rest
}) => {
  const {
    fields: arrayFields,
    append,
    remove,
    replace,
  } = useFieldArray({
    control,
    name,
  });

  const defaultValue = Object.keys(fields).reduce((obj, key) => {
    obj[key] = undefined;
    return obj;
  }, {});

  const [watchedValues, setWatchedValues] = useState([]);
  const [errorState, setErrorState] = useState({});

  const results = useWatch({ control, name: "line" });

  useEffect(() => {
    const newErrorState = {};

    if (results && results.length > 0) {
      let firstHuntVM = {};

      for (let i = 0; i < results.length; i++) {
        const line = results[i];

        if(line?.serviceName === "hunt" && line?.hunt_order === "A"){
          console.log(line?.voice_mail)
          firstHuntVM[line?.hunt_group] = line?.voice_mail;
        }
        newErrorState[`line[${i}].[porting_number]`] = line?.porting === 'New';
        newErrorState[`line[${i}].[modem_use]`] = line?.serviceName !== 'modem';
        newErrorState[`line[${i}].[hunt_order]`] = line?.serviceName !== 'hunt';
        newErrorState[`line[${i}].[hunt_type]`] = line?.serviceName !== 'hunt';
        newErrorState[`line[${i}].[hunt_group]`] = line?.serviceName !== 'hunt';
        newErrorState[`line[${i}].[call_forward_number]`] = line?.serviceName === 'voice' ? line?.voice_mail || line?.call_waiting : true;
        newErrorState[`line[${i}].[call_waiting]`] = line?.serviceName === 'voice' ? line?.call_forward_number : true;
        newErrorState[`line[${i}].[caller_id_name]`] = !['voice', 'hunt'].includes(line?.serviceName);
        newErrorState[`line[${i}].[caller_id_override]`] = !['voice', 'hunt'].includes(line?.serviceName);
        newErrorState[`line[${i}].[port_e911]`] = !['voice', 'hunt'].includes(line?.serviceName);
        newErrorState[`line[${i}].[port_e911_address]`] = ['voice', 'hunt'].includes(line?.serviceName) ? line?.port_e911 === 'false' : true;
        newErrorState[`line[${i}].[port_e911_city]`] = ['voice', 'hunt'].includes(line?.serviceName) ? line?.port_e911 === 'false' : true;
        newErrorState[`line[${i}].[port_e911_name]`] = ['voice', 'hunt'].includes(line?.serviceName) ? line?.port_e911 === 'false' : true;
        newErrorState[`line[${i}].[port_e911_state]`] = ['voice', 'hunt'].includes(line?.serviceName) ? line?.port_e911 === 'false' : true;
        newErrorState[`line[${i}].[port_e911_zip]`] = ['voice', 'hunt'].includes(line?.serviceName) ? line?.port_e911 === 'false' : true;
        newErrorState[`line[${i}].[voice_mail]`] = vmailRuleHunt(line, firstHuntVM)
      }
    }

    setErrorState((prevState) => {
      return {...prevState, ...newErrorState}
    })

  }, [results]);

  useEffect(() => {
    if (arrayFields.length === 0) {
      replace([defaultValue]);
    }
  }, [arrayFields]);

  const addColumn = () => {
    append(defaultValue);
  };

  const removeColumn = (idx) => {
    remove(idx);
    if (arrayFields.length === 1) onReset?.();
  };

  useEffect(() => {
    if (watch) {
      setWatchedValues(watch(name));
    }
  }, [isValidating, addColumn]);

  const getSelectOptions = (
    filter,
    options,
    name,
    fields,
    watchedValues,
    idx,
  ) => {
    if (!filter || !watchedValues) return options;

    if (fields[name].filter) {
      const { filterFieldName } = fields[name];
      const option = fields[filterFieldName]?.selectOptions?.find(
        (x) => x.value === watchedValues?.[idx]?.[filterFieldName],
      );
      if (option) {
        const filteredOptions = fields[name].filter(option.type);
        const selectedOption = filteredOptions?.find(
          (x) => x.value === watchedValues?.[idx]?.[name],
        );

        return [
          selectedOption || { value: "", label: "Choose ..." },
          ...filteredOptions.filter(
            (opt) => opt.value !== selectedOption?.value,
          ),
        ];
      }
    }

    return options;
  };

  return (
    <div className="space-y-2">
      {label && (
        <Label name={name} label={label} error={error} options={options} />
      )}
        <div className="flex justify-end mb-2">
    {dynamic && (
      <Button
        variant="secondary"
        size="small"
        onClick={addColumn}
      >
        + Add Line
      </Button>
    )}
  </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 z-20">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Values
              </th>
              {arrayFields.map((_, idx) => (
                <th
                  key={idx}
                  scope="col"
                  className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <div className="flex justify-between items-center">
                    <span>Line {idx + 1}</span>
                    {dynamic && (
                      <Button
                        variant="secondary"
                        size="small"
                        onClick={() => removeColumn(idx)}
                      >
                        <TrashIcon className="h-5 w-5 text-red-500" />
                      </Button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {Object.keys(fields).map((key) => (
              <tr key={key}>
                <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium text-gray-900">
                  {fields[key].label}
                </td>
                {arrayFields.map((field, idx) => (
                  <td key={`${field.id}-${key}`} className="px-2 py-2 whitespace-nowrap text-left">
                    <FormInput
                      {...rest}
                      register={register}
                      control={control}
                      name={`${name}[${idx}].[${key}]`}
                      type={fields[key].type}
                      placeholder={fields[key].placeholder || fields[key].label}
                      selectOptions={getSelectOptions(
                        fields[key].filter,
                        dynamicOptions?.[key] || fields[key].selectOptions,
                        key,
                        fields,
                        watchedValues,
                        idx,
                      )}
                      error={
                        fields[key].error
                          ? fields[key].error[idx]?.[key]?.message
                          : error
                      }
                      query={query}
                      disabled={errorState[`${name}[${idx}].[${key}]`]}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InputArray;
