import React, { useState, useEffect } from "react";
import withFlowState from "components/hoc/withFlowState";
import { formatTitle } from "utils";
import { NODE_TYPE } from "utils/constants";
import FlowStep from "../FlowStep";
import TechInfo from "../TechInfo";
import { useAuthState } from "contexts/auth";

const WaitStep = (props) => {
  const {
    state,
    updateFlowStateStepData,
    determineNextStep,
    stepState,
    injectValue,
    onAttachmentClickHandler
  } = props;
  const { workflowNodes, instanceState, currentNode } = state;

  const {user} = useAuthState();

  const [techInfo, setTechInfo] = useState(null);
  const [seconds, setSeconds] = useState(stepState?.delay || 0);
  const skipTimer =
    process.env.REACT_APP_ENV === "development" ||
    process.env.REACT_APP_ENV === "uat" ||
    (user && user?.username !== process.env.REACT_APP_FIELD_TECH)

  const timer = () => setSeconds(seconds - 1);
  useEffect(() => {
    if (seconds > 0) {
      const id = setInterval(timer, 1000);
      return () => clearInterval(id);
    } else {
      updateFlowStateStepData({
        nodeId: currentNode,
        nodeType: NODE_TYPE.WAIT,
        next: determineNextStep(),
      });
    }
  }, [seconds]);

  useEffect(() => {
    // this is to fix next button being pressable when coming back to this node from previous step
    const next = skipTimer ? determineNextStep() : null;
    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: NODE_TYPE.WAIT,
      next,
    });
    setTechInfo(
      Object.values(instanceState)?.filter(
        (value) => value?.tech_name && value?.tech_phone,
      ),
    );
  }, [currentNode, workflowNodes]);

  return (
    <FlowStep data={stepState} onAttachmentClickHandler={onAttachmentClickHandler}>
      <h1 className="mb-6 min-h-[6rem] sm:mb-8 sm:min-h-0 sm:text-lg">
        {formatTitle(injectValue(stepState?.heading || ""))}
      </h1>
      <div className="space-y-2">{seconds || 'Please click the next button to continue installation'}</div>
      <TechInfo techInfo={techInfo} />
    </FlowStep>
  );
};

export default withFlowState(WaitStep);
