import React from "react";

const Table = ({ columns, rows, onSort, sortKey, sortDirection }) => {
  const handleSort = (key) => {
    if (onSort) onSort(key); // Trigger the sorting callback in the parent component
  };

  return (
    <table className="w-full divide-y divide-gray-300 dark:divide-gray-300/30">
      <thead className="sticky top-0 z-20 bg-background-secondary dark:bg-background-secondary-dark">
        <tr>
          {columns.map((column, idx) => (
            <TableHeaderCell
              key={idx}
              isSortable={!!column.sortable} // Check if the column is sortable
              onSort={() => handleSort(column.key)} // Handle sort when clicked
              isSorted={sortKey === column.key} // Check if this column is the currently sorted column
              sortDirection={sortDirection} // Pass down the sorting direction
            >
              {column.title}
            </TableHeaderCell>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-300 bg-background-primary dark:divide-gray-300/30 dark:bg-background-primary-dark">
        {rows.map((row, idx) => (
          <tr
            key={idx}
            className="border-b border-gray-300 last:border-b-0 dark:border-gray-300/30"
          >
            {columns.map((column, idx) => (
              <TableCell
                classNames={`${
                  column.key === "workflows_description"
                    ? "truncate max-w-[10rem]"
                    : ""
                }`}
                key={idx}
              >
                {column.render ? column.render(row) : row[column.key]}
              </TableCell>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;

export const TableHeaderCell = ({
  children,
  isSortable,
  onSort,
  isSorted,
  sortDirection,
}) => {
  return (
    <th
      scope="col"
      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-header dark:text-header-dark cursor-pointer"
      onClick={isSortable ? onSort : null}
    >
      <div className="flex items-center">
        {children}
        {isSortable && (
          <span className="ml-2">
            {isSorted ? (
              sortDirection === "asc" ? (
                <span>&uarr;</span> // Up arrow for ascending
              ) : (
                <span>&darr;</span> // Down arrow for descending
              )
            ) : (
              <span>&#x21C5;</span> // Arrow indicating sortable but not sorted
            )}
          </span>
        )}
      </div>
    </th>
  );
};

export const TableCell = ({ classNames, children }) => {
  return (
    <td
      className={`whitespace-nowrap px-2 py-2 text-sm text-header dark:text-header-dark ${classNames}`}
    >
      {children}
    </td>
  );
};
