import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import withFlowState from "components/hoc/withFlowState";
import FormInput from "components/common/form/FormInput";
import { formatTitle } from "utils";
import { NODE_TYPE } from "utils/constants";
import FlowStep from "../FlowStep";
import TechInfo from "../TechInfo";

const InputStep = (props) => {
  const [techInfo, setTechInfo] = useState(null);
  const {
    state,
    updateFlowStateStepData,
    sequence,
    determineNextStep,
    stepState,
    injectValue,
    onAttachmentClickHandler
  } = props;
  const { workflowNodes, flowState, instanceState, currentNode } = state;

  const { control, register, setValue, watch, reset, formState } = useForm({
    mode: "onChange",
  });

  const { isValid, isValidating, errors } = formState;

  useEffect(() => {
    setTechInfo(
      Object.values(instanceState)?.filter(
        (value) => value?.tech_name && value?.tech_phone,
      ),
    );
    reset();
    const allowedMultipleEntries = stepState?.fields.reduce((acc, values) => {
      return {
        ...acc,
        [values.name]: values.allowMultipleEntries,
      };
    }, {});
    Object.keys(flowState[sequence]?.state || {}).forEach((key) => {
      // for multiple entries because we alter the structure of the state to make it an array of values,
      // to set values we need to build back an array of objects instead of an array of values
      let valuesToSet;
      if (allowedMultipleEntries[key] === "Yes") {
        valuesToSet = flowState[sequence]?.state[key].reduce((acc, values) => {
          return [...acc, { [key]: values }];
        }, []);
      }
      setValue(
        key,
        valuesToSet ? valuesToSet : flowState[sequence]?.state[key],
      );
    });
  }, [currentNode, workflowNodes]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const values =
        value &&
        Object.entries(value).reduce((accumulator, values) => {
          const valuesArray = [];
          // if a multiple entry input make the values one array instead of array of objects per ticket request
          if (typeof values[1] != "string") {
            values[1].forEach((obj) => {
              valuesArray.push(obj[Object.keys(obj)[0]]);
            });
          }
          return {
            ...accumulator,
            [values[0]]: valuesArray.length > 0 ? valuesArray : values[1],
          };
        }, {});
      updateFlowStateStepData({
        nodeId: currentNode,
        nodeType: NODE_TYPE.INPUT,
        state: { ...values },
      });
    });

    return () => subscription.unsubscribe();
  }, [watch, currentNode]);

  useEffect(() => {
    // changed from stepState to workflowNodes[currentNode]
    // when coming back from a previous step next is unclickable
    // until a field was edited
    updateFlowStateStepData({
      next: determineNextStep({ isValid }),
    });
  }, [isValid, isValidating]);

  return (
    <FlowStep data={stepState} onAttachmentClickHandler={onAttachmentClickHandler}>
      <h1 className="text-bold mb-6 min-h-[6rem] sm:mb-8 sm:min-h-0 sm:text-lg">
        {formatTitle(injectValue(stepState?.heading || ""))}
      </h1>
      <form className="space-y-4">
        <div className="flex flex-col items-center">
          <div className="w-full space-y-4 sm:w-1/2">
            {stepState &&
              stepState.fields.map((field, index) => {
                const options = { required: field.required === "Yes" };
                if (field.pattern)
                  options.pattern = {
                    value: new RegExp(field.pattern, "g"),
                    message: "Invalid input",
                  };
                if (field?.allowMultipleEntries === "Yes") {
                  const dynamicField = {
                    [field.name]: {
                      ...field,
                      options: { ...options },
                      error: { ...errors?.[field.name] },
                    },
                  };
                  return (
                    <div key={`${sequence}_${currentNode}_${index}`}>
                      <FormInput
                        register={register}
                        control={control}
                        name={field.name}
                        type={"array"}
                        fields={dynamicField}
                      />
                    </div>
                  );
                }
                return (
                  <div key={`${sequence}_${currentNode}_${index}`}>
                    <FormInput
                      register={register}
                      control={control}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                      options={options}
                      selectOptions={field.selectTypeOptions?.split("\n") || []}
                      error={errors?.[field.name]?.message}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </form>
      <TechInfo techInfo={techInfo} />
    </FlowStep>
  );
};

export default withFlowState(InputStep);
