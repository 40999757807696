import {
  applyEdgeChanges,
  applyNodeChanges,
  addEdge as _addEdge,
} from "reactflow";
import { GROUP_NODE_TYPES, NODE_TYPE } from "utils/constants";

export const getNextNodeID = (type, nodes) => {
  const nodesByType = nodes.filter((n) => n.type === type);
  nodesByType.sort((a, b) => {
    let idA = parseInt(a.id.substring(a.id.indexOf("-") + 1)),
      idB = parseInt(b.id.substring(b.id.indexOf("-") + 1));

    if (idA < idB) {
      return -1;
    }
    if (idA > idB) {
      return 1;
    }
    return 0;
  });

  if (nodesByType.length >= 1) {
    let id = nodesByType[nodesByType.length - 1].id;
    return `${type + "-" + (parseInt(id.substring(id.indexOf("-") + 1)) + 1)}`;
  } else {
    return `${type + "-" + 1}`;
  }
};

export const getNestedNodes = (nodes, nodeId) =>
  nodes.reduce((acc, n) => {
    if (n.parentNode === nodeId) {
      if (GROUP_NODE_TYPES.includes(n.type)) {
        acc = [...acc, ...getNestedNodes(nodes, n.id)];
      }
      acc = [...acc, n];
    }
    return acc;
  }, []);

/**
 *
 * @param {string} sourceHandle
 * @param {Object[]} nodes
 * @returns
 */
export const getLabel = (sourceHandle, nodes) => {
  const nodeId = sourceHandle.split("_")[0];
  const index = sourceHandle.split("_")[1];
  const node = nodes.find((node) => node.id === nodeId);
  const { data } = node;
  if (data) {
    switch (node.type) {
      case NODE_TYPE.RADIO:
        return data.answers[index]?.text.length > 40
          ? data.answers[index]?.text.slice(0, 40) + "..."
          : data.answers[index]?.text || "";

      case NODE_TYPE.CHECKBOX:
        return data.handles[index].handle[0].length > 40
          ? data.handles[index].handle[0].slice(0, 40) + "..."
          : data.handles[index].handle || [];

      case NODE_TYPE.API:
        return (
          (data.results[index]?.status || "") +
          " " +
          (data.results[index]?.success || "") +
          " " +
          (data.results[index]?.response || "")
        );

      case NODE_TYPE.START:
        return "Start >>";

      case NODE_TYPE.WAIT:
        return "Wait >>";

      case NODE_TYPE.INPUT:
        return "Input >>";

      case NODE_TYPE.INSTRUCTIONAL:
        return "Instruction >>";

      case NODE_TYPE.IF:
        return `${index === "0" ? "True" : "False"} >>`;

      default:
        return sourceHandle;
    }
  } else return sourceHandle;
};

export const deleteNodes = (nodes, nodesToDelete) =>
  applyNodeChanges(
    nodesToDelete.map(({ id }) => ({ id, type: "remove" })),
    nodes,
  );

export const deleteEdges = (edges, edgesToDelete) =>
  applyEdgeChanges(
    edgesToDelete.map(({ id }) => ({ id, type: "remove" })),
    edges,
  );

// applyNodeChanges adds nodes at the start of the array
// group nodes should always be set before their children
export const addNode = (node, nodes) =>
  GROUP_NODE_TYPES.includes(node.id)
    ? applyNodeChanges([{ item: node, type: "add" }], nodes)
    : [...nodes, node];

export const addEdge = (edge, edges) => _addEdge(edge, edges);

export const removeSelectedNodeFromLoop = (nodes) => {
  const node = nodes.find((n) => n.selected);
  const { parentNode, extent, ...rest } = node;
  const parent = nodes.find(({ id }) => id === parentNode);

  // move the node position to be outside the loop
  const position = {
    x: parent.position.x + node.position.x,
    y: parent.position.y + node.position.y,
  };
  const offset = 20;
  let closest = "top";
  let diff = node.position.y;
  if (node.position.x < diff) {
    closest = "left";
    diff = node.position.x;
  }
  if (parent.position.y + parent.height - position.y - node.height < diff) {
    closest = "bottom";
    diff = parent.position.y + parent.height - position.y - node.height;
  }
  if (parent.position.x + parent.width - position.x - node.width < diff) {
    closest = "right";
    diff = parent.position.x + parent.width - position.x - node.width;
  }

  switch (closest) {
    case "top":
      position.y -= diff + node.height + offset;
      break;
    case "left":
      position.x -= diff + node.width + offset;
      break;
    case "bottom":
      position.y += diff + node.height + offset;
      break;
    case "right":
      position.x += diff + node.width + offset;
      break;
    default:
      break;
  }

  return nodes.map((n) =>
    n.id !== node.id
      ? n
      : {
          ...rest,
          position,
        },
  );
};

export const createLoopFromSelectedNodes = (nodes) => {
  // offset determines the "padding" the loop group will add around the selected nodes
  const offset = 80;
  const selectedNodes = nodes.filter((node) => node.selected).sort();
  const sortedNodes = selectedNodes.sort((a, b) =>
    b.position.y >= a.position.y ? -1 : 1,
  );
  const position = {
    min: {
      x: selectedNodes[0]?.position?.x,
      y: selectedNodes[0]?.position?.y,
    },
    max: {
      x: selectedNodes[0]?.position?.x,
      y: selectedNodes[0]?.position?.y,
    },
  };
  selectedNodes.forEach((node) => {
    // calculate min and max position for x axis
    if (node.position.x < position.min.x) {
      position.min.x = node.position.x;
    } else if (node.position.x + node.width > position.max.x) {
      position.max.x = node.position.x + node.width;
    }
    // calculate min and max position for y axis
    if (node.position.y < position.min.y) {
      position.min.y = node.position.y;
    } else if (node.position.y + node.height > position.max.y) {
      position.max.y = node.position.y + node.height;
    }
  });
  const id = getNextNodeID(NODE_TYPE.LOOP, nodes);
  const groupNode = {
    id,
    type: "loop",
    data: { label: null },
    position: {
      x: position.min.x - offset,
      y: position.min.y - offset,
    },
    style: {
      width: position.max.x - position.min.x + 2 * offset,
      height: position.max.y - position.min.y + 2 * offset,
    },
    dragHandle: ".drag-handle",
  };

  const selectedNodeIds = selectedNodes.map((node) => node.id);
  return [
    groupNode,
    ...nodes.filter(({ id }) => !selectedNodeIds.includes(id)),
    ...sortedNodes.map((node) => ({
      ...node,
      parentNode: node.parentNode ?? id,
      extent: "parent",
      // when inside a parent node child position is relative to parent
      position: node.parentNode
        ? node.position
        : {
            x: offset + node.position.x - position.min.x,
            y: offset + node.position.y - position.min.y,
          },
    })),
  ];
};

export const moveNodeIntoGroup = (node, groupNode, nodes) => {
  const offset = 20;

  // calculate new node position relative to parent
  const position = {
    x: Math.max(offset, node.position.x - groupNode.position.x),
    y: Math.max(offset, node.position.y - groupNode.position.y),
  };

  // resize parent if it's not large enough to contain new node
  let parentWidth = groupNode.width;
  let parentHeight = groupNode.height;
  if (groupNode.width < position.x + node.width + offset)
    parentWidth = Math.ceil(position.x + node.width + offset);
  if (groupNode.height < position.y + node.height + offset)
    parentHeight = Math.ceil(position.y + node.height + offset);
  const shouldResize =
    groupNode.width !== parentWidth || groupNode.height !== parentHeight;

  return nodes.map((n) => {
    if (n.id === node.id)
      return {
        ...n,
        parentNode: groupNode.id,
        extent: "parent",
        position,
      };
    if (n.id === groupNode.id && shouldResize)
      return {
        ...n,
        className: "",
        style: {
          width: parentWidth,
          height: parentHeight,
        },
      };
    return {
      ...n,
      className: "",
    };
  });
};

export const duplicateNode = (nodeId, nodes, edges) => {
  let nodeToDuplicate = nodes.find((currentNode) => currentNode.id === nodeId);
  let edgesToDuplicate = [];

  const newNodeProps = {
    id: getNextNodeID(nodeToDuplicate.type, nodes),
    position: {
      x: nodeToDuplicate.position.x,
      y: nodeToDuplicate.position.y + nodeToDuplicate.height + 50,
    },
  };

  const oldToNewIdsMap = { [nodeToDuplicate.id]: newNodeProps.id };

  let updatedNodes = addNode(
    { ...nodeToDuplicate, selected: false, ...newNodeProps },
    nodes,
  );
  let updatedEdges = edges;
  // duplicate nested nodes in case of group nodes
  if (GROUP_NODE_TYPES.includes(nodeToDuplicate.type)) {
    const nestedNodes = getNestedNodes(nodes, nodeToDuplicate.id);
    edgesToDuplicate = edges.filter(
      (edge) =>
        // select edges of nodes that have both target and source node selected
        nestedNodes.some((node) => node.id === edge.source) &&
        nestedNodes.some((node) => node.id === edge.target),
    );
    nestedNodes.forEach((node) => {
      const id = getNextNodeID(node.type, updatedNodes);
      oldToNewIdsMap[node.id] = id;
      edgesToDuplicate = edgesToDuplicate.map(({ id: _id, ...edge }) => ({
        ...edge,
        source: edge.source.replace(node.id, id),
        sourceHandle: edge.sourceHandle.replace(node.id, id),
        target: edge.target.replace(node.id, id),
        targetHandle: edge.targetHandle.replace(node.id, id),
      }));
      updatedNodes = addNode(
        {
          ...node,
          id,
        },
        updatedNodes,
      );
    });
    updatedNodes = updatedNodes.map((node) =>
      !Object.values(oldToNewIdsMap).includes(node.id)
        ? node
        : {
            ...node,
            parentNode: node.parentNode
              ? oldToNewIdsMap[node.parentNode]
              : undefined,
          },
    );
    if (edgesToDuplicate.length > 0)
      updatedEdges = edgesToDuplicate.reduce(
        (edges, edge) => addEdge(edge, edges),
        [...updatedEdges],
      );
  }
  return { updatedNodes, updatedEdges, oldToNewIdsMap };
};

export const pasteNodes = (
  text,
  selectedNodes,
  nodes,
  edges,
  workflow,
  defaultCenter,
) => {
  try {
    const {
      nodes: pastedNodes = [],
      edges: selectedEdges = [],
      workflow_id,
    } = JSON.parse(text);
    const newNodes = [];
    // filter out id from copied edges so a new one can be generated
    let newEdges = [...selectedEdges.map(({ id, ...rest }) => rest)];
    // top-left most position that is used as a reference to calculate the offset
    const referencePosition = {
      x: pastedNodes[0]?.positionAbsolute.x,
      y: pastedNodes[0]?.positionAbsolute.y,
    };
    pastedNodes.forEach((node) => {
      if (node.positionAbsolute.x < referencePosition.x)
        referencePosition.x = node.positionAbsolute.x;
      if (node.positionAbsolute.y < referencePosition.y)
        referencePosition.y = node.positionAbsolute.y;
    });

    let { parentNode } = pastedNodes.find(({ parentNode }) => parentNode) || {};
    // handle parentNode depending on:
    if (
      // source is a different workflow remove parentNode
      workflow.id !== workflow_id ||
      // no nodes are selected (clicked on the pane) remove parentNode
      selectedNodes.length === 0 ||
      // pasted nodes don't share the same parent remove parentNode
      (parentNode &&
        !pastedNodes.every(
          (node) =>
            node.parentNode === parentNode ||
            (GROUP_NODE_TYPES.includes(node.type) && node.id === parentNode),
        ))
    )
      parentNode = undefined;
    // a parent node is selected when pasting, paste the nodes inside the parent
    if (
      selectedNodes.length === 1 &&
      GROUP_NODE_TYPES.includes(selectedNodes[0].type)
    )
      parentNode = selectedNodes[0].id;

    const center =
      !defaultCenter || parentNode ? { x: 0, y: 0 } : defaultCenter;
    pastedNodes.forEach((node, index) => {
      if (node.type === NODE_TYPE.START) return;
      const position = {
        x: center.x + node.positionAbsolute.x - referencePosition.x,
        y: center.y + node.positionAbsolute.y - referencePosition.y,
      };
      const id = getNextNodeID(node.type, [...nodes, ...newNodes]);
      const _parentNode =
        node.parentNode &&
        newNodes.find(({ oldId }) => node.parentNode === oldId);
      const newNodeProps = {
        ...node,
        oldId: node.id,
        id,
        position: _parentNode ? node.position : position,
        selected: false,
        parentNode: _parentNode ? _parentNode.id : parentNode,
        extent: _parentNode || parentNode ? "parent" : undefined,
      };
      newNodes.push(newNodeProps);
      newEdges = newEdges.map((edge) => ({
        ...edge,
        source: edge.source.replace(node.id, id),
        sourceHandle: edge.sourceHandle.replace(node.id, id),
        target: edge.target.replace(node.id, id),
        targetHandle: edge.targetHandle.replace(node.id, id),
      }));
    });

    const updatedNodes = newNodes.reduce(
      (nodes, node) => addNode(node, nodes),
      [...nodes],
    );

    const updatedEdges =
      newEdges.length > 0
        ? newEdges.reduce((edges, edge) => addEdge(edge, edges), [...edges])
        : edges;

    return {
      updatedNodes,
      updatedEdges,
      newEdges,
    };
  } catch (error) {
    return;
  }
};

export const colorGraphCycleEdges = (cycle, initialEdges) => {
  let updatedEdges = initialEdges;
  const edgesToNodeIdMap = updatedEdges.reduce((map, edge) => {
    if (!map[edge.source]) map[edge.source] = [edge];
    else map[edge.source].push(edge);
    return map;
  }, {});
  let edges = [];
  for (let index = 0; index < cycle.length - 1; index++) {
    const source = cycle[index];
    const target = cycle[index + 1];
    edges = [
      ...edges,
      ...edgesToNodeIdMap[source].filter((edge) => edge.target === target),
    ];
    // include last to first node edge in the cycle
    if (index === cycle.length - 2) {
      edges = [
        ...edges,
        ...edgesToNodeIdMap[target].filter((edge) => edge.target === cycle[0]),
      ];
    }
  }
  const edgeIds = edges.map(({ id }) => id);
  if (edgeIds.length > 0)
    updatedEdges = updatedEdges.map((e) => {
      const { zIndex, ...edge } = e;
      return !edgeIds.includes(edge.id)
        ? {
            ...edge,
            style: {
              ...edge.style,
              stroke: "#b1b1b7",
              opacity: 1,
              strokeWidth: 2,
            },
            animated: false,
          }
        : {
            ...edge,
            zIndex: 2,
            style: {
              ...edge.style,
              stroke: "#dc2626",
              opacity: 1,
              strokeWidth: 6,
            },
            animated: true,
          };
    });
  return updatedEdges;
};
