import React from "react";
import Label from "../Label";
import { classNames } from "utils";
import { isBoolean } from "utils/helpers";

const Checkbox = ({
  name,
  type,
  label,
  register,
  error,
  options = {},
  ...rest
}) => {
  const hasError = isBoolean(error) ? error : !!error;
  return (
    <div>
      <div className="relative mt-4 flex items-start">
        <div className="flex h-5 items-center">
          <input
            id={`${name}-checkbox`}
            name={name}
            type={type}
            disabled={options.readOnly}
            {...register(name, options)}
            {...rest}
            className={classNames(
              hasError
                ? "border-red-500 focus:ring-red-500 dark:border-red-600 dark:focus:ring-red-600"
                : "border-gray-300 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-300/30",
              "rounded border text-sm placeholder-gray-400 shadow-sm focus:outline-none disabled:cursor-not-allowed disabled:opacity-60 dark:placeholder-gray-500 sm:text-base",
            )}
          />
        </div>
        <div className="ml-3 text-sm">
          {label && (
            <Label
              name={`${name}-checkbox`}
              label={label}
              error={hasError}
              options={options}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
