import { states } from "./forms";
// All functions in this file are used to load data on dropdowns
// Make sure to return an array of objects with value and label keys

export const getStates = () => {
  return [
    { value: null, label: "Select State" },
    ...states.map((state) => ({
      value: state,
      label: state,
    })),
  ];
};
