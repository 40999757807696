const { useFlowState } = require("contexts/flow");

const withFlowState = (WrappedComponent) => {
  const ComponentWithState = (props) => {
    const flowState = useFlowState();
    return <WrappedComponent {...flowState} {...props} />;
  };
  return ComponentWithState;
};

export default withFlowState;
