import { getEndpoints } from "api";
import React, { createContext, useState, useContext, useEffect } from "react";
import { ALERTS, GROUP_NODE_TYPES, NODE_TYPE } from "utils/constants";
// import { useWebSocket } from "hooks/useWebSocket";
import { useAuthState } from "contexts/auth";
import AddNodeModal from "workflow-editor/components/AddNodeModal";
import { useEditor } from "workflow-editor/hooks/useEditor";
import { notify } from "utils/notification";
import DeleteConfirmation from "components/common/DeleteConfirmation";
import { getNestedNodes } from "workflow-editor/utils/editor";
import { useValidation } from "workflow-editor/hooks/useValidation";
import { searchNodesForReference } from "workflow-editor/utils/variable-references";
import { EVENT_TYPES, eventHub } from "workflow-editor/events";

const EditorContext = createContext();

export function EditorProvider({
  initialNodes = [],
  initialEdges = [],
  initialErrors,
  initialWorkflow,
  children,
}) {
  const { user } = useAuthState();
  const {
    reset,
    nodes,
    edges,
    addOrUpdateNode,
    addEdge,
    deleteNodes,
    deleteEdges,
    conflictingWork,
    setConflictingWork,
    unsavedWork,
    ...editor
  } = useEditor(initialNodes, initialEdges, initialErrors, initialWorkflow);
  const { errors, suppressError } = useValidation(initialErrors);

  const [workflow, setWorkflow] = useState(initialWorkflow);
  const [options, setOptions] = useState({
    endpoints: {},
  });
  const [deleteNodeConfirmation, setDeleteNodeConfirmation] = useState(null);
  const [displayNodeModal, setDisplayNodeModal] = useState(false);
  const [editedNode, setEditedNode] = useState(null);
  const [parentNode, setParentNode] = useState(null);
  const [sourceHandle, setSourceHandle] = useState();
  const [modalKey, setModalKey] = useState(Date.now());
  const resetModal = () => setModalKey(Date.now());

  // simulates a workflow import event to run all validations
  useEffect(() => {
    if (initialErrors === undefined)
      eventHub.emit(EVENT_TYPES.WORKFLOW_IMPORT, {
        state: {
          edges: edges ?? initialEdges,
          nodes: nodes ?? initialNodes,
        },
      });
  }, [initialErrors]);

  const openNodeModal = ({ node, parentNode, handle } = {}) => {
    // reset modal if the selected node to edit changes
    if (!node) {
      if (editedNode) {
        resetModal();
        setEditedNode(null);
      }
    } else {
      if (editedNode?.id !== node?.id) {
        resetModal();
        setEditedNode(node);
      }
    }
    setSourceHandle(handle);
    setParentNode(parentNode);
    setDisplayNodeModal(true);
  };

  const closeNodeModal = (reset) => {
    setDisplayNodeModal(false);
    if (reset) {
      setEditedNode(null);
      setParentNode(null);
      setSourceHandle();
      resetModal();
    }
  };

  const onCancelDeleteNodes = () => {
    setDeleteNodeConfirmation(null);
  };

  const onConfirmDeleteNodes = () => {
    deleteNodes(deleteNodeConfirmation.nodes);
    setDeleteNodeConfirmation(null);
  };

  const onWorkflowUpdate = (payload) => {
    const { modified_by, data } = payload;
    // warn editor if a change was made by another user on the current workflow
    if (user.username === modified_by || workflow.id !== data.id) return;

    if (!unsavedWork) {
      // update workflow to new version
      setWorkflow(data);
      const tree = data.flow_tree;
      reset({
        nodes: tree.nodes.map((n) => ({ ...n, selected: false })),
        edges: tree.edges,
      });
      // display notification
      notify.error(ALERTS.WORKFLOW_UPDATED);
    } else {
      // display warning
      notify.error(ALERTS.WORKFLOW_UPDATE_CONFLICT);
      setConflictingWork(payload);
    }
  };

  // useWebSocket({
  //   namespace: "workflows",
  //   events: {
  //     "workflow-update": onWorkflowUpdate,
  //   },
  // });

  useEffect(() => {
    const fetchEndpoints = async () => {
      const {
        data: { data: endpoints },
      } = await getEndpoints();
      setOptions((prevState) => ({
        ...prevState,
        endpoints,
      }));
    };

    fetchEndpoints();
  }, []);

  const deleteNodesWithConfirmation = (nds) => {
    // include loop node children if they not selected
    const _nodes = nds.reduce((nodesToDelete, node) => {
      if (node.type === NODE_TYPE.START) {
        notify.error("Start node is required.");
        return [];
      }
      if (GROUP_NODE_TYPES.includes(node.type)) {
        const nestedNodes = getNestedNodes(nodes, node.id);
        nodesToDelete = [
          ...nodesToDelete,
          ...nestedNodes.filter(
            ({ id }) => !nds.find((node) => node.id === id),
          ),
        ];
      }
      nodesToDelete = [...nodesToDelete, node];
      return nodesToDelete;
    }, []);
    const nodeIds = _nodes.map(({ id }) => id);
    const references = searchNodesForReference(nodes, nodeIds);
    const referencedNodes = Object.keys(references);
    if (
      referencedNodes.length &&
      // skip delete confirmation modal if selected nodes only reference each other
      !referencedNodes.every((id) => nodeIds.includes(id))
    ) {
      const message =
        _nodes.length === 1
          ? `This node is referenced on the following nodes: ${referencedNodes.join(
              ", ",
            )}. Are you sure you want to delete it?`
          : `Selected nodes are referenced on other nodes. Are you sure you want to delete the selected nodes?`;
      const title =
        _nodes.length === 1
          ? `Delete node ${_nodes[0].id}`
          : `Delete nodes ${_nodes.map((node) => node.id).join(", ")}`;
      setDeleteNodeConfirmation({ nodes: _nodes, message, title });
    } else {
      deleteNodes(_nodes);
    }
  };

  const nodeModalTitle = editedNode ? "Edit Node" : "Add Node";

  return (
    <EditorContext.Provider
      value={{
        ...editor,
        errors,
        deleteNodesWithConfirmation,
        deleteNodes,
        deleteEdges,
        reset,
        nodes,
        edges,
        workflow,
        unsavedWork,
        conflictingWork,
        setConflictingWork,
        options,
        displayNodeModal,
        openNodeModal,
        suppressError,
      }}
    >
      <AddNodeModal
        // force the modal to reset by giving it a different key
        key={modalKey}
        onClose={closeNodeModal}
        editedNode={editedNode}
        isOpen={displayNodeModal}
        onSave={(node, handle) => addOrUpdateNode(node, sourceHandle ?? handle)}
        workflow={workflow}
        title={nodeModalTitle}
        options={options}
        parentNode={parentNode}
      />
      {deleteNodeConfirmation && (
        <DeleteConfirmation
          title={deleteNodeConfirmation.title}
          message={deleteNodeConfirmation.message}
          defaultStateOpen
          onCancel={onCancelDeleteNodes}
          onConfirm={onConfirmDeleteNodes}
        />
      )}
      {children}
    </EditorContext.Provider>
  );
}

export function useEditorState() {
  const state = useContext(EditorContext);

  return {
    ...state,
  };
}
