import { getToken } from "api";
import { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useAuthState } from "./auth";
import { Socket } from "phoenix";
import {checkIfTokenExpiredAndRefresh} from 'api';
// const URL = process.env.REACT_APP_BACKEND_BASE_URL ?? "https://wizard.epik.io";

// const URL = `http://wizard2.epik.io/socket`
const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { user } = useAuthState();
  const [sockets, setSockets] = useState({});
  // const addNamespace = (namespace) => {
  //   if (!namespace || sockets[namespace]) return;
  //   setSockets({
  //     ...sockets,
  //     [namespace]: io(`${URL}/${namespace}`, {
  //       transports: ["websocket", "polling"],
  //       auth: { token: getToken() },
  //       reconnection: true,
  //       reconnectionDelay: 1000,
  //       reconnectionDelayMax: 5000,
  //       reconnectionAttempts: 20
  //     }),
  //   });
  // };

  // useEffect(() => {
  //   if (!sockets.main && user) {
  //     setSockets({
  //       ...sockets,
  //       main: io(URL, {
  //         transports: ["websocket", "polling"],
  //         auth: { token: getToken() },
  //         reconnection: true,
  //         reconnectionDelay: 1000,
  //         reconnectionDelayMax: 5000,
  //         reconnectionAttempts: 20
  //       }),
  //     });
  //   }
  // }, [sockets, user]);

  // useEffect(() => {
  //   return () => {
  //     Object.keys(sockets).forEach((namespace) =>
  //       sockets[namespace].disconnect(),
  //     );
  //   };
  // }, []);

  async function socketUpdate(){
    let token = await checkIfTokenExpiredAndRefresh(localStorage.getItem("access_token"));
    
    let socket = new Socket(`${process.env.REACT_APP_V2_BASE_URL_WS}/socket`, {
      params: {token}
    })

    socket.connect()    
    setSockets(socket)
  }

  useEffect(() => {
    socketUpdate();
  }, [])

  return (
    <SocketContext.Provider value={{ sockets }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
