// do not enable dark mode for the following paths
export const ignoredPaths = []; // ["/", "/login", "/signup", "/flow"];
// check localStorage if "theme" is set to "dark" else check system preferences
export const isDarkMode = () =>
  localStorage.getItem("theme") === "dark"

// adds or removes the "dark" class to the main html element
export const update = () => {
  if (
    isDarkMode() &&
    !ignoredPaths.some((path) => path === window.location.pathname)
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }
};
// On page load
update();
