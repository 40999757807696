import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

const MenuDropDown = ({ children, menuItems, renderItem = null }) => {
  return (
    <Menu as="div" className="relative z-50 inline-block text-left">
      {children}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-background-primary shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-background-primary-dark">
          <div className="py-1">
            {menuItems.map((item, index) => (
              <div className="" key={index}>
                <Menu.Item key={item.label}>
                  {renderItem
                    ? ({ active }) => renderItem(item, active)
                    : ({ active }) => (
                        <div
                          role={"button"}
                          onClick={item.action}
                          className={`${
                            active
                              ? "bg-background-secondary text-blue-900 dark:bg-background-secondary-dark dark:text-blue-400"
                              : "text-blue-700"
                          } flex w-full justify-between px-4 py-2 text-left text-sm leading-5`}
                        >
                          <span>{item.label}</span>
                        </div>
                      )}
                </Menu.Item>
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MenuDropDown;
