import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { useFlowState } from 'contexts/flow';
import { useAuthState } from 'contexts/auth';
import { useSocket } from 'contexts/socket';
import html2canvas from 'html2canvas';
import { sendScreenShot, postFrontendErrorLogs } from 'api';

function Messages({ type, text }) {
  if (type === 'activationTech') {
    return (
      <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
        <span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
          <div className="rounded-full bg-gray-100 border p-1">
            <svg xmlns="http://www.w3.org/2000/svg" stroke="none" fill="black" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height="20" width="20">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
            </svg>
          </div>
        </span>
        <p className="leading-relaxed"><span className="block font-bold text-gray-700">A</span>{text}</p>
      </div>
    )
  }
  return (
    <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 100 100"
      >
        <path d="M80 71.2V74c0 3.3-2.7 6-6 6H26c-3.3 0-6-2.7-6-6v-2.8c0-7.3 8.5-11.7 16.5-15.2.3-.1.5-.2.8-.4.6-.3 1.3-.3 1.9.1C42.4 57.8 46.1 59 50 59c3.9 0 7.6-1.2 10.8-3.2.6-.4 1.3-.4 1.9-.1.3.1.5.2.8.4 8 3.4 16.5 7.8 16.5 15.1z"></path>
        <ellipse cx="50" cy="36.5" rx="14.9" ry="16.5"></ellipse>
      </svg>
      <p className="leading-relaxed"><span className="block font-bold text-gray-700">You </span>{text}</p>
    </div>
  )
}

function Chat() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [dragInfo, setDragInfo] = useState(null)
  const [newMessage, setNewMessage] = useState('')
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const { sockets } = useSocket();

   const { workflowInstanceId, iwTicketNumber, state } = useFlowState();

   const {instanceState} = state;

  const endRef = useRef(null);

  const { user } = useAuthState();

  async function captureScreenShot(){
    return new Promise((resolve) => {
        html2canvas(document.getElementById('root')).then((canvas) => {
            const screenShotData = canvas.toDataURL('image/png');
            resolve(screenShotData)
        })
    })
  }

  async function captureAndSendScreenShot(){
    try{
          const screenShotData = await captureScreenShot();
          await sendScreenShot(workflowInstanceId, {
            imgData: screenShotData,
            workflowInstanceId,
            iwTicket: iwTicketNumber
          });
        }catch(error){
            postFrontendErrorLogs(error)
            return false
    }
  }

  let handleDragStart = (e, data) => {
    setDragInfo({
      x: data.x,
      y: data.y,
      time: Date.now()
    })
  }

  let handleDragStop = (e, data) => {
    setPosition({ x: data?.x, y: data?.y })

    if (!dragInfo) return
    let change = {
      x: Math.abs(data.x - dragInfo.x),
      y: Math.abs(data.y - dragInfo.y),
      time: Date.now() - dragInfo.time
    }
    if (change.x + change.y <= 10 && change.time < 300) e.srcElement?.click?.()
  }

  useEffect(() => {
    endRef?.current?.scrollIntoView()
  }, [messages])

  useEffect(() => {
    if (sockets?.isConnected && workflowInstanceId) {
      let newChannel = sockets.channel(`room:${workflowInstanceId}`, { name: localStorage.getItem("chat_name") || "Field Tech", iwTicket: iwTicketNumber })
      setChannel(newChannel)
      newChannel.join()
        .receive("ok", resp => { console.log("Joined successfully", resp) })
        .receive("error", resp => { console.log("Unable  to join", resp) })

      newChannel.on("new_conversation", (payload) => {
        newChannel.push("new_message", { 
          conversation_id: payload?.id, 
          message_text: instanceState?.agent ? `${instanceState?.agent} has been assigned your ticket for support` : `An Activation Rep has been assigned your ticket for support`, 
          user_id: "5da4b51f-87a9-41ab-aae7-667f1db40c0c", 
          type: "activationTech" })

          captureAndSendScreenShot()
      })

      newChannel.push("get_messages", { workflowInstanceId })
        .receive("ok", (payload) => {
          if (Array.isArray(payload) && payload.length) {
            setMessages(payload)
            setConversation(payload[0]?.conversation_id)
            return
          }

          if (payload?.id) {
            setConversation(payload?.id)
          }
        })
    }
  }, [workflowInstanceId, iwTicketNumber])

  useEffect(() => {
    if (channel) {
      channel.on("message_added", (payload) => {
        setMessages(prevMessages => [...prevMessages, payload])
        if (!isChatOpen) {
          setIsChatOpen(true)
        }
      })
    }

  }, [channel])

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  function sendMessage(event) {
    event?.preventDefault();
    if (newMessage?.trim() !== '') {
      channel.push("new_message", { conversation_id: conversation || messages[0]?.conversation_id, message_text: newMessage, user_id: "dfcd3023-d633-4ef3-8dfe-a997fd72b55e", type: "fieldTech" })
      setNewMessage('')
    }
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event?.preventDefault();

      sendMessage();
    }
  };

  if (!workflowInstanceId) {
    return <></>
  }

  return (
    <>
      <Draggable bounds={"parent"} position={position} onStart={handleDragStart} onStop={handleDragStop}>
        <button
          className={`z-50 fixed bottom-4 left-0 inline-flex items-center justify-center text-sm font-medium border rounded-full w-16 h-16 bg-black hover:bg-gray-700 m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900 ${isChatOpen ? 'hidden' : ''}`}
          type="button"
          aria-haspopup="dialog"
          aria-expanded={isChatOpen}
          data-state={isChatOpen ? 'open' : 'closed'}
          onClick={toggleChat}
        >
          <svg onClick={toggleChat} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-white block border-gray-200 align-middle handle">
            <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" />
          </svg>
        </button>
      </Draggable>
      {isChatOpen && (
        <div className="z-50 fixed bottom-1 left-0 mr-4 bg-white p-6 rounded-lg border border-[#e5e7eb] w-full max-w-[95%] md:w-[50%] lg:max-w-[50%] h-auto md:h-[634px] overflow-hidden">
          {/* Close button */}
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
            onClick={toggleChat}
            aria-label="Close chat"
            onTouchEnd={toggleChat}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
          {/* Chat window content */}
          <div className="flex flex-col space-y-1.5 pb-6">
            <h2 className="font-semibold text-lg tracking-tight">Activation Support</h2>
            <p className="text-sm text-[#6b7280] leading-3">Powered Epik Granite</p>
          </div>
          <div className="pr-4 overflow-auto" style={{ "height": "480px" }}>
            {/* Chat messages */}
            {messages?.map(({ id, type, message_text, time_stamp }) => {
              return <Messages key={id} type={type} text={message_text} timeStamp={time_stamp} />
            })}
            <div ref={endRef}></div>
          </div>
          {/* Input box */}
          <div className="flex items-center pt-1">
            <form className="flex items-center justify-center w-full space-x-2">
              <input
                className="flex h-full w-full rounded-md border border-[#e5e7eb] text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                placeholder="Type your message"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleEnterPress}
                type="text"
              />

              <button
                className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
                onClick={sendMessage}
              >Send</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Chat;
