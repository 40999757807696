import { PhotoIcon, PlayIcon } from "@heroicons/react/24/outline";
import { classNames, getAttachmentType } from "utils";

const AttachmentIcon = ({ attachments, onClick, className }) => {
  if (!attachments || attachments?.length === 0) return null;
  // display appropriate icon if all attachments are of the same type

  // videos
  if (
    attachments.every((attachment) => getAttachmentType(attachment) === "video")
  )
    return (
      <PlayIcon
        onClick={onClick}
        className={classNames(className, "rounded-md border p-1")}
      />
    );
  // images
  else if (
    attachments.every((attachment) => getAttachmentType(attachment) === "image")
  )
    return <PhotoIcon onClick={onClick} className={className} />;
  // mixed
  else return <PhotoIcon onClick={onClick} className={className} />;
};

export default AttachmentIcon;
