import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { classNames } from "utils";
import { pagination } from "utils/pagination";

const Page = ({ page, label, currentPage, onPageChange }) => {
  return (
    <div
      className={`inline-flex cursor-pointer items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-secondary-text  dark:hover:border-gray-300/30 dark:hover:text-secondary-text-dark ${
        page === currentPage
          ? "border-indigo-500 text-indigo-600 dark:text-indigo-400"
          : ""
      }`}
      onClick={() => onPageChange(page)}
    >
      {label}
    </div>
  );
};

const Pagination = ({ currentPage, pageCount = 0, onPageChange }) => {
  const [pages, setPages] = useState([]);

  const onPageChangeHandler = (page) => {
    onPageChange(page);
  };

  useEffect(() => {
    setPages(pagination(currentPage, pageCount));
  }, [currentPage, pageCount]);

  return (
    <nav className="flex items-center justify-between rounded-lg border-t border-gray-300 bg-background-primary px-4 pb-4 dark:border-gray-300/30 dark:bg-background-primary-dark sm:px-4">
      <div
        className={
          currentPage === 1
            ? "-mt-px flex w-0 flex-1 cursor-not-allowed"
            : "-mt-px flex w-0 flex-1"
        }
      >
        <div
          onClick={() => onPageChangeHandler(currentPage - 1)}
          className={classNames(
            "inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-secondary-text  dark:hover:border-gray-300/30 dark:hover:text-secondary-text-dark",
            currentPage === 1 ? "pointer-events-none text-gray-300" : "",
          )}
          role="button"
        >
          <ArrowLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          Previous
        </div>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map((p, idx) => (
          <Page
            key={idx}
            page={
              p === "..."
                ? Math.round(
                    (pages[idx + 1] - pages[idx - 1]) / 2 + pages[idx - 1],
                  )
                : p
            }
            label={p}
            currentPage={currentPage}
            onPageChange={onPageChangeHandler}
          />
        ))}
      </div>
      <div
        className={
          currentPage === pageCount
            ? "-mt-px flex w-0 flex-1 cursor-not-allowed justify-end"
            : "-mt-px flex w-0 flex-1 justify-end"
        }
      >
        <div
          className={classNames(
            "inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-secondary-text  dark:hover:border-gray-300/30 dark:hover:text-secondary-text-dark",
            currentPage === pageCount
              ? "pointer-events-none text-gray-300"
              : "",
          )}
          onClick={() => onPageChangeHandler(currentPage + 1)}
          role="button"
          to="#"
        >
          Next
          <ArrowRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </nav>
  );
};

export default Pagination;
