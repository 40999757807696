import React from "react";
import Label from "../Label";
import { classNames } from "utils";
import { isBoolean } from "utils/helpers";

const Input = ({
  name,
  type,
  label,
  register,
  error,
  options = {},
  onBlur,
  value,
  ...rest
}) => {
  const hasError = !!error;

  return (
    <>
      {label && (
        <Label name={name} label={label} error={hasError} options={options} />
      )}
      <div className="mt-1">
        <input
          autoComplete="off"
          type={type}
          max={options.max || "2050-01-01"}
          min={options.min}
          value={value}
          readOnly={options.readOnly}
          // TODO: find a better way to handle this
          // min={type === "date" ? new Date().toISOString().split("T")[0] : null}
          {...register(name, {
            onBlur: (event) => {
              if (onBlur) onBlur(event);
            },
            ...options,
          })}
          {...rest}
          className={classNames(
            hasError
              ? "border-red-500 focus:ring-red-500 dark:border-red-600 dark:focus:ring-red-600"
              : "border-gray-300 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-300/30",
            "block w-full appearance-none rounded-md border bg-background-primary px-3 py-2 text-sm placeholder-gray-400 shadow-sm read-only:cursor-not-allowed read-only:border-slate-200 read-only:bg-slate-50 read-only:text-slate-500 read-only:shadow-none focus:outline-none focus:ring-2 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none dark:bg-background-primary-dark dark:placeholder-gray-500 sm:text-base",
          )}
        />
        {!isBoolean(error) && error && (
          <div className="mt-1 text-sm text-red-500 dark:text-red-600">
            {error}
          </div>
        )}
      </div>
    </>
  );
};

export default Input;
