import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { useMemo } from "react";
import { classNames, getAttachmentSrc, getAttachmentType } from "utils";

export const AttachmentPreview = ({
  attachment,
  onRemove,
  className,
  onClick,
  thumbnail = false,
}) => {
  const src = useMemo(() => getAttachmentSrc(attachment), [attachment]);
  const type = useMemo(() => getAttachmentType(attachment), [attachment]);

  return (
    <div
      className={classNames(className, onClick && "cursor-pointer", "relative")}
      onClick={onClick}
    >
      {onRemove && (
        <XCircleIcon
          className="absolute right-0.5 top-0.5 z-10 h-6 w-6 cursor-pointer rounded-full stroke-blue-500/60 stroke-1 text-xs text-gray-300"
          onClick={onRemove}
        />
      )}
      {{
        image: (
          <img
            src={src}
            className={classNames(
              "max-h-[inherit] w-fit rounded-lg",
              thumbnail ? "h-full w-full object-cover" : "object-contain",
            )}
            alt={src}
          />
        ),
        video: (
          <>
            <video
              controls={!thumbnail}
              preload={thumbnail ? "metadata" : "auto"}
              src={src}
              className={classNames(
                "max-h-[inherit] w-fit rounded-lg",
                thumbnail ? "h-full w-full object-cover" : "object-contain",
              )}
              alt={src}
            />
            {thumbnail && (
              <PlayCircleIcon className="absolute left-1/2 top-1/2 z-10 h-1/2 w-1/2 -translate-x-1/2 -translate-y-1/2 transform stroke-1 text-gray-300" />
            )}
          </>
        ),
      }[type] || null}
    </div>
  );
};
