import { Button, Modal } from "components/common";
import { RichTextEditor } from "components/common/RichTextEditor";
import { useCallback, useRef } from "react";

export const InfoEditorModal = ({
  onSave,
  isOpen = false,
  onClose = null,
  initialValue,
}) => {
  const editorRef = useRef(null);

  const onCancel = useCallback((event) => {
    onClose?.(event);
  }, []);

  const _onSave = useCallback(async () => {
    await editorRef.current.uploadImages();
    onSave?.(editorRef.current.getContent());
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="max-w-3xl"
      renderFooter={() => (
        <div className="flex justify-end gap-4 bg-background-primary px-4 py-3 dark:bg-background-primary-dark sm:px-6">
          <Button onClick={_onSave}>Save</Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="h-[80vh]">
        <RichTextEditor
          onInit={(event, editor) => (editorRef.current = editor)}
          initialValue={initialValue}
        />
      </div>
    </Modal>
  );
};
