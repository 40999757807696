import React from "react";
import { classNames } from "utils";
import Spinner from "../Spinner";

const getVariantClasses = () => ({
  primary: `text-primary-text-dark dark:text-primary-text font-bold bg-[#3b5aa9] dark:bg-[#3b5aa9] hover:bg-[#3b5aa9] dark:hover:bg-blue-600 focus:ring-2 focus:ring-blue-300 disabled:bg-background-secondary dark:disabled:bg-background-secondary-dark disabled:text-gray-400 disabled:border disabled:border-gray-400`,

  secondary: `bg-background-primary dark:bg-background-primary-dark font-bold text-blue-500 border border-blue-500 dark:border-blue-500 hover:ring-1 hover:ring-blue-500 hover:bg-background-secondary dark:hover:bg-background-secondary-dark hover:text-blue-600 focus:z-10 focus:ring-2 focus:ring-blue-300 focus:text-blue-600  disabled:bg-background-secondary dark:disabled:bg-background-secondary-dark disabled:text-gray-400 disabled:border disabled:border-gray-400`,

  delete: `text-primary-text-dark font-bold bg-red-500 dark:bg-red-600 hover:bg-red-600 dark:hover:bg-red-500 focus:ring-2 focus:ring-red-300 disabled:bg-red-50 disabled:text-red-300 disabled:border disabled:border-red-300`,
});

const getSizeClasses = () => ({
  small: `px-2 py-1 rounded`,
  medium: `rounded-md p-2 min-w-[120px]`,
  large: `rounded-lg px-5 py-2.5`,
});

const Button = ({
  children,
  type = "button",
  color = "blue",
  variant = "primary",
  size = "medium",
  className = "",
  onClick,
  isLoading = false,
  ...props
}) => {
  const commonClasses =
    "z-10 relative inline-flex justify-center items-center border dark:border-gray-300/30 shadow-sm  focus:outline-none  disabled:cursor-not-allowed disabled:opacity-80 text-sm font-medium";

  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        commonClasses,
        getVariantClasses()[variant],
        getSizeClasses()[size],
        className,
      )}
      disabled={isLoading}
      {...props}
    >
      {isLoading && (
        <Spinner className="mr-2 h-4 w-4 text-primary-text dark:text-primary-text-dark" />
      )}
      {children}
    </button>
  );
};

export default Button;
