import { EventHub } from "workflow-editor/utils/eventHub";

export const eventHub = new EventHub();

export const EVENT_TYPES = {
  WORKFLOW_SAVE: "workflow.save",
  WORKFLOW_RESET: "workflow.reset",
  WORKFLOW_LAYOUT: "workflow.layout",
  WORKFLOW_IMPORT: "workflow.import",
  STATE_UNDO: "state.undo",
  STATE_REDO: "state.redo",
  NODE_CREATE: "node.create",
  NODE_UPDATE: "node.update",
  NODE_DELETE: "node.delete",
  NODE_DUPLICATE: "node.duplicate",
  EDGE_CREATE: "edge.create",
  EDGE_DELETE: "edge.delete",
};
