import { useCallback, useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { EVENT_TYPES, eventHub } from "../events";
import {
  VALIDATION_PIPELINES,
  ValidationManager,
} from "workflow-editor/validation";
import {
  runAllValidations,
  sortErrorsAlphanumeric,
} from "workflow-editor/utils/validation";

export const useValidation = (initialErrors = {}) => {
  const [errors, setErrors] = useState(initialErrors);

  const validationManager = useMemo(
    () => new ValidationManager(initialErrors),
    [],
  );
  const updateState = useCallback(
    debounce((errors) => {
      setErrors(structuredClone(sortErrorsAlphanumeric(errors)));
    }, 150),
    [],
  );

  const suppressError = useCallback(
    (key, index) => updateState(validationManager.suppressError(key, index)),
    [updateState],
  );

  const resetState = useCallback(
    (event) => {
      const {
        state: { errors },
      } = event;
      if (errors !== undefined) updateState(errors);
      else updateState(runAllValidations(event, validationManager));
    },
    [updateState, validationManager],
  );

  useEffect(() => {
    const unsubscribeFunctions = [
      eventHub.on(EVENT_TYPES.WORKFLOW_RESET, resetState),
      eventHub.on(EVENT_TYPES.WORKFLOW_IMPORT, resetState),
      eventHub.on(EVENT_TYPES.STATE_UNDO, resetState),
      eventHub.on(EVENT_TYPES.STATE_REDO, resetState),
      ...Object.entries(VALIDATION_PIPELINES).map(([eventName, validators]) =>
        eventHub.on(eventName, validationManager.run(validators, updateState)),
      ),
    ];
    return () => unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
  }, []);

  return {
    errors,
    suppressError,
  };
};
