import { Fragment, useState, useMemo } from "react";
import { Link, Outlet, useMatch, useResolvedPath } from "react-router-dom";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  SquaresPlusIcon,
  DocumentChartBarIcon,
  Cog6ToothIcon,
  Bars3Icon,
  UsersIcon,
  XMarkIcon,
  InboxIcon,
  DocumentDuplicateIcon,
  ClipboardIcon
} from "@heroicons/react/24/outline";
import { useAuthState } from "contexts/auth";
import { ShowForPermission } from "utils/helpers";
import { ROLES } from "utils/constants";
import { useTheme } from "contexts/theme";

const navigation = [
  {
    name: "Workflows",
    href: "/workflows",
    icon: SquaresPlusIcon,
    current: false,
    permission: [ROLES.ADMIN, ROLES.EDITOR],
  },
  {
    name: "Forms",
    href: "/forms",
    icon: DocumentDuplicateIcon,
    current: false,
    permission: [ROLES.ADMIN, ROLES.EDITOR],
  },
  {
    name: "Schedule",
    href: "/schedule",
    icon: InboxIcon,
    current: false,
    permission: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
  },
  {
    name: "Survey",
    href: "/survey",
    icon: ClipboardIcon,
    current: false,
    permission: [ROLES.ADMIN, ROLES.EDITOR],
  },
  {
    name: "Users",
    href: "/users",
    icon: UsersIcon,
    current: false,
    permission: [ROLES.ADMIN],
  },
  {
    name: "Reports",
    href: "/reports",
    icon: DocumentChartBarIcon,
    current: false,
    permission: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
  },
  {
    name: "Settings",
    href: "/settings",
    icon: Cog6ToothIcon,
    current: false,
    permission: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavLink({ children, to, className, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      to={to}
      className={classNames(
        match
          ? "bg-background-secondary text-header dark:bg-background-secondary-dark dark:text-header-dark"
          : "text-secondary-text hover:bg-background hover:text-header dark:text-secondary-text-dark dark:hover:bg-background-secondary-dark/70 hover:dark:text-header-dark",
        className,
      )}
      {...props}
    >
      {children}
    </Link>
  );
}

function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, logout } = useAuthState();
  const { switchDarkMode } = useTheme();

  const userNavigation = useMemo(
    () => [
      { name: "Dark Mode", action: switchDarkMode },
      { name: "Your Profile", href: "#" },
      { name: "Settings", href: "#" },
      { name: "Sign out", href: "#", action: logout },
    ],
    [logout],
  );

  return (
    <>
      <div className="h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-background-primary dark:bg-background-primary-dark">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-primary-text-dark dark:text-primary-text"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                  <div className="flex flex-shrink-0 items-center justify-between px-4">
                    <Link className="w-1/2" to={"/app/"}>
                      <img
                        className="block h-8 w-auto dark:hidden"
                        src="/logoWithName.png"
                        alt="Granite EPIK Activation"
                      />
                      <img
                        className="hidden h-8 w-auto dark:block"
                        src="/logoWithNameDark.png"
                        alt="Granite EPIK Activation"
                      />
                    </Link>
                    <img className="inline-block h-4 w-auto" src="/greenix-logo.png"/>  
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map((item) => (
                      <NavLink
                        to={`/app${item.href}`}
                        key={item.name}
                        className={
                          "group flex items-center rounded-md px-2 py-2 text-base font-medium"
                        }
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 flex-shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 border-t border-gray-300 p-4 dark:border-gray-300/30">
                  <a href="#" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div className="flex h-10 w-10 items-center justify-center rounded-full p-1 bg-blend-darken">
                        <img
                          className="h-full w-full rounded-full p-[2px]"
                          src={`/person.png`}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-secondary-text group-hover:text-gray-900 dark:text-secondary-text-dark">
                          {user.username}
                        </p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-secondary-text dark:group-hover:text-secondary-text-dark">
                          View profile
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-300 bg-background-primary dark:border-gray-300/30 dark:bg-background-primary-dark">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              <div className="flex flex-shrink-0 items-center justify-between px-4">
                <Link className="w-1/2" to={"/app/"}>
                  <img
                    className="block h-8 w-auto dark:hidden"
                    src="/logoWithName.png"
                    alt="Granite EPIK Activation"
                  />
                  <img
                    className="hidden h-8 w-auto dark:block"
                    src="/logoWithNameDark.png"
                    alt="Granite EPIK Activation"
                  />
                </Link>
                <img className="inline-block h-4 w-auto" src="/greenix-logo.png"/>  
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-background-primary px-2 dark:bg-background-primary-dark">
                {navigation.map((item, idx) => (
                  <ShowForPermission key={idx} permission={item.permission}>
                    <NavLink
                      to={`/app${item.href}`}
                      key={item.name}
                      className={
                        "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                      }
                    >
                      <item.icon
                        className="mr-3 h-6 w-6 flex-shrink-0"
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  </ShowForPermission>
                ))}
              </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-300 p-4 dark:border-gray-300/30">
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="bg-background-primary text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:bg-background-primary-dark">
                    <span className="sr-only">Open user menu</span>
                    <div className="flex flex-1">
                      <div className="flex h-10 w-10 items-center justify-center rounded-full p-1 bg-blend-darken">
                        <img
                          className="h-full w-full rounded-full p-[2px]"
                          src={`/person.png`}
                          alt=""
                        />
                      </div>
                      <div className="ml-3 text-left">
                        <p className="text-sm font-medium text-secondary-text group-hover:text-gray-900 dark:text-secondary-text-dark">
                          {user.username}
                        </p>
                        <p className="text-xs font-medium text-gray-500 group-hover:text-secondary-text dark:group-hover:text-secondary-text-dark">
                          View profile
                        </p>
                      </div>
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute top-[-120px] mt-2 w-48 rounded-md bg-background-primary py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-background-primary-dark">
                    {userNavigation.map((item) => (
                      <Menu.Item
                        key={item.name}
                        onClick={() => item?.action?.()}
                      >
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active
                                ? "bg-background-secondary dark:bg-background-secondary-dark"
                                : "",
                              "block px-4 py-2 text-sm text-secondary-text dark:text-secondary-text-dark",
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <div className="flex h-full flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-background-primary pl-1 pt-1 dark:bg-background-primary-dark sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 overflow-y-auto">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}

export default Layout;
