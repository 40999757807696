import React from "react";
import { classNames } from "utils";
import Label from "../Label";
import { isBoolean } from "utils/helpers";

const TextArea = ({ name, label, register, error, value, options = {}, ...rest }) => {
  const hasError = isBoolean(error) ? error : !!error;
  return (
    <>
      {label && (
        <Label name={name} label={label} error={hasError} options={options} />
      )}
      <textarea
        name={name}
        value={value}
        readOnly={options.readOnly}
        {...register(name, options)}
        {...rest}
        className={classNames(
          hasError
            ? "border-red-500 dark:border-red-600"
            : "border-gray-300 dark:border-gray-300/30",
          "block w-full appearance-none rounded-md border bg-background-primary px-3 py-2 text-sm placeholder-gray-400 shadow-sm read-only:cursor-not-allowed read-only:border-slate-200 read-only:bg-slate-50 read-only:text-slate-500 read-only:shadow-none focus:border-blue-500 focus:outline-none focus:ring-blue-500 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none dark:bg-background-primary-dark dark:placeholder-gray-500 sm:text-base",
        )}
      />
      {!isBoolean(error) && error && (
        <div className="mt-1 text-sm text-red-500 dark:text-red-600">
          {error}
        </div>
      )}
    </>
  );
};

export default TextArea;
