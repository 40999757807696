import React from "react";
import withFlowState from "components/hoc/withFlowState";
import Button from "components/common/Button";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useAuthState } from "contexts/auth";

const Navigation = (props) => {
  const {
    isNextEnabled,
    sequence,
    onPrevious,
    onNext,
    apiRunning,
    stepState,
    state: { flowState, flowPath },
    onAttachmentClickHandler,
  } = props;

  const {user} = useAuthState();
  const isPreviousEnabled = flowPath.length > 1 && !apiRunning;
  const _isNextEnabled = isNextEnabled || !!flowState[sequence]?.next;

  return (
    <div className="flex-shrink-0 overflow-hidden rounded-b-md py-5 sm:py-8">
      {/* mobile */}
      <div className="flex items-stretch justify-around text-secondary-text dark:text-secondary-text-dark sm:hidden">
        <Button
          variant="primary"
          className="mobile-navigation_button group !border-blue-600 dark:border-blue-600"
          size="small"
          onClick={onPrevious}
          disabled={!isPreviousEnabled}
        >
          <ArrowLeftIcon
            className="block h-5 w-5 fill-blue-500 group-disabled:fill-gray-500"
            aria-hidden="true"
          />
        </Button>
        <Button
          variant="primary"
          className="mobile-navigation_button group border-red-500 dark:border-red-500"
          size="small"
          // TODO: add proper action
          // onClick={() => onNext()}
          disabled={true}
        >
          <ExclamationTriangleIcon
            className="block h-5 w-5 stroke-red-500 group-disabled:stroke-gray-500"
            aria-hidden="true"
          />
        </Button>
        <Button
          variant="primary"
          className="mobile-navigation_button group !border-blue-600 dark:!border-blue-600"
          size="small"
          onClick={() => onAttachmentClickHandler(0)}
          disabled={!(stepState?.info || stepState?.images?.length)}
        >
          <InformationCircleIcon
            className="block h-5 w-5 stroke-blue-500 group-disabled:!stroke-gray-500"
            aria-hidden="true"
          />
        </Button>
        <Button
          variant="primary"
          className="mobile-navigation_button group !bg-blue-600 dark:!bg-blue-600"
          size="small"
          onClick={() => onNext()}
          disabled={!_isNextEnabled}
        >
          <ArrowRightIcon
            className="block h-5 w-5 fill-blue-500 stroke-blue-50 group-disabled:fill-gray-500 group-disabled:stroke-gray-500 "
            aria-hidden="true"
          />
        </Button>
      </div>
      {/* desktop */}
      <div className="hidden items-center text-secondary-text dark:text-secondary-text-dark sm:flex">
        {user && user?.username !== process.env.REACT_APP_FIELD_TECH && (<Button
          variant="secondary"
          className="p-2"
          onClick={onPrevious}
          disabled={!isPreviousEnabled}
        >
          Previous
        </Button>)}
        <Button
          variant="primary"
          className="ml-3 p-2"
          onClick={() => onNext()}
          disabled={!_isNextEnabled}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default withFlowState(Navigation);
