import withFlowState from "components/hoc/withFlowState";
import { useEffect } from "react";
import { NODE_TYPE } from "utils/constants";
import FlowStep from "../FlowStep";

const ReferenceFlowStep = (props) => {
  const {
    state,
    updateFlowStateStepData,
    onNext,
    determineNextStep,
    stepState,
  } = props;
  const { currentNode, path } = state;

  useEffect(() => {
    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: NODE_TYPE.REFERENCE_FLOW,
      next: determineNextStep(),
      data: stepState.data,
    });
  }, [currentNode]);

  // continue once updateFlowStateStepData gets updated
  useEffect(() => {
    if (path[path.length - 1]?.id === currentNode) onNext();
  }, [path, currentNode]);

  return <FlowStep data={stepState} />;
};

export default withFlowState(ReferenceFlowStep);
