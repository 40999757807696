import React from "react";
import DisplayCurrentStep from "../DisplayCurrentStep";
import AttachmentIcon from "components/common/attachments/AttachmentIcon";

const FlowStep = ({ children, data, onAttachmentClickHandler }) => (
  <>
    <div className="flex flex-grow flex-col rounded-md border bg-background-primary shadow-md dark:border-gray-300/30 dark:bg-background-primary-dark sm:flex-grow-0">
      <div className="relative overflow-y-auto px-6 pb-12 pt-6 text-gray-500 sm:overflow-y-visible sm:p-8">
        <DisplayCurrentStep />
        {children}
        <div className="absolute right-0 top-4 sm:-right-2">
          {data?.images && (
            <div className="hidden flex-row flex-wrap gap-4 px-6 sm:flex sm:px-8">
              <AttachmentIcon
                className="h-10 w-10 cursor-pointer border-blue-600 text-blue-600 dark:border-blue-300 dark:text-blue-300"
                attachments={data?.images}
                onClick={() => onAttachmentClickHandler(0)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  </>
);
export default FlowStep;
