import React from "react";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { useFlowState } from "contexts/flow";

const TechInfo = ({ techInfo }) => {
  const {techInfo : agentInfo} = useFlowState();

  return (
    <>
      {agentInfo?.agentName && agentInfo?.phoneNumber && (
        <div className="relative hidden">
          <div className="group absolute -bottom-8 -right-2 block sm:bottom-0">
            <span className="mb-7 hidden border p-2 shadow group-hover:inline-block group-hover:bg-white dark:border-gray-300/30 dark:group-hover:bg-gray-900">
              <p>Technican Name: {agentInfo?.agentName }</p>
              <p>Technican Number: {agentInfo?.phoneNumber}</p>
            </span>
            <div className="absolute -bottom-4 right-0 p-2">
              <PhoneIcon className="h-8 w-8 text-blue-600" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TechInfo;
