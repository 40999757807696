import { NODE_TYPE } from "utils/constants";
import { getReferenceProperties } from "./variable-references";

const filterSelectOptions = (selectOptions, string) =>
  selectOptions
    .map((item) => {
      if (item.toLowerCase().includes(string.toLowerCase())) {
        return {
          label: item,
          value: item,
        };
      }
    })
    .filter(Boolean);

/**
 * Handles the selection of autocomplete options and updates its value accordingly, getting search param in consideration.
 *
 * @param {string} options.search - The search string user types to filter select options.
 * @param {string} options.value - selected option
 *
 * @description This function follows the algorithm:
 * 1. Gets the substring after the last "${" in the search.
 * 2. Checks if there is any dot (.) in that substring.
 *    2.1 If there is no dot: replace the substring with the value of the selected option and concat it with "${".
 *    2.2 If there is dot: Get the substring after the last dot in the search,
 *        replace it with the selected option value, and concat it with the part of the search before the last dot.
 */
export const onVariableSelectionHandler = ({ search, onChange, value }) => {
  const indexOfLastTag = search.lastIndexOf("${");

  if (indexOfLastTag !== -1) {
    const substringAfterLastTag = search.substring(indexOfLastTag + 2);
    const indexOfLastDot = substringAfterLastTag.lastIndexOf(".");
    const updatedValue =
      indexOfLastDot !== -1
        ? // 2.2
          search.substring(0, indexOfLastTag + 2) +
          substringAfterLastTag.substring(0, indexOfLastDot + 1) +
          value
        : // 2.1
          search.substring(0, indexOfLastTag + 2) + value;

    onChange(updatedValue);
  }
};

/**
 * Handles autocomplete suggestions based on the value between "${" and "}"
 *
 * @param {Object} options - The options object.
 * @param {string} options.value - The input value.
 * @param {Object} options.formSchema - The form schema.
 * @param {Object[]} options.nodes - An array of node objects.
 * @returns {string[]} - An array of autocomplete suggestions.
 *
 *
 * @description This function follows the algorithm:
 * 1. Controls the substring between "${" and "}" in the value.
 * 2. Checks if there is any dot (.) in that substring.
 *    2.1. If there is no dot: it returns node ids and form_schema keys.
 *    2.2. If there is dot: it checks the variable type before dot whether it's list field or not
 *        2.2.1. If it's list field, it returns list field properties, otherwise
 *        2.2.2. It returns node reference properties
 */
export const onChangeAutoCompleteHandler = ({ value, formSchema, nodes }) => {
  const openingTagIndex = value.lastIndexOf("${");
  const closingTagIndex = value.lastIndexOf("}");
  // apply only on the substring between "${" and "}"
  if (openingTagIndex > -1 && openingTagIndex > closingTagIndex) {
    const dotsCount = countDotsAfterLastTag(value);
    const parts = value.split("${");
    const variable = parts[parts.length - 1];
    // show form schema keys and node ids
    if (dotsCount === 0) {
      const nodeIds = nodes
        ?.filter(({ type }) => ![NODE_TYPE.START, NODE_TYPE.END].includes(type))
        .map(({ id }) => id);
      let selectOptions = [...Object.keys(formSchema ?? {}), ...nodeIds];
      selectOptions = selectOptionsWithListFields({
        formSchema,
        selectOptions,
      });
      return filterSelectOptions(selectOptions, variable);
    }
    // show variable properties
    if (dotsCount === 1) {
      const [variableType, variableProperty] = variable.split(".");
      // determine whether variable type is list field(ex list[0]) or not
      const match = variableType?.match(/^(.+?)(\[[0-9]+\])?$/);

      if (match) {
        const nodeExistsInWorkflow = nodes.some(
          ({ id }) => id === variableType,
        );
        const fieldKey = match[1]; // Captures the field key
        const isListField = match[2]; // Captures [index] if present

        let data;
        if (isListField) {
          data = Object.keys(formSchema[fieldKey]?.fields ?? {});
        } else data = getReferenceProperties(`${variableType}.`, nodes) ?? [];

        if (data.length && (isListField || nodeExistsInWorkflow)) {
          return filterSelectOptions(data, variableProperty);
        }
        return [];
      }
    }
  }
};

const countDotsAfterLastTag = (inputString) => {
  const lastIndex = inputString.lastIndexOf("${");

  if (lastIndex === -1) return 0;

  const substringAfterLastTag = inputString.slice(lastIndex);
  // number of dots after last "${"
  return (substringAfterLastTag?.match(/\./g) || []).length;
};

const selectOptionsWithListFields = ({ formSchema, selectOptions }) => {
  const listFieldsArrays = Object.entries(formSchema ?? {})
    .filter(([_, item]) => item.type === "array")
    .map(([key, item]) => ({
      name: key,
      array: Array.from(
        { length: Object.keys(item.fields).length },
        (_, index) => `${key}[${index}]`,
      ),
    }));

  listFieldsArrays.length &&
    listFieldsArrays.forEach(({ name, array }) => {
      selectOptions = selectOptions.filter((item) => item !== name);
      selectOptions.push(...array);
    });

  return selectOptions;
};
