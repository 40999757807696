import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button } from "components/common";

const Search = ({ searchQuery, onSearch, onSearchQueryChange }) => {
  return (
    <>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          type="text"
          value={searchQuery}
          className="block w-full rounded-md border-gray-300 bg-background-primary pr-10 text-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-300/30 dark:bg-background-primary-dark sm:text-base"
          placeholder="Search..."
          onKeyDown={(e) => {
            if (e.code === "Enter") onSearch(e);
          }}
          onChange={onSearchQueryChange}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
      <Button variant="primary" type="button" onClick={onSearch}>
        Search
      </Button>
    </>
  );
};

export default Search;
