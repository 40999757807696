import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { formLayouts as layouts } from "utils/layouts";
import { Button, Modal } from "components/common";
import { classNames } from "utils";
import FormInput from "components/common/form/FormInput";
import {
  createUserSetting,
  getAllUsers,
  getWorkflowInstances,
  getWorkflows,
  getCurrentUser,
  updateUserSetting,
  downloadReport
} from "api";
import { removeEmptyProps } from "utils/helpers";
import Pagination from "components/common/Pagination";
import MenuDropDown from "components/common/MenuDropDown";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Menu } from "@headlessui/react";
import { UserSettingTypes } from "utils/constants";
import { useAuthState } from "contexts/auth";
import Spinner from "components/common/Spinner";
import { postFrontendErrorLogs } from "api";
import Table from "components/common/Table";

const fields = [
  "workflow_instances__id",
  "workflow_instances__iw_ticket",
  "workflow_instances__account_number",
  "workflow_instances__customer_name",
  "workflow_instances__form_data",
  "workflow_instances__dispatch_date",
  "workflows__name",
  "workflows__customer_name",
  "workflow_instances__status",
];
const Reports = () => {
  const [layout] = useState(layouts.reports.view);
  const [searchResults, setSearchResults] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dynamicOptions, setDynamicOptions] = useState({});
  const [resetKey, setResetKey] = useState(Math.random().toString(36));
  const [loading, setLoading] = useState(false);
  const [displayViewModal, setDisplayViewModal] = useState(false);
  const { user } = useAuthState();
  const [views, setViews] = useState([]);
  const [currentView, setCurrentView] = useState(null);
  const {
    control,
    register,
    watch,
    reset,
    handleSubmit,
    getValues,
    formState: { errors: formErrors, isValidating, isDirty },
  } = useForm({
    mode: "onBlur",
  });

  const columns = useMemo(
    () => [
      {
        title: "IW Ticket",
        key: "workflow_instances_iw_ticket",
      },
      {
        title: "Account Number",
        key: "workflow_instances_account_number",
      },
      {
        title: "Customer",
        key: "workflow_instances_customer_name",
        render: (row) =>
          row?.workflow_instances_customer_name
            ? row.workflow_instances_customer_name
            : row.workflows_customer_name,
      },
      {
        title: "Address",
        key: "workflow_instances_form_data",
        render: (row) =>
          `${row?.workflow_instances_form_data?.address_1} ${row?.workflow_instances_form_data?.city} ${row?.workflow_instances_form_data?.state}`,
      },
      {
        title: "Submitted",
        key: "workflow_instances_created_by",
      },
      {
        title: "Dispatch Date",
        key: "workflow_instances_dispatch_date",
        render: (row) =>
          new Date(row.workflow_instances_dispatch_date).toLocaleDateString(
            undefined,
            {
              timeZone: "UTC",
              day: "2-digit",
              month: "numeric",
              year: "numeric",
            },
          ),
      },
      {
        title: "Workflow",
        key: "workflows_name",
      },
      {
        title: "Status",
        key: "workflow_instances_status",
      },
    ],
    [],
  );

  const fetchUsers = () => {
    setLoading(true);
    getAllUsers().then((response) => {
      if (response.data.success) {
        setDynamicOptions((prevState) => ({
          ...prevState,
          created_by: [
            { value: "", label: "" },
            ...response.data.data?.map(({ username, name }) => ({
              value: username,
              label: name,
            })),
          ],
        }));
      }
      setLoading(false);
    });
  };

  const fetchWorkflows = () => {
    setLoading(true);
    getWorkflows({
      fields: ["workflows__name"],
    }).then((response) => {
      if (response.data.success) {
        setDynamicOptions((prevState) => ({
          ...prevState,
          name: ["", ...response.data.data?.map(({ name }) => name)],
        }));
      }
      setLoading(false);
    });
  };

  const fetchCurrentUser = () => {
    setLoading(true);
    return getCurrentUser().then(({ data }) => {
      if (data.success) {
        setViews(
          data.data.user_settings.filter(
            (s) =>
              s.type === UserSettingTypes.Report_View && s.is_deleted === false,
          ),
        );
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchUsers();
    fetchWorkflows();
    fetchCurrentUser();
  }, []);

  const onSubmit = async (input) => {
    searchWorkflowInstances(input);
  };

  const onSaveView = async (e) => {
    try {
      e.preventDefault();
      const data = getValues();
      let response = null;
      if (data.view_id) {
        response = await updateUserSetting(data.view_id, {
          user_id: user.id,
          type: UserSettingTypes.Report_View,
          settings: cleanSettingsData(data),
          name: data.view_name,
        });
      } else {
        response = await createUserSetting({
          user_id: user.id,
          type: UserSettingTypes.Report_View,
          settings: cleanSettingsData(data),
          name: data.view_name,
        });
      }
      setDisplayViewModal(false);
      fetchCurrentUser().then(() => {
        if (response?.data?.success) {
          onViewSelection(response.data.data);
        }
      });
    } catch (error) {
      postFrontendErrorLogs(error);
    }
  };

  const searchWorkflowInstances = async (input, page = 1, per_page = 20) => {
    if (input.dispatch_date__lte) {
      let dt = new Date(input.dispatch_date__lte);
      dt.setDate(dt.getDate() + 1);
      input.dispatch_date__lte = dt.toISOString().substring(0, 10);
    }
    const {
      data: { data = [], pages = 0 },
    } = await getWorkflowInstances({
      ...removeEmptyProps(cleanSettingsData(input)),
      fields,
      is_deleted: false,
      page,
      per_page,
    });
    setPageCount(pages);
    setSearchResults(data);
    setResetKey(Math.random().toString(36));
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    searchWorkflowInstances({ ...getValues() }, page);
  };

  const resetForm = () => {
    reset(
      Object.keys(layout.fields).reduce((acc, key) => {
        acc[key] = "";
        return acc;
      }, {}),
    );
    setCurrentView(null);
    setSearchResults(null);
  };

  const onViewSelection = (view) => {
    setCurrentView(view);
    const settings = cleanSettingsData(view.settings);
    const searchInput = { ...settings, view_id: view.id, view_name: view.name };
    reset(searchInput);
    searchWorkflowInstances({ ...searchInput }, 1);
  };

  const cleanSettingsData = (settings) =>
    Object.keys(layout.fields).reduce((acc, key) => {
      acc[key] = settings[key] || "";
      return acc;
    }, {});

  const handleDownload = async (e) => {
    e.preventDefault();
    let form = e.target
    const formData = new FormData(form);
    const formJson = Object.fromEntries(formData.entries());

    const { data } = await downloadReport(JSON.stringify({"type": formJson?.type}))
    const url = window.URL.createObjectURL(new Blob([data]));

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
  }

  return (
    <div className="flex h-screen flex-col shadow">
      <div className="sticky top-0 z-30 flex items-center gap-5 border-b border-gray-300 bg-background-primary px-4 py-5 dark:border-gray-300/30 dark:bg-background-primary-dark sm:px-7">
        <div className="flex w-full items-center justify-between">
          <h3 className="flex-shrink-0 text-lg font-medium leading-6 text-header dark:text-header-dark">
            {`Reporting`}
          </h3>
          <div>
            <MenuDropDown
              menuItems={views.map((v) => ({
                label: v.name,
                action: () => onViewSelection(v),
              }))}
            >
              <Menu.Button as="button" className="flex items-center">
                <div className="flex flex-row gap-6">
                  <div className="flex-shrink-0 text-sm text-gray-500">
                    {currentView ? currentView.name : `(default)`}
                  </div>
                  <div className="flex items-center">
                    <ChevronDownIcon className="h-5 w-5 text-blue-600" />
                  </div>
                </div>
              </Menu.Button>
            </MenuDropDown>
          </div>
        </div>
      </div>
      <div className="relative space-y-4 p-2 sm:p-3">
        {loading && (
          <div className="absolute left-0 top-0 z-10 flex h-screen w-full items-center justify-center bg-background-primary bg-opacity-50 dark:bg-background-primary-dark">
            <Spinner />
          </div>
        )}
        {!loading && (
          <div className="relative rounded-lg bg-background-primary p-6 dark:bg-background-primary-dark">
            <form
              className="space-y-4"
              action="#"
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
            >
              <dl className="grid grid-cols-12 gap-4  border-b border-b-gray-300 pb-4 dark:border-b-gray-300/30">
                {layout &&
                  Object.entries(layout.fields).map(
                    ([
                      key,
                      {
                        type,
                        label,
                        options,
                        selectOptions,
                        values,
                        fields,
                        xs,
                        sm,
                        query,
                      },
                    ]) => (
                      <div
                        className={classNames(
                          xs ? `col-span-${xs}` : "",
                          sm ? `sm:col-span-${sm}` : "",
                        )}
                        key={key}
                      >
                        <FormInput
                          register={register}
                          control={control}
                          watch={watch}
                          isValidating={isValidating}
                          name={key}
                          type={type}
                          label={label}
                          options={options}
                          values={values}
                          error={!!formErrors?.[key]}
                          fields={fields}
                          selectOptions={dynamicOptions?.[key] || selectOptions}
                          query={query}
                        />
                      </div>
                    ),
                  )}
              </dl>
              <div className="flex gap-4">
                <Button type="submit" variant="primary">
                  Search
                </Button>
                <Button type="button" variant="secondary" onClick={resetForm}>
                  Reset
                </Button>
                <Button
                  type="button"
                  variant="default"
                  className="min-w-fit border-0 p-2 text-blue-600 underline shadow-none disabled:text-gray-500"
                  disabled={!isDirty}
                  onClick={() => setDisplayViewModal(true)}
                >
                  Save view
                </Button>
              </div>
              <Modal
                title={`Save View`}
                isOpen={displayViewModal}
                onClose={() => setDisplayViewModal(false)}
                renderFooter={() => (
                  <div className="gap-2 bg-background-primary px-4 py-3 dark:bg-background-primary-dark sm:flex sm:flex-row-reverse sm:px-6">
                    <Button onClick={onSaveView}>Save</Button>
                    <Button
                      variant="secondary"
                      onClick={() => setDisplayViewModal(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              >
                <div className="space-y-4 overflow-y-auto p-4">
                  <form>
                    <input
                      type="hidden"
                      name="view_id"
                      value={currentView?.id}
                      {...register("view_id")}
                    />
                    <FormInput
                      register={register}
                      control={control}
                      name={"view_name"}
                      type={"text"}
                      label={"Name"}
                      options={{ required: true }}
                      className="mt-2 flex flex-wrap gap-3"
                    />
                  </form>
                </div>
              </Modal>
            </form>
          </div>
        )}
      </div>
      {searchResults?.length > 0 && (
        <div className="p-2 sm:p-3">
          <div className="relative min-h-[28rem] w-full flex-1 overflow-auto rounded-lg bg-background-primary dark:bg-background-primary-dark">
            <Table columns={columns} rows={searchResults} />
          </div>
          {searchResults?.length > 0 && (
            <Pagination
              key={resetKey}
              currentPage={currentPage}
              pageCount={pageCount}
              onPageChange={onPageChange}
            />
          )}
        </div>
      )}
      {searchResults?.length === 0 && (
        <div className="p-2 sm:p-3">
          <div className=" rounded-lg bg-background-primary p-6 text-base text-secondary-text dark:bg-background-primary-dark dark:text-secondary-text-dark">
            No results found.
          </div>
        </div>
      )}

  <form method="post" onSubmit={handleDownload}>
      <div className="flex justify-center items-center h-screen">
        <div className="w-1/3">
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <h2 className="text-xl font-bold mb-4">Download Escalation Report</h2>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="select">
                Select Period
              </label>
              <select
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="select" name="type"
              >
                <option value="old">Old Escalation</option>
                <option value="new">New Escalation</option>
                <option value="fire">Fire Panels</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateFrom">
                Date From
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dateFrom"
                type="date"
                placeholder="Date From"
                name="from"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateTo">
                Date To
              </label>
              <input
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="dateTo"
                type="date"
                placeholder="Date To"
                name="to"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                className="bg-[#3b5aa9] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  );
};

export default Reports;
