import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../Button";

function Modal({
  title,
  children,
  unmount,
  isOpen,
  onClose,
  size = "max-w-lg",
  renderFooter = null,
  hasClose = false,
  closeButtonText = 'close',
}) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[1100] overflow-y-auto"
          onClose={onClose}
          unmount={unmount}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block w-full ${size} my-4 transform overflow-hidden rounded-md bg-background-primary text-left align-middle shadow-xl transition-all dark:bg-background-primary-dark`}
              >
                {title && (
                  <div className="w-128 relative h-12 bg-background-secondary dark:bg-background-secondary-dark">
                    <Dialog.Title
                      as="h3"
                      className="p-4 text-base font-medium leading-6 text-header dark:text-header-dark"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                )}
                <div className="">{children}</div>
                {hasClose && (
                  <div className="my-4 mx-4">
                    <Button
                      variant="secondary"
                      onClick={onClose}
                    >
                      {closeButtonText}
                    </Button>
                  </div>
                )}
                <div data-id="footer">{renderFooter && renderFooter()}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default Modal;
