import FormInput from "components/common/form/FormInput";

export const renderViewControl = (key, field, value) => {
  const { cols, sm, xs } = field;
  const classNameArray = [];
  if (xs || sm) {
    if (xs) {
      classNameArray.push(xs.start ? `col-start-${xs.start}` : "");
      classNameArray.push(xs.end ? `col-end-${xs.end + 1}` : "");
    }
    if (sm) {
      classNameArray.push(sm.start ? `sm:col-start-${sm.start}` : "");
      classNameArray.push(sm.end ? `sm:col-end-${sm.end + 1}` : "");
    }
  } else {
    classNameArray.push(cols.xs ? `col-span-${cols.xs}` : `col-span-12`);
    classNameArray.push(cols.sm ? `sm:col-span-${cols.sm}` : `col-span-12`);
  }
  const colClassName = classNameArray.join(" ");

  if (field.type === "array" || field.type === "arrayWithModal") {
    const newFields = Object.entries(field.fields || []).map(
      ([key, value]) => ({
        key,
        label: value.label,
      }),
    );

    return (
      <div
        className="col-start-1 col-end-13 sm:col-start-1 sm:col-end-13"
        key={key}
      >
        <dt className="text-sm font-medium text-gray-500">
          <div className="flex">
            <span>{field.label}</span>
          </div>
        </dt>
        <div className="flex w-full flex-col items-start gap-4 overflow-x-auto">
          <div className="flex w-full items-start gap-10">
            {newFields.map((f, idx) => (
              <div className="min-w-[7rem] flex-1" key={idx}>
                <dt className="font-small text-sm text-gray-500">
                  <div className="mt-1 flex">
                    <span>{f.key}</span>
                  </div>
                </dt>
              </div>
            ))}
          </div>
          {value.map((row, index) => (
            <div className="flex w-full items-start gap-10" key={index}>
              {newFields.map((item, idx) => (
                <dd
                  key={`${item.key}_${idx}`}
                  className="mt-1 min-w-[7rem] flex-1 text-sm text-header dark:text-header-dark"
                >
                  {row[item.key]}
                </dd>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={colClassName} key={key}>
      <dt className="text-sm font-medium text-gray-500">
        <div className="flex">
          <span>{field.label}</span>
        </div>
      </dt>
      {value instanceof Object ? (
        <div className={"flex items-start gap-10"}>
          {value.map((obj) =>
            Object.keys(obj).map((key) => {
              return (
                <div key={key} className="min-w-[7rem] flex-1">
                  <dt className="font-small text-sm text-gray-500">
                    <div className="mt-1 flex">
                      <span>{key.replace(/_/g, " ")} </span>
                    </div>
                  </dt>
                  <dd className="mt-1 text-sm text-header dark:text-header-dark">
                    {" "}
                    {obj[key]}{" "}
                  </dd>
                </div>
              );
            }),
          )}
        </div>
      ) : (
        <dd className="mt-1 text-sm text-header dark:text-header-dark">
          {value}
        </dd>
      )}
    </div>
  );
};

export const renderEditComponent = (
  key,
  field,
  register,
  control,
  errors,
  dynamicOptions,
  forceViewportSize,
) => {
  const { type, label, options, selectOptions, values, fields, defaultValue } = field;
  let { cols, sm, xs } = field;
  const classNameArray = [];
  if (forceViewportSize) {
    classNameArray.push(
      field[forceViewportSize]?.start
        ? `col-start-${field[forceViewportSize]?.start}`
        : "",
    );
    classNameArray.push(
      field[forceViewportSize]?.end
        ? `col-end-${Number(field[forceViewportSize].end) + 1}`
        : "",
    );
  } else if (xs || sm) {
    if (xs) {
      classNameArray.push(xs.start ? `col-start-${xs.start}` : "");
      classNameArray.push(xs.end ? `col-end-${xs.end}` : "");
    }
    if (sm) {
      classNameArray.push(sm.start ? `sm:col-start-${sm.start}` : "");
      classNameArray.push(sm.end ? `sm:col-end-${sm.end}` : "");
    }
  } else {
    classNameArray.push(cols.xs ? `col-span-${cols.xs}` : `col-span-12`);
    classNameArray.push(cols.sm ? `sm:col-span-${cols.sm}` : `col-span-12`);
  }
  const colClassName = classNameArray.join(" ");
  return (
    <div className={colClassName}>
      <FormInput
        register={register}
        control={control}
        name={key}
        type={type}
        label={label}
        options={options}
        values={values}
        error={!!errors?.[key]}
        fields={fields}
        selectOptions={dynamicOptions?.[key] || selectOptions}
        dynamicOptions={type === "array" ? dynamicOptions : null}
        defaultValue={defaultValue}
      />
    </div>
  );
};
