import { toObject } from "utils";
import {
  getReachableNodes,
  getReferableStrings,
  matchStrings,
  validReferenceRegexp,
} from "../utils/variable-references";
import {
  removeDeletedNodeErrors,
  addedNodeReplacesNotFoundErrorsWithUnreachable,
  deletedNodeIsReferenced,
  graphHasCycles,
  referencesAccessValidProperties,
  referencesAreReachable,
  addedEdgeFixesReferences,
  referencesAreValidNodeTypes,
  referencesExist,
  deletedEdgeBreaksReferences,
  hasMissingHandleConnections,
  referencesAreReachableFromAllBranches,
  deletedEdgeFixesReferences,
  addedEdgeBreaksReferences,
} from "./validators";
import { EVENT_TYPES } from "../events";

export const VALIDATION_ERRORS = {
  REFERENCE_INVALID_TYPE: "REFERENCE_INVALID_TYPE",
  REFERENCE_INVALID_PROPERTY: "REFERENCE_INVALID_PROPERTY",
  REFERENCE_NOT_FOUND: "REFERENCE_NOT_FOUND",
  REFERENCE_UNREACHABLE: "REFERENCE_UNREACHABLE",
  REFERENCE_UNREACHABLE_SOME_PATHS: "REFERENCE_UNREACHABLE_SOME_PATHS",
  NODE_MISSING_CONNECTIONS: "NODE_MISSING_CONNECTIONS",
  GRAPH_CYCLE: "GRAPH_CYCLE",
};

export const VALIDATION_PIPELINES = {
  [EVENT_TYPES.NODE_CREATE]: [
    referencesExist,
    referencesAreValidNodeTypes,
    referencesAccessValidProperties,
    referencesAreReachable,
    hasMissingHandleConnections,
    addedNodeReplacesNotFoundErrorsWithUnreachable,
  ],
  [EVENT_TYPES.NODE_UPDATE]: [
    referencesExist,
    referencesAreValidNodeTypes,
    referencesAccessValidProperties,
    referencesAreReachable,
    // needs to run because some node types can add/remove handles
    hasMissingHandleConnections,
    referencesAreReachableFromAllBranches,
  ],
  [EVENT_TYPES.NODE_DELETE]: [deletedNodeIsReferenced, removeDeletedNodeErrors],
  [EVENT_TYPES.EDGE_CREATE]: [
    graphHasCycles,
    addedEdgeFixesReferences,
    addedEdgeBreaksReferences,
    hasMissingHandleConnections,
  ],
  [EVENT_TYPES.EDGE_DELETE]: [
    graphHasCycles,
    deletedEdgeBreaksReferences,
    deletedEdgeFixesReferences,
    hasMissingHandleConnections,
  ],
};

/**
 * Used to load the data in the validations context
 *
 * If the data already exists skips computation
 */
class DataLoader {
  setContext = (context) => {
    this.context = context;
    return this;
  };

  canLoad = (name, allowedEventTypes = []) => {
    if (!allowedEventTypes.includes(this.context.event.type))
      throw new Error(
        `DataLoader.${name} cannot be loaded for event type: ${this.context.event.type}`,
      );
  };

  referencedNodeIds = () => {
    this.canLoad("referencedNodeIds", [
      EVENT_TYPES.NODE_CREATE,
      EVENT_TYPES.NODE_UPDATE,
      EVENT_TYPES.NODE_DELETE,
    ]);
    if (!this.context.referencedNodeIds) {
      const { data: node } = this.context.event;
      const strings = getReferableStrings(node);
      this.context.strings = strings;
      this.context.referencedNodeIds = matchStrings(
        strings,
        validReferenceRegexp,
        (match) => match.split(".")[0].replace("${", "").trim(),
      );
    }
    return this;
  };

  nodesToObj = () => {
    if (!this.context.nodesToObj) {
      const {
        state: { nodes },
      } = this.context.event;
      this.context.nodesToObj = toObject(nodes, (node) => node.id);
    }
    return this;
  };

  edgesToNodeIdMap = () => {
    if (!this.context.edgesToNodeIdMap) {
      const {
        state: { edges },
      } = this.context.event;
      this.context.edgesToNodeIdMap = edges.reduce(
        (map, edge) => {
          if (!map.previous[edge.target]) map.previous[edge.target] = [edge];
          else if (
            !map.previous[edge.target].find(
              ({ source }) => source === edge.source,
            )
          ) {
            map.previous[edge.target].push(edge);
          }
          if (!map.following[edge.source]) map.following[edge.source] = [edge];
          else if (
            !map.following[edge.source].find(
              ({ target }) => target === edge.target,
            )
          ) {
            map.following[edge.source].push(edge);
          }
          return map;
        },
        { previous: {}, following: {} },
      );
    }
    return this;
  };

  previousNodeIds = () => {
    this.canLoad("previousNodeIds", [
      EVENT_TYPES.NODE_CREATE,
      EVENT_TYPES.NODE_UPDATE,
    ]);
    if (!this.context.previousNodeIds) {
      const {
        state: { nodes },
        data: node,
      } = this.context.event;
      this.edgesToNodeIdMap();
      this.context.previousNodeIds = getReachableNodes(
        node.id,
        nodes,
        this.context.edgesToNodeIdMap,
      ).nodeIds;
    }
    return this;
  };

  followingNodeIds = () => {
    this.canLoad("followingNodeIds", [
      EVENT_TYPES.EDGE_CREATE,
      EVENT_TYPES.EDGE_DELETE,
    ]);
    if (!this.context.followingNodeIds) {
      const {
        state: { nodes },
        data: edge,
      } = this.context.event;
      this.edgesToNodeIdMap();
      const { nodeIds, duplicateIds } = getReachableNodes(
        edge.target,
        nodes,
        this.context.edgesToNodeIdMap,
        "following",
      );
      this.context.followingNodeIds = nodeIds;
      this.context.duplicateIds = duplicateIds;
    }
    return this;
  };
}

/**
 * Context is used to store data that validations share
 *
 * call context.hasMutated() to update the errors state
 */
export class Context {
  mutated = false;
  constructor(event, loader) {
    this.event = event;
    this.loader = loader.setContext(this);
  }
  hasMutated = () => {
    this.mutated = true;
  };
  load = () => this.loader;
}

export class ValidationManager {
  constructor(initialErrors = {}) {
    // mutable object that stores the results of validators
    this.errors = structuredClone(initialErrors);
    this.loader = new DataLoader();
  }

  suppressError = (key, index) => {
    const error = this.errors[key].splice(index, 1)[0];
    if (error.suppressed) {
      delete error.suppressed;
      this.errors[key].unshift(error);
    } else {
      error.suppressed = true;
      this.errors[key].push(error);
    }
    return this.errors;
  };

  /**
   * Runs the validators in the same order as defined in the array
   */
  run =
    (validators = [], callback) =>
    (event) => {
      const context = new Context(event, this.loader);
      validators.forEach((validation) =>
        validation(event, context, this.errors),
      );
      Object.keys(this.errors).forEach((id) => {
        if (this.errors[id].length === 0) {
          delete this.errors[id];
          context.hasMutated();
        }
      });
      if (context.mutated && callback) callback(this.errors);
    };
}

export const formatValidationErrorMessage = (error) => {
  switch (error.type) {
    case VALIDATION_ERRORS.REFERENCE_NOT_FOUND:
      return `'${error.item}' node does not exist`;
    case VALIDATION_ERRORS.REFERENCE_INVALID_TYPE:
      return `'${error.item}' is not a valid node type`;
    case VALIDATION_ERRORS.REFERENCE_INVALID_PROPERTY: {
      return `'${error.item.split(".")[1]}' property in '${
        error.item
      }' is not valid${
        error.info?.validProperties?.length > 0
          ? `. Available properties: '${error.info.validProperties.join(
              "', '",
            )}'`
          : ""
      }`;
    }
    case VALIDATION_ERRORS.REFERENCE_UNREACHABLE:
      return `'${error.item}' node is unreachable`;
    case VALIDATION_ERRORS.REFERENCE_UNREACHABLE_SOME_PATHS:
      return `'${error.item}' potentially unreachable from one or more paths`;
    case VALIDATION_ERRORS.NODE_MISSING_CONNECTIONS:
      return "Node is missing connections";
    case VALIDATION_ERRORS.GRAPH_CYCLE:
      return error.item.join(" → ");
    default:
      return `item: '${error.item}'. error: '${error.type}'`;
  }
};
