import React from "react";
import Modal from "./helpModal";

function SurveyHeader({ title, setCurrentStep,  showModal, setShowModal}) {

    return (
        <>
        <header className="bg-[#3b5aa9] text-white py-4 px-6 flex items-center justify-between">
            <div className="flex items-center">
                <button onClick={() => setCurrentStep(1)} className="text-white mr-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 15.75l-3.75-3.75m0 0l3.75-3.75m-3.75 3.75h9" />
                    </svg>
                </button>
                <span className="text-lg font-semibold">{title}</span>
            </div>
            <button onClick={() => setShowModal(true)} className="text-white">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                </svg>

            </button>
        </header>
        <Modal isOpen={showModal} onClose={() => setShowModal(false)} />
        </>
    )
}

export default SurveyHeader;