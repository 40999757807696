import React, { useState } from "react";
// import GaugeComponent from 'react-gauge-component'
import { Button } from "components/common";
import SurveyHeader from "./surveyHeader";


let iframeSources = {
    "WA": "//speed1.epik.io",
    "OR": "//speed1.epik.io",
    "CA": "//speed1.epik.io",
    "NV": "//speed1.epik.io",
    "ID": "//speed1.epik.io",
    "UT": "//speed1.epik.io",
    "WY": "//speed1.epik.io",
    "MT": "//speed1.epik.io",
    "AZ": "//speed1.epik.io",
    "CO": "//speed1.epik.io",
    "NM": "//speed1.epik.io",
    "TX": "//speed1.epik.io",
    "OK": "//speed1.epik.io",
    "AR": "//speed1.epik.io",
    "LA": "//speed1.epik.io",
    "KS": "//speed1.epik.io",
    "MS": "//speed4.epik.io",
    "AL": "//speed4.epik.io",
    "GA": "//speed4.epik.io",
    "FL": "//speed4.epik.io",
    "SC": "//speed4.epik.io",
    "NC": "//speed4.epik.io",
    "TN": "//speed4.epik.io",
    "ND": "//speed2.epik.io",
    "SD": "//speed2.epik.io",
    "NE": "//speed2.epik.io",
    "MN": "//speed2.epik.io",
    "IA": "//speed2.epik.io",
    "MO": "//speed2.epik.io",
    "IL": "//speed2.epik.io",
    "WI": "//speed2.epik.io",
    "MI": "//speed2.epik.io",
    "IN": "//speed2.epik.io",
    "KY": "//speed2.epik.io",
    "OH": "//speed2.epik.io",
    "WV": "//speed3.epik.io",
    "VA": "//speed3.epik.io",
    "MD": "//speed3.epik.io",
    "PA": "//speed3.epik.io",
    "NY": "//speed3.epik.io",
    "NJ": "//speed3.epik.io",
    "CT": "//speed3.epik.io",
    "MA": "//speed3.epik.io",
    "NH": "//speed3.epik.io",
    "VT": "//speed3.epik.io",
    "ME": "//speed3.epik.io",
    "RI": "//speed3.epik.io",
    "AK": "//speed1.epik.io",
    "HI": "//speed1.epik.io"
}

function SpeedTestTask({ setCurrentStep, state, saveForm, form }) {
    const [showModal, setShowModal] = useState(false);
    const [downloadSpeed, setDownloadSpeed] = useState('');
    const [uploadSpeed, setUploadSpeed] = useState('');
    const [ping, setPing] = useState('');
    const [jitter, setJitter] = useState('');

    const handleFormSubmit = async(e) => {
        e.preventDefault();
        let data = [
            {
                downloadSpeed,
                uploadSpeed,
                ping,
                jitter
            }
        ];

        let response = await saveForm(data, form?.id);
        if (response?.message !== 'Form submitted' && response?.message !== 'Submission Updated') {
            toast.error(`Unable to save data`)
            return
          }
      
          setCurrentStep(1)
    };

    return (
        <div className="min-h-screen bg-[#e6f0ff] font-poppins">
            <SurveyHeader title={'Speed Test'} setCurrentStep={setCurrentStep} setShowModal={setShowModal} showModal={showModal} />
            <div className="flex flex-col gap-3 items-center justify-center p-4">
                <h3 className="text-1xl p-1 font-medium">Please complete the following steps:</h3>
                <div className="grid w-full max-w-md gap-4 bg-white p-6 sm:grid-cols-1 md:grid-cols-1">
                    <ol className="flex justify-center items-start flex-col px-10">
                        <li className="list-decimal"><span>Turn off Wifi on mobile device</span></li>
                        <li className="list-decimal"><span>Downgrade phone to LTE</span></li>
                        <li className="list-decimal"><span>When ready run speed test below</span></li>
                    </ol>
                </div>
            </div>
            <div className="text-right p-4">
            <div className="mb-5 relative">
                <div className="w-full pb-[80%] relative overflow-hidden"> {/* overflow hidden added */}
                    <iframe
                        className="border-none absolute top-0 left-0 w-full h-full rounded-lg" // Zoom 1.1x
                        src={iframeSources[state] || '//speed1.epik.io'}
                        title="Speed Test"
                    />
                </div>
            </div>
        </div>

            {/* Form for Download Speed, Upload Speed, Ping, and Jitter */}
            <div className="p-4">
                <form onSubmit={handleFormSubmit} className="grid gap-4 max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
                    <div className="flex flex-col">
                        <label htmlFor="downloadSpeed" className="mb-2 font-medium">Download Speed (Mbps)</label>
                        <input
                            type="text"
                            id="downloadSpeed"
                            value={downloadSpeed}
                            onChange={(e) => setDownloadSpeed(e.target.value)}
                            className="border border-gray-300 rounded-md p-2"
                            placeholder="Enter download speed"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="uploadSpeed" className="mb-2 font-medium">Upload Speed (Mbps)</label>
                        <input
                            type="text"
                            id="uploadSpeed"
                            value={uploadSpeed}
                            onChange={(e) => setUploadSpeed(e.target.value)}
                            className="border border-gray-300 rounded-md p-2"
                            placeholder="Enter upload speed"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="ping" className="mb-2 font-medium">Ping (ms)</label>
                        <input
                            type="text"
                            id="ping"
                            value={ping}
                            onChange={(e) => setPing(e.target.value)}
                            className="border border-gray-300 rounded-md p-2"
                            placeholder="Enter ping"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="jitter" className="mb-2 font-medium">Jitter (ms)</label>
                        <input
                            type="text"
                            id="jitter"
                            value={jitter}
                            onChange={(e) => setJitter(e.target.value)}
                            className="border border-gray-300 rounded-md p-2"
                            placeholder="Enter jitter"
                        />
                    </div>
                    <Button
                    type="submit"
                    form="generic-form"
                    onClick={handleFormSubmit}
                    className="cursor-pointer transition-all bg-[#3b5aa9] text-white px-6 py-2 rounded-lg
                            border-[#3b5aa9]
                            border-b-[4px] hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[6px]
                            active:border-b-[2px] active:brightness-90 active:translate-y-[2px]"
                >
                Save
                </Button>
                </form>
            </div>
        </div>
    );
}

export default SpeedTestTask;
