import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { useReactFlow } from "reactflow";
import { useForm } from "react-hook-form";
import Modal from "components/common/Modal";
import Button from "components/common/Button";
import { NODE_CONFIG, NODE_TYPE, ALERTS } from "utils/constants";
import { RadioGroup } from "components/common";
import { ErrorBoundary } from "components/common/ErrorBoundary";
import FormInput from "components/common/form/FormInput";
import FileUploader from "components/common/FileUploader";
import { uploadFiles } from "api";
import { classNames } from "utils";
import ReferenceFlowNode from "./ReferenceFlow";
import Label from "components/common/form/Label";
import { useEditorState } from "workflow-editor/context/editor";
import {
  ChevronUpIcon,
  WrenchIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { AttachmentPreview } from "components/common/attachments/AttachmentPreview";
import AttachmentsModal from "components/common/attachments/AttachmentModal";
import { notify } from "utils/notification";
import { postFrontendErrorLogs } from "api";
import {
  onVariableSelectionHandler,
  onChangeAutoCompleteHandler,
} from "workflow-editor/utils/addNode.autocompleteHandler";
import { InfoEditorModal } from "../InfoEditorModal";
import { InfoModal } from "components/common/InfoModal";

const AddNodeModal = ({
  onSave,
  workflow,
  title = null,
  editedNode = null,
  isOpen = false,
  onClose = null,
  options = {},
  parentNode = null,
}) => {
  const { nodes } = useEditorState();
  const [nodeType, setNodeType] = useState(NODE_CONFIG[0]);
  const [dynamicOptions, setDynamicOptions] = useState({});
  const [nodeState, setNodeState] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [info, setInfo] = useState(editedNode?.data?.info);
  const [existingAttachments, setExistingAttachments] = useState(
    editedNode?.data?.images ?? [],
  );
  const [displayAttachments, setDisplayAttachments] = useState(
    existingAttachments.length > 0,
  );
  const [loading, setLoading] = useState(false);
  const { project } = useReactFlow();
  const checkboxHandles = useRef([""]);
  const [displayAttachmentsModal, setDisplayAttachmentsModal] = useState(false);
  const [displayInfoEditorModal, setDisplayInfoEditorModal] = useState(false);
  const [displayInfoPreviewModal, setDisplayInfoPreviewModal] = useState(false);
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(null);

  const onAttachmentClickHandler = (index) => {
    setCurrentAttachmentIndex(index);
    setDisplayAttachmentsModal(true);
  };

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isValidating, isSubmitting, isSubmitSuccessful },
  } = useForm({
    mode: "all",
  });

  const onCloseHandler = () => {
    onClose?.(true);
  };

  const parseNotificationSettings = async () => {
    try {
      if (workflow?.notifications_config?.length > 0) {
        setDynamicOptions((prevState) => ({
          ...prevState,
          failureGroup: [
            {
              value: "",
              label: "Choose ...",
            },
            ...workflow?.notifications_config?.map((setting) => ({
              value: setting.message,
              label: setting.message,
            })),
          ],
        }));
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (nodeType.type === NODE_TYPE.CHECKBOX) {
      const subscription = watch((value, { name }) => {
        if (
          (name || "").includes("answers") &&
          value?.answers?.filter((a) => a).length > 0 // react strict mode fix
        ) {
          // if answer is added or removed name = 'answers'
          // otherwise if answer text gets updated name = 'answers[<id>].[text]'
          const match = name.match(/^answers\[(?<id>\d+)\]\./);
          let _handles = value?.handles;
          if (match) {
            // text update
            const previousValue = checkboxHandles.current[match.groups.id];
            const currentValue = value.answers[match.groups.id].text;
            // skip if duplicate answers are present
            if (
              value.answers.filter(({ text }) => text === previousValue)
                .length > 1 ||
              value.answers.filter(({ text }) => text === currentValue).length >
                1
            )
              return;
            // update answer text in handlers with new value
            _handles = _handles.map((h) => ({
              ...h,
              handle: h.handle?.map((e) =>
                previousValue !== e ? e : currentValue,
              ),
            }));
          } else {
            // answer added/removed
            const answerTexts = value?.answers?.map((a) => a.text);
            _handles = _handles
              ?.filter((h) => h.handle?.some((e) => answerTexts.includes(e)))
              .map((h) => ({
                ...h,
                handle: h.handle?.filter((e) => answerTexts.includes(e)),
              }));
          }

          checkboxHandles.current = value?.answers.map((a) => a.text);
          setDynamicOptions({
            handle: value?.answers.map((a) => ({
              value: a.text,
              label: a.text,
            })),
          });
          setValue("handles", _handles);
        }
      });
      return () => subscription.unsubscribe();
    }
    if (nodeType.type === NODE_TYPE.API) {
      const subscription = watch((value, { name }) => {
        const category = value.category === "common" ? "main" : value.category;
        if (name === "category" || name === "arguments") {
          const endpoint = (options.endpoints[category] ?? [])
            .map(({ endpoint, arguments: parameters }) => ({
              value: endpoint.split("/api/")[1],
              label: endpoint.split("/api/")[1],
              parameters,
            }))
            .sort((a, b) => a.value.localeCompare(b.value));
          setDynamicOptions((prevState) => ({
            ...prevState,
            endpoint,
          }));
        }
        // loads the select dropdown with the response options for the selected endpoint
        if (name === "endpoint") {
          const responses = options.endpoints?.[category]?.find(
            (endpoint) => endpoint.endpoint === "/api/" + value.endpoint,
          )?.responses;
          if (responses) {
            const response = Object.entries(responses).map(
              ([value, label]) => ({
                value,
                label,
              }),
            );
            setDynamicOptions((prevState) => ({
              ...prevState,
              response: [
                {
                  value: null,
                  label: "",
                },
                ...response,
              ],
            }));
          } else {
            setDynamicOptions((prevState) => ({
              ...prevState,
              response: [],
            }));
          }
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [watch, nodeType]);

  // fix when opening an editedNode endpoint defaults to
  // first endpoint from the list instead of the saved one
  useEffect(() => {
    if (dynamicOptions.endpoint) {
      if (
        editedNode?.data?.endpoint &&
        dynamicOptions.endpoint.some(
          ({ value }) => value === editedNode.data.endpoint,
        )
      ) {
        setValue("endpoint", editedNode.data.endpoint, {
          shouldValidate: true,
        });
      } else
        setValue("endpoint", dynamicOptions.endpoint[0].value, {
          shouldValidate: true,
        });
    }
  }, [editedNode, dynamicOptions.endpoint]);

  useEffect(() => {
    if (editedNode && dynamicOptions.response) {
      const { endpoint, category, results } = getValues();
      // reset results
      if (
        editedNode.data.endpoint !== endpoint ||
        editedNode.data.category !== category
      )
        for (let idx in results) {
          setValue(`results.${idx}.response`, "", {
            shouldValidate: true,
          });
        }
      // reapply the values of the response fields
      else
        for (let idx in editedNode.data.results) {
          setValue(
            `results.${idx}.response`,
            editedNode.data.results[idx].response,
            {
              shouldValidate: true,
            },
          );
        }
    }
  }, [editedNode, dynamicOptions.response]);

  useEffect(() => {
    if (nodeType.type === NODE_TYPE.CHECKBOX) {
      const values = getValues();
      const answers =
        values?.answers?.filter((a) => a).map((a) => a.text) || [];
      const newEntries =
        answers.filter((a) => !nodeState.answers?.includes(a)) || [];
      const oldEntries =
        nodeState?.answers?.filter((a) => !answers.includes(a)) || [];
      if (newEntries.length > 0 && oldEntries.length > 0) {
        const oldEntry = oldEntries[0];
        const newEntry = newEntries[0];
        const handles = values?.handles?.map((h) => {
          if (h.handle?.includes(oldEntry)) {
            return {
              handle: [...h.handle.filter((e) => e !== oldEntry), newEntry],
            };
          }
          return h;
        });
        setValue("handles", handles);
      }
      setNodeState((prevState) => ({
        ...prevState,
        answers: values?.answers?.filter((a) => a).map((a) => a.text),
      }));
    }
  }, [isValidating]);

  const saveAttachments = async () => {
    let images = [];
    if (attachments.length) {
      const formData = new FormData();
      attachments.forEach((file) => formData.append("files", file));
      const {
        data: { data: files },
      } = await uploadFiles(formData);
      images = files
        .filter((r) => r.status === "success")
        .map((r) => r.filename);
    }
    if (existingAttachments.length) {
      images = [...existingAttachments, ...images];
    }
    return images;
  };

  useEffect(() => {
    if (isSubmitting) {
      const values = getValues();
      if (nodeType.type === NODE_TYPE.CHECKBOX) {
        clearErrors();
        const { removed, checkBoxDuplicates } = removeDuplicateCheckboxes(
          values?.handles,
        );
        if (removed?.length !== values?.handles.length) {
          handleError("handles", checkBoxDuplicates);
        }
      }
      if (nodeType.type === NODE_TYPE.API) {
        clearErrors();
        const { removed, apiDuplicates } = removeDuplicateAPIS(values?.results);
        if (removed?.length !== values?.results.length) {
          handleError("results", apiDuplicates);
        }
      }
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      onCloseHandler();
    }
  }, [isSubmitSuccessful]);

  const removeDuplicateAPIS = (handles) => {
    const uniques = [];
    let apiDuplicates = [];
    const removed = handles.filter((el, index, arr) => {
      if (
        !uniques.includes(
          el.status?.toString() +
            el.success?.toString() +
            el.response?.toString(),
        )
      ) {
        uniques.push(
          el.status?.toString() +
            el.success?.toString() +
            el.response?.toString(),
        );
        return true;
      }
      apiDuplicates.push(
        arr.findIndex((firstEl) => {
          return (
            firstEl.status?.toString() +
              firstEl.success?.toString() +
              firstEl.response?.toString() ===
            el.status?.toString() +
              el.success?.toString() +
              el.response?.toString()
          );
        }) + 1,
      );
      apiDuplicates.push(index + 1);
      return false;
    });
    apiDuplicates = removeDuplicateRows(apiDuplicates);
    return { removed, apiDuplicates };
  };

  const removeDuplicateCheckboxes = (handles) => {
    const uniques = [];
    let checkBoxDuplicates = [];
    const removed = handles.filter((el, index, arr) => {
      if (!uniques.includes(el.handle?.sort()?.toString())) {
        uniques.push(el.handle?.sort()?.toString());
        return true;
      }
      checkBoxDuplicates.push(
        arr.findIndex(
          (firstEl) => firstEl.handle?.toString() === el.handle?.toString(),
        ) + 1,
      );
      checkBoxDuplicates.push(index + 1);
      return false;
    });
    checkBoxDuplicates = removeDuplicateRows(checkBoxDuplicates);
    return { removed, checkBoxDuplicates };
  };

  const removeDuplicateRows = (dups) => {
    const uniques = [];
    const removed = dups.filter((el) => {
      if (!uniques.includes(el)) {
        uniques.push(el);
        return true;
      }
      return false;
    });
    return removed;
  };

  const handleError = (formSection, formRows) => {
    setError(formSection, { type: "duplicate", message: "Duplicate entry" });
    notify.error(
      `${ALERTS.DUPLICATE_ENTRIES} \nRows [${formRows}] under "${formSection}"`,
    );
  };

  // function that returns the correct properties of a node
  const filterNodeProperties = (entry) => {
    if (Object.keys(nodeType.schema).includes(entry[0])) {
      return entry;
    }
  };

  const onSubmit = async (data, closeModalCallback) => {
    setLoading(true);
    let position = project({
      x: window.innerWidth / 3,
      y: window.innerHeight / 3,
    });
    if (parentNode) {
      const parent = nodes.find(({ id }) => id === parentNode);
      const nodeWidth = nodeType.type === NODE_TYPE.LOOP ? 512 : 384;
      const offset = 40;
      if (parent?.height) {
        position = {
          x: parent.width - nodeWidth - offset,
          y: parent.height / 2,
        };
      }
    }
    try {
      let newData = {};
      if (nodeType.type !== NODE_TYPE.REFERENCE_FLOW) {
        newData = {
          ...Object.fromEntries(
            Object.entries(data).filter(filterNodeProperties),
          ),
          images: [],
        };
      } else {
        newData = {
          ...data,
          images: [],
        };
      }
      if (nodeType.type === NODE_TYPE.API) {
        // add endpoint and parameters back as they get filtered out by `filterNodeProperties`
        newData.endpoint = data.endpoint;
        newData.parameters = data.parameters;
        // filter out arguments that have an empty key/value
        newData.arguments = newData.arguments.filter(
          ({ key, value }) => key && value,
        );
      }
      if (attachments.length || existingAttachments.length) {
        newData.images = await saveAttachments();
      }
      if (info !== undefined) {
        newData.info = info;
      }
      // edited node
      if (editedNode) {
        // when changing node type we need to filter out old node properties
        // add a property to note to delete the old node for the addNode function
        if (editedNode.type !== nodeType.type) {
          const node = {
            id: editedNode.id,
            type: nodeType.type,
            position: editedNode.position,
            data: newData,
            deleteExistingNode: true,
          };
          if (!!editedNode.parentNode) {
            node.parentNode = editedNode.parentNode;
            node.extent = "parent";
          }
          onSave(node);
        } else {
          const node = { ...editedNode, data: newData };
          onSave(node);
        }
      }
      // new node
      else {
        const node = {
          type: nodeType.type,
          data: newData,
          position,
        };
        if (!!parentNode) {
          node.parentNode = parentNode;
          node.extent = "parent";
        }
        onSave(node);
      }
      setLoading(false);
      typeof closeModalCallback === "function" && closeModalCallback();
    } catch (error) {
      setLoading(false);
      postFrontendErrorLogs(error);
    }
  };

  const onRadioChange = (node) => {
    switch (node.type) {
      case NODE_TYPE.CHECKBOX:
      case NODE_TYPE.RADIO:
        const values = getValues();
        setCheckboxOptions(values?.answers);
        setNodeType(node);
        break;
      case NODE_TYPE.API:
        setApiOptions();
        setNodeType(node);
        break;
      default:
        reset();
        setNodeType(node);
        break;
    }
  };

  useEffect(() => {
    parseNotificationSettings();
  }, [nodeType]);

  const setApiOptions = useCallback(() => {
    const category = [
      "common",
      ...Object.keys(options.endpoints).filter((c) => c !== "main"),
    ];
    setDynamicOptions((prevState) => ({
      ...prevState,
      category,
    }));
  }, [options]);

  useEffect(() => {
    if (editedNode) {
      reset(editedNode.data);
      setNodeType(NODE_CONFIG.find((node) => node.type === editedNode.type));
      if (editedNode.type === NODE_TYPE.CHECKBOX) {
        setCheckboxOptions(editedNode.data?.answers);
      }
      if (editedNode.type === NODE_TYPE.LOOP) setLoopNodeOptions(editedNode);
    } else {
      reset({});
    }
  }, [editedNode]);

  useEffect(() => {
    setApiOptions();
  }, [setApiOptions]);

  const setCheckboxOptions = (options = []) => {
    setDynamicOptions({
      handle: options.map((a) => ({
        value: a.text,
        label: a.text,
      })),
    });
    setNodeState((prevState) => ({
      ...prevState,
      answers: options.map((a) => a.text),
    }));
  };

  const setLoopNodeOptions = (loopNode) => {
    const childNodes = nodes.filter(
      ({ parentNode }) => parentNode === loopNode.id,
    );
    if (childNodes.length > 0)
      setDynamicOptions({
        starting_node: [
          { value: "", label: "Select starting node" },
          ...childNodes.map(({ id }) => ({
            value: id,
            label: id,
          })),
        ],
      });
  };

  const onRemoveAttachment = (index) => {
    const newAttachments = [...existingAttachments];
    newAttachments.splice(index, 1);
    setExistingAttachments(newAttachments);
  };

  const onRemoveAllAttachments = () => {
    setExistingAttachments([]);
  };

  const selectedEndpoint = watch("endpoint");
  const parameters =
    dynamicOptions?.endpoint?.find(({ value }) => selectedEndpoint === value)
      ?.parameters ?? [];
  const parameterFields = parameters.reduce((fields, parameter) => {
    fields[parameter] = {
      type: "text",
      label: parameter,
      options: {
        required: true,
      },
    };
    return fields;
  }, {});

  // filtered out start node from radio group options
  const radioGroupValues = NODE_CONFIG.filter(
    (node) => node.type !== NODE_TYPE.START,
  );

  const onInfoEditorSave = (content) => {
    setInfo(content);
    setDisplayInfoEditorModal(false);
  };

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      unmount={false}
      size="max-w-4xl"
    >
      {!editedNode && (
        <div className="border-b border-b-gray-300 p-4 dark:border-b-gray-300/30">
          <RadioGroup
            title={"Step Type"}
            values={radioGroupValues}
            selected={nodeType}
            onChange={onRadioChange}
          />
        </div>
      )}
      {nodeType.type === NODE_TYPE.REFERENCE_FLOW && (
        <ReferenceFlowNode
          nodes={nodes}
          workflow={workflow}
          editedNode={editedNode}
          onSave={onSubmit}
          onClose={onCloseHandler}
        />
      )}
      {nodeType.type !== NODE_TYPE.REFERENCE_FLOW && (
        <form
          action="#"
          method="GET"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-full flex-1 flex-col divide-y divide-gray-300 dark:divide-gray-300/30"
        >
          <div className="flex flex-row">
            <div className="flex-1 space-y-4 overflow-y-auto p-4">
              <ErrorBoundary>
                {Object.entries(nodeType.schema).map(
                  ([
                    key,
                    { type, label, options, selectOptions, values, fields },
                  ]) => {
                    if (key === "starting_node") {
                      if (!editedNode) return null;
                      const childNodes = nodes.filter(
                        ({ parentNode }) => parentNode === editedNode.id,
                      );
                      if (childNodes.length === 0) return null;
                    }
                    return (
                      <Fragment key={key}>
                        <div className="mt-2">
                          <FormInput
                            register={register}
                            watch={watch}
                            isValidating={isValidating}
                            control={control}
                            name={key}
                            type={type}
                            label={label}
                            options={options}
                            values={values}
                            error={!!errors?.[key]}
                            fields={fields}
                            selectOptions={
                              dynamicOptions?.[key] || selectOptions
                            }
                            dynamicOptions={
                              type === "array" ? dynamicOptions : null
                            }
                            query={(value) =>
                              onChangeAutoCompleteHandler({
                                value,
                                formSchema: workflow.form?.form_schema,
                                nodes,
                              })
                            }
                            onVariableSelectionHandler={
                              onVariableSelectionHandler
                            }
                          />
                        </div>
                        {nodeType.type === NODE_TYPE.API &&
                          key === "category" && (
                            <>
                              <div className="mt-2">
                                <FormInput
                                  register={register}
                                  watch={watch}
                                  isValidating={isValidating}
                                  control={control}
                                  name="endpoint"
                                  type="select"
                                  label="Endpoint"
                                  options={options}
                                  values={values}
                                  error={!!errors?.["endpoint"]}
                                  fields={fields}
                                  selectOptions={
                                    dynamicOptions?.["endpoint"] ||
                                    selectOptions
                                  }
                                  value={selectedEndpoint}
                                  dynamicOptions={
                                    type === "array" ? dynamicOptions : null
                                  }
                                />
                              </div>
                              {parameters.length > 0 && (
                                <div className="mt-2">
                                  <div className="space-y-4">
                                    <Label
                                      name="parameters"
                                      label="Parameters"
                                    />
                                    <div className="flex items-start gap-3">
                                      {parameters.map((parameter) => {
                                        const key = `parameters.${parameter}`;
                                        return (
                                          <div className="flex-1" key={key}>
                                            <FormInput
                                              key={key}
                                              register={register}
                                              watch={watch}
                                              isValidating={isValidating}
                                              control={control}
                                              name={key}
                                              type="autoComplete"
                                              label={parameter}
                                              options={{ required: true }}
                                              error={
                                                !!errors?.parameters?.[
                                                  parameter
                                                ]
                                              }
                                              fields={parameterFields}
                                              query={(value) =>
                                                onChangeAutoCompleteHandler({
                                                  value,
                                                  formSchema:
                                                    workflow.form?.form_schema,
                                                  nodes,
                                                })
                                              }
                                              onVariableSelectionHandler={
                                                onVariableSelectionHandler
                                              }
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                      </Fragment>
                    );
                  },
                )}
              </ErrorBoundary>
            </div>
          </div>
          <div
            className={classNames(
              !nodeType.includeFileUpload && "hidden",
              "space-y-4 p-4",
            )}
          >
            <div
              className="flex cursor-pointer select-none gap-4"
              onClick={() => setDisplayAttachments(!displayAttachments)}
            >
              <Label label="Attachments" />
              <div className="cursor-pointer">
                <ChevronUpIcon
                  className={classNames(
                    "h-5 w-5",
                    !displayAttachments && "rotate-180",
                  )}
                />
              </div>
            </div>
            <div
              className={classNames(
                "flex flex-row gap-4",
                !displayAttachments && "hidden",
              )}
            >
              <div className="flex w-full items-center gap-4 overflow-x-auto">
                {existingAttachments?.map((attachment, index) => (
                  <AttachmentPreview
                    className="h-24 w-24 shrink-0 rounded-lg border border-gray-300 dark:border-gray-300/30"
                    key={index}
                    attachment={attachment}
                    onRemove={() => onRemoveAttachment(index)}
                    thumbnail={true}
                    onClick={() => onAttachmentClickHandler(index)}
                  />
                ))}
                <FileUploader
                  files={attachments}
                  setFiles={setAttachments}
                  onClick={(index) =>
                    onAttachmentClickHandler(existingAttachments.length + index)
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              !nodeType.includeFileUpload && "hidden",
              "space-y-4 p-4",
            )}
          >
            <div className="flex select-none gap-8">
              <Label label="Info" />
              <div className="flex gap-2">
                <WrenchIcon
                  title="Editor"
                  onClick={() => setDisplayInfoEditorModal(true)}
                  className="h-5 w-5 cursor-pointer text-secondary-text hover:!text-blue-600 dark:text-secondary-text-dark"
                />
                <EyeIcon
                  title="Editor"
                  onClick={() => info && setDisplayInfoPreviewModal(true)}
                  className={classNames(
                    "h-5 w-5",
                    info
                      ? "cursor-pointer text-secondary-text hover:!text-blue-600 dark:text-secondary-text-dark"
                      : "text-gray-400 dark:text-gray-400/70",
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end bg-background-primary px-4 py-3 dark:bg-background-primary-dark">
            <div className="flex gap-4">
              <Button
                type="submit"
                className="ml-2"
                onSubmit={onSubmit}
                isLoading={loading}
              >
                Save
              </Button>
              <Button onClick={onCloseHandler} variant="secondary">
                Cancel
              </Button>
            </div>
          </div>
        </form>
      )}
      {displayAttachmentsModal && (
        <AttachmentsModal
          attachments={[...existingAttachments, ...attachments]}
          openIndex={currentAttachmentIndex}
          onClose={() => setDisplayAttachmentsModal(false)}
        />
      )}
      <InfoEditorModal
        isOpen={displayInfoEditorModal}
        onClose={() => setDisplayInfoEditorModal(false)}
        onSave={onInfoEditorSave}
        initialValue={info}
      />
      <InfoModal
        isOpen={displayInfoPreviewModal}
        onClose={() => setDisplayInfoPreviewModal(false)}
        html={info}
      />
    </Modal>
  );
};

export default AddNodeModal;
