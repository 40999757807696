import React, { useState, useEffect } from "react";
import withFlowState from "components/hoc/withFlowState";
import { UPS_PATTERN, USPS_PATTERN, FEDEX_PATTERN, formatTitle } from "utils";
import FlowStep from "../FlowStep";
import TechInfo from "../TechInfo";
import { NODE_TYPE } from "utils/constants";

const InstructionsStep = (props) => {
  const {
    state,
    updateFlowStateStepData,
    sequence,
    determineNextStep,
    stepState,
    injectValue,
    onAttachmentClickHandler
  } = props;
  const { workflowNodes, flowState, instanceState, currentNode } = state;

  const [trackingURL, setTrackingURL] = useState(null);
  const [techInfo, setTechInfo] = useState(null);

  const trackingNumberVendors = {
    usps: {
      patterns: USPS_PATTERN,
      url: "https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=",
    },
    fedex: {
      patterns: FEDEX_PATTERN,
      url: "https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=",
    },
    ups: {
      patterns: UPS_PATTERN,
      url: "https://www.ups.com/mobile/track?trackingNumber=",
    },
  };

  const getTrackingHyperLink = (trackingNumberVendors, trackingNumber) => {
    let urlNotFound = true;
    Object.keys(trackingNumberVendors).forEach((vendor) => {
      if (urlNotFound) {
        for (let idx in trackingNumberVendors[vendor].patterns) {
          let data = trackingNumber?.match(
            trackingNumberVendors[vendor].patterns[idx],
          );
          if (data) {
            setTrackingURL(
              `${trackingNumberVendors[vendor].url}${trackingNumber}`,
            );
            urlNotFound = false;
            break;
          }
        }
      } else {
        return;
      }
    });
  };

  useEffect(() => {
    setTechInfo(
      Object.values(instanceState)?.filter(
        (value) => value?.tech_name && value?.tech_phone,
      ) || "Testing tech",
    );
    if (stepState?.type === NODE_TYPE.INSTRUCTIONAL)
      updateFlowStateStepData({
        nodeId: currentNode,
        nodeType: stepState?.type,
        next: determineNextStep(),
      });
  }, [currentNode, workflowNodes]);

  useEffect(() => {
    setTrackingURL(null);
    if (
      instanceState["tracking_number"] &&
      stepState?.question?.toLowerCase().includes("tracking_number")
    ) {
      getTrackingHyperLink(
        trackingNumberVendors,
        instanceState["tracking_number"],
      );
    }
  }, [currentNode]);

  useEffect(() => {}, []);

  const onChange = (answer) => {
    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: stepState?.type,
      selected: answer.text,
      next: determineNextStep({ answer }),
    });
  };

  const onCheckboxChange = (answer) => {
    let selected = flowState[sequence]?.selected || [];
    if (selected.includes(answer.text)) {
      selected = selected.filter((v) => v !== answer.text);
    } else {
      selected.push(answer.text);
    }
    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: stepState?.type,
      selected,
      next: determineNextStep({ selected }),
    });
  };

  return (
    <FlowStep data={stepState} onAttachmentClickHandler={onAttachmentClickHandler}>
      {stepState?.type !== NODE_TYPE.INSTRUCTIONAL && (
        <h1 className="mb-6 min-h-[6rem] sm:mb-8 sm:min-h-0 sm:text-lg">
          {trackingURL ? (
            <>
              {formatTitle(
                stepState?.question.slice(0, stepState?.question.indexOf("$")),
              )}{" "}
              <a
                href={trackingURL}
                target={"_blank"}
                rel={"noreferrer"}
                className="text-dark-sky-blue underline underline-offset-1"
              >
                {" "}
                {instanceState["tracking_number"]}{" "}
              </a>{" "}
            </>
          ) : (
            formatTitle(injectValue(stepState?.question || ""))
          )}
        </h1>
      )}
      <div className="space-y-2">
        {stepState?.type === NODE_TYPE.RADIO &&
          stepState.answers.map((answer, index) => (
            <div
              key={`${sequence}_${currentNode}_${index}`}
              className="flex items-center"
            >
              <input
                name={stepState.question}
                id={`${sequence}_${currentNode}_${index}`}
                value={`${sequence}_${currentNode}_${answer.text}`}
                type="radio"
                defaultChecked={flowState[sequence]?.selected === answer.text}
                onChange={(e) => onChange(answer)}
                className="peer hidden h-4 w-4 cursor-pointer border-gray-300 text-blue-600 focus:ring-blue-500 dark:border-gray-300/30 sm:block"
              />
              {/* mobile */}
              <label
                htmlFor={`${sequence}_${currentNode}_${index}`}
                className="block w-full cursor-pointer rounded-md border border-blue-500 px-3 py-2 text-sm shadow-sm ring-blue-500 focus:outline-none peer-checked:border-2 peer-checked:bg-background-secondary dark:peer-checked:bg-background-secondary-dark sm:hidden"
              >
                {injectValue(answer.text || "")}
              </label>
              {/* desktop */}
              <label
                htmlFor={`${sequence}_${currentNode}_${index}`}
                className="ml-3 hidden cursor-pointer text-base sm:block"
              >
                {injectValue(answer.text || "")}
              </label>
            </div>
          ))}
        {stepState?.type === NODE_TYPE.CHECKBOX &&
          stepState.answers.map((answer, index) => (
            <div
              key={`${sequence}_${currentNode}_${index}`}
              className="flex items-start"
            >
              <input
                name={`${sequence}_${currentNode}_${index}`}
                id={`${sequence}_${currentNode}_${index}`}
                value={`${sequence}_${currentNode}_${answer.text}`}
                type="checkbox"
                checked={!!flowState[sequence]?.selected.includes(answer.text)}
                onChange={(e) => onCheckboxChange(answer)}
                className="mt-1 h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500 dark:border-gray-300/30"
              />
              <label
                htmlFor={`${sequence}_${currentNode}_${index}`}
                className="ml-3 block"
              >
                {injectValue(answer.text || "")}
              </label>
            </div>
          ))}
        {stepState?.type === NODE_TYPE.INSTRUCTIONAL && (
          <pre className="whitespace-pre-wrap">{injectValue(stepState?.instruction || "")}</pre>
        )}
      </div>
      <TechInfo techInfo={techInfo} />
    </FlowStep>
  );
};

export default withFlowState(InstructionsStep);
