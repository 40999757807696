import React from "react";
import { BrowserRouter } from "react-router-dom";
import Home from "components/index";
import { AuthProvider } from "contexts/auth";
import { ThemeProvider } from "contexts/theme";
import { SocketProvider } from "contexts/socket";
import { ErrorBoundary } from "components/common/ErrorBoundary";
import SomethingWentWrong from "components/screens/Error";
import { postFrontendErrorLogs } from "api";

const App = () => {
  return (
    <React.StrictMode>
      <ErrorBoundary
        fallback={<SomethingWentWrong />}
        onError={postFrontendErrorLogs}
      >
        <BrowserRouter>
          <ThemeProvider>
            <AuthProvider>
              <SocketProvider>
                <Home />
              </SocketProvider>
            </AuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

export default App;
