import React, { createContext, useState, useEffect, useContext } from "react";
import { userLogin, getTeammateOf } from "api";
import { ROLES } from "utils/constants";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [state, setState] = useState({
    status: "pending",
    error: null,
    user: null,
  });
  const [teammates, setTeammates] = useState([]);

  const getUser = async () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  useEffect(() => {
    getUser().then(
      (user) => setState({ status: "success", error: null, user }),
      (error) => setState({ status: "error", error, user: null }),
    );
  }, []);

  useEffect(() => {
    if (state?.user && !state.user.roles.includes(ROLES.USER)) {
      getTeammateOf(state.user.id).then(({ data: { data } }) => {
        setTeammates(data);
      });
    }
  }, [state]);

  const login = async (username, password, callback) => {
    try {
      const input = {
        username,
        password,
      };
      const { status, data } = await userLogin(input);
      if (status === 200 && data.success) {
        localStorage.setItem("access_token", data.data.access_token);
        localStorage.setItem("user", JSON.stringify(data.data.user));
        setState({ status: "success", error: null, user: data.data.user });
        return {
          success: true,
        };
      } else if (!data.success) {
        return {
          success: false,
          error: data.text.error,
        };
      }
    } catch (error) {
      return {
        success: false,
        error: error.toString(),
      };
    }
  };

  const logout = async (callback) => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    setState({ status: "success", user: null });
    callback && callback();
  };

  const userHasAccess = (createdByUsername, allowedRoles = []) => {
    if (!state.user) return false;
    const { user } = state;
    return (
      user.roles.includes(ROLES.ADMIN) ||
      allowedRoles.some((role) => user.roles.includes(role)) ||
      user.username === createdByUsername ||
      teammates.filter(({ user }) => user.username === createdByUsername)
        .length > 0
    );
  };

  const updateAuthState = (newState) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        teammates,
        updateAuthState,
        login,
        logout,
        userHasAccess,
      }}
    >
      {state.status === "pending" ? (
        "Loading..."
      ) : state.status === "error" ? (
        <div>
          Oh no
          <div>
            <pre>{state.error.message}</pre>
          </div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

function useAuthState() {
  const state = useContext(AuthContext);
  const isPending = state.status === "pending";
  const isError = state.status === "error";
  const isSuccess = state.status === "success";
  const isAuthenticated = state.user && isSuccess;
  const isAdmin = state.user && isSuccess && state.user.roles.includes("ADMIN");
  const isTurnupUser = !!state.user?.turnup;
  return {
    ...state,
    isPending,
    isError,
    isSuccess,
    isAuthenticated,
    isAdmin,
    isTurnupUser,
  };
}

export { AuthProvider, useAuthState };
