import withFlowState from "components/hoc/withFlowState";
import { useEffect } from "react";
import { NODE_TYPE } from "utils/constants";
import FlowStep from "../FlowStep";
import { injectValue } from "utils";

const parseArray = (value) => {
  let ret = null;
  try {
    ret = JSON.parse(value);
    if (!Array.isArray(ret)) ret = null;
  } catch (error) {
    ret = null;
  }
  return ret;
};

const LoopStep = (props) => {
  const {
    state,
    updateFlowStateStepData,
    onNext,
    determineNextStep,
    stepState,
  } = props;
  const { currentNode, path, instanceState } = state;

  useEffect(() => {
    const source = parseArray(
      injectValue(stepState?.data?.source, instanceState, path),
    );

    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: NODE_TYPE.LOOP,
      next: determineNextStep(),
      data: { ...stepState.data, source },
      state: {
        data: [],
        index: 0,
        item: source ? source[0] : null,
        source,
      },
    });
  }, [currentNode]);

  // continue once updateFlowStateStepData gets updated
  useEffect(() => {
    if (path[path.length - 1]?.id === currentNode) onNext();
  }, [path, currentNode]);

  return <FlowStep data={stepState} />;
};

export default withFlowState(LoopStep);
