import { useLocation } from "react-router-dom";
import { createContext, useState, useContext, useEffect } from "react";
import { isDarkMode, ignoredPaths, update } from "utils/darkMode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(
    isDarkMode() &&
      !ignoredPaths.some((path) => path === window.location.pathname),
  );

  // listen to route changes and update dark mode accordingly
  useEffect(() => {
    update();
    setDarkMode(isDarkMode());
  }, [location]);

  const switchDarkMode = () => {
    if (ignoredPaths.some((path) => path === window.location.pathname)) return;
    if (isDarkMode()) {
      localStorage.setItem("theme", "light");
      document.documentElement.classList.remove("dark");
    } else {
      localStorage.setItem("theme", "dark");
      document.documentElement.classList.add("dark");
    }
    setDarkMode(isDarkMode());
  };

  return (
    <ThemeContext.Provider value={{ darkMode, switchDarkMode }}>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        theme={darkMode ? "dark" : "light"}
        closeButton={false}
      />
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
