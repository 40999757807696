import { FilterOps, FilterOpsByType } from "./constants";

export const states = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NS",
  "NT",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];

export const WORKFLOW_TRIGGER = {
  iwTicket: {
    type: "text",
    label: "Field Service Ticket Number",
    options: { required: true, pattern: /^[0-9]{9}|[0-9]{7}$/g, maxLength: 60 },
  },
  firstName:{
    type: "text",
    label: "Your First Name",
    options: { required: true, pattern: /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/g, maxLength: 150}
  },
  lastName:{
    type: "text",
    label: "Your Last Name",
    options: { required: true, pattern: /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/g, maxLength: 150}
  },
  company:{
    type: "text",
    label: "Your Company Name",
    options: { required: true, maxLength: 300}
  },
  email:{
    type: "text",
    label: "Your Email Address",
    options: { required: true, pattern: /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi}
  },
  phoneNumber: {
    type: "text",
    label: "Your Cell Phone Number",
    options: { required: true, pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g },
  },
  verificationCode: {
    type: "text",
    label: "Verification Code (sent to your phone)" ,
    options: { required: true, pattern: /^\d{6}/g, maxLength: 7 },
  },
};

export const WORKFLOW_TRIGGER2 = {
  iwTicket: {
    type: "text",
    label: "Field Service Ticket Number",
    options: { required: true, pattern: /^[0-9]{9}|[0-9]{7}$/g, maxLength: 60 },
  },
  firstName:{
    type: "text",
    label: "Your First Name",
    options: { required: true, pattern: /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/g, maxLength: 150}
  },
  lastName:{
    type: "text",
    label: "Your Last Name",
    options: { required: true, pattern: /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/g, maxLength: 150}
  },
  company:{
    type: "text",
    label: "Your Company Name",
    options: { required: true, maxLength: 300}
  },
  email:{
    type: "text",
    label: "Your Email Address",
    options: { required: true, pattern: /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi}
  }
};

export const AGENT_SIGNATURE = {
  agentName: {
    type: "text",
    label: "Your Name",
    options: { required: true,
      minLength: {
        value: 3,
        message: "At least three characters for the name",
      },
    },
  },
};

export const SERIAL_NUMBER = {
  serialNumber: {
    type: "text",
    label: "Please Enter Box Serial Number to verify",
    options: { required: true, pattern: /^[0-9]{9}|[0-9]{7}$/g, maxLength: 12 },
  },
};

export const SIGNUP_FORM_FIELDS = {
  firstName: {
    type: "text",
    label: "First Name",
    options: {
      required: true,
      minLength: {
        value: 3,
        message: "At least three characters for the name",
      },
    },
  },
  lastName: {
    type: "text",
    label: "Last Name",
    options: { required: true },
  },
  phoneNumber: {
    type: "text",
    label: "Phone Number",
    options: { required: false },
  },
  email: {
    type: "email",
    label: "Email",
    options: { required: true },
  },
  password: {
    type: "password",
    label: "Password",
    options: { required: true, minLength: 6 },
  },
  confirmPassword: {
    type: "password",
    label: "Password (Confirm)",
    options: { required: true },
  },
  terms: {
    type: "checkbox",
    label: "Agree with Terms and conditions",
    options: { required: true },
  },
};

export const LOGIN_FORM_FIELDS = {
  email: {
    type: "email",
    label: "Email",
    options: { required: true },
  },
  password: {
    type: "password",
    label: "Password",
    options: { required: true },
  },
  rememberMe: {
    type: "checkbox",
    label: "Remember Me",
    options: { required: false },
  },
};

export const FORGOT_FORM_FIELDS = {
  email: {
    type: "email",
    label: "Email",
    options: { required: true },
  },
};

export const RESET_FORM_FIELDS = {
  email: {
    type: "email",
    label: "Email",
    options: { required: true },
  },
  password: {
    type: "password",
    label: "Password",
    options: { required: true },
  },
  confirmPassword: {
    type: "password",
    label: "Password (Confirm)",
    options: { required: true },
  },
};

export const CREATE_USER_FORM_FIELDS = {
  firstName: {
    type: "text",
    label: "First Name",
    options: {
      required: true,
    },
  },
  lastName: {
    type: "text",
    label: "Last Name",
    options: { required: true },
  },
  email: {
    type: "email",
    label: "Email",
    options: { required: true },
  },
  password: {
    type: "password",
    label: "Password",
    options: { required: true },
  },
  confirmPassword: {
    type: "password",
    label: "Password (Confirm)",
    options: { required: true },
  },
  roles: {
    label: "Roles",
    type: "select",
    selectOptions: ["USER", "EDITOR", "VIEWER", "WRITER", "ADMIN"],
    options: { required: true },
  },
};

export const CREATE_WORKFLOW_FORM_FIELDS = {
  name: {
    type: "text",
    label: "Name",
    options: {
      required: true,
    },
  },
  description: {
    type: "textArea",
    label: "Description",
    options: { required: false },
  },
  form_id: {
    type: "select",
    label: "Form",
    selectOptions: [],
    options: { required: false },
  },
  customer_name: {
    type: "text",
    label: "Customer Name",
    options: { required: false },
  },
  account_number: {
    type: "text",
    label: "Account Number",
    options: { required: false },
  },
};

export const RADIO_NODE_FORM_FIELDS = {
  question: {
    type: "text",
    label: "Question",
    options: {
      required: true,
    },
  },
  answers: {
    type: "array",
    label: "Answers",
    fields: {
      answer: {
        type: "text",
        label: "Question",
        options: {
          required: true,
        },
      },
    },
  },
};

export const CHECKBOX_NODE_FORM_FIELDS = {
  question: {
    type: "text",
    label: "Question",
    options: {
      required: true,
    },
  },
  answers: {
    type: "array",
    label: "Answers",
    fields: {
      answer: {
        type: "text",
        label: "Question",
        options: {
          required: true,
        },
      },
    },
  },
  handles: {
    type: "array",
    label: "Handles",
    fields: {
      answer: {
        type: "text",
        label: "Question",
        options: {
          required: true,
        },
      },
    },
  },
};

export const TESTING_FORM_FIELDS = {
  firstName: {
    type: "text",
    label: "First Name",
    options: {
      required: true,
      minLength: {
        value: 3,
        message: "At least three characters for the name",
      },
    },
  },
  lastName: {
    type: "text",
    label: "Last Name",
    options: { required: false },
  },
  description: {
    type: "textArea",
    label: "Describe Yourself",
    options: {
      required: true,
      minLength: {
        value: 3,
        message: "At least three characters for the name",
      },
    },
  },
  email: {
    type: "email",
    label: "Email",
    options: { required: true },
  },
  password: {
    type: "password",
    label: "Password",
    options: { required: true, minLength: 6 },
  },
  confirmPassword: {
    type: "password",
    label: "Password (Confirm)",
    options: { required: true, minLength: 6 },
  },
  terms: {
    type: "checkbox",
    label: "Agree with Terms and conditions",
    options: { required: true },
  },
  pushNotifications: {
    type: "radio",
    name: "push-notifications",
    values: ["notifications", "no-notifications"],
    label: "Notifications",
    options: { required: false },
  },
};

export const FILTER_SCHEDULE_FIELDS = {
  conditions: {
    type: "array",
    label: "",
    options: {
      required: true,
    },
    fields: {
      variable: {
        type: "select",
        label: "Column",
        placeholder: "Column",
        selectOptions: [
          {
            value: "",
            label: "",
          },
          {
            type: "string",
            value: "customer_name",
            label: "Customer Name",
          },
          {
            type: "date",
            value: "dispatch_date",
            label: "Dispatch Date",
          },
          {
            type: "string",
            value: "status",
            label: "Status",
          },
          {
            type: "string",
            value: "created_by",
            label: "Submitter",
          },
          {
            type: "string",
            value: "Workflow__name",
            label: "Workflow",
          },
        ],
        options: {
          required: true,
        },
      },
      op: {
        type: "select",
        label: "Condition",
        placeholder: "Op",
        filter: (value = "string") =>
          Object.entries(FilterOps)
            .filter(([key]) => FilterOpsByType[value].includes(key))
            .map(([key, value]) => ({
              value: key,
              label: value,
            })) || [],
        filterFieldName: "variable",
        selectOptions: [],
        options: {
          required: true,
        },
      },
      value: {
        type: "text",
        label: "Value",
        placeholder: "Value",
        options: {
          required: true,
        },
      },
    },
  },
};

export const FILTER_USER_FIELDS = {
  conditions: {
    type: "array",
    label: "",
    fields: {
      variable: {
        type: "select",
        label: "Column",
        placeholder: "Column",
        selectOptions: [
          {
            value: "",
            label: "",
          },
          {
            type: "string",
            value: "username",
            label: "Username",
          },
          {
            type: "string",
            value: "name",
            label: "Name",
          },
          {
            type: "string",
            value: "phone",
            label: "Phone",
          },
          {
            type: "string",
            value: "roles",
            label: "Role",
          },
          {
            type: "boolean",
            value: "is_active",
            label: "Is Active",
          },
          {
            type: "boolean",
            value: "is_deleted",
            label: "Is Deleted",
          },
        ],
        options: {
          required: true,
        },
      },
      op: {
        type: "select",
        label: "Condition",
        placeholder: "Op",
        filter: (value = "string") =>
          Object.entries(FilterOps)
            .filter(([key]) => FilterOpsByType[value].includes(key))
            .map(([key, value]) => ({
              value: key,
              label: value,
            })) || [],
        filterFieldName: "variable",
        selectOptions: [],
        options: {
          required: true,
        },
      },
      value: {
        type: "text",
        label: "Value",
        placeholder: "Value",
        options: {
          required: true,
        },
      },
    },
  },
};
