import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormInput from "components/common/form/FormInput";
import Chat from "components/common/ChatWidget";

import { getWorkflowInstance, getWorkflow, updateWorkflowInstance, getBoxPorts, updateActivationTicket, getProvisionStatus } from "api";
import { Button, Modal } from "components/common";
import * as loaders from "utils/loaders";
import { postFrontendErrorLogs } from "api";
import Spinner from "components/common/Spinner";


const orderInfo = [
  "serial_number",
  "customer_name",
  "iw_ticket",
  "tracking_number",
  "account_number",
  "dispatch_date",
  "dispatch_time",
  "new_construction",
];

const networkInfo = [
  "ethernet_connection",
  "switch_id",
  "port_number",
  "static_ip_",
  "subnet",
  "gateway",
];

const addressInfo = ["address_1", "address_2", "city", "state_", "zip_code"];
const lineInfo = [
  "portNumber",
  "serviceName",
  "npa",
  "porting",
  "porting_number",
  "huntOrder",
  "huntType",
  "huntGroup"
];

const newLineSchema = {
  state: "",
  portNumber: "",
  serviceName: "",
  npa: "",
  porting: "false",
  porting_number: "",
};

function findDuplicates(lines) {
  const portValues = {}

  lines.forEach((line) => {
    const { portNumber } = line;

    if (!(portNumber in portValues)) {
      portValues[portNumber] = 0
    }

    portValues[portNumber]++;
  })

  const duplicates = Object.keys(portValues).filter(port => portValues[port] > 1);

  return duplicates
}

const requiredProperties = [
  'portNumber',
  'serviceName',
]

const hasAllRequiredProperties = (obj) => {
  return requiredProperties.every(prop => obj[prop] !== null && obj[prop] !== undefined && obj[prop] !== '');
};

let status = {
  failed_error_while_ordering_numbers : 'There was an error while ordering numbers, please order numbers and provision the ports',
  failed_error_while_provisioning_box: 'There was an issue assigning the ordered numbers to box, please update the box manually',
  failed_error_not_enough_numbers: 'There was an error while ordering some numbers, please order numbers and provision the ports',
  failed_error_port_config: 'Unable to update port, please provision the box manually',
  failed_error_box_offline_after_port_config: 'Unable to update port, please provision the box manually',
  failed_error_ssh_connection: 'Box seems offline, please verify and  provision the box manually',
  failed_error_port_xml_gen: 'Unable to update port, please provision the box manually',
  failed_error_port_xml_push: 'Unable to update port, please provision the box manually',
  failed_error_port_xml_ssh: 'Unable to update port, please provision the box manually',
  failed_error_second_dc_switch: 'Ports updated but error while doing data center switch, please move the box to best data center',
  failed_error_catch: 'Error while running provision box, please verify scope of work, and provision the box manually',
  failed_error_ata_reboot: 'Error while rebooting ATA, please reboot the ATAs',
  failed_first_dc_switch: 'Error while moving the box to LA, please make sure the box is online and provision it manually',
  failed_box_offline_after_first_dc_switch: 'Box seems offline, please verify and  provision the box manually',
  failed_box_offline: 'Box seems offline, please verify and  provision the box manually',
  ata_update_failed: 'Box ATA update failed provision box manually',
}


function wait(seconds) {
  const delayInMilliSeconds = seconds * 1000;
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("Done");
    }, delayInMilliSeconds);
  });
}



const VerifyForm = ({ id, setStartFlow, setVerifyForm, agentName = "--" }) => {
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const [formData, setFormData] = useState(null);
  const [boxPorts, setBoxPorts] = useState(null);
  const [form, setForm] = useState(null);
  const [dynamicOptions, setDynamicOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [isInfoModalOpen, setInfoModalOpen] = useState(true);
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [isSignatureModalOpen, setSignatureModalOpen] = useState(false);
  const [newLines, setNewLines] = useState(0);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState('')
  const [timer, setTimer] = useState('');
  const [provision, setProvision] = useState(false);

  const {
    control,
    register,
    reset,
    trigger,
    getValues,
    formState: { errors },
  } = useForm();

  const handleInfoModalPopup = () => {
    setInfoModalOpen(!isInfoModalOpen);
  };

  const handleErrorModalPopup = () => {
    setErrorModalOpen(!isErrorModalOpen);
  };

  const handleSignatureModalPopup = () => {
    setSignatureModalOpen(!isSignatureModalOpen);
  };

  function useInterval(callback, delay) {
    const savedCallback = useRef();
   
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
   
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }


  async function provisionStatus(){
    try{
        let {data} = await getProvisionStatus(formData?.serial_number);
        if(data?.success && data?.data?.length){
            if(data?.data[0]?.task_state === 'in-progress'){
              return;
            }

            if(data?.data[0]?.task_state === 'ata_reboot'){
              setVerifyForm(false)
              setStartFlow(true)
              return;
            }

            if(data?.data[0]?.task_state === 'success'){
              setVerifyForm(false)
              setStartFlow(true)
              return;
            }

            let message = status[data?.data[0]?.task_state]
            await updateActivationTicket({
              escalate_message: message || `Error while running provision box`,
              activation_ticket: activation_ticket
            })
            setVerifyForm(false)
            setStartFlow(true)
        }
        setVerifyForm(false)
        setStartFlow(true)
    }catch(error){
      postFrontendErrorLogs(error)
      setVerifyForm(false)
      setStartFlow(true)
    }
  }

  async function startProgress() {
    if(provision){
      if (progress < 100) {
        setProgress((progress + 1))
        await provisionStatus()
      }else{
        setVerifyForm(false)
        setStartFlow(true)
      }
    }
  }

  useInterval(() => {
    startProgress()
  }, 200000);

  async function onEscalate(){
    try{
      await updateActivationTicket({
        escalate_message: 'Field tech might be need help on scope of work',
        activation_ticket: formData?.activation_ticket
      })
      handleSignatureModalPopup()
    }catch(error){
      postFrontendErrorLogs(error)
      handleSignatureModalPopup()
    }
  }
  
  async function onSubmit() {
    try {
      let lines = formData.line;

      if (!lines?.length) {
        setError(`Need at least one line to continue`)
        handleSignatureModalPopup()
        return
      }

      setLoading(true)
      const values = getValues();
      let updatedFormData = values
      const line = [];
      if (formData?.line?.length) {
        for (let idx = 0; idx < formData.line.length + newLines; idx++) {
          let lineData = {};
          for (let [key, value] of Object.entries(formData.line[idx])) {
            lineData[key] = values[`${idx}-${key}`] || value;
            delete updatedFormData[`${idx}-${key}`]
          }

          line.push({
            ...lineData,
          });
        }
      }

      await updateWorkflowInstance(id, { form_data: { ...updatedFormData, line } });
      handleSignatureModalPopup()
      setProvision(true)

      await provisionStatus()
      
      setLoading(false)
      startProgress()
    } catch (err) {
      postFrontendErrorLogs(err);
      setLoading(false)
    }
  }

  const fetchFlow = async () => {
    getWorkflowInstance(id)
      .then(({ data }) => {
        if (data.success) {
          setFormData({
            ...data.data.form_data,
            created_by: data.data.created_by,
            modified_by: data.data.modified_by,
          });

          getBoxPorts(data?.data?.form_data?.serial_number).then(({data}) => {
            setBoxPorts(data?.data?.boxPorts)
          })

          return getWorkflow(data.data.workflow_id);
        }
      })
      .then(({ data }) => {
        if (data.success) {
          setForm(data.data.form);
        }
        setLoading(false);
      })
      .catch((err) => {
        postFrontendErrorLogs(err);
        setLoading(false);
      });
  };

  function addNewLine() {
    let lines = formData.line;
    lines.push({
      ...newLineSchema,
      state: formData?.state_,
    });

    if(lines.length > boxPorts){
      setError(`No more Ports on the box`);
      return   
    }

    setFormData({
      ...formData,
      line: lines,
    });
  }

  function removeLine(idx) {
    let lines = formData.line;

    if (idx > -1) {
      lines.splice(idx, 1);
    }

    if (!lines?.length) {
      setError(`Need at least one line to continue`)
      return
    }

    setFormData({
      ...formData,
      line: lines,
    });
  }

  useEffect(() => {
    fetchFlow();
  }, []);

  useEffect(() => {
    if (form) {
      const selects = Object.entries(form.form_schema)
        .filter(([_, value]) => value.type === "select" && value.loader)
        .map(([key, value]) => ({ ...value, key }));

      selects.forEach((x) => {
        const isAsync = loaders[x.loader].constructor.name === "AsyncFunction";
        if (isAsync) {
          loaders[x.loader]().then((response) => {
            setDynamicOptions((prevState) => ({
              ...prevState,
              [x.key]: response,
            }));
          });
        } else {
          const response = loaders[x.loader]();
          setDynamicOptions((prevState) => ({
            ...prevState,
            [x.key]: response,
          }));
        }
      });
      reset(formData);
    }
  }, [form]);

  const onPageNavigate = () => {
    window.scrollTo({
      top: isScrollBottom ? 0 : document?.body?.scrollHeight,
      behavior: "smooth",
    });

    setIsScrollBottom(!isScrollBottom);
  };

  function onSave() {
    const values = getValues();
    let updatedFormData = values
    const line = [];
    if (formData?.line?.length) {
      for (let idx = 0; idx < formData.line.length + newLines; idx++) {
        let lineData = {};
        for (let [key, value] of Object.entries(formData.line[idx])) {
          lineData[key] = values[`${idx}-${key}`] || value;
          delete updatedFormData[`${idx}-${key}`]
        }

        line.push({
          ...lineData,
        });
      }
    }

    let duplicates = findDuplicates(line);

    if (duplicates.length) {
      setError(`Duplicate port number for lines`);
      handleErrorModalPopup()
      return
    }

    let missingRequiredInfo = false;

    line.forEach((obj, index) => {
      if (!hasAllRequiredProperties(obj)) {
        setError(`Missing required property on port ${line[index]?.portNumber}`);
        handleErrorModalPopup()
        missingRequiredInfo = true
        return
      }
    });

    if(missingRequiredInfo){
      return
    }

    setSignatureModalOpen(true)

  }


  if (loading) {
    return <Spinner />;
  }

  if(provision){
    return (
    <>
      <div className="flex flex-col items-center justify-center pt-40" >
        <div>
        <div className="cube-wrapper">
          <div className="cube">
            <div className="cube-faces">
              <div className="cube-face shadow"></div>
              <div className="cube-face bottom"></div>
              <div className="cube-face top"></div>
              <div className="cube-face left"></div>
              <div className="cube-face right"></div>
              <div className="cube-face back"></div>
              <div className="cube-face front"></div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div className="pt-20">
        <div className="text-center">
        <p className="font-bold font-mono">Please wait while we provision your box</p>
        </div>
      </div>
    </>)
  }
  return (
    <div className="min-h-screen shadow">
      <div className="sticky top-0 z-10 flex items-center gap-5 border-b border-gray-300 bg-background-primary px-4 py-5 dark:border-gray-300/30 dark:bg-background-primary-dark sm:px-7">
        <div className="flex-1">
          <h3 className="text-lg font-medium leading-6 text-header dark:text-header-dark">
            {formData?.customer_name}
          </h3>
          <p className="max-w-1xl mt-1 text-sm text-gray-500">
            {formData?.account_number}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <Button
            variant="secondary"
            size="small"
            className="mr-2 w-24"
            onClick={() => { setVerifyForm(false) }}
          >
            Go Back
          </Button>
          <Button
            variant="primary"
            size="small"
            className={`w-fit`}
            onClick={onSave}
            isLoading={false}
          >
            Save & Verify Scope of Work
          </Button>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center p-4">
        <h1 className="text-1xl p-1 font-medium">Order Info</h1>
        <div className="grid w-full max-w-md gap-4 bg-white p-6 sm:grid-cols-1 md:grid-cols-2">
          {orderInfo?.map((key) => {
            return (
              <div>
                <FormInput
                  register={register}
                  control={control}
                  label={form?.form_schema[key]?.label}
                  name={key}
                  type={form?.form_schema[key]?.type}
                  options={{...form?.form_schema[key]?.options, readOnly: true}}
                  selectOptions={
                    dynamicOptions?.[key] ||
                    form?.form_schema[key]?.selectOptions
                  }
                  values={formData[key]}
                />
              </div>
            );
          })}
        </div>
        <h1 className="text-1xl p-1 font-medium">Address Info</h1>
        <div className="grid w-full max-w-md gap-4 bg-white p-6 sm:grid-cols-1 md:grid-cols-2">
          {addressInfo.map((key) => {
            return (
              <div>
                <FormInput
                  register={register}
                  control={control}
                  label={form?.form_schema[key]?.label}
                  name={key}
                  type={form?.form_schema[key]?.type}
                  options={{...form?.form_schema[key]?.options, readOnly: true}}
                  selectOptions={
                    dynamicOptions?.[key] ||
                    form?.form_schema[key]?.selectOptions
                  }
                  values={formData[key]}
                />
              </div>
            );
          })}
        </div>
        <h1 className="text-1xl p-1 font-medium">
          Lines Info (Number of lines on Box: {formData?.line?.length || 0})
        </h1>
        <div className="grid w-full max-w-md gap-4 bg-white p-6 sm:grid-cols-1 md:grid-cols-2">
          {formData?.line?.map((port, idx) => {
            return (
              <div className="col-start-1 col-end-3" key={idx}>
                <span className="relative flex justify-center">
                  <div className="absolute inset-x-0 top-1/2 h-px -translate-y-1/2 bg-transparent bg-gradient-to-r from-transparent via-gray-500 to-transparent opacity-75"></div>

                  <span className="relative z-10 bg-white px-6">Line</span>
                </span>

                {lineInfo.map((key) => {
                  if(!port?.huntGroup && (key === 'huntOrder' || key === 'huntType' || key === 'huntGroup')){
                    return
                  }
                  return (
                  <div key={key}>
                    <FormInput
                      register={register}
                      control={control}
                      label={form?.form_schema?.line?.fields[key]?.label}
                      name={`${idx}-${key}`}
                      type={form?.form_schema?.line?.fields[key]?.type}
                      options={{...form?.form_schema?.line?.fields[key]?.options, readOnly: true, required: true}}
                      values={port[key]}
                      defaultValue={port[key]}
                      selected={port[key]}
                      selectOptions={
                        dynamicOptions?.[key] ||
                        form?.form_schema?.line?.fields[key]?.selectOptions
                      }
                      dynamicOptions={dynamicOptions}
                    />
                  </div>
                )})}
                <div>
                  <button className="flex py-2" onClick={() => removeLine(idx)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="red"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                      />
                    </svg>
                    <span className="text-red-500">Delete Line</span>
                  </button>
                </div>
              </div>
            );
          })}
          <div>
            <button onClick={addNewLine}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#3b5aa9"
                className="h-12 w-12"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Add new Line</span>
            </button>
          </div>
        </div>
      </div>
      <button
        onClick={onPageNavigate}
        className="fixed bottom-8 right-8 hidden rounded-full bg-blue-500 p-2 text-white hover:bg-blue-600 focus:border-blue-300 focus:outline-none focus:ring"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          class="h-6 w-6"
          transform={
            isScrollBottom
              ? "matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)"
              : ""
          }
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
          />
        </svg>
      </button>
      <>
      < Modal title={"STOP!"} isOpen={isInfoModalOpen} onClose={handleInfoModalPopup} size={"max-w-screen-sm"} unmount={isInfoModalOpen} hasClose={true} closeButtonText={'OK'}>
          <div className="text-pretty my-4 mx-4 ">
            <h6 class="text-2xl text-blue-600 leading-tight text-center font-bold">Please Note:</h6>
            <div class="text-center font-bold underline">You can no longer edit the scope of work in Wizard as before.</div>
            <div class="text-center font-bold underline">Any changes must be made by a Granite technician. See updated instructions below.</div>
            <br></br>
            <p className="decoration-4 font-bold py-1 text-red-500">**IMPORTANT**</p><p className="inline">Please review the scope of work line information on the following screen and compare against waht the customer has on site.</p>
            <br></br>
            <br></br>
            <p>If you have questions about the scope of work or of changes are needed--such as add, remove, or change lines--please call the EPIK Technical Coordination team at 833-479-4798 and have the Granite Field Service Ticket number ready. When you are certain the information on this screen is what should be installed, please click "Save & Verify Scope of Work" at the top of the page.</p>
          </div>
        </Modal>

        < Modal title={"ERROR"} isOpen={isErrorModalOpen} onClose={handleErrorModalPopup} size={"max-w-screen-sm"} unmount={isErrorModalOpen} hasClose={true} closeButtonText={'OK'}>
          <div className="text-pretty my-4 mx-4 ">
            <p className="text-white-500">
              {error}
            </p>
          </div>

        </Modal>

        < Modal title={"STOP!"} isOpen={isSignatureModalOpen} onClose={handleSignatureModalPopup} size={"max-w-screen-sm"} unmount={handleSignatureModalPopup} hasClose={true} closeButtonText={'Go Back'}>
          <div className="text-pretty my-4 mx-4 ">
            <p className="text-white-500">
              By entering your information below and signing this form, you are verifying that you have reviewed the lines at the customer's site, have made any changes to the scope of work on this page, have called the EPIK TC team if needed, and are certain the scope of work is correct.
              <p className="decoration-4 font-bold py-1">**IMPORTANT**</p>If this install includes any fire alarm lines, it is highly recommended that you immediately ask the customer contact on site to call their alarm monitoring company to put their alarm panel into test mode for a minimum of 3 hours.  Getting this done will take some time and starting the process later in the flow will waste time. <strong>Also note that failure to place the alarm system into test mode may result in a false alarm dispatch of emergency services that you will be held liable for.</strong>
            </p>
            <p className="text-red-600 decoration-4 font-bold py-1">I have verified that this is the serial number on site:</p>
            <p className="font-mono text-red-600 text-2xl font-bold italic"> {getValues("serial_number") || '--'} </p>
            <div className="relative py-4">
              <p className="font-mono text-2xl font-bold italic"> {agentName || '--'} </p>
              <div>
              <Button
                    variant="primary"
                    className="bottom-0 right-0"
                    onClick={onSubmit}
                >
                    Verified & Signed
                </Button>
              <Button
                    variant="primary"
                    className="bottom-0 right-0"
                    onClick={onEscalate}
                >
                    Escalate
                </Button>
                </div>
            </div>
          </div>
        </Modal>

      <Chat/>
      </>
    </div>
  );
};

export default VerifyForm;
