import React, { useState, useEffect, createContext } from "react";
import Chat from "./Chat.js";
import Home from "./Home.js";
import ScreenShots from "./ScreenShots.js";
import FlowState from "./FlowState.js";
import { useSocket } from "contexts/socket";
import { getRecentFlows } from "api";

export const DashboardContext = createContext();

const Dashboard = () => {
  let [selectedTab, setSelectTab] = useState('Home');
  let [selectedFlow, setSelectedFlow] = useState(null);
  let [agentChannel, setAgentChannel] = useState(null);
  let [notifications, setNotifications] = useState([])
  let [activeTech, setActiveTech] = useState({});
  let [instances, setInstances] = useState([]);
  let [isLoading, setLoading] = useState(true);

  let { sockets } = useSocket();

  function setFlowId(id, tab) {
    setSelectedFlow(id)
    setSelectTab(tab)
  }

  function updateNotifications(conv_id) {
    let updated = notifications
    notifications[conv_id] = false
    setNotifications(updated)
  }

  async function updateFlows() {
      try {
          const {data} = await getRecentFlows();

          setInstances(data?.data)
          setLoading(false)
      } catch (error) {
          console.log(error)
      }
  }

  useEffect(() => {
    if (sockets?.isConnected) {
      let channel = sockets.channel(`agent:lobby`)
      channel.join()
        .receive("ok", resp => { console.log("Joined successfully", resp) })
        .receive("error", resp => { console.log("Unable  to join", resp) })

      setAgentChannel(channel)
    }
  }, [sockets])

  useEffect(() => {
    if (agentChannel) {
      agentChannel.on("update_presence", (payload) => {
        setActiveTech(payload)
      })

      agentChannel.on("message_notification", (payload) => {
        if (payload?.type === "activationTech") {
          return
        }

        let newNotification = notifications
        newNotification[payload?.conv_id] = true
        setNotifications(newNotification)
      })

      agentChannel.on("update_data", (payload) => {
        setInstances(payload?.data)
        setLoading(false)
      })
    }
  }, [agentChannel])

  useEffect(() => {
    updateFlows()
  }, [])

  if (isLoading) {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="relative">
                <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                </div>
            </div>
        </div>
    )
  }

  return (
    <>
      <DashboardContext.Provider value={{ selectedFlow, activeTech, instances, notifications, agentChannel, updateNotifications, setFlowId, setSelectTab }}>
      <div>
        <div className="flex h-screen bg-gray-100">
          {/* sidebar */}
          <div className="hidden md:flex flex-col w-48 bg-gray-800 rounded-2xl">
            <div className="flex flex-col flex-1 overflow-y-auto">
              <nav className="flex flex-col flex-1 overflow-y-auto bg-gradient-to-b from-gray-700 to-gray-500 px-2 py-4 gap-10">
                <div className="flex flex-col flex-1 gap-3">
                  <button
                    className={`flex items-center px-4 py-2 mt-2 text-gray-100 rounded-2xl ${selectedTab === "Home" ? 'bg-gray-400 bg-opacity-25' : 'hover:bg-gray-400 hover:bg-opacity-25'}`}
                    onClick={() => setSelectTab("Home")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      style={{ marginRight: "80 px" }}
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6l2 2a1 1 0 0 1-1.414 1.414L19 12.414V19a2 2 0 0 1-2 2h-3a1 1 0 0 1-1-1v-3h-2v3a1 1 0 0 1-1 1H7a2 2 0 0 1-2-2v-6.586l-.293.293a1 1 0 0 1-1.414-1.414l2-2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Home
                  </button>
                  <button
                    className={`flex items-center px-4 py-2 mt-2 text-gray-100 rounded-2xl ${selectedTab === "Chat" ? 'bg-gray-400 bg-opacity-25' : 'hover:bg-gray-400 hover:bg-opacity-25'}`}
                    onClick={() => setSelectTab("Chat")}
                  >
                    <svg
                      fill="currentColor"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      id="chat"
                      data-name="Line Color"
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon line-color"
                    >
                      <path
                        id="primary"
                        d="M18.81,16.23,20,21l-4.95-2.48A9.84,9.84,0,0,1,12,19c-5,0-9-3.58-9-8s4-8,9-8,9,3.58,9,8A7.49,7.49,0,0,1,18.81,16.23Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </svg>
                    Chat
                  </button>
                  <button
                    className={`flex items-center px-4 py-2 mt-2 text-gray-100 rounded-2xl ${selectedTab === "ScreenShots" ? 'bg-gray-400 bg-opacity-25' : 'hover:bg-gray-400 hover:bg-opacity-25'}`}
                    onClick={() => setSelectTab("ScreenShots")}
                  >
                    <svg
                      fill="currentColor"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3,21 C1.8954305,21 1,20.1045695 1,19 L1,5 C1,3.8954305 1.8954305,3 3,3 L21,3 C22.1045695,3 23,3.8954305 23,5 L23,19 C23,20.1045695 22.1045695,21 21,21 L3,21 Z M21,5 L3,5 L3,19 L21,19 L21,5 Z M9.1843164,7.78768371 C9.67333621,7.30584332 10.2804658,7 11,7 L13,7 C13.7195342,7 14.3266638,7.30584332 14.8156836,7.78768371 C14.8879867,7.85892532 14.9541108,7.93005667 15.0143026,8 L15.6,8 C16.9414906,8 18,9.13411721 18,10.5 L18,14.5 C18,15.8658828 16.9414906,17 15.6,17 L8.4,17 C7.05850939,17 6,15.8658828 6,14.5 L6,10.5 C6,9.13411721 7.05850939,8 8.4,8 L8.98569742,8 C9.0458892,7.93005667 9.11201328,7.85892532 9.1843164,7.78768371 Z M11,9 C10.8706092,9 10.7333263,9.06915668 10.5880336,9.21231629 C10.4804087,9.31836108 10.405992,9.42597745 10.383646,9.46815566 L10.1018749,10 L8.4,10 C8.19509331,10 8,10.2090285 8,10.5 L8,14.5 C8,14.7909715 8.19509331,15 8.4,15 L15.6,15 C15.8049067,15 16,14.7909715 16,14.5 L16,10.5 C16,10.2090285 15.8049067,10 15.6,10 L13.8981251,10 L13.616354,9.46815566 C13.594008,9.42597745 13.5195913,9.31836108 13.4119664,9.21231629 C13.2666737,9.06915668 13.1293908,9 13,9 L11,9 Z M12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 Z"
                      />
                    </svg>
                    ScreenShots
                  </button>
                  <button
                    className={`flex items-center px-4 py-2 mt-2 text-gray-100 rounded-2xl ${selectedTab === "FlowState" ? 'bg-gray-400 bg-opacity-25' : 'hover:bg-gray-400 hover:bg-opacity-25'}`}
                    onClick={() => setSelectTab("FlowState")}
                  >
                    <svg
                      fill="currentColor"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      baseProfile="tiny"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M18 16.184v-1.851c0-1.93-1.57-3.5-3.5-3.5-.827 0-1.5-.673-1.5-1.5v-1.517c1.161-.415 2-1.514 2-2.816 0-1.654-1.346-3-3-3s-3 1.346-3 3c0 1.302.839 2.401 2 2.815v1.518c0 .827-.673 1.5-1.5 1.5-1.93 0-3.5 1.57-3.5 3.5v1.851c-1.161.415-2 1.514-2 2.816 0 1.654 1.346 3 3 3s3-1.346 3-3c0-1.302-.839-2.401-2-2.816v-1.851c0-.827.673-1.5 1.5-1.5.979 0 1.864-.407 2.5-1.058.636.651 1.521 1.058 2.5 1.058.827 0 1.5.673 1.5 1.5v1.851c-1.161.415-2 1.514-2 2.816 0 1.654 1.346 3 3 3s3-1.346 3-3c0-1.302-.839-2.401-2-2.816zm-11 3.816c-.552 0-1-.449-1-1s.448-1 1-1 1 .449 1 1-.448 1-1 1zm5-16c.552 0 1 .449 1 1s-.448 1-1 1-1-.449-1-1 .448-1 1-1zm5 16c-.552 0-1-.449-1-1s.448-1 1-1 1 .449 1 1-.448 1-1 1z" />
                    </svg>
                    FlowState
                  </button>
                </div>
              </nav>
            </div>
          </div>
          {/* Main content */}
          <div className="flex flex-col flex-1 overflow-y-auto">
            {(() => {
              switch (selectedTab) {
                case 'Home':
                  return (<Home />)
                case 'Chat':
                  return (<Chat />)
                case 'ScreenShots':
                  return (<ScreenShots />)
                case 'FlowState':
                  return (<FlowState />)
                default:
                  return (<Home />)
              }
            })()}
          </div>
        </div>
        </div>
      </DashboardContext.Provider>
    </>
  )
}

export default Dashboard;
