import React from "react";
import Label from "../Label";
import { isBoolean } from "utils/helpers";
import { classNames } from "utils";

const Radio = ({
  name,
  type,
  label,
  value: defaultValue,
  values,
  register,
  error,
  options = {},
  ...rest
}) => {
  const hasError = isBoolean(error) ? error : !!error;

  return (
    <div>
      {label && (
        <Label name={name} label={label} error={hasError} options={options} />
      )}
      <div className={classNames(rest.className || "mt-2 space-y-2")}>
        {rest?.selectOptions?.map((value, index) => (
          <div className="flex items-center" key={`value-${index}`}>
            <input
              type="radio"
              value={value?.value || value}
              disabled={
                options.readOnly &&
                defaultValue &&
                defaultValue !== (value?.value ?? value)
              }
              {...register(name, options)}
              {...rest}
              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500 disabled:cursor-not-allowed disabled:opacity-60 dark:border-gray-300/30"
            />
            <label
              htmlFor={`${name}-${index}`}
              className="ml-3 block text-sm font-medium text-secondary-text dark:text-secondary-text-dark"
            >
              {value?.label || value}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Radio;
