import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import { AppLayout, LoginLayout } from "components/layouts/index.js";
import RequireAuth from "./auth/RequireAuth";
import Reports from "./screens/Reports";
import Settings from "./screens/Settings";
import NotFound from "./screens/NotFound";
import Forbidden from "./screens/Forbidden";
import Chat from "./screens/Chat";
// import Support from "./screens/Support/index.js"
import Dashboard from './screens/AgentDashboard/Dashboard.js';
import Survey from "./screens/Survey";
import { FlowProvider } from "contexts/flow";
import { useAuthState } from "contexts/auth";
import { ROLES } from "utils/constants";
import Home2 from "./flow/Home2";
import Report from "./screens/Survey/surveyReport";
import EditForm from "./screens/Survey/pmForm";
import SurveyList from "../components/screens/Survey/surveyList.js";

const Home = lazy(() => import("components/screens/Home"));
const Workflows = lazy(() => import("components/screens/Workflows"));
const WorkflowView = lazy(() => import("components/screens/Workflows/View"));
const WorkflowEdit = lazy(() => import("components/screens/Workflows/Edit"));
const WorkflowEditor = lazy(() =>
  import("components/screens/Workflows/Editor"),
);
const ScheduleForms = lazy(() => import("components/screens/Schedule"));
const ScheduleFormView = lazy(() => import("components/screens/Schedule/View"));
const ScheduleFormEdit = lazy(() => import("components/screens/Schedule/Edit"));

const Forms = lazy(() => import("components/screens/Forms"));
const FormView = lazy(() => import("components/screens/Forms/View"));
const FormEdit = lazy(() => import("components/screens/Forms/Edit"));

const Users = lazy(() => import("components/screens/Users"));
const UserView = lazy(() => import("components/screens/Users/View"));
const UserEdit = lazy(() => import("components/screens/Users/Edit"));

const FlowHome = lazy(() => import("components/flow/Home"));

const Login = lazy(() => import("components/screens/Login"));
const Forgot = lazy(() => import("components/screens/Forgot"));
const Reset = lazy(() => import("components/screens/Reset"));
const Signup = lazy(() => import("components/screens/Signup"));

// const SurveyList = lazy(() => import("../components/screens/Survey/surveyList.js"));

const App = () => {
  const { user, isTurnupUser, logout } = useAuthState();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  // redirects a person with role user to /flow on any page they enter
  useEffect(() => {
    if (user?.roles === ROLES.USER) {
      if (isTurnupUser) {
        logout();
        navigate("/login");
      } else navigate("/flow");
    }
  }, []);

  const appPaths = {
    "": {
      permissions: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
      component: <Home />,
    },
    workflows: {
      permissions: [ROLES.ADMIN, ROLES.EDITOR],
      component: <Workflows />,
    },
    "workflows/edit/:id": {
      permissions: [ROLES.ADMIN, ROLES.EDITOR],
      component: <WorkflowEdit />,
    },
    "workflows/view/:id": {
      permissions: [ROLES.ADMIN, ROLES.EDITOR],
      component: <WorkflowView />,
    },
    "workflows/editor/:id": {
      permissions: [ROLES.ADMIN, ROLES.EDITOR],
      component: <WorkflowEditor />,
    },
    forms: {
      permissions: [ROLES.ADMIN, ROLES.EDITOR],
      component: <Forms />,
    },
    "forms/view/:id": {
      permissions: [ROLES.ADMIN, ROLES.EDITOR],
      component: <FormView />,
    },
    "forms/edit/:id": {
      permissions: [ROLES.ADMIN, ROLES.EDITOR],
      component: <FormEdit />,
    },
    survey: {
      permissions: [ROLES.ADMIN, ROLES.EDITOR],
      component: <SurveyList />,
    },
    schedule: {
      permissions: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
      component: <ScheduleForms />,
    },
    "schedule/view/:id": {
      permissions: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
      component: <ScheduleFormView />,
    },
    "schedule/edit/:id": {
      permissions: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
      component: <ScheduleFormEdit />,
    },
    users: {
      permissions: [ROLES.ADMIN],
      component: <Users />,
    },
    "users/edit/:id": {
      permissions: [ROLES.ADMIN],
      component: <UserEdit />,
    },
    "users/view/:id": {
      permissions: [ROLES.ADMIN],
      component: <UserView />,
    },
    reports: {
      permissions: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
      component: <Reports />,
    },
    settings: {
      permissions: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.WRITER],
      component: <Settings />,
    },
    403: {
      permissions: [ROLES.ALL],
      component: <Forbidden />,
    },
    "*": {
      permissions: [ROLES.ALL],
      component: <NotFound />,
    },
  };

  const hasAccess = (path, componentToRender) => {
    const rolesWithAccess = appPaths[path].permissions || [];
    return rolesWithAccess.includes(ROLES.ALL) ||
      rolesWithAccess.includes(user?.roles) ? (
      componentToRender
    ) : (
      <Forbidden />
    );
  };

  const renderAppRoutes = Object.keys(appPaths).map((path, index) => {
    return (
      <Route
        key={index}
        path={path}
        element={hasAccess(path, appPaths[path].component)}
      />
    );
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route index element={
          <FlowProvider>
              <FlowHome />
            </FlowProvider>} />
          <Route path="*" element={<NotFound />} />
        <Route path="/" element={<LoginLayout />}>
          <Route path="admin" element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="reset" element={<Reset />} />
        </Route>
        <Route
          path="/app"
          element={
            <RequireAuth>
              <AppLayout />
            </RequireAuth>
          }
        >
          {renderAppRoutes}
        </Route>
        <Route
          path="/flow/:workflowInstanceId?"
          element={
            <FlowProvider dryRun={params.get("dry_run") === "true"}>
              <FlowHome />
            </FlowProvider>
          }
        />
        <Route
          path="/flow/form/view/:id"
          element={
              <ScheduleFormEdit />
          }
        />
        <Route 
        path="/chat/view/:workflowInstanceId?"
        element={
          <Chat/>
        }/>

        <Route 
        path="/survey"
        element={
          <Home2/>
        }/>

        <Route 
        path="/pm/form/:id"
        element={
          <EditForm/>
        }/>

        <Route 
        path="/surveytest"
        element={
          <Survey/>
        }/>

        <Route 
        path="/survey-review?"
        element={
          <Report/>
        }/>

        <Route path="/activations" element={
          <RequireAuth>
            <Dashboard/>
          </RequireAuth>  
        }/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default App;
