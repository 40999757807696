import { useCallback, useMemo, useState } from "react";
import {
  applyEdgeChanges,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { toObject } from "utils";
import { ALERTS, GROUP_NODE_TYPES, NODE_TYPE } from "utils/constants";
import { checkCondition } from "utils/helpers";
import { notify } from "utils/notification";
import debounce from "lodash.debounce";
import {
  addEdge as _addEdge,
  addNode,
  createLoopFromSelectedNodes,
  deleteEdges as _deleteEdges,
  deleteNodes as _deleteNodes,
  duplicateNode as _duplicateNode,
  getLabel,
  getNextNodeID,
  moveNodeIntoGroup,
  pasteNodes as _pasteNodes,
  removeSelectedNodeFromLoop,
  colorGraphCycleEdges,
} from "workflow-editor/utils/editor";
import { EVENT_TYPES, eventHub } from "workflow-editor/events";

export const useEditor = (
  initialNodes,
  initialEdges,
  initialErrors,
  initialWorkflow,
) => {
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedEdges, setSelectedEdges] = useState([]);
  const [unsavedWork, setUnsavedWork] = useState(false);
  const [conflictingWork, setConflictingWork] = useState(null);
  const [undoCounter, setUndoCounter] = useState(0);
  const { project, getIntersectingNodes } = useReactFlow();
  const nodesToObj = useMemo(() => toObject(nodes, (node) => node.id), [nodes]);

  const updateUndoCounter = () => {
    setUndoCounter(undoCounter + 1);
  };

  const debounceUndoCounter = useCallback(debounce(updateUndoCounter, 300), [
    undoCounter,
  ]);

  const reset = (
    { nodes, edges, errors, type } = {
      type: EVENT_TYPES.WORKFLOW_RESET,
    },
    isUnsaved,
  ) => {
    setNodes(nodes ?? initialNodes);
    setEdges(edges ?? initialEdges);

    eventHub.emit(type, {
      state: {
        edges: edges ?? initialEdges,
        nodes: nodes ?? initialNodes,
        errors: type === EVENT_TYPES.WORKFLOW_RESET ? initialErrors : errors,
      },
    });
    if (isUnsaved !== undefined) {
      setUnsavedWork(isUnsaved);
    }
  };

  const addEdge = useCallback(
    ({ source, sourceHandle, target, targetHandle }) => {
      const edgeExists = edges.find(
        (edge) => edge.sourceHandle === sourceHandle,
      );
      if (edgeExists) {
        notify.error(ALERTS.MULTI_SINGLE_SOURCE_HANDLE);
        return;
      }

      const updatedEdges = _addEdge(
        {
          source,
          sourceHandle,
          target,
          targetHandle,
          type: "smoothstep",
          label: getLabel(sourceHandle, nodes),
        },
        edges,
      );
      eventHub.emit(EVENT_TYPES.EDGE_CREATE, {
        state: {
          nodes,
          edges: updatedEdges,
        },
        data: updatedEdges[updatedEdges.length - 1],
      });
      setEdges(updatedEdges);
      setUnsavedWork(true);
      debounceUndoCounter();
    },
    [edges, nodes, debounceUndoCounter],
  );

  const deleteEdges = useCallback(
    (edgesToDelete) => {
      if (edgesToDelete.length > 0) {
        const updatedEdges = _deleteEdges(edges, edgesToDelete);
        edgesToDelete.forEach((edge) =>
          eventHub.emit(EVENT_TYPES.EDGE_DELETE, {
            state: {
              edges: updatedEdges,
              nodes,
            },
            data: edge,
          }),
        );
        setEdges(updatedEdges);
        setUnsavedWork(true);
        debounceUndoCounter();
        return updatedEdges;
      }
    },
    [nodes, edges, debounceUndoCounter],
  );

  const deleteNodes = useCallback(
    (nodesToDelete) => {
      if (nodesToDelete.length > 0) {
        let updatedNodes = _deleteNodes(nodes, nodesToDelete);

        // removes starting node from loops if it is deleted
        if (
          nodesToDelete.some(({ id, parentNode }) => {
            if (!parentNode) return false;
            return nodesToObj[parentNode]?.data?.starting_node === id;
          })
        ) {
          updatedNodes = updatedNodes.map((node) => {
            if (!node.type !== NODE_TYPE.LOOP) return node;
            if (
              !node.data?.starting_node ||
              !nodesToDelete.some(({ id }) => id === node.data.starting_node)
            )
              return node;
            const { starting_node, ...data } = node.data;
            return {
              ...node,
              data,
            };
          });
        }

        // delete edges connected to the deleted nodes
        const nodeIds = nodesToDelete.map(({ id }) => id);
        const updatedEdges = deleteEdges([
          ...edges.filter(
            (e) => nodeIds.includes(e.source) || nodeIds.includes(e.target),
          ),
        ]);

        nodesToDelete.forEach((node) =>
          eventHub.emit(EVENT_TYPES.NODE_DELETE, {
            state: {
              edges: updatedEdges ?? edges,
              nodes: updatedNodes,
            },
            data: node,
          }),
        );
        setNodes(updatedNodes);
        setUnsavedWork(true);
        debounceUndoCounter();
      }
    },
    [nodes, edges, debounceUndoCounter, deleteEdges],
  );

  const duplicateNode = useCallback(
    (nodeId) => {
      if (nodeId.slice(0, nodeId.indexOf("-")) === "start") {
        notify.error(ALERTS.START_NODE);
        return;
      }
      const { updatedNodes, updatedEdges, oldToNewIdsMap } = _duplicateNode(
        nodeId,
        nodes,
        edges,
      );
      eventHub.emit(EVENT_TYPES.NODE_DUPLICATE, {
        state: {
          edges: updatedEdges,
          nodes: updatedNodes,
        },
        data: oldToNewIdsMap,
      });
      setNodes(updatedNodes);
      setEdges(updatedEdges);
      setUnsavedWork(true);
      debounceUndoCounter();
    },
    [nodes, edges, debounceUndoCounter],
  );

  const onNodesChange = useCallback(
    (changes) => {
      const hasChanges = changes?.some(
        (c) => c.type === "position" && !c.dragging,
      );
      setNodes((nds) => applyNodeChanges(changes, nds));
      if (hasChanges) debounceUndoCounter();
      checkCondition(unsavedWork, () => {
        const dbChanges = changes.some(
          (change) => change.type === "select" || change.type === "dimensions",
        );
        if (!dbChanges) {
          setUnsavedWork(true);
        }
      });
    },
    [nodes, unsavedWork, debounceUndoCounter],
  );

  const onEdgesChange = useCallback(
    (changes) => {
      const hasChanges = changes?.some((c) => c.type === "remove");
      setEdges((es) => applyEdgeChanges(changes, es));
      if (hasChanges) {
        debounceUndoCounter();
        setUnsavedWork(true);
      }
    },
    [setEdges, unsavedWork, debounceUndoCounter],
  );

  const onConnect = useCallback(
    (params) => {
      // prevent connecting a node from outside a loop to a node inside a loop
      const sourceNode = nodesToObj[params.source];
      const targetNode = nodesToObj[params.target];
      if (targetNode?.parentNode) {
        let source = sourceNode.parentNode;
        let invalidLoopSource = true;
        while (source) {
          if (source === targetNode.parentNode) {
            invalidLoopSource = false;
            break;
          }
          source = nodesToObj[source]?.parentNode;
        }
        if (invalidLoopSource) {
          notify.error(ALERTS.INVALID_LOOP_SOURCE_HANDLE);
          return;
        }
      }
      addEdge(params);
    },
    [addEdge, nodesToObj],
  );

  const onEdgeClick = useCallback((event, clickedEdge) => {
    setEdges((edges) =>
      edges.map((e) => {
        const { zIndex, ...edge } = e;
        return edge.id === clickedEdge.id
          ? {
              ...edge,
              style: {
                ...edge.style,
                stroke: "#b1b1b7",
                opacity: 1,
                strokeWidth: 2,
              },
              animated: true,
            }
          : {
              ...edge,
              style: {
                ...edge.style,
                stroke: "#b1b1b7",
                opacity: 1,
                strokeWidth: 2,
              },
              animated: false,
            };
      }),
    );
  }, []);

  const resetEdgesStyle = useCallback(() => {
    setEdges((edges) =>
      edges.map((e) => {
        const { zIndex, ...edge } = e;
        return {
          ...edge,
          style: {
            ...edge.style,
            stroke: "#b1b1b7",
            opacity: 1,
            strokeWidth: 2,
          },
          animated: false,
        };
      }),
    );
  }, []);

  const onPaneClick = useCallback(
    (event) => {
      resetEdgesStyle();
    },
    [resetEdgesStyle],
  );

  const onNodeClick = useCallback(
    (event) => {
      resetEdgesStyle();
    },
    [resetEdgesStyle],
  );

  const onInit = useCallback((instance) => {
    setReactFlowInstance(instance);
    setUnsavedWork(false);
  }, []);

  /**
   * highlight loop node when dragging a node from outside on top of it
   */
  const onNodeDrag = useCallback(
    (event, node) => {
      if (!node.parentNode) {
        const loopIntersections = getIntersectingNodes(node)
          .filter((n) => n.type === NODE_TYPE.LOOP)
          .map((n) => n.id);
        setNodes((ns) =>
          ns.map((n) => ({
            ...n,
            className: loopIntersections.includes(n.id)
              ? "bg-blue-500/10 dark:bg-white/10 shadow-sm dark:shadow-white/30"
              : "",
          })),
        );
      }
    },
    [getIntersectingNodes],
  );

  /**
   * Add node when dropping on top of a loop node
   */
  const onNodeDragStop = useCallback(
    (event, node) => {
      if (!node.parentNode) {
        const isInGroup = (n) => {
          if (!n.parentNode) return false;
          if (n.parentNode === node.id) return true;
          return isInGroup(nodesToObj[n.parentNode]);
        };
        const groupIntersections = getIntersectingNodes(node).filter(
          (n) => GROUP_NODE_TYPES.includes(n.type) && !isInGroup(n),
        );
        if (groupIntersections.length > 0) {
          const parent = groupIntersections[groupIntersections.length - 1];
          const updatedNodes = moveNodeIntoGroup(node, parent, nodes);

          setNodes(updatedNodes);
          setSelectedNodes(updatedNodes.filter((n) => n.selected));
        }
      }
    },
    [getIntersectingNodes, nodes],
  );

  const onSelectionChange = ({ nodes, edges }) => {
    setSelectedNodes(nodes);
    setSelectedEdges(edges);
  };

  const removeNodeFromLoop = useCallback(() => {
    setNodes(removeSelectedNodeFromLoop(nodes));
  }, [nodes]);

  const createLoop = useCallback(() => {
    setNodes(createLoopFromSelectedNodes(nodes));
  }, [nodes]);

  const copySelectedNodes = () => {
    let selectedNodes = nodes.filter((node) => node.selected);
    const nestedNodes = selectedNodes.filter((node) =>
      GROUP_NODE_TYPES.includes(node.type),
    );
    nestedNodes.forEach(
      ({ id }) =>
        (selectedNodes = [
          ...selectedNodes,
          ...nodes.filter(({ parentNode }) => parentNode === id),
        ]),
    );
    const selectedEdges = edges.filter(
      (edge) =>
        // select edges of nodes that have both target and source node selected
        selectedNodes.some((node) => node.id === edge.source) &&
        selectedNodes.some((node) => node.id === edge.target),
    );
    if (selectedNodes.length > 0) {
      navigator.clipboard.writeText(
        JSON.stringify({
          nodes: selectedNodes,
          edges: selectedEdges,
          workflow_id: initialWorkflow.id,
        }),
      );
    }
  };

  const pasteNodes = useCallback(() => {
    navigator.clipboard.readText().then((text) => {
      const result = _pasteNodes(
        text,
        selectedNodes,
        nodes,
        edges,
        initialWorkflow,
        project({
          x: window.innerWidth / 3,
          y: window.innerHeight / 3,
        }),
      );
      if (!result) return;
      const { updatedNodes, updatedEdges, newEdges } = result;
      setNodes(updatedNodes);
      if (newEdges.length > 0) {
        setEdges(updatedEdges);
      }
      setUnsavedWork(true);
      debounceUndoCounter();
    });
  }, [nodes, edges, debounceUndoCounter]);

  const selectNode = (node) =>
    setNodes(
      nodes.map((n) => ({
        ...n,
        selected: n.id === node.id,
      })),
    );

  const addOrUpdateNode = useCallback(
    (node, handle) => {
      const startExists = nodes.find((node) => node.type === NODE_TYPE.START);
      if (startExists && node.type === NODE_TYPE.START) {
        notify.error(ALERTS.START_NODE);
        return;
      }
      const sourceNode = nodes.find((n) => n.id === handle?.source);
      setUnsavedWork(true);
      // if there is a node id it means we are editing a node
      if (node.id) {
        // edit node with new values
        const updatedNodes = nodes.map((n) =>
          n.id !== node.id ? n : { ...node, data: { ...node.data } },
        );
        let updatedEdges = edges;

        const edgesConnectedToRemovedHandles = (check) =>
          updatedEdges.filter(({ source, sourceHandle }) => {
            if (source !== node.id) return;
            return Number(sourceHandle.split("_")[1]) >= check;
          });

        let deletedEdges = [];
        switch (node.type) {
          case NODE_TYPE.RADIO:
            deletedEdges = edgesConnectedToRemovedHandles(
              node.data.answers.length,
            );
            break;
          case NODE_TYPE.CHECKBOX:
            deletedEdges = edgesConnectedToRemovedHandles(
              node.data.handles.length,
            );
            break;
          case NODE_TYPE.API:
            deletedEdges = edgesConnectedToRemovedHandles(
              node.data.results.length,
            );
            break;
          case NODE_TYPE.ESCALATE:
            deletedEdges = edgesConnectedToRemovedHandles(
              node.data.omitResolved ? 1 : 2,
            );
            break;
          default:
            break;
        }
        if (deletedEdges.length > 0) {
          updatedEdges = updatedEdges.filter(
            ({ id }) => !deletedEdges.some((edge) => edge.id === id),
          );
          deletedEdges.map((edge) =>
            eventHub.emit(EVENT_TYPES.EDGE_DELETE, {
              state: {
                edges: updatedEdges,
                nodes: updatedNodes,
              },
              data: edge,
            }),
          );
        }

        updatedEdges = updatedEdges.map((edge) =>
          edge.source !== node.id
            ? edge
            : {
                ...edge,
                label: getLabel(edge.sourceHandle, updatedNodes),
              },
        );

        eventHub.emit(EVENT_TYPES.NODE_UPDATE, {
          state: {
            edges: updatedEdges,
            nodes: updatedNodes,
          },
          data: node,
        });
        setNodes(updatedNodes);
        setEdges(updatedEdges);
      } else {
        node.id = getNextNodeID(node.type, nodes);
        const nodeToAdd = {
          ...node,
          data: { ...node.data },
        };

        if (sourceNode?.parentNode) {
          nodeToAdd.parentNode = sourceNode.parentNode;
          nodeToAdd.extent = "parent";
          nodeToAdd.position = {
            x: sourceNode.position.x,
            y: sourceNode.position.y + 200,
          };
        }
        if (GROUP_NODE_TYPES.includes(node.type))
          nodeToAdd.dragHandle = ".drag-handle";

        const updatedNodes = addNode(nodeToAdd, nodes);
        eventHub.emit(EVENT_TYPES.NODE_CREATE, {
          state: {
            edges,
            nodes: updatedNodes,
          },
          data: nodeToAdd,
        });

        setNodes(updatedNodes);
        if (handle) {
          addEdge({
            ...handle,
            target: node.id,
            targetHandle: node.id,
          });
        }
      }
      debounceUndoCounter();
    },
    [nodes, edges, debounceUndoCounter, deleteEdges],
  );

  const centerViewOnNodes = useCallback(
    (nodeIds) => {
      const nodes = nodeIds.map((nodeId) => nodesToObj[nodeId]);
      reactFlowInstance.fitView({
        duration: 2000,
        padding: 0.15,
        maxZoom: 0.95,
        nodes,
      });
    },
    [nodesToObj, reactFlowInstance],
  );

  const displayGraphCycle = useCallback(
    (cycle) => {
      const updatedEdges = colorGraphCycleEdges(cycle, edges);
      setEdges(updatedEdges);
      centerViewOnNodes(cycle);
    },
    [edges, centerViewOnNodes],
  );

  return {
    undoCounter,
    nodes,
    edges,
    selectedNodes,
    selectedEdges,
    reactFlowInstance,
    unsavedWork,
    conflictingWork,
    setConflictingWork,
    reset,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onEdgeClick,
    onPaneClick,
    onNodeClick,
    onInit,
    addEdge,
    duplicateNode,
    deleteNodes,
    deleteEdges,
    onNodeDrag,
    onNodeDragStop,
    onSelectionChange,
    createLoop,
    removeNodeFromLoop,
    copySelectedNodes,
    pasteNodes,
    selectNode,
    addOrUpdateNode,
    centerViewOnNodes,
    displayGraphCycle,
  };
};
