import {
  ArrowPathIcon,
  ArrowsRightLeftIcon,
  ClockIcon,
  CodeBracketIcon,
  CursorArrowRippleIcon,
  DocumentTextIcon,
  StopIcon,
  WrenchScrewdriverIcon,
  CommandLineIcon,
} from "@heroicons/react/24/outline";

export const NODE_WIDTH = 300;
export const NODE_HEIGHT = 300;

export const ROLES = {
  ADMIN: "ADMIN",
  EDITOR: "EDITOR",
  VIEWER: "VIEWER",
  WRITER: "WRITER",
  USER: "USER",
  ALL: "ALL",
};

export const ItemTypes = {
  CARD: "card",
};

export const NODE_TYPE = {
  API: "api",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  ESCALATE: "escalate",
  WAIT: "wait",
  IF: "if",
  INPUT: "input",
  START: "start",
  END: "end",
  START_SPECIFIC: "start-specific", // only used by the flow step required to start from a specific node
  REFERENCE_FLOW: "reflow",
  LOOP: "loop",
  INSTRUCTIONAL: "instructional",
};

export const NODE_TYPE_ICON = {
  [NODE_TYPE.API]: CodeBracketIcon,
  [NODE_TYPE.CHECKBOX]: ArrowsRightLeftIcon,
  [NODE_TYPE.RADIO]: CursorArrowRippleIcon,
  [NODE_TYPE.ESCALATE]: WrenchScrewdriverIcon,
  [NODE_TYPE.WAIT]: ClockIcon,
  [NODE_TYPE.IF]: ArrowsRightLeftIcon,
  [NODE_TYPE.INPUT]: DocumentTextIcon,
  [NODE_TYPE.START]: ArrowPathIcon,
  [NODE_TYPE.END]: StopIcon,
  [NODE_TYPE.INSTRUCTIONAL]: CommandLineIcon,
};

export const NODE_TYPE_COLOR = {
  [NODE_TYPE.API]: "bg-violet-300 dark:bg-violet-500", //#C4B5FD
  [NODE_TYPE.CHECKBOX]: "bg-pink-300 dark:bg-pink-500", //#F9A8D4
  [NODE_TYPE.RADIO]: "bg-fuchsia-300 dark:bg-fuchsia-500", //#F0ABFC
  [NODE_TYPE.ESCALATE]: "bg-orange-400 dark:bg-orange-500", //#FB923C
  [NODE_TYPE.WAIT]: "bg-blue-300 dark:bg-blue-500", //#93C5FD
  [NODE_TYPE.IF]: "bg-yellow-300 dark:bg-yellow-500", //#FDE047
  [NODE_TYPE.INPUT]: "bg-teal-300 dark:bg-teal-500", //#5EEAD4
  [NODE_TYPE.START]: "bg-cyan-300 dark:bg-cyan-500", //#67E8F9
  [NODE_TYPE.REFERENCE_FLOW]: "bg-cyan-300 dark:bg-cyan-500", //#67E8F9
  [NODE_TYPE.END]: "", //"bg-green-400" #4ADE80, //"bg-red-400" #F87171
  [NODE_TYPE.LOOP]: "bg-amber-300 dark:bg-amber-500", // #FCD34D
  [NODE_TYPE.INSTRUCTIONAL]: "bg-rose-400 dark:bg-rose-500", //#fb7185
};

// node types that are of group type (can contain other nodes)
export const GROUP_NODE_TYPES = [NODE_TYPE.LOOP];

export const WORKFLOW_STATUS = {
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  FAILURE: "Failure",
  ESCALATED: "Escalated",
};

export const NEW_WORKFLOW_TEMPLATE = {
  nodes: [
    {
      id: "start-1",
      type: "start",
      position: {
        x: 500,
        y: 260,
      },
      width: 384,
      height: 91,
    },
  ],
  edges: [],
  errors: {},
};

export const OPS = {
  EQUAL: "Equal",
  NOT_EQUAL: "Not Equal",
  GT: "Greater Than",
  LT: "Less Than",
  GTE: "Greater Than or Equals",
  LTE: "Less Than or Equals",
  CONTAIN: "Contain",
};

export const LOGICAL_OPS = {
  AND: "AND",
  OR: "OR",
};

export const FilterOps = {
  like: "Contains",
  eq: "Equal",
  gt: "Greater Than",
  lt: "Less Than",
  gte: "Greater Than or Equals",
  lte: "Less Than or Equals",
  in: "In",
};

export const FilterOpsByType = {
  string: ["like", "eq", "in"],
  number: ["eq", "gt", "lt", "gte", "lte", "in"],
  date: ["eq", "gt", "lt", "gte", "lte", "in"],
  boolean: ["eq"],
};

export const ALERTS = {
  START_NODE: "Only one start node allowed",
  MULTI_SINGLE_SOURCE_HANDLE:
    "Cannot connect multiple edges from single source handle",
  INVALID_LOOP_SOURCE_HANDLE:
    "Cannot connect source handle outside loop to target handle inside loop",
  UNAUTHENTICATED: "You are not authenticated, please log out and log back in",
  DUPLICATE_ENTRIES: " There are duplicate entries.",
  WORKFLOW_UPDATED: "Workflow has been updated",
  WORKFLOW_UPDATE_CONFLICT:
    "Workflow updates detected. Please refresh the page to get the latest changes",
};

export const COLORS = {
  GRANITE_ATLANTIC_BLUE: "#3B5AA9",
  GRANITE_BLUE_2: "#4B9CD3",
  GRANITE_NAVY: "#003057",
  GRANITE_GRAY: "#575454",
  GRANITE_GRAY_DARK: "#A1A1AA",
};

export const FILE_TYPES = {
  image: ["png", "jpg", "jpeg", "gif"],
  video: ["mp4"],
};

export const NODE_CONFIG = [
  {
    id: 1,
    title: "Radio",
    type: NODE_TYPE.RADIO,
    includeFileUpload: true,
    schema: {
      question: {
        type: "autoComplete",
        label: "Question",
        options: {
          required: true,
        },
      },
      answers: {
        type: "array",
        label: "Answers",
        fields: {
          text: {
            type: "autoComplete",
            label: "Answer",
            placeholder: "Answer",
            options: {
              required: true,
              validate: {
                unique: (value, formValues) =>
                  formValues.answers.filter(({ text }) => text === value)
                    .length === 1 || "Duplicate answer",
              },
            },
          },
        },
      },
    },
  },
  {
    id: 2,
    title: "Checkbox",
    type: NODE_TYPE.CHECKBOX,
    description: "",
    includeFileUpload: true,
    schema: {
      question: {
        type: "autoComplete",
        label: "Question",
        options: {
          required: true,
        },
      },
      answers: {
        type: "array",
        label: "Answers",
        fields: {
          text: {
            type: "autoComplete",
            label: "Answer",
            options: {
              required: true,
              validate: {
                unique: (value, formValues) =>
                  formValues.answers.filter(({ text }) => text === value)
                    .length === 1 || "Duplicate answer",
              },
            },
          },
        },
      },
      handles: {
        type: "array",
        label: "Handles",
        fields: {
          handle: {
            type: "multiSelect",
            label: "Handle",
            selectOptions: [],
            options: {
              required: false,
            },
          },
        },
      },
      omitResolved: {
        type: "checkbox",
        label: 'Omit "Resolved" prompt',
        options: {
          required: false,
        },
      },
    },
  },
  {
    id: 3,
    title: "Wait",
    type: NODE_TYPE.WAIT,
    includeFileUpload: true,
    description: "",
    schema: {
      heading: {
        type: "text",
        label: "Heading",
        options: {
          required: true,
        },
      },
      timer: {
        type: "text",
        label: "Timer (seconds)",
        options: {
          required: true,
        },
      },
    },
  },
  {
    id: 4,
    title: "API",
    type: NODE_TYPE.API,
    schema: {
      name: {
        type: "autoComplete",
        label: "Name",
        options: {
          required: true,
        },
      },
      category: {
        type: "select",
        label: "Category",
        selectOptions: [],
        options: {
          required: true,
        },
      },
      arguments: {
        type: "array",
        label: "Arguments",
        options: {
          collapsed: true,
        },
        fields: {
          key: {
            label: "Key",
            type: "text",
            options: {
              required: false,
            },
          },
          value: {
            label: "Value",
            type: "text",
            options: {
              required: false,
            },
          },
        },
      },
      results: {
        type: "array",
        label: "Results",
        fields: {
          status: {
            label: "Status",
            type: "select",
            selectOptions: [
              { value: 200, label: "Status Code 200" },
              { value: 500, label: "Failure" },
            ],
            options: {
              required: true,
            },
          },
          success: {
            label: "Success",
            type: "select",
            selectOptions: ["true", "false"],
            options: {
              required: true,
            },
          },
          response: {
            type: "select",
            label: "Response",
            options: {
              required: false,
              min: 1,
            },
            selectOptions: [],
          },
        },
      },
    },
  },
  {
    id: 5,
    title: "Escalate",
    type: NODE_TYPE.ESCALATE,
    description: "",
    schema: {
      failureGroup: {
        type: "select",
        label: "Failure Group",
        selectOptions: [],
        options: {
          required: true,
        },
      },
      failure: {
        type: "autoComplete",
        label: "Failure",
        options: {
          required: true,
        },
      },
      omitResolved: {
        type: "checkbox",
        label: 'Omit "Resolved" prompt',
        options: {
          required: false,
        },
      },
    },
  },
  {
    id: 6,
    title: "Conditional",
    type: NODE_TYPE.IF,
    schema: {
      name: {
        type: "text",
        label: "Name",
        options: {
          required: true,
        },
      },
      conditions: {
        label: "Conditions",
        type: "condition",
        options: {
          nested: true,
          maxDepth: 1,
        },
      },
    },
  },
  {
    id: 7,
    title: "Input",
    type: NODE_TYPE.INPUT,
    includeFileUpload: true,
    schema: {
      heading: {
        type: "text",
        label: "Heading",
        options: {
          required: true,
        },
      },
      fields: {
        type: "array",
        label: "Fields",
        options: {
          required: true,
        },
        fields: {
          name: {
            type: "text",
            label: "Name",
            options: {
              required: true,
            },
          },
          label: {
            type: "text",
            label: "Label",
            options: {
              required: true,
            },
          },
          type: {
            type: "select",
            label: "Type",
            selectOptions: [
              "text",
              "textArea",
              "select",
              "number",
              "email",
              "phoneNumber",
              "regex",
              "nonNumeric",
            ],
            options: {
              required: true,
            },
          },
          pattern: {
            type: "text",
            label: "Regex",
            options: {
              required: false,
            },
            checkIf: {
              fieldName: "type",
              fieldValue: ["regex"],
            },
          },
          selectTypeOptions: {
            type: "textArea",
            label: "Select Options (1 per line)",
            options: {
              required: true,
            },
            checkIf: {
              fieldName: "type",
              fieldValue: ["select"],
            },
          },
          allowMultipleEntries: {
            type: "select",
            label: "Allow Multiple Entries",
            selectOptions: ["No", "Yes"],
            options: {
              required: true,
            },
            checkIf: {
              fieldName: "type",
              fieldValue: ["text", "number", "phoneNumber", "nonNumeric"],
            },
          },
          required: {
            type: "select",
            label: "Required",
            selectOptions: ["Yes", "No"],
            options: {
              required: true,
            },
          },
        },
      },
    },
  },
  {
    id: 10,
    title: "Start",
    type: NODE_TYPE.START,
    schema: {},
  },
  {
    id: 11,
    title: "End",
    type: NODE_TYPE.END,
    description: "",
    schema: {
      status: {
        type: "radio",
        label: "Status",
        values: ["Success", "Failure"],
        options: {
          required: true,
        },
      },
      message: {
        type: "autoComplete",
        label: "Message",
        options: {
          required: true,
        },
      },
    },
  },
  {
    id: 12,
    title: "Reference Flow",
    type: NODE_TYPE.REFERENCE_FLOW,
    schema: {
      reference_flows: {
        type: "select",
        label: "Reference Flow",
        selectOptions: [],
        options: {
          required: true,
        },
      },
      reference_flow_label: {
        type: "text",
        label: "Reference Flow Label",
        options: {
          required: true,
        },
      },
      variables: {
        type: "array",
        label: "Variables",
        fields: {
          name: {
            type: "text",
            label: "Name",
            options: {
              required: true,
            },
          },
          value: {
            type: "text",
            label: "Value",
            options: {
              required: true,
            },
          },
        },
      },
    },
  },
  {
    id: 13,
    title: "Loop",
    type: NODE_TYPE.LOOP,
    schema: {
      source: {
        type: "autoComplete",
        label: "Source",
        options: {
          required: true,
        },
      },
      starting_node: {
        type: "select",
        label: "Starting Node",
        selectOptions: [],
      },
    },
  },
  {
    id: 14,
    title: "Instructional",
    type: NODE_TYPE.INSTRUCTIONAL,
    includeFileUpload: true,
    schema: {
      heading: {
        type: "autoComplete",
        label: "Heading",
        options: {
          required: true,
        },
      },
      instruction: {
        type: "textArea",
        label: "Instruction",
        options: {
          required: true,
        },
      },
    },
  },
];

export const UserSettingTypes = {
  Report_View: "Report_View",
};
