import React, { useState } from "react";
import { Button, Modal } from "components/common";
import { classNames } from "utils";
import { AttachmentPreview } from "./AttachmentPreview";

const AttachmentsModal = ({ attachments, openIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(openIndex);

  return (
    <Modal
      isOpen
      onClose={onClose}
      size="max-w-4xl"
      renderFooter={() => (
        <div className="flex justify-end bg-background-primary px-4 py-3 dark:bg-background-primary-dark sm:px-6">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      )}
    >
      <div className="flex flex-col gap-4 p-4">
        <div className="flex-1">
          <AttachmentPreview
            attachment={attachments[currentIndex]}
            className="flex h-[65vh] w-full justify-center"
          />
        </div>
        <div>
          {attachments && (
            <div className="flex flex-row gap-4 overflow-x-auto">
              {attachments.map((image, index) => (
                <div
                  className="relative mb-4 flex items-center justify-center"
                  key={index}
                >
                  <AttachmentPreview
                    attachment={image}
                    className={classNames(
                      "h-16 w-16",
                      index === currentIndex ? "border-2 border-gray-500" : "",
                    )}
                    thumbnail={true}
                    onClick={() => setCurrentIndex(index)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AttachmentsModal;
