import React, { useState, useEffect, useRef } from 'react';

function Header({ name }) {
    return (
        <>
            <div class='pb-2 mx-auto space-y-6'>

                <h2 class="text-2xl font-bold">{name}</h2>
            </div>
        </>
    )
}

export default Header;