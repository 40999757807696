import React, { useRef } from "react";
import Button from "../Button";
import { AttachmentPreview } from "../attachments/AttachmentPreview";
import { FILE_TYPES } from "utils/constants";
import { notify } from "utils/notification";

const fileTypeRegex = new RegExp(
  `(${Object.entries(FILE_TYPES)
    .map(([key, types]) => `${key}/(${types.join("|")})`)
    .join("|")})`,
);

const FileUploader = ({ files, setFiles, onClick }) => {
  const ref = useRef();

  const onChange = (e) => {
    const { files: _files } = e.target;
    const validFiles = [];
    for (let i = 0; i < _files.length; i++) {
      const file = _files[i];
      if (file?.type?.match(fileTypeRegex)) {
        validFiles.push(file);
      } else {
        notify.error(`${file.name} is not of valid type!`);
      }
    }

    // clear input value so onChange can trigger again if the same file is selected
    e.target.value = "";

    if (validFiles.length) {
      setFiles([...files, ...validFiles]);
      return;
    }
  };

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  return (
    <>
      {files.map((file, idx) => (
        <AttachmentPreview
          className="h-24 w-24 shrink-0 rounded-lg border border-gray-300 dark:border-gray-300/30"
          key={idx}
          attachment={file}
          onRemove={() => removeFile(idx)}
          thumbnail={true}
          onClick={() => onClick?.(idx)}
        />
      ))}
      <Button
        variant="secondary"
        className="h-24 min-h-0 w-24 min-w-0 shrink-0"
        onClick={() => ref.current.click()}
      >
        <span className="text-md sm:text-lg">+</span>
      </Button>
      <input
        ref={ref}
        className="hidden"
        type="file"
        id="file"
        onChange={onChange}
        accept={Object.entries(FILE_TYPES)
          .map(([key, types]) =>
            types.map((type) => `${key}/${type}`).join(", "),
          )
          .join(", ")}
        multiple
      />
    </>
  );
};

export default FileUploader;
