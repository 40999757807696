import * as React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuthState } from "contexts/auth";

const RequireAuth = ({ children }) => {
  const auth = useAuthState();
  const location = useLocation();
  if(auth.isTurnupUser && auth.isAuthenticated){
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

const RequireFlowAuth = ({ children }) => {
  const auth = useAuthState();
  const location = useLocation();
  if (!auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};
export { RequireAuth, RequireFlowAuth };
export default RequireAuth;
