import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useSocket } from "contexts/socket";
import { useAuthState } from "contexts/auth";

import { postFrontendErrorLogs, checkActivationTicketStatus, getCurrentTimer, updateExistingTimer, getLatestNote, sendAutoMessage, updateEscalationV1 } from "api";

const CONNECT_WISE_STATUS = {
    "wip": 1344,
    "escalate": 1345,
    "troubleshooting": 1346,
    "issue_resolved": 1347,
    "success": 1350,
    "fail": 1349,
    "cancelled": 1351,
    "problem_unresolved": 1353
}

const CONNECT_WISE_PRIORITES = {
    8: "medium",
    15 : "high",
    6: "critical"
}

const PRIORITY_TIER ={
    "medium": 'Tier 1 Escalation in Process',
    "high": 'Tier 2 Escalation in Process',
    "critical": 'Tier 3 Escalation in Process',
}

function hasRemainingTime(dateTime) {
    const startTimer = new Date(dateTime)
    const currentDateTime = new Date();

    const difference = currentDateTime - startTimer;

    return difference < (15 * 60 * 1000) ? difference : false;
}

const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = ((time % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

function wait(seconds) {
    const delayInMilliSeconds = seconds * 1000;
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("Done");
      }, delayInMilliSeconds);
    });
}

const EscalationTimer = (props) => {
    const [priority, setPriority] = useState("medium");
    const [timer, setTimer] = useState(300000);
    const [remainingTime, setRemainingTime] = useState(timer);
    const [existingTimerID, setExistingTimerID] = useState(null);
    const [ticketWorkedOn, setTicketWorkedOn] = useState(false);
    const [autoMessageId, setAutoMessageId] = useState(0);

    const {user} = useAuthState();

    const {
        activationTicket,
        note,
        setNote,
        setSelectedValue,
        escalationReason,
        setShowPhone,
        workflowInstanceId,
        setResolutionValue,
        escalationId
    } = props

    const ticketStatusCheck = async () => {
        try {
            const data = await checkActivationTicketStatus(activationTicket);
            if(data?.data?.data?.id === CONNECT_WISE_STATUS['troubleshooting']){
                setTicketWorkedOn(true)
            }

            if (data?.data?.data?.id === CONNECT_WISE_STATUS["issue_resolved"] || data?.data?.data?.id === CONNECT_WISE_STATUS["success"]) {
                await wait(10)
                setSelectedValue("Yes")

                const latestNote = await getLatestNote(activationTicket);
                const resolvedNote = `Resolved: ${escalationReason || 'error'}. ${latestNote?.data?.data?.text || ""}.  Please click Next below to resume the installation.`
                setNote(resolvedNote)
                setResolutionValue(resolvedNote)
                clearInterval(autoMessageId)
                await updateExistingTimer({
                    id: existingTimerID,
                    is_resolved: true,
                    priority: "medium",
                    resolution_details: resolvedNote,
                    is_deleted: true,
                    activation_ticket: activationTicket,
                    escalate_message: escalationReason,
                    is_failed: false
                })
            }

            if (data?.data?.data?.id === CONNECT_WISE_STATUS["fail"] || data?.data?.data?.id === CONNECT_WISE_STATUS["cancelled"] || data?.data?.data?.id === CONNECT_WISE_STATUS["problem_unresolved"]) {
                await wait(10)
                setSelectedValue("No")

                const latestNote = await getLatestNote(activationTicket);
                setNote(latestNote?.data?.data?.text || "")
                setResolutionValue(latestNote?.data?.data?.text || "")

                clearInterval(autoMessageId)
                await updateExistingTimer({
                    id: existingTimerID,
                    is_resolved: false,
                    priority,
                    resolution_details: latestNote?.data?.data?.text || "",
                    is_deleted: true,
                    activation_ticket: activationTicket,
                    escalate_message: escalationReason,
                    is_failed: true
                })
            }

        } catch (error) {
            postFrontendErrorLogs(error);
        }
    }

    const getExistingTimer = async () => {
        try {
            const { data } = await getCurrentTimer({
                activation_ticket: activationTicket,
                escalate_message: escalationReason,
            });

            if (data?.data?.id && data?.data?.escalated_at) {
                const existingTimer = hasRemainingTime(data.data.escalated_at)

                if (existingTimer) {
                    setExistingTimerID(data?.data?.id);
                    setRemainingTime(existingTimer);
                    setPriority(CONNECT_WISE_PRIORITES[data?.data?.priority] || "medium")
                }

                return true
            }

            return false
        } catch (error) {
            postFrontendErrorLogs(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await getExistingTimer();

                // if (response) {
                //     return
                // }

                const { data } = await updateExistingTimer({
                    is_resolved: false,
                    priority,
                    resolution_details: note || "",
                    is_deleted: false,
                    activation_ticket: activationTicket,
                    escalate_message: escalationReason,
                    is_failed: false
                })
                setExistingTimerID(data?.data?.id)
            } catch (error) {
                postFrontendErrorLogs(error);
            }
        }

        fetchData()

        function tick() {
            ticketStatusCheck();
        }

        function autoMessageTick(){
            sendAutoMessage(workflowInstanceId)
        }

        const id = setInterval(tick, 30000);
        const messageId = setInterval(autoMessageTick, 90000);

        setAutoMessageId(messageId);

        return () => {
            clearInterval(id)
            clearInterval(messageId)
        }

    }, [])

    useEffect(() => {
        const timerId = setInterval(() => {
            if (remainingTime > 0) {
                setRemainingTime(time => time - 1000);
            }
        }, 1000);

        return () => {
            clearInterval(timerId);
        };
    }, [remainingTime]);

    useEffect(() => {
        if (remainingTime <= 0) {
            switch (priority) {
                case 'medium':
                    setPriority('high');
                    setRemainingTime(300000);
                    updateExistingTimer({
                        id: existingTimerID,
                        is_resolved: false,
                        priority: "high",
                        is_deleted: false,
                        activation_ticket: activationTicket,
                        escalate_message: escalationReason,
                        resolution_details: note || "",
                        is_failed: false
                    });
                    updateEscalationV1(escalationId, 'high');
                    break;
                case 'high':
                    setPriority('critical');
                    setRemainingTime(300000);
                    updateExistingTimer({
                        id: existingTimerID,
                        is_resolved: false,
                        priority: "critical",
                        is_deleted: false,
                        activation_ticket: activationTicket,
                        escalate_message: escalationReason,
                        resolution_details: note || "",
                        is_failed: false
                    });
                    updateEscalationV1(escalationId, 'critical');
                    break;
                case 'critical':
                    setShowPhone(true)
                    break;
                default:
                    break;
            }
        }
    }, [remainingTime, priority]);

    return (
        <>
            <h1 className="mb-6 min-h-[6rem] sm:mb-8 sm:min-h-0 sm:text-lg">
                {PRIORITY_TIER[priority]}
            </h1>
            <div className="space-y-2">
                {ticketWorkedOn ? (<p className="text-green-500">Your issue has been picked up by Granite Activation Tech. You will be contacted shortly, be sure to check the chat tool at the bottom of the screen for messages from the Activation team.</p>) 
                : (<p className="text-gray-500">You will receive a response within the next {formatTime(remainingTime)} minutes</p>)}
            </div>
        </>
    )
}

export default EscalationTimer;