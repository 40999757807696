import { postFrontendErrorLogs } from "api";

export class EventHub {
  listeners = new Map();

  async emit(eventName, event = {}, ...args) {
    if (this.listeners.has(eventName)) {
      for (const listener of this.listeners.get(eventName)) {
        try {
          await listener({ type: eventName, ...event }, ...args);
        } catch (error) {
          postFrontendErrorLogs(error);
        }
      }
    }
  }

  on(eventName, listener) {
    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, [listener]);
    } else {
      this.listeners.get(eventName).push(listener);
    }

    // Return a function to remove the listener
    return () => {
      this.off(eventName, listener);
    };
  }

  off(eventName, listener) {
    if (this.listeners.has(eventName)) {
      const listenerIndex = this.listeners.get(eventName).indexOf(listener);

      if (listenerIndex >= 0) {
        this.listeners.get(eventName).splice(listenerIndex, 1);
      }
    }
  }
}
