import React from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "contexts/auth";
import { ROLES } from "utils/constants";
import { useTheme } from "contexts/theme";
import CheckboxSlider from "components/common/form/CheckboxSlider";
import ChatWidget from "components/common/ChatWidget";

const Layout = ({ workflowInstanceId, agentInfo, children }) => {
  const { user } = useAuthState();
  const { switchDarkMode } = useTheme();
  return (
    <>
    <div className="flex h-full min-h-full w-full flex-col">
      <div className="border-b border-gray-300 bg-background-primary dark:border-gray-300/30 dark:bg-background-primary-dark">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 justify-between items-center">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <Link
                  to={user?.roles === ROLES.USER ? "/flow" : "/app/"}
                  onClick={
                    user?.roles === ROLES.USER
                      ? () => window.location.reload()
                      : null
                  }
                >
                  <img
                    className="block h-12 w-auto dark:hidden"
                    src="/logoWithName.png"
                    alt="Granite EPIK Activation"
                  />
                  <img
                    className="hidden h-12 w-auto dark:block"
                    src="/logoWithNameDark.png"
                    alt="Granite EPIK Activation"
                  />
                </Link>
              </div>
            </div>
            {/* <div class="flex items-center">
              <div className="relative sm:hidden">
                <CheckboxSlider handleOnClick={switchDarkMode} />
              </div>
            </div> */}
            <img className="h-6 w-auto" src="/greenix-logo.png"/>
          </div>
        </div>
      </div>

      {/* <div className="w-full h-screen flex items-center justify-center bg-background dark:bg-background-dark"> */}
      <div className="flex flex-1 justify-center bg-background px-4 py-2 dark:bg-background-dark sm:py-12">
        <div className="flex w-full flex-col sm:w-2/3">{children}</div>
      </div>
    </div>
    <ChatWidget/>
    </>
  );
};

export default Layout;
