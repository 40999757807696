import React from "react";
import PropTypes from "prop-types";
import Input from "../Input";
import Select from "../Select";
import TextArea from "../TextArea";
import Checkbox from "../Checkbox";
import Radio from "../Radio";
import AutoComplete from "../AutoComplete";
import { InputArray } from "../index";
import Condition from "../Condition";
import Toggle from "../Toggle";
import ImageUpload from "../ImageUpload";
import ToggleWithConditional from "../ToggleWithConditional";
import SelectWithConditional from "../SelectWithConditional";
import InputArrayWithModal from "../InputArrayWithModal";

const FormInput = ({
  label,
  name,
  type,
  placeholder,
  value,
  options,
  error,
  register,
  control,
  watch,
  isValidating,
  query,
  dynamic,
  onReset,
  dynamicOptions,
  selectOptions,
  onVariableSelectionHandler,
  ...rest
}) => (
  <>
    {{
      text: (
        <Input
          name={name}
          type={type}
          placeholder={placeholder}
          label={label}
          register={register}
          options={options}
          error={error}
          value={value}
          {...rest}
        />
      ),
      phoneNumber: (
        <Input
          name={name}
          type={"tel"}
          placeholder={placeholder}
          label={label}
          register={register}
          options={{
            ...options,
            maxLength: {
              value: 10,
              message: "Maximum length is 10 characters",
            },
            pattern: {
              value: /\d{10}/,
              message: "Phone number must be in the format 1234567890",
            },
          }}
          error={error}
          {...rest}
        />
      ),
      textArea: (
        <TextArea
          name={name}
          type={type}
          value={value}
          label={label}
          placeholder={placeholder}
          options={options}
          error={error}
          register={register}
          rows={5}
          {...rest}
        />
      ),
      checkbox: (
        <Checkbox
          name={name}
          type={type}
          label={label}
          options={options}
          error={error}
          register={register}
          {...rest}
        />
      ),
      toggle: (
        <Toggle
          name={name}
          type={type}
          label={label}
          options={options}
          error={error}
          register={register}
          {...rest}
        />
      ),
      toggleWithConditional: (
        <ToggleWithConditional
          name={name}
          type={type}
          label={label}
          options={options}
          error={error}
          register={register}
          {...rest}
        />
      ),
      radio: (
        <Radio
          name={name}
          type={type}
          label={label}
          options={options}
          error={error}
          value={value}
          values={rest.values}
          register={register}
          {...rest}
        />
      ),
      select: (
        <Select
          name={name}
          label={label}
          type={type}
          placeholder={placeholder}
          register={register}
          control={control}
          options={options}
          selectOptions={selectOptions}
          error={error}
          {...rest}
        />
      ),
      selectWithConditional: (
        <SelectWithConditional
          name={name}
          label={label}
          type={type}
          placeholder={placeholder}
          register={register}
          control={control}
          options={options}
          selectOptions={selectOptions}
          error={error}
          {...rest}
        />
      ),
      multiSelect: (
        <Select
          name={name}
          label={label}
          type={type}
          placeholder={placeholder}
          register={register}
          control={control}
          options={options}
          selectOptions={selectOptions}
          error={error}
          isMulti
          {...rest}
        />
      ),
      autoComplete: (
        <AutoComplete
          name={name}
          label={label}
          type={type}
          placeholder={placeholder}
          register={register}
          control={control}
          options={options}
          error={error}
          query={query}
          onVariableSelectionHandler={onVariableSelectionHandler}
          {...rest}
        />
      ),
      array: (
        <InputArray
          name={name}
          label={label}
          onReset={onReset}
          type={type}
          placeholder={placeholder}
          register={register}
          control={control}
          watch={watch}
          isValidating={isValidating}
          fields={rest.fields}
          error={error}
          options={options}
          dynamic={dynamic}
          dynamicOptions={dynamicOptions}
          query={query}
          onVariableSelectionHandler={onVariableSelectionHandler}
          {...rest}
        />
      ),
      arrayWithModal: (
        <InputArrayWithModal
          name={name}
          label={label}
          onReset={onReset}
          type={type}
          placeholder={placeholder}
          register={register}
          control={control}
          watch={watch}
          isValidating={isValidating}
          fields={rest.fields}
          error={error}
          options={options}
          dynamic={dynamic}
          dynamicOptions={dynamicOptions}
          query={query}
          onVariableSelectionHandler={onVariableSelectionHandler}
          {...rest}
        />
      ),
      condition: (
        <Condition
          name={name}
          label={label}
          type={type}
          placeholder={placeholder}
          register={register}
          control={control}
          watch={watch}
          isValidating={isValidating}
          fields={rest.fields}
          error={error}
          dynamicOptions={dynamicOptions}
          options={options}
          {...rest}
        />
      ),
      nonNumeric: (
        <Input
          name={name}
          type={"text"}
          placeholder={placeholder}
          label={label}
          register={register}
          options={{
            ...options,
            pattern: {
              value: /^\D+$/g,
              message: "Numbers are not allowed",
            },
          }}
          error={error}
          {...rest}
        />
      ),
      image: (
        <ImageUpload
          name={name}
          type={type}
          label={label}
          placeholder={placeholder}
          options={options}
          error={error}
          register={register}
          rows={5}
          {...rest}
        />
      ),
    }[type] || (
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        label={label}
        register={register}
        options={options}
        error={error}
        value={value}
        {...rest}
      />
    )}
  </>
);

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "phoneNumber",
    "password",
    "textArea",
    "checkbox",
    "radio",
    "select",
    "multiSelect",
    "array",
    "date",
    "time",
    "autoComplete",
    "condition",
    "nonNumeric",
    "toggle",
    "image",
    "toggleWithConditional",
    "selectWithConditional",
    "arrayWithModal"
  ]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  register: PropTypes.func.isRequired,
  control: PropTypes.any,
  useFieldsArray: PropTypes.any,
  error: PropTypes.any,
  placeholder: PropTypes.string,
  options: PropTypes.any,
};

export default FormInput;
