import React, { useEffect, useState, useContext } from "react";
import { getRecentFlows } from "api";
import { DashboardContext } from "./Dashboard";
import Search from "components/common/Search";

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { timeZoneName: "short" });
}

function checkTechStatus(iwTicket, data) {
    try {
        let record = data[iwTicket]
        if (record?.metas) {
            return record?.metas
        }

        return []
    } catch (err) {
        console.log(err)
        return []
    }
}

function renderAgentStatus(iwTicket, data) {
    if (!data || !Object.keys(data)?.length) {
        return (
            <div className="flex gap-2">
                <span
                    className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600"
                >
                    <span className="h-1.5 w-1.5 rounded-full bg-red-600"></span>
                    offline
                </span>
            </div>
        )
    }

    let techs = checkTechStatus(iwTicket, data)

    if (!techs?.length) {
        return (
            <div className="flex gap-2">
                <span
                    className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600"
                >
                    <span className="h-1.5 w-1.5 rounded-full bg-red-600"></span>
                    offline
                </span>
            </div>
        )
    }

    return techs?.map(({ name }) => {
        return (
            <div className="flex gap-2">
                <span
                    className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                >
                    <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                    {name}
                </span>
            </div>
        )
    })
}

function SortIcon({onSort}){
    return(
        <button onClick={onSort}>
            <img className="inline-block h-4 w-auto" src="/sort.png"/>  
        </button>
    )
}

const Home = () => {
    const { setFlowId, activeTech, instances } = useContext(DashboardContext);
    const [search, setSearch] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [searchClose, setSearchClose] = useState(false)
    const [data, setData] = useState([])
    const [sort, setSort] = useState(false)


    // async function updateFlows() {
    //     try {
    //         const {data} = await getRecentFlows();

    //         setData(data?.data)
    //         setLoading(false)
    //     } catch (error) {
    //         console.log(error)
    //         setLoading(false)
    //     }
    // }

    // useEffect(() => {
    //     updateFlows()

    //     let id = setInterval(() => {
    //         setLoading(true);
    //         updateFlows();
    //     }, 60000)

    //     return () => {
    //         clearInterval(id);
    //     }
    // }, [])

    // if (isLoading) {
    //     return (<div className="flex items-center justify-center h-screen">
    //         <div className="relative">
    //             <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
    //             <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
    //             </div>
    //         </div>
    //     </div>
    //     )
    // }

    function filterOutRow(iwTicket){
        setData(instances.filter((row) => row?.iw_ticket === iwTicket))
    }

    function onSearch(event) {
        event.preventDefault();
        setSearchClose(true)
        setSearchTerm(search)
        filterOutRow(search)
    }

    function clearSearch() {
        setSearchTerm('')
        setSearchClose(false)
        setSearch('')
        setData([])
    }

    function renderRows(row){
        return (<tr id="" className="hover:bg-gray-50">
            <td className="flex gap-3 px-6 py-4 font-normal text-gray-900">
                <div className="font-medium text-gray-700">{row?.iw_ticket}</div>
            </td>
            <td className="px-6 py-4">
                {renderAgentStatus(row?.iw_ticket, activeTech)}
            </td>
            <td className="px-6 py-4">{row?.status || 'N/A'}</td>
            <td className="px-6 py-4">{formatDate(row?.created_at) || 'N/A'}</td>
            <td className="px-6 py-4">{row?.form_data?.activation_ticket || 'N/A'}</td>
            <td className="px-6 py-4">
                <div className="flex gap-2">
                    <span
                        className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
                    >
                        {row?.form_data?.customer_name || 'N/A'}
                    </span>
                    <span
                        className="inline-flex items-center gap-1 rounded-full bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600"
                    >
                        {row?.form_data?.serial_number || 'N/A'}
                    </span>
                    <span
                        className="inline-flex items-center gap-1 rounded-full bg-violet-50 px-2 py-1 text-xs font-semibold text-violet-600"
                    >
                        {row?.form_data?.address_1 || 'N/A'}
                    </span>
                    <span
                        className="inline-flex items-center gap-1 rounded-full bg-violet-50 px-2 py-1 text-xs font-semibold text-violet-600"
                    >
                        {row?.form_data?.zip_code || 'N/A'}
                    </span>
                </div>
            </td>
            <td className="px-6 py-4">
                <div className="flex gap-2">
                    {row?.form_data?.line?.map((line) => {
                        return (
                            <span
                                className="inline-flex items-center gap-1 rounded-full bg-blue-50 px-2 py-1 text-xs font-semibold text-blue-600"
                            >
                                {line?.serviceName}
                            </span>
                        )
                    })}
                </div>
            </td>
            <td className="px-6 py-4">
                <div className="flex justify-end gap-4">
                    <button onClick={() => setFlowId(row?.id, "Chat")}>
                        <svg
                            fill="#000000"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            id="chat"
                            data-name="Line Color"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon line-color"
                        >
                            <path
                                id="primary"
                                d="M18.81,16.23,20,21l-4.95-2.48A9.84,9.84,0,0,1,12,19c-5,0-9-3.58-9-8s4-8,9-8,9,3.58,9,8A7.49,7.49,0,0,1,18.81,16.23Z"
                                style={{
                                    fill: "none",
                                    stroke: "rgb(0, 0, 0)",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: 2,
                                }}
                            />
                        </svg>
                    </button>
                    <button onClick={() => setFlowId(row?.id, "ScreenShots")}>
                        <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M3 3h2v2H3V3zm4 0h2v2H7V3zm4 0h2v2h-2V3zm4 0h2v2h-2V3zm4 0h2v2h-2V3zm0 4h2v2h-2V7zM3 19h2v2H3v-2zm0-4h2v2H3v-2zm0-4h2v2H3v-2zm0-4h2v2H3V7zm7.667 4l1.036-1.555A1 1 0 0 1 12.535 9h2.93a1 1 0 0 1 .832.445L17.333 11H20a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h2.667zM14 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                            </g>
                        </svg>
                    </button>
                    <button onClick={() => setFlowId(row?.id, "FlowState")}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 0.96 0.96"
                            xmlSpace="preserve"
                        >
                            <path
                                className="blueprint_een"
                                d="M0.93 0.66h-0.15v-0.18h-0.14c-0.001 -0.002 -0.002 -0.004 -0.004 -0.006l-0.135 -0.135c-0.002 -0.002 -0.004 -0.003 -0.006 -0.004V0.3H0.66a0.03 0.03 0 0 0 0.03 -0.03V0.06a0.03 0.03 0 0 0 -0.03 -0.03H0.3a0.03 0.03 0 0 0 -0.03 0.03v0.21a0.03 0.03 0 0 0 0.03 0.03h0.165v0.035c-0.002 0.001 -0.004 0.002 -0.006 0.004l-0.135 0.135c-0.002 0.002 -0.003 0.004 -0.004 0.006H0.18v0.18H0.03a0.03 0.03 0 0 0 -0.03 0.03v0.21a0.03 0.03 0 0 0 0.03 0.03h0.36a0.03 0.03 0 0 0 0.03 -0.03v-0.21a0.03 0.03 0 0 0 -0.03 -0.03H0.21v-0.15h0.11c0.001 0.002 0.002 0.004 0.004 0.006l0.135 0.135a0.03 0.03 0 0 0 0.042 0l0.135 -0.135c0.002 -0.002 0.003 -0.004 0.004 -0.006H0.75v0.15h-0.18a0.03 0.03 0 0 0 -0.03 0.03v0.21a0.03 0.03 0 0 0 0.03 0.03h0.36a0.03 0.03 0 0 0 0.03 -0.03v-0.21a0.03 0.03 0 0 0 -0.03 -0.03M0.33 0.24V0.09h0.3v0.15zm0.03 0.63H0.06v-0.15h0.3zm0.12 -0.282L0.387 0.495 0.48 0.402l0.093 0.093zM0.9 0.87H0.6v-0.15h0.3zM0.39 0.15h0.18v0.03h-0.18zm-0.09 0.66H0.12v-0.03h0.18zm0.54 0h-0.18v-0.03h0.18z"
                            />
                        </svg>
                    </button>
                </div>
            </td>
        </tr>
        )
    }

    function sortData(filterBy){
        let tempData = instances

        if(sort){
            tempData = tempData.sort((a,b) => {
                if(a[filterBy] < b[filterBy]){
                    return -1;
                }
    
                if(a[filterBy] > b[filterBy]){
                    return 1;
                }
                
                return 0;
            })
    
            setData(tempData)
            setSort(false)
            return
        }

        tempData = tempData.sort((a,b) => {
            if(a[filterBy] > b[filterBy]){
                return -1;
            }

            if(a[filterBy] < b[filterBy]){
                return 1;
            }
            
            return 0;
        })

        setData(tempData)
        setSort(true)
    }
    
    return (
        <div className="overflow-auto rounded-lg border border-gray-200 shadow-md m-5">
            
            <div className='flex gap-4 items-center justify-end'>
                <Search searchQuery={search} onSearchQueryChange={(e) => setSearch(e.target.value)} onSearch={onSearch} />
            </div>
            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                            IW Ticket
                        </th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                            <div className="flex items-center">
                                Field Tech
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                            <div className="flex items-center">
                                Flow Status
                                <SortIcon onSort={() => sortData("status")} />
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                            <div className="flex items-center">
                                Created At
                                <SortIcon onSort={() => sortData("created_at")} />
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                            Activation Ticket
                        </th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                            Info
                        </th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900">
                            Lines
                        </th>
                        <th scope="col" className="px-6 py-4 font-medium text-gray-900"></th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                    {data?.length ? data.map(renderRows) : instances?.map(renderRows)}
                </tbody>
            </table>
        </div>
    )
}
export default Home;