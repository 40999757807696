import React from "react";
import withFlowState from "components/hoc/withFlowState";

const DisplayCurrentStep = (props) => {
  const {
    state: { currentNode, dryRun },
    debugMode,
  } = props;

  return (
    <>
      {(dryRun ||
        process.env.REACT_APP_ENV === "development" ||
        process.env.REACT_APP_ENV === "uat") &&
        debugMode && (
          <div className="absolute right-4 top-4 font-bold text-red-500 dark:text-red-600">
            {`node - ${currentNode}`}
          </div>
        )}
    </>
  );
};

export default withFlowState(DisplayCurrentStep);
