import { Button } from "components/common";
import React from "react";

function SomethingWentWrong() {
  return (
    <>
      <div className="flex min-h-full flex-col pb-12 pt-16">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Granite EPIK Activation</span>
              <img
                className="h-16 w-auto"
                src="/logo.png"
                alt="Granite EPIK Activation"
              />
            </a>
          </div>
          <div className="py-12">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-header dark:text-header-dark sm:text-5xl">
                Something went wrong
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Try refreshing the page, or try again later
              </p>
              <div className="mt-6">
                <Button
                  onClick={() => window.location.reload()}
                  variant="primary"
                >
                  Refresh Page
                </Button>
              </div>
            </div>
          </div>
        </main>
        <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-center space-x-4">
            <a
              href="#"
              className="text-sm font-medium text-secondary-text hover:text-secondary-text-dark dark:text-secondary-text-dark dark:hover:text-secondary-text"
            >
              Contact Support
            </a>
            <span
              className="inline-block border-l border-gray-300 dark:border-gray-300/30"
              aria-hidden="true"
            />
            <a
              href="#"
              className="text-sm font-medium text-secondary-text hover:text-secondary-text-dark dark:text-secondary-text-dark dark:hover:text-secondary-text"
            >
              Status
            </a>
          </nav>
        </footer>
      </div>
    </>
  );
}

export default SomethingWentWrong;
