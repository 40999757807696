import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";

/**
 * overrides react-toastify's methods to generalize notifications
 */
export const notify = {
  ...toast,
  error: (data, options = {}) =>
    toast.error(data, {
      icon: <ExclamationTriangleIcon className="h-6 w-6" />,
      ...options,
    }),
};
