import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getChatMessages,
  getScreenShots,
  getCurrentFlowState,
  getChatLink,
} from "api";
import { Button } from "components/common";
import { useAuthState } from "contexts/auth";
import Spinner from "components/common/Spinner";
import NotificationSound from "../../../notification-sound.mp3";
import { useSocket } from "contexts/socket";
import { toast } from "react-toastify";


function formatDate(dateString) {
  const date = new Date(`${dateString}Z`);
  return date.toLocaleString("en-US", { timeZoneName: "short" });
}

const JsonViewer = ({ workflowInstanceID }) => {
  const [instanceData, setInstanceData] = useState(false);

  async function getFlowState(workflowInstanceID) {
    const { data } = await getCurrentFlowState(workflowInstanceID);
    setInstanceData(JSON.parse(data?.data) || false);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      getFlowState(workflowInstanceID);
    }, 60000);

    getFlowState(workflowInstanceID);

    return () => {
      clearInterval(intervalId);
    };
  }, [workflowInstanceID]);

  return (
    <div className="px-2">
      <pre>{JSON.stringify(instanceData, null, 2)}</pre>
    </div>
  );
};

const ViewScreenShots = ({ workflowInstanceID }) => {
  const [flowScreenShots, setFlowScreenShots] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getWorkflowImages(workflowInstanceID) {
    setLoading(true);
    try{
      const {data} = await getScreenShots(workflowInstanceID);

      if(!data?.length){
        setLoading(false);
        return
      }

      setFlowScreenShots(data || []);
      setLoading(false);
    }catch(error){
      postFrontendErrorLogs(error)
      setLoading(false);
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      getWorkflowImages(workflowInstanceID);
    }, 90000);

    getWorkflowImages(workflowInstanceID);

    return () => {
      clearInterval(intervalId);
    };
  }, [workflowInstanceID]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="max-auto container my-8">
      <div className="grid grid-cols-1 gap-4 ">
        {flowScreenShots.length &&
          flowScreenShots.map((record) => {
            return (
              <div key={record.id} className="rounded-md bg-gray-200 p-4">
                <p className="text-sm font-semibold text-black">
                  TimeStamp: {formatDate(record.timeStamp)}
                </p>
                <img
                  src={record?.url}
                  className="mb-2 h-fit w-fit object-cover"
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const Chat = () => {
  const { workflowInstanceId } = useParams();
  const { user } = useAuthState();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState([]);
  const [chatEnded, setChatEnded] = useState(false);
  const [enableFieldAgentChat, setEnableFieldAgentChat] = useState(false);
  const [workflowInstanceData, setWorkflowInstanceData] = useState({});
  const [userTyping, setUserTyping] = useState(false);
  const audioPlayer = useRef(null);

  // const divRef = useRef(null);

  // function playAudio() {
  //     audioPlayer.current.play();
  // }

  // const {sockets} = useSocket();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getChatMessages(workflowInstanceId);

        setChatEnded(data.chatStatus);
        setMessages(data.messages);
        setWorkflowInstanceData(data.workflowInstance.form_data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [workflowInstanceId]);

  // useEffect(() => {
  //     divRef.current.scrollIntoView({ behavior: 'smooth' });
  // }, [messages]);

  // const handleSendMessage = async () => {
  //     if (newMessage.trim() !== '') {
  //         await addNewMessage({
  //             room: workflowInstanceId,
  //             user_id: user?.id,
  //             text: newMessage
  //         })
  //         setNewMessage('')
  //         sockets['chat'].emit('toggle_agent_chat', { room: workflowInstanceId, user: user?.id, toggle: true});
  //     }
  // }

  // const handleEnterPress = (event) => {
  //     if (event.key === 'Enter') {
  //         handleSendMessage();
  //     }
  // };

  // const handleFieldAgentChat = () => {
  //     // sockets['chat'].emit('toggle_agent_chat', { room: workflowInstanceId, user: user?.id, toggle: !enableFieldAgentChat});
  // }

  // useEffect(() => {
  //     if(sockets?.chat){
  //         if(newMessage){
  //             sockets['chat'].emit('typing_message', {room: workflowInstanceId, toggle: true, user: user?.id})
  //             return
  //         }

  //         sockets['chat'].emit('typing_message', {room: workflowInstanceId, toggle: false, user: user?.id})
  // }
  // }, [newMessage])

  async function redirectChat() {
    try {
      // if (Object.keys(user).length) {
      //   const { data } = await getChatLink({
      //     name: user?.name,
      //     email: user?.username,
      //     workflowInstanceId
      //   });

      //   let link = data?.url;

      //   let endpoint = link.split("3010")[1];

      //   window.open(`https://chat.epik.io${endpoint}`, "_blank");
      //   return;
      // }
      window.open(`https://wizard.epik.io/activations`, "_blank");
      return
      // toast.error("Please Login to Continue", {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: false,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: 0,
      //   theme: "light",
      // });

      return;
    } catch (error) {
      toast.error("Please contact support", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="sticky top-0 z-30 flex items-center gap-5 border-b border-gray-300 bg-background-primary px-4 py-5 dark:border-gray-300/30 dark:bg-background-primary-dark sm:px-7">
        <h1 className="text-1xl">{`Activation site: ${workflowInstanceData?.account_number} - ${workflowInstanceData?.address_1} | ${workflowInstanceData?.city} | IW Ticket: ${workflowInstanceData?.iw_ticket} | SN: ${workflowInstanceData?.serial_number}`}</h1>

        <Button
          variant="primary"
          size="small"
          className="w-fit"
          onClick={redirectChat}
        >
          Click to Open Chat
        </Button>
      </div>
      <div className="flex h-screen">
        <div className="flex h-screen w-1/2 flex-col shadow">
          <div className="flex-1 overflow-auto bg-gray-200">
            <div className="top-0 z-30 flex bg-gray-400">
              <h1 className="text-1xl p-2">Session screen</h1>
            </div>
            <ViewScreenShots workflowInstanceID={workflowInstanceId} />
          </div>{" "}
        </div>
        <div className="flex h-screen w-1/2 flex-col shadow">
          <div className="flex-1 overflow-auto bg-gray-100">
            <div className="top-0 z-30 flex bg-gray-400">
              <h1 className="text-1xl p-2">Workflow Instance Data</h1>
            </div>
            <JsonViewer workflowInstanceID={workflowInstanceId} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
