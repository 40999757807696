import React from "react";
import { classNames } from "utils";

const Label = ({ name, label, error, options = {} }) => {
  return (
    <label
      htmlFor={name}
      className={classNames(
        "block text-sm font-medium ",
        error
          ? "text-red-500 dark:text-red-600"
          : "text-secondary-text dark:text-secondary-text-dark",
      )}
    >
      {label} {options.required && "*"}
    </label>
  );
};

export default Label;
