import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSocket } from 'contexts/socket';
import { useAuthState } from 'contexts/auth';
import { DashboardContext } from "./Dashboard";
import { getRecentConv, getRecentConvMessages } from 'api';

import Header from './Header.js';

function formatDate(dateString) {
  const date = new Date(`${dateString}Z`);
  return date.toLocaleString("en-US", { timeZoneName: "short" });
}

function Message({ type, text, time_stamp }) {
  if (type === "fieldTech") {
    return (
      <div className="col-start-6 col-end-13 p-3 rounded-lg">
        <div className="flex items-center justify-start flex-row-reverse">
          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
            F
          </div>
          <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
            <div>{text}</div>
            <div className="text-xs text-gray-500">{formatDate(time_stamp)}</div>
          </div>
        </div>
      </div>

    )
  }

  return (
    <div className="col-start-1 col-end-8 p-3 rounded-lg">
      <div className="flex flex-row items-center">
        <div
          className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0"
        >
          A
        </div>
        <div
          className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl"
        >
          <div>
            {text}
          </div>
          <div className="text-xs text-gray-500">{formatDate(time_stamp)}</div>
        </div>
      </div>
    </div>
  )
}

function Conversations({ name, conversationId, onConversationClick, notifications, isSelected }) {
  return (
    <button 
      onClick={() => onConversationClick(conversationId)} 
      className={`flex items-center justify-between hover:bg-gray-100 rounded-xl p-2 ${isSelected ? 'bg-blue-200' : ''}`}
      style={{ width: '100%', display: 'flex', alignItems: 'center' }}
    >
      <div className="flex items-center">
        {notifications && notifications[conversationId] && (
          <span className="w-6 h-3 bg-green-600 rounded-full"></span>
        )}
        <div className="text-sm font-semibold">{name}</div>
      </div>
    </button>
  );
}
function displayName(form_data){
  return `${form_data?.customer_name || ''} - ${form_data?.account_number || ''} - ${form_data?.iw_ticket || ''} -  ${form_data?.serial_number || ''}`
}

function Chat() {
  const {selectedFlow, updateNotifications, notifications} = useContext(DashboardContext);

  const [workflowInstanceId, setWorkflowInstanceId] = useState(selectedFlow ||  false);
  const [channel, setChannel] = useState(null);
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState('')
  const endRef = useRef(null);
  const { user } = useAuthState();


  const { sockets } = useSocket();

  async function getConversations() {
    try {
      let {data} = await getRecentConv()

      setLoading(false)
      setConversations(data?.data)
      if(workflowInstanceId){
        let conv = data?.data?.find(({wId}) => wId === workflowInstanceId)
        setSelectedConversation(conv?.id)
    }
      return data
    } catch (error) {
      console.log(error)
      setLoading(false)

      return []
    }
  }

  useEffect(() => {
    endRef?.current?.scrollIntoView()
  }, [messages])

  useEffect(() => {
    if (sockets?.isConnected && (selectedFlow || workflowInstanceId)) {
      let newChannel = sockets.channel(`room:${selectedFlow || workflowInstanceId}`)
      
      setChannel(newChannel)

      newChannel.join()
        .receive("ok", resp => { console.log("Joined successfully", resp) })
        .receive("error", resp => { console.log("Unable  to join", resp) })
    }
  }, [workflowInstanceId])

  useEffect(() => {
    getConversations();
  }, []);

  useEffect(() => {
    if (channel) {
      channel.on("message_added", (payload) => {
        setMessages(prevMessages => [...prevMessages, payload])
      })

      channel.on("new_conversation", (payload) => {
        getConversations();
      })
    }

  }, [channel])

  async function onConversationClick(id) {
    try {
      setLoading(true)
      setSelectedConversation(id)

      let conv = conversations?.find((conv_) => conv_?.id === id)
      setWorkflowInstanceId(conv?.wId)
      updateNotifications(id)

      let {data} = await getRecentConvMessages(id)

      setMessages(data?.data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)

      return []
    }
  }

  function sendMessage(event) {
    if (newMessage.trim() !== '') {
      channel.push("new_message", { conversation_id: selectedConversation, message_text: newMessage, user_id: user?.id, type: "activationTech" })
        .receive("ok", payload => console.log("phoenix replied:", payload))
      setNewMessage('')
    }
  }

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      sendMessage();
    }
  };

  function getConversationName(conversation_id){
    let conv = conversations?.find(({id}) => id === conversation_id)

    return displayName(conv?.form_data)
  }
  // if (!user?.id){
  //   return (
  //     <h3>You are not logged in</h3>
  //   )
  // }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="relative">
          <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
          <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex h-screen antialiased text-gray-800">
      <div className="flex flex-row h-full w-full overflow-x-hidden">
        <div className="flex flex-col py-8 pl-6 pr-2 w-64 bg-white flex-shrink-0">
          <div className="flex flex-col mt-8 overflow-auto">
            <div className="flex flex-row items-center justify-between text-xs">
              <span className="font-bold">Active Conversations</span>
              <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">{conversations.length}</span>
            </div>
            <div className="flex flex-col space-y-1 mt-4 h-full overflow-y-auto">
              {conversations.length && conversations?.map(({ id, form_data, wId }) => {
                const isSelected = selectedConversation === id;
                return <Conversations  key={id} name={displayName(form_data)} conversationId={id} notifications={notifications} isSelected={isSelected} onConversationClick={onConversationClick} />
              })}
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-auto h-full p-6">
          <div
            className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4"
          >
            <div className="flex flex-col h-full overflow-x-auto mb-4">
              <div className="flex flex-col h-full">
                {selectedConversation && (<Header name={getConversationName(selectedConversation)}/>)}
                <div className="grid grid-cols-12 gap-y-2">
                  {messages?.map(({ id, type, message_text, time_stamp }) => {
                    return <Message key={id} type={type} text={message_text} time_stamp={time_stamp}/>
                  })}
                  <div ref={endRef}></div>
                </div>
              </div>
            </div>
            <div
              className={`flex flex-row items-center h-16 rounded-xl bg-white w-full px-4 ${!!selectedConversation ? '' : 'hidden'}`}
            >
              <div>
              </div>
              <div className="flex-grow ml-4">
                <div className="relative w-full">
                  <input
                    type="text"
                    className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handleEnterPress}
                  />
                </div>
              </div>
              <div className="ml-4">
                <button
                  onClick={sendMessage}
                  className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
                  type="submit"
                >
                  <span>Send</span>
                  <span className="ml-2">
                    <svg
                      className="w-4 h-4 transform rotate-45 -mt-px"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
