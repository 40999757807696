import React, { useEffect, useState } from "react";
import FormInput from "../FormInput";
import { useFieldArray } from "react-hook-form";
import Button from "components/common/Button";
import { LOGICAL_OPS, OPS } from "utils/constants";
import { classNames } from "utils";
import Label from "../Label";
import {
  onChangeAutoCompleteHandler,
  onVariableSelectionHandler,
} from "workflow-editor/utils/addNode.autocompleteHandler";
import { useEditorState } from "workflow-editor/context/editor";

const ruleFields = {
  variable: {
    type: "autoComplete",
    label: "Value",
    placeholder: "Value",
    options: {
      required: true,
    },
  },
  op: {
    type: "select",
    label: "Op",
    placeholder: "Op",
    selectOptions:
      Object.entries(OPS).map(([key, value]) => ({
        value: key,
        label: value,
      })) || [],
    options: {
      required: true,
    },
  },
  value: {
    type: "autoComplete",
    label: "Value",
    placeholder: "Value",
    options: {
      required: true,
    },
  },
};

const logicalOperatorField = {
  type: "select",
  selectOptions:
    Object.entries(LOGICAL_OPS).map(([key, value]) => ({
      value: key,
      label: value,
    })) || [],
  options: {
    required: true,
  },
};

const RuleGroup = ({
  name,
  label,
  error,
  register,
  control,
  watch,
  isValidating,
  options = {
    nested: false,
    // the max depth groups can be nested
    maxDepth: 1,
  },
  depth = 0,
  onDelete,
}) => {
  const operatorName = `${name}.logicalOp`;
  const rulesName = `${name}.rules`;

  const { nodes, workflow } = useEditorState();
  const {
    fields: arrayFields,
    append,
    remove,
    replace,
  } = useFieldArray({
    control,
    name: rulesName,
  });

  const defaultValue = Object.keys(ruleFields).reduce((obj, key) => {
    obj[key] = undefined;
    return obj;
  }, {});

  const [watchedValues, setWatchedValues] = useState([]);
  useEffect(() => {
    if (arrayFields.length === 0) {
      // use replace instead of append as in strict mode component get remounted and effects run twice
      if (depth === 0) replace([defaultValue]);
      else onDelete();
    }
  }, [arrayFields]);

  useEffect(() => {
    if (watch) {
      setWatchedValues(watch(name));
    }
  }, [isValidating]);

  const getSelectOptions = (
    filter,
    options,
    name,
    fields,
    watchedValues,
    idx,
  ) => {
    if (!filter || !watchedValues) return options;

    if (fields[name].filter) {
      const { filterFieldName } = fields[name];
      const option = fields[filterFieldName]?.selectOptions?.find(
        (x) => x.value === watchedValues?.[idx]?.[filterFieldName],
      );
      if (option) {
        return [
          { value: "", label: "Choose ..." },
          ...fields[name].filter(option.type),
        ];
      }
    }
  };

  return (
    <div className="space-y-4">
      {label && (
        <Label
          name={name}
          label={label}
          error={error}
          options={{ required: true }}
        />
      )}
      <div
        className={classNames(
          "flex gap-3 rounded-l-lg py-1 pr-1 sm:py-2 sm:pr-2",
          depth % 2 === 0
            ? "bg-background-primary dark:bg-background-primary-dark"
            : "bg-background-secondary dark:bg-background-secondary-dark",
          depth > 0 && "-mr-1 pl-1 sm:-mr-2 sm:pl-2",
        )}
      >
        {/* Logical Operator */}
        <div className="relative flex items-center justify-center">
          <FormInput
            register={register}
            control={control}
            name={operatorName}
            error={error}
            className="z-10 block rounded-lg border-blue-500 bg-background-primary pr-7 text-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:bg-background-primary-dark sm:p-2 sm:pr-8 sm:text-base"
            {...logicalOperatorField}
          />
          <div className="absolute left-1/2 top-0 h-full w-1/2 border-y border-l border-blue-500" />
        </div>
        {/* Rules */}
        <div className="flex flex-1 flex-col gap-y-6 sm:gap-y-4">
          {arrayFields.map((field, idx) =>
            field.rules ? (
              <RuleGroup
                key={field.id}
                name={`${rulesName}[${idx}]`}
                register={register}
                control={control}
                isValidating={isValidating}
                watch={watch}
                depth={depth + 1}
                options={options}
                error={error}
                onDelete={() => {
                  remove(idx);
                }}
              />
            ) : (
              <div
                className="flex flex-col gap-2 sm:flex-row sm:gap-3"
                key={field.id}
              >
                {Object.entries(ruleFields).map(
                  ([
                    key,
                    {
                      type,
                      label: fieldLabel,
                      options,
                      filter,
                      selectOptions,
                      values,
                    },
                  ]) => (
                    <div className="flex-1" key={key}>
                      <FormInput
                        register={register}
                        control={control}
                        label={idx === 0 ? fieldLabel : null}
                        name={`${rulesName}[${idx}].[${key}]`}
                        type={type}
                        placeholder={fieldLabel}
                        options={options}
                        selectOptions={getSelectOptions(
                          filter,
                          selectOptions,
                          key,
                          ruleFields,
                          watchedValues,
                          idx,
                        )}
                        values={values}
                        error={error}
                        query={(value) =>
                          onChangeAutoCompleteHandler({
                            value,
                            formSchema: workflow.form?.form_schema,
                            nodes,
                          })
                        }
                        onVariableSelectionHandler={onVariableSelectionHandler}
                      />
                    </div>
                  ),
                )}
                <div className="flex flex-col self-end sm:self-center">
                  {idx === 0 && <div className="hidden h-5 sm:block" />}
                  <Button
                    variant="secondary"
                    size="small"
                    className="z-0 mt-1 h-8 w-8"
                    onClick={() => {
                      remove(idx);
                    }}
                  >
                    -
                  </Button>
                </div>
              </div>
            ),
          )}
          <div className="flex gap-2">
            <Button
              variant="secondary"
              size="small"
              className="z-0 h-fit"
              onClick={() => {
                append(defaultValue);
              }}
            >
              Add Rule
            </Button>
            {options.nested && depth < options.maxDepth && (
              <Button
                variant="secondary"
                size="small"
                className="z-0 h-fit"
                onClick={() => {
                  append({
                    logicalOp: "AND",
                    rules: [defaultValue],
                  });
                }}
              >
                Add Group
              </Button>
            )}
            {depth > 0 && (
              <Button
                variant="secondary"
                size="small"
                className="z-0 ml-auto h-fit"
                onClick={onDelete}
              >
                Remove Group
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RuleGroup;
