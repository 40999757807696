import { useEffect, useState } from "react";
import debounce from "lodash.debounce";

export const useOnline = () => {
  const [onLine, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const listener = (event) => {
      const isOnline = event.type === "online";
      debounce(
        () => {
          setOnline(window.navigator.onLine);
        },
        isOnline ? 2000 : 0,
      )();
    };
    window.addEventListener("online", listener);
    window.addEventListener("offline", listener);

    return () => {
      window.removeEventListener("online", listener);
      window.removeEventListener("offline", listener);
    };
  }, []);

  return onLine;
};
