import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "utils";

const RadioGroupButtons = ({ title, values, selected, onChange }) => {
  return (
    <RadioGroup value={selected} onChange={onChange}>
      <RadioGroup.Label className="text-base font-medium text-header dark:text-header-dark">
        {title}
      </RadioGroup.Label>

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-4">
        {values.map((value) => (
          <RadioGroup.Option
            key={value.id}
            value={value}
            className={({ checked, active }) =>
              classNames(
                checked
                  ? "border-transparent"
                  : "border-gray-300 dark:border-gray-300/30",
                active ? "border-blue-500 ring-2 ring-blue-500" : "",
                "relative flex cursor-pointer rounded-lg border bg-background-primary p-2 shadow-sm focus:outline-none dark:bg-background-primary-dark",
              )
            }
          >
            {({ checked, active }) => (
              <>
                <div className="flex flex-1">
                  <div className="flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className="block text-sm font-medium text-header dark:text-header-dark"
                    >
                      {value.title}
                    </RadioGroup.Label>
                  </div>
                </div>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-5 w-5 text-blue-600",
                  )}
                  aria-hidden="true"
                />
                <div
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-blue-500" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg",
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioGroupButtons;
