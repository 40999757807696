import React, { useState, useEffect } from "react";
import withFlowState from "components/hoc/withFlowState";
import { updateActivationTicket, postEscalationV1, postResolutionV1 } from "api";
import { NODE_TYPE, WORKFLOW_STATUS } from "utils/constants";
import EscalationTimer from "components/common/EscalationTimer";
import FlowStep from "../FlowStep";
import Navigation from "../Navigation";
import TechInfo from "../TechInfo";
import { postFrontendErrorLogs } from "api";

const EscalateStep = (props) => {
  const [escalate, setEscalate] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [resolutionValue, setResolutionValue] = useState("");
  const [showPhone, setShowPhone] = useState(false);
  const [techInfo, setTechInfo] = useState(null);
  const [note, setNote] = useState("");
  const [escalationId, setEscalationId] = useState('');

  const {
    state,
    onPrevious,
    onNext,
    updateFlowStateStepData,
    sequence,
    determineNextStep,
    referenceFlow,
    stepState: _stepState,
    injectValue,
    workflowInstanceId
  } = props;
  const {
    workflow,
    slackChannels,
    workflowNodes,
    currentNode,
    instanceData,
    instanceState,
  } = state;

  const [stepState, setStepState] = useState(_stepState);

  useEffect(() => {
    setTechInfo(
      Object.values(instanceState)?.filter(
        (value) => value?.tech_name && value?.tech_phone,
      ) || "Testing Tech",
    );
    let stepData = stepState;

    setStepState(stepData);
    const next = determineNextStep({
      selectedValue,
      resolutionValue,
    });
    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: NODE_TYPE.ESCALATE,
      escalate: injectValue(stepData?.failure || ""),
      next: next,
      selected: null,
    });
  }, [currentNode, workflowNodes]);

  const postEscalation = async (payload) => {
    try{
      updateActivationTicket(payload)
      const {data} = await postEscalationV1(payload);

      if(data?.escalationId){
        setEscalationId(data?.escalationId)
      }
    }catch(error){
      postFrontendErrorLogs(error);
    }
  }

  const postResolution = async (payload) => {
    try{
      updateActivationTicket(payload)
      if(escalationId){
        await postResolutionV1(escalationId, payload);
      }
    }catch(error){
      postFrontendErrorLogs(error);
    }
  }

  const postStatusToSlack = async (payload) => {
    try {
      return updateActivationTicket(payload);
    } catch (error) {
      postFrontendErrorLogs(error);
    }
  };

  const onPreviousHandler = () => {
    updateFlowStateStepData(
      {
        nodeId: currentNode,
        nodeType: NODE_TYPE.ESCALATE,
        escalate: injectValue(stepState?.failure || ""),
        next: null,
        selected: null,
      },
      WORKFLOW_STATUS.IN_PROGRESS,
    );
    onPrevious();
  };
  const onNextHandler = () => {
    if (stepState.omitResolved) {
      postEscalation({
        workflow_id: workflow.id,
        name: instanceData?.input_data?.tech_name,
        phone: instanceData?.input_data?.tech_phone,
        status: WORKFLOW_STATUS.ESCALATED,
        currentNode,
        omitResolved: true,
        escalate_message: injectValue(stepState?.failure || ""),
        notes: "",
        ...instanceState,
        reference_flow_id: referenceFlow?.id,
      });
      onNext();
    } else {
      if (!escalate) {
        setEscalate(true);
        postEscalation({
          workflow_id: workflow.id,
          name: instanceData?.input_data?.tech_name,
          phone: instanceData?.input_data?.tech_phone,
          status: WORKFLOW_STATUS.ESCALATED,
          escalate_message: injectValue(stepState?.failure || ""),
          submitter_email: injectValue("${Submitter Email}"),
          notes: "",
          currentNode,
          referenceFlow,
          workflowInstanceId,
          resolution: false,
          ...instanceState,
        });
        updateFlowStateStepData(
          {
            nodeId: currentNode,
            nodeType: NODE_TYPE.ESCALATE,
            escalate: injectValue(stepState?.failure || ""),
            next: null,
            selected: null,
          },
          WORKFLOW_STATUS.ESCALATED,
        );
      } else {
        postResolution({
          workflowInstanceId,
          note,
          referenceFlow,
          currentNode,
          resolution: true,
          ...instanceState,
        })
        
        onNext(WORKFLOW_STATUS.IN_PROGRESS);
      }
    }
  };

  const onChange = (value) => {
    setSelectedValue(value);
  };

  const onTextChange = (e) => {
    return
    // e.preventDefault();
    // const value = e.target.value;
    // setNote(value)
    // setResolutionValue(value);
  };

  useEffect(() => {
    const next = determineNextStep({
      selectedValue,
    });
    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: NODE_TYPE.ESCALATE,
      next,
      resolution: selectedValue === "Yes" ? resolutionValue : null,
      issueDetails: selectedValue === "No" ? resolutionValue : null,
    });
  }, [selectedValue]);

  return (
    <>
      <FlowStep data={stepState}>
        {!escalate && (
          <>
            <h1 className="mb-4 text-lg font-bold">{`Escalate Activation Problem`}</h1>
            <p>The activation step you attempted has failed. <strong>Do NOT</strong> abort the install process. When you click "Next" below the issue will 
            be reported to a <strong> Granite Activation Technician</strong> who will troubleshoot the issue for you ASAP. Please click <strong>"Next"</strong> below and wait for help. </p>
            <div className="py-2 space-y-2 text-red-500 dark:text-red-600">
              {injectValue(stepState.failure || "")}
            </div>
            <div className="space-y-2">
              Click ‘Next’ to alert Granite support for assistance with this
              issue
            </div>
          </>
        )}
        {escalate && (
          <>
            <h1 className="mb-6 min-h-[6rem] sm:mb-8 sm:min-h-0 sm:text-lg">
              { showPhone
              ? "Please call 833-719-8378 option 5 to speak with a Granite activation tech about this issue." 
              : "Please wait while Granite Activation Technician resolves the issue"
              }
            </h1>
            <div className="space-y-4">
              <div className="w-full space-y-2 sm:w-1/2">
              {!selectedValue ? (<EscalationTimer
              activationTicket={instanceState.activation_ticket}
              note={note}
              setNote={setNote}
              setSelectedValue={setSelectedValue}
              escalationReason={injectValue(stepState?.failure || "")}
              setShowPhone={setShowPhone}
              workflowInstanceId={workflowInstanceId}
              setResolutionValue={setResolutionValue}
              escalationId={escalationId}
              />) : <></>
              }
              </div>
              { note &&(
              <div className={`${techInfo?.length >= 1 && "sm:pb-8"}`}>
                <textarea
                  minLength={10}
                  required
                  name="resolution"
                  onChange={onTextChange}
                  rows={5}
                  className={
                    "block w-full appearance-none rounded-md border border-blue-500 bg-white px-3 py-2 text-sm placeholder-gray-400 shadow-sm focus:outline-none focus:ring-blue-500 dark:bg-transparent dark:placeholder-gray-500 sm:text-base whitespace-pre-wrap"
                  }
                  value={note}
                  readOnly={true}
                />
              </div>)}
            </div>
          </>
        )}
        <TechInfo techInfo={techInfo} />
      </FlowStep>
      <Navigation
        isNextEnabled={!escalate}
        onNext={onNextHandler}
        onPrevious={onPreviousHandler}
      />
    </>
  );
};

export default withFlowState(EscalateStep);
