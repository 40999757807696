import { Context, VALIDATION_ERRORS } from ".";
import { EVENT_TYPES } from "workflow-editor/events";
import {
  getGraphCycles,
  getReachableNodes,
  getReferableStrings,
  getReferenceProperties,
  isInGroupNode,
  isReferenceReachableFromAllBranches,
  matchStrings,
  searchNodesForReference,
  validReferenceRegexp,
} from "../utils/variable-references";
import {
  allReferenceRegexp,
  isEventTypeAllowed,
  isMissingEdgeConnections,
} from "workflow-editor/utils/validation";

/**
 * Checks if references are valid node types
 * ```js
 * // valid
 * "${checkbox-1.answer}"
 * // invalid
 * "${incorrect-1.answer}"
 * ```
 * @param {Context} context
 */
export const referencesAreValidNodeTypes = (event, context, errors) => {
  const errorType = VALIDATION_ERRORS.REFERENCE_INVALID_TYPE;

  const { data: node } = event;
  context.load().referencedNodeIds();
  const { referencedNodeIds, strings } = context;
  const [existingErrors, remainingErrors] = (errors[node.id] ?? []).reduce(
    (acc, error) => {
      acc[error.type === errorType ? 0 : 1].push(error);
      return acc;
    },
    [[], []],
  );

  const matchAll = matchStrings(strings, allReferenceRegexp, (match) =>
    match.split(".")[0].replace("${", "").trim(),
  );

  const newErrors =
    referencedNodeIds.length === matchAll.length
      ? []
      : matchAll.reduce((err, nodeId) => {
          if (
            !referencedNodeIds.includes(nodeId) &&
            !err.find(({ item }) => item === nodeId)
          )
            err.push({
              type: errorType,
              item: nodeId,
            });
          return err;
        }, []);

  const hasNotMutated = ({ item }) =>
    newErrors.find((err) => err.item === item);
  if (
    newErrors.length !== existingErrors.length ||
    !existingErrors.every(hasNotMutated)
  ) {
    errors[node.id] = [...remainingErrors, ...newErrors];
    context.hasMutated();
  }
};

/**
 * Checks if references access valid node properties
 * ```js
 * // valid
 * "${api-1.status_code}"
 * // invalid
 * "${api-1.invalid}"
 * ```
 * @param {Context} context
 */
export const referencesAccessValidProperties = (event, context, errors) => {
  const errorType = VALIDATION_ERRORS.REFERENCE_INVALID_PROPERTY;

  const {
    data: node,
    state: { nodes },
  } = event;
  context.load().referencedNodeIds();
  const { strings } = context;
  const [existingErrors, remainingErrors] = (errors[node.id] ?? []).reduce(
    (acc, error) => {
      acc[error.type === errorType ? 0 : 1].push(error);
      return acc;
    },
    [[], []],
  );

  const matchAll = matchStrings(strings, allReferenceRegexp, (match) => {
    const [id, property] = match
      .split(".")
      .map((str, index) => str.replace(index === 0 ? "${" : "}", "").trim());
    return `${id}.${property}`;
  });

  const newErrors = matchAll.reduce((err, reference) => {
    const [id, property] = reference.split(".");
    const validProperties = getReferenceProperties(reference, nodes);
    if (
      !remainingErrors.find(
        ({ type, item }) =>
          type === VALIDATION_ERRORS.REFERENCE_INVALID_TYPE && item === id,
      ) &&
      validProperties &&
      !validProperties.includes(property) &&
      !err.find(({ item }) => item === reference)
    )
      err.push({
        type: errorType,
        item: reference,
        info: {
          validProperties,
        },
      });
    return err;
  }, []);

  const hasNotMutated = ({ item }) =>
    newErrors.find((err) => err.item === item);
  if (
    newErrors.length !== existingErrors.length ||
    !existingErrors.every(hasNotMutated)
  ) {
    errors[node.id] = [...remainingErrors, ...newErrors];
    context.hasMutated();
  }
};

/**
 * Checks if referenced nodes exist
 * @param {Context} context
 */
export const referencesExist = (event, context, errors) => {
  isEventTypeAllowed("referencesExist", event, [
    EVENT_TYPES.NODE_CREATE,
    EVENT_TYPES.NODE_UPDATE,
  ]);
  const errorType = VALIDATION_ERRORS.REFERENCE_NOT_FOUND;

  const { data: node } = event;
  context.load().referencedNodeIds().nodesToObj();
  const { referencedNodeIds, nodesToObj } = context;
  const [existingErrors, remainingErrors] = (errors[node.id] ?? []).reduce(
    (acc, error) => {
      acc[error.type === errorType ? 0 : 1].push(error);
      return acc;
    },
    [[], []],
  );

  const newErrors = referencedNodeIds.reduce((err, nodeId) => {
    if (!nodesToObj[nodeId] && !err.find(({ item }) => item === nodeId))
      err.push({
        type: errorType,
        item: nodeId,
      });
    return err;
  }, []);

  const hasNotMutated = ({ item }) =>
    newErrors.find((err) => err.item === item);
  if (
    newErrors.length !== existingErrors.length ||
    !existingErrors.every(hasNotMutated)
  ) {
    errors[node.id] = [...remainingErrors, ...newErrors];
    context.hasMutated();
  }
};

/**
 * Checks if referenced nodes are reachable
 * @param {Context} context
 */
export const referencesAreReachable = (event, context, errors) => {
  isEventTypeAllowed("referencesAreReachable", event, [
    EVENT_TYPES.NODE_CREATE,
    EVENT_TYPES.NODE_UPDATE,
  ]);
  const errorType = VALIDATION_ERRORS.REFERENCE_UNREACHABLE;

  const { data: node } = event;
  context.load().referencedNodeIds().previousNodeIds().nodesToObj();
  const { referencedNodeIds, previousNodeIds, nodesToObj } = context;
  const [existingErrors, remainingErrors] = (errors[node.id] ?? []).reduce(
    (acc, error) => {
      acc[error.type === errorType ? 0 : 1].push(error);
      return acc;
    },
    [[], []],
  );

  const newErrors = referencedNodeIds.reduce((err, nodeId) => {
    if (
      nodesToObj[nodeId] &&
      !previousNodeIds.includes(nodeId) &&
      !err.find(({ item }) => item === nodeId) &&
      !isInGroupNode(node.id, nodeId, nodesToObj)
    )
      err.push({
        type: errorType,
        item: nodeId,
      });
    return err;
  }, []);

  const hasNotMutated = ({ item }) =>
    newErrors.find((err) => err.item === item);
  if (
    newErrors.length !== existingErrors.length ||
    !existingErrors.every(hasNotMutated)
  ) {
    errors[node.id] = [...remainingErrors, ...newErrors];
    context.hasMutated();
  }
};

/**
 * Checks if referenced nodes are reachable from all branches
 * @param {Context} context
 */
export const referencesAreReachableFromAllBranches = (
  event,
  context,
  errors,
) => {
  isEventTypeAllowed("referencesAreReachableFromAllBranches", event, [
    EVENT_TYPES.NODE_CREATE,
    EVENT_TYPES.NODE_UPDATE,
  ]);
  const errorType = VALIDATION_ERRORS.REFERENCE_UNREACHABLE_SOME_PATHS;

  const { data: node } = event;
  context.load().referencedNodeIds().nodesToObj().edgesToNodeIdMap();
  const { referencedNodeIds, nodesToObj, edgesToNodeIdMap } = context;
  const [existingErrors, remainingErrors] = (errors[node.id] ?? []).reduce(
    (acc, error) => {
      acc[error.type === errorType ? 0 : 1].push(error);
      return acc;
    },
    [[], []],
  );

  const unreachableIds = remainingErrors
    .filter(({ type }) => type === VALIDATION_ERRORS.REFERENCE_UNREACHABLE)
    .map(({ item }) => item);

  const existingReferences = referencedNodeIds.filter(
    (nodeId) => !unreachableIds.includes(nodeId),
  );

  const result = existingReferences.map((referenceId) =>
    isReferenceReachableFromAllBranches(
      referenceId,
      node.id,
      nodesToObj,
      edgesToNodeIdMap,
    ),
  );

  const newErrors = existingReferences.reduce((err, nodeId, index) => {
    if (
      nodesToObj[nodeId] &&
      !result[index] &&
      !err.find(({ item }) => item === nodeId)
    )
      err.push({
        type: errorType,
        item: nodeId,
      });
    return err;
  }, []);

  const hasNotMutated = ({ item }) =>
    newErrors.find((err) => err.item === item);
  if (
    newErrors.length !== existingErrors.length ||
    !existingErrors.every(hasNotMutated)
  ) {
    errors[node.id] = [...remainingErrors, ...newErrors];
    context.hasMutated();
  }
};

/**
 * Checks if the added edge fixes existing `REFERENCE_UNREACHABLE` errors
 * @param {Context} context
 */
export const addedEdgeFixesReferences = (event, context, errors) => {
  isEventTypeAllowed("addedEdgeFixesReferences", event, [
    EVENT_TYPES.EDGE_CREATE,
  ]);
  const errorType = VALIDATION_ERRORS.REFERENCE_UNREACHABLE;

  const {
    state: { nodes },
    data: edge,
  } = event;

  context.load().followingNodeIds().edgesToNodeIdMap();
  const { followingNodeIds, edgesToNodeIdMap } = context;
  [...new Set([edge.target, ...followingNodeIds])].forEach((nodeId) => {
    const [existingErrors, remainingErrors] = (errors[nodeId] ?? []).reduce(
      (acc, error) => {
        acc[error.type === errorType ? 0 : 1].push(error);
        return acc;
      },
      [[], []],
    );
    if (existingErrors?.length) {
      const reachableNodes = getReachableNodes(
        nodeId,
        nodes,
        edgesToNodeIdMap,
      ).nodeIds;
      const newErrors = existingErrors.filter(({ item }) => {
        return !reachableNodes.includes(item);
      });
      if (newErrors.length !== existingErrors) {
        errors[nodeId] = [...remainingErrors, ...newErrors];
        context.hasMutated();
      }
    }
  });
};

/**
 * Iterates the following nodes and checks for new
 * `REFERENCE_UNREACHABLE_SOME_PATHS` errors
 * @param {Context} context
 */
export const addedEdgeBreaksReferences = (event, context, errors) => {
  isEventTypeAllowed("addedEdgeBreaksReferences", event, [
    EVENT_TYPES.EDGE_CREATE,
  ]);
  const errorType = VALIDATION_ERRORS.REFERENCE_UNREACHABLE_SOME_PATHS;

  const { data: edge } = event;

  const alreadyCheckedReferences = new Set();

  context.load().followingNodeIds().nodesToObj().edgesToNodeIdMap();
  const { followingNodeIds, nodesToObj, edgesToNodeIdMap } = context;
  [...new Set([edge.target, ...followingNodeIds])].forEach((nodeId) => {
    const [existingErrors, remainingErrors] = (errors[nodeId] ?? []).reduce(
      (acc, error) => {
        acc[error.type === errorType ? 0 : 1].push(error);
        return acc;
      },
      [[], []],
    );

    if (!nodesToObj[nodeId]) return;
    const strings = getReferableStrings(nodesToObj[nodeId]);
    const referencedNodeIds = matchStrings(
      strings,
      validReferenceRegexp,
      (match) => match.split(".")[0].replace("${", "").trim(),
    );

    const unreachableIds = remainingErrors
      .filter(({ type }) => type === VALIDATION_ERRORS.REFERENCE_UNREACHABLE)
      .map(({ item }) => item);

    const existingReferences = referencedNodeIds.filter(
      (nodeId) => !unreachableIds.includes(nodeId),
    );

    const result = existingReferences.map((referenceId) => {
      if (alreadyCheckedReferences.has(nodeId)) return true;
      const reachable = isReferenceReachableFromAllBranches(
        referenceId,
        nodeId,
        nodesToObj,
        edgesToNodeIdMap,
      );
      if (reachable) alreadyCheckedReferences.add(referenceId);
      return reachable;
    });
    const newErrors = existingReferences.reduce((err, nodeId, index) => {
      if (
        nodesToObj[nodeId] &&
        !result[index] &&
        !err.find(({ item }) => item === nodeId)
      )
        err.push({
          type: errorType,
          item: nodeId,
        });
      return err;
    }, []);

    const hasNotMutated = ({ item }) =>
      newErrors.find((err) => err.item === item);
    if (
      newErrors.length !== existingErrors.length ||
      !existingErrors.every(hasNotMutated)
    ) {
      errors[nodeId] = [...remainingErrors, ...newErrors];
      context.hasMutated();
    }
  });
};

/**
 * Iterates the following nodes and checks whether any reference
 * has become unreachable by deleting the edge
 * @param {Context} context
 */
export const deletedEdgeBreaksReferences = (event, context, errors) => {
  isEventTypeAllowed("deletedEdgeBreaksReferences", event, [
    EVENT_TYPES.EDGE_DELETE,
  ]);
  const errorType = VALIDATION_ERRORS.REFERENCE_UNREACHABLE;

  const {
    state: { nodes },
    data: edge,
  } = event;

  context.load().followingNodeIds().nodesToObj().edgesToNodeIdMap();
  const { followingNodeIds, nodesToObj, edgesToNodeIdMap } = context;
  [...new Set([edge.target, ...followingNodeIds])].forEach((nodeId) => {
    const [existingErrors, remainingErrors] = (errors[nodeId] ?? []).reduce(
      (acc, error) => {
        acc[error.type === errorType ? 0 : 1].push(error);
        return acc;
      },
      [[], []],
    );
    if (!nodesToObj[nodeId]) return;
    const strings = getReferableStrings(nodesToObj[nodeId]);
    const referencedNodeIds = matchStrings(
      strings,
      validReferenceRegexp,
      (match) => match.split(".")[0].replace("${", "").trim(),
    );
    const previousNodeIds = getReachableNodes(
      nodeId,
      nodes,
      edgesToNodeIdMap,
    ).nodeIds;
    const newErrors = referencedNodeIds.reduce((err, nodeId) => {
      if (
        nodesToObj[nodeId] &&
        !previousNodeIds.includes(nodeId) &&
        !err.find(({ item }) => item === nodeId)
      )
        err.push({
          type: errorType,
          item: nodeId,
        });
      return err;
    }, []);

    const hasNotMutated = ({ item }) =>
      newErrors.find((err) => err.item === item);
    if (
      newErrors.length !== existingErrors.length ||
      !existingErrors.every(hasNotMutated)
    ) {
      errors[nodeId] = [
        ...remainingErrors.filter((error) => {
          if (error.type !== VALIDATION_ERRORS.REFERENCE_UNREACHABLE_SOME_PATHS)
            return true;
          return !hasNotMutated(error);
        }),
        ...newErrors,
      ];
      context.hasMutated();
    }
  });
};

/**
 * Iterates the following nodes and checks whether
 * `REFERENCE_UNREACHABLE_SOME_PATHS` errors have been fixed
 * @param {Context} context
 */
export const deletedEdgeFixesReferences = (event, context, errors) => {
  isEventTypeAllowed("deletedEdgeFixesReferences", event, [
    EVENT_TYPES.EDGE_DELETE,
  ]);
  const errorType = VALIDATION_ERRORS.REFERENCE_UNREACHABLE_SOME_PATHS;

  const { data: edge } = event;

  context.load().followingNodeIds().nodesToObj().edgesToNodeIdMap();
  const { followingNodeIds, nodesToObj, edgesToNodeIdMap } = context;
  [...new Set([edge.target, ...followingNodeIds])].forEach((nodeId) => {
    const [existingErrors, remainingErrors] = (errors[nodeId] ?? []).reduce(
      (acc, error) => {
        acc[error.type === errorType ? 0 : 1].push(error);
        return acc;
      },
      [[], []],
    );
    if (existingErrors?.length) {
      const result = existingErrors.map(({ item }) =>
        isReferenceReachableFromAllBranches(
          item,
          nodeId,
          nodesToObj,
          edgesToNodeIdMap,
        ),
      );

      const newErrors = existingErrors.filter((_, index) => {
        return !result[index];
      });
      if (newErrors.length !== existingErrors.length) {
        errors[nodeId] = [...remainingErrors, ...newErrors];
        context.hasMutated();
      }
    }
  });
};

/**
 * Checks if graph has cycles
 * @param {Context} context
 */
export const graphHasCycles = (event, context, errors) => {
  isEventTypeAllowed("graphHasCycles", event, [
    EVENT_TYPES.EDGE_CREATE,
    EVENT_TYPES.EDGE_DELETE,
  ]);
  const errorType = VALIDATION_ERRORS.GRAPH_CYCLE;

  const {
    state: { nodes, edges },
  } = event;
  context.load().edgesToNodeIdMap();
  const { edgesToNodeIdMap } = context;
  const [existingErrors, remainingErrors] = (errors.graph_cycles ?? []).reduce(
    (acc, error) => {
      acc[error.type === errorType ? 0 : 1].push(error);
      return acc;
    },
    [[], []],
  );

  const startingNodes = nodes
    .filter((node) => !edges.find(({ target }) => target === node.id))
    .map(({ id }) => id);
  const duplicateIds = [
    ...new Set(
      startingNodes
        .map(
          (nodeId) =>
            getReachableNodes(nodeId, nodes, edgesToNodeIdMap, "following")
              ?.duplicateIds ?? [],
        )
        .flat(),
    ),
  ];

  const newErrors = duplicateIds.reduce((acc, nodeId) => {
    const cycles = getGraphCycles(edgesToNodeIdMap, nodeId);
    const filtered = cycles.filter(
      (cycle) =>
        !acc.some(
          (c) =>
            c.item.length === cycle.length &&
            cycle.every((nodeId) => c.item.includes(nodeId)),
        ),
    );
    if (filtered.length)
      acc = [
        ...acc,
        ...filtered.map((cycle) => ({
          type: errorType,
          item: cycle,
        })),
      ];
    return acc;
  }, []);

  for (let index = newErrors.length - 1; index >= 0; index--) {
    const cycle = newErrors[index].item;
    const existingError = existingErrors.find(({ item }) =>
      item.every((nodeId, idx) => cycle[idx] === nodeId),
    );
    if (existingError?.suppressed) newErrors[index].suppressed = true;
  }
  errors.graph_cycles = [...remainingErrors, ...newErrors].sort((a, b) =>
    a.suppressed === true ? 1 : b.suppressed === true ? -1 : 0,
  );
  context.hasMutated();
};

/**
 * Replaces existing `REFERENCE_NOT_FOUND` errors that contain the created node
 * with a `REFERENCE_UNREACHABLE` error type
 * @param {Context} context
 */
export const addedNodeReplacesNotFoundErrorsWithUnreachable = (
  event,
  context,
  errors,
) => {
  isEventTypeAllowed("addedNodeReplacesNotFoundErrorsWithUnreachable", event, [
    EVENT_TYPES.NODE_CREATE,
  ]);

  const errorType = VALIDATION_ERRORS.REFERENCE_NOT_FOUND;
  const { data: node } = event;

  // remove existing errors from other nodes that reference the newly created node
  Object.keys(errors).forEach((key) => {
    if (key === "graph_cycles" || key === node.id) return;
    const filteredErrors = errors[key].filter(
      (error) => error.type !== errorType && error.item !== node.id,
    );
    if (filteredErrors.length !== errors[key].length) {
      errors[key] = [
        ...filteredErrors,
        {
          type: VALIDATION_ERRORS.REFERENCE_UNREACHABLE,
          item: node.id,
        },
      ];
      context.hasMutated();
    }
  });
};

/**
 * Adds `REFERENCE_NOT_FOUND` error and removed associated errors
 * to nodes that reference the deleted node
 * @param {Context} context
 */
export const deletedNodeIsReferenced = (event, context, errors) => {
  isEventTypeAllowed("deletedNodeIsReferenced", event, [
    EVENT_TYPES.NODE_DELETE,
  ]);

  const {
    state: { nodes },
    data: node,
  } = event;
  const references = searchNodesForReference(nodes, [node.id]);
  const nodeIds = Object.keys(references);
  if (nodeIds.length > 0) {
    nodeIds.forEach((nodeId) => {
      errors[nodeId] = [
        ...(errors[nodeId] ?? []).filter(({ type, item }) => {
          switch (type) {
            case VALIDATION_ERRORS.REFERENCE_UNREACHABLE:
            case VALIDATION_ERRORS.REFERENCE_UNREACHABLE_SOME_PATHS:
              return item !== node.id;
            default:
              return true;
          }
        }),
        {
          type: VALIDATION_ERRORS.REFERENCE_NOT_FOUND,
          item: node.id,
        },
      ];
    });
    context.hasMutated();
  }
};

/**
 * Removes errors of the deleted node
 * @param {Context} context
 */
export const removeDeletedNodeErrors = (event, context, errors) => {
  isEventTypeAllowed("removeDeletedNodeErrors", event, [
    EVENT_TYPES.NODE_DELETE,
  ]);

  const { data: node } = event;
  if (errors[node.id]) {
    errors[node.id] = [];
  }
};

/**
 * Checks if node has missing source/target handle connections.
 * This is ignored for group (loop) nodes
 * @param {Context} context
 */
export const hasMissingHandleConnections = (event, context, errors) => {
  isEventTypeAllowed("hasMissingHandleConnections", event, [
    EVENT_TYPES.NODE_CREATE,
    EVENT_TYPES.NODE_UPDATE,
    EVENT_TYPES.EDGE_CREATE,
    EVENT_TYPES.EDGE_DELETE,
  ]);

  const errorType = VALIDATION_ERRORS.NODE_MISSING_CONNECTIONS;
  const {
    state: { edges },
    data,
  } = event;

  const handler = (node) => {
    if (!node || node.parentNode) return;

    const [existingErrors, remainingErrors] = (errors[node.id] ?? []).reduce(
      (acc, error) => {
        acc[error.type === errorType ? 0 : 1].push(error);
        return acc;
      },
      [[], []],
    );

    if (isMissingEdgeConnections(node, edges)) {
      if (existingErrors.length === 0) {
        errors[node.id] = [
          ...remainingErrors,
          {
            type: errorType,
          },
        ];
        context.hasMutated();
      }
    } else if (existingErrors.length > 0) {
      errors[node.id] = remainingErrors;
      context.hasMutated();
    }
  };

  switch (event.type) {
    case EVENT_TYPES.NODE_UPDATE:
    case EVENT_TYPES.NODE_CREATE:
      handler(data);
      break;
    case EVENT_TYPES.EDGE_CREATE:
    case EVENT_TYPES.EDGE_DELETE: {
      context.load().nodesToObj();
      const { nodesToObj } = context;
      const nodes = [nodesToObj[data.source], nodesToObj[data.target]];
      nodes.map(handler);
      break;
    }
    default:
      break;
  }
};
