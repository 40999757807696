import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { EyeIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import Spinner from "components/common/Spinner";
import Table from "components/common/Table";
import Modal from "./pmModal.js";
import { Button } from "components/common/index.js";
import Pagination from "components/common/Pagination";
import Search from "../../common/Search";
import axios from "axios";
import EditFormModal from "./pmFormModal";
import { toast } from "react-toastify";

const applyFilters = (data, filters) => {
  if (!filters || Object.keys(filters).length === 0) return data;

  return data.filter(item => {
    return Object.keys(filters).every(key => {
      const filterValue = filters[key].toLowerCase();
      if (!item[key]) return false;
      return item[key].toString().toLowerCase().includes(filterValue);
    });
  });
};

const sortData = (data, sortKey, direction) => {
  if (!sortKey) return data;
  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortKey]?.toString().toLowerCase() || '';
    const bValue = b[sortKey]?.toString().toLowerCase() || '';
    if (aValue < bValue) return direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return direction === 'asc' ? 1 : -1;
    return 0;
  });
  return sortedData;
};

const SurveyList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState(searchParams.get("query") || "");
  const [filters, setFilters] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortKey, setSortKey] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [pmFilter, setPmFilter] = useState('');

  const createBulkTickets = async () => {
    setLoading(true);
    try {
      let { data } = await axios.post(`https://wizard.epik.io/v3/api/cw/create/bulk-tickets`, selectedRows);
      if (data?.message === 'Success') {
        await fetch();
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(`Error while creating tickets: ${error}`);
      setLoading(false);
    }
  };

  const fetch = useCallback(async () => {
    setLoading(true);
    setErrors(null);
    try {
      const response = await axios.get('https://wizard.epik.io/v3/api/survey/list', {
        params: { page, per_page: perPage, query: searchQuery }
      });
      if (response?.data?.success) {
        setData(response.data.data);
      } else {
        setErrors(`An error occurred while fetching data`);
      }
    } catch (error) {
      setErrors(`An error occurred while fetching data`);
    } finally {
      setLoading(false);
    }
  }, [page, perPage, searchQuery]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    const filtered = applyFilters(data, filters);
    setFilteredData(sortData(filtered, sortKey, sortDirection));
  }, [data, filters, sortKey, sortDirection]);

  const handleFilterChange = (newFilters) => {
    if(newFilters){
      setPmFilter(newFilters)
      setFilters({pm: newFilters});
    }else{
      setPmFilter("")
      setFilters({})
    }

  };

  const handleSort = (key) => {
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    setSortKey(key);
  };

  const handleEditClick = (survey) => {
    setSelectedSurvey(survey);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    fetch();
    setIsModalOpen(false);
    setSelectedSurvey(null);
  };

  const toggleRowSelection = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === filteredData.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredData.map((row) => row.id));
    }
  };

  const columns = useMemo(
    () => [
      {
        title: (
          <input
            type="checkbox"
            checked={selectedRows.length === filteredData.length}
            onChange={toggleSelectAll}
          />
        ),
        key: "select",
        render: (row) => (
          <input
            type="checkbox"
            checked={selectedRows.includes(row.id)}
            onChange={() => toggleRowSelection(row.id)}
          />
        ),
      },
      { title: 'Ticket', key: 'ticket_id', sortable: true },
      { title: 'Account', key: 'account_id', sortable: true },
      { title: 'Customer', key: 'customer_name', sortable: true },
      { title: 'PM', key: 'pm', sortable: true },  // Added PM column
      { title: 'Status', key: 'status', sortable: true },
      { title: 'Date Created', key: 'created_at', sortable: true, render: (row) => new Date(row.created_at).toLocaleDateString() },
      {
        title: "",
        key: "actions",
        render: (row) => (
          <div className="flex items-center gap-3">
            <Link to={`/pm/form/${row.id}`}>
              <EyeIcon title="View" className="h-5 cursor-pointer text-blue-500" aria-hidden="true" />
            </Link>
            <div className="cursor-pointer" onClick={() => handleEditClick(row.id)}>
              <PencilSquareIcon
                title="Edit"
                className="h-5 text-yellow-500"
                aria-hidden="true"
              />
            </div>
          </div>
        ),
      },
    ],
    [selectedRows, filteredData]
  );

  return (
    <div className="flex h-screen flex-col shadow">
      <div className="sticky top-0 z-30 flex items-center gap-5 border-b border-gray-300 bg-background-primary px-4 py-5 dark:border-gray-300/30 dark:bg-background-primary-dark sm:px-7">
        <div className="flex w-full items-center justify-between">
          <h3 className="text-lg font-medium leading-6 text-header dark:text-header-dark">Survey List</h3>
          <div className="flex gap-4">
            <Search
              searchQuery={searchQuery}
              onSearchQueryChange={(e) => setSearchQuery(e.target.value)}
              onSearch={() => {
                setPage(1);
                setSearchParams({ query: searchQuery });
              }}
            />
            <Button onClick={createBulkTickets}>
              Create Tickets
            </Button>
          </div>
        </div>
      </div>

      {/* PM Filter */}
      <div className="p-4">
        <label htmlFor="pmFilter" className="block mb-2 text-sm font-medium text-gray-700">Filter by PM</label>
        <input
          id="pmFilter"
          type="text"
          value={pmFilter}
          onChange={(e) => handleFilterChange(e.target.value)}
          className="block w-full p-2 border border-gray-300 rounded-md"
          placeholder="Enter PM Name"
        />
      </div>

      <div className="relative flex flex-1 flex-col space-y-4 overflow-y-auto p-2 sm:p-3">
        {loading && (
          <div className="absolute left-0 top-0 z-10 flex h-screen w-full items-center justify-center bg-background-primary bg-opacity-50 dark:bg-background-primary-dark">
            <Spinner />
          </div>
        )}
        <div className="flex min-h-[28rem] flex-1 flex-col overflow-hidden">
          {filteredData.length > 0 && (
            <div className="relative w-full flex-1 overflow-auto rounded-lg bg-background-primary dark:bg-background-primary-dark">
              <Table
                columns={columns}
                rows={filteredData}
                onSort={handleSort}
                sortKey={sortKey}
                sortDirection={sortDirection}
              />
            </div>
          )}
          {filteredData.length === 0 && !loading && !errors && (
            <div className="rounded-lg bg-background-primary p-6 text-base text-secondary-text dark:bg-background-primary-dark dark:text-secondary-text-dark">
              No results found.
            </div>
          )}
          {errors && (
            <div className="rounded-md bg-red-50 p-4 text-sm text-red-800">
              <div>{errors}</div>
              <div>Check the query for invalid entries.</div>
            </div>
          )}
          {filteredData.length > 0 && (
            <Pagination
              currentPage={page}
              pageCount={Math.ceil(2 / perPage)}
              onPageChange={(newPage) => setPage(newPage)}
            />
          )}
        </div>
      </div>
      {isModalOpen && selectedSurvey && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <EditFormModal surveyInstanceId={selectedSurvey} />
        </Modal>
      )}
    </div>
  );
};

export default SurveyList;
