import React, { useState } from "react";
import debounce from "lodash.debounce";
import { Combobox, Transition } from "@headlessui/react";
import { useController } from "react-hook-form";
import { classNames } from "utils";
import Label from "../Label";
import { isBoolean } from "utils/helpers";
import Spinner from "components/common/Spinner";

const AutoComplete = ({
  name: nameProp,
  control,
  register,
  label,
  placeholder,
  error,
  options,
  query,
  onVariableSelectionHandler = null,
}) => {
  const {
    field: { onChange, name, value: _value },
  } = useController({
    name: nameProp,
    control,
    register,
    rules: options,
  });

  const [search, setSearch] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setloading] = useState(false);

  const selected = selectedOptions?.find((option) => option.value === _value);
  const value = selected?.label || _value || "";

  const onChangeHandler = async (value) => {
    setloading(true);
    setSearch(value);
    const data = await query(value);
    setSelectedOptions(data);
    setloading(false);
  };

  const handleSelection = (value, onBlur) => {
    if (onVariableSelectionHandler && !onBlur) {
      onVariableSelectionHandler({ search, onChange, value });
      setSelectedOptions([]);
    } else {
      onChange(value);
    }
  };

  const hasError = isBoolean(error) ? error : !!error;

  return (
    <>
      {label && (
        <Label name={name} label={label} error={hasError} options={options} />
      )}
      <Combobox
        as="div"
        nullable
        id={name}
        value={value}
        open
        onChange={handleSelection}
      >
        {({ open }) => (
          <div className="relative mt-1 flex items-center">
            <Combobox.Input
              autoComplete="off"
              placeholder={placeholder || label}
              className={classNames(
                hasError
                  ? "border-red-500 dark:border-red-600"
                  : "border-gray-300 dark:border-gray-300/30",
                "block w-full rounded-md border-gray-300 bg-background-primary pr-12 text-sm placeholder-gray-400 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-300/30 dark:bg-background-primary-dark dark:placeholder-gray-500 sm:text-base",
              )}
              onKeyDown={(event) => {
                if (
                  onVariableSelectionHandler &&
                  ["Enter", "Tab"].includes(event.code)
                ) {
                  handleSelection(event.target.value, true);
                }
              }}
              onChange={debounce(function (event) {
                onChangeHandler(event.target.value);
              }, 350)}
              onBlur={(event) =>
                onVariableSelectionHandler &&
                handleSelection(event.target.value, true)
              }
            />
            {loading && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <Spinner className="w-4 h-4" />
              </div>
            )}
            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute mt-1 w-full rounded-md bg-background-primary shadow-lg dark:bg-background-primary-dark"
            >
              {selectedOptions?.length > 0 && (
                <Combobox.Options className="absolute z-50 mt-5 max-h-60 w-full overflow-auto rounded-md bg-background-primary py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-background-primary-dark sm:text-base">
                  {selectedOptions?.map(({ value, label }) => (
                    <Combobox.Option
                      className={({ active }) =>
                        classNames(
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                          active
                            ? "bg-blue-500 text-primary-text-dark dark:text-primary-text"
                            : "text-header dark:text-header-dark",
                        )
                      }
                      key={value}
                      value={value}
                    >
                      {label}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
              {selectedOptions?.length === 0 && (
                <div className="absolute z-50 mt-5 max-h-60 w-full cursor-default select-none overflow-auto rounded-md bg-background-primary py-3 pl-3 pr-9 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-background-primary-dark sm:text-base">
                  Node doesn't exist in this workflow.
                </div>
              )}
              {selectedOptions?.length === 0 && query !== undefined && (
                <div className="absolute z-50 mt-5 max-h-60 w-full cursor-default select-none overflow-auto rounded-md bg-background-primary py-3 pl-3 pr-9 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-background-primary-dark sm:text-base">
                  No results found.
                </div>
              )}
            </Transition>
          </div>
        )}
      </Combobox>
    </>
  );
};

export default AutoComplete;
