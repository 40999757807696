import parse from "html-react-parser";
import Modal from "./Modal";
import Button from "./Button";

export const InfoModal = ({ html = '', isOpen, onClose }) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    size="max-w-4xl"
    renderFooter={() => (
      <div className="flex justify-end bg-background-primary px-4 py-3 dark:bg-background-primary-dark sm:px-6">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </div>
    )}
  >
    <div className="h-[80vh] overflow-auto bg-background-secondary px-4 dark:bg-background-secondary-dark [&>*]:[all:_revert]">
      {parse(html)}
    </div>
  </Modal>
);
