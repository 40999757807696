import React from 'react';

const Spinner = ({ size = 'medium', className = '' }) => {
  const sizeClasses = {
    small: 'w-8 h-8',
    medium: 'w-12 h-12',
    large: 'w-16 h-16',
  };

  return (
    <div className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-[#e6f0ff]/80">
      <div
        className={`animate-spin rounded-full border-4 border-[#3b5aa9] border-t-transparent ${sizeClasses[size]} ${className}`}
      ></div>
    </div>
  );
};

export default Spinner;