import { NODE_TYPE } from "utils/constants";
import { EVENT_TYPES } from "workflow-editor/events";
import {
  VALIDATION_PIPELINES,
  ValidationManager,
} from "workflow-editor/validation";

export const allReferenceRegexp = /\${(([^}]*?)-[0-9]+)\.\w+}/g;

export const isEventTypeAllowed = (name, event, allowedEventTypes = []) => {
  if (!allowedEventTypes.includes(event.type))
    throw new Error(
      `Validator: '${name}' cannot run for event type: '${event.type}'`,
    );
};

export const sortErrorsAlphanumeric = (errors) =>
  Object.keys(errors)
    .sort((a, b) => {
      // always returns graph_cycles at the top
      if ((a === "graph_cycles") != (b === "graph_cycles")) {
        return a === "graph_cycles" ? 1 : -1;
      }
      return a.localeCompare(b, undefined, { numeric: true });
    })
    .reduce((obj, key) => {
      obj[key] = errors[key];
      return obj;
    }, {});

/**
 * Check whether node has all handles connected by an edge
 * @returns {boolean}
 */
export const isMissingEdgeConnections = (node, edges) => {
  // every node apart from `START` has a single target handle
  if (
    node.type !== NODE_TYPE.START &&
    !edges.find(({ target }) => target === node.id)
  )
    return true;
  // end nodes have no source handles
  if (node.type === NODE_TYPE.END) return false;

  const sourceEdges = edges.filter(({ source }) => source === node.id);
  switch (node.type) {
    case NODE_TYPE.RADIO:
      return node.data.answers.length !== sourceEdges.length;
    case NODE_TYPE.CHECKBOX:
      return node.data.handles.length !== sourceEdges.length;
    case NODE_TYPE.API:
      return node.data.results.length !== sourceEdges.length;
    case NODE_TYPE.ESCALATE:
      return sourceEdges.length !== (node.data.omitResolved ? 1 : 2);
    case NODE_TYPE.IF:
    case NODE_TYPE.REFERENCE_FLOW:
      return sourceEdges.length !== 2;
    case NODE_TYPE.START:
    case NODE_TYPE.LOOP:
    case NODE_TYPE.INPUT:
    case NODE_TYPE.WAIT:
    case NODE_TYPE.INSTRUCTIONAL:
      return sourceEdges.length !== 1;
    default:
      return false;
  }
};

/**
 * Runs all validations. Used for backward compatibility
 * to run on workflows that don't have the validations data
 * @param {ValidationManager} validationManager
 */
export const runAllValidations = (event, validationManager) => {
  const { state: { nodes, edges } = {} } = event;
  if (!nodes || !edges) return;

  // simulate node update events to run all node based validators
  nodes.forEach((node) =>
    validationManager.run(VALIDATION_PIPELINES[EVENT_TYPES.NODE_UPDATE])({
      type: EVENT_TYPES.NODE_UPDATE,
      state: { nodes, edges },
      data: node,
    }),
  );
  // simulate edge create events to run all edge based validators
  if (edges.length > 0)
    validationManager.run(VALIDATION_PIPELINES[EVENT_TYPES.EDGE_CREATE])({
      type: EVENT_TYPES.EDGE_CREATE,
      state: { nodes, edges },
      data: edges[0],
    });

  return validationManager.errors;
};
