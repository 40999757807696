import { Suspense, lazy } from "react";
import { Editor } from "@tinymce/tinymce-react";

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";
// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
// import "tinymce/plugins/preview";
import "tinymce/plugins/importcss";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autosave";
import "tinymce/plugins/save";
import "tinymce/plugins/directionality";
// import "tinymce/plugins/code";
// import "tinymce/plugins/visualblocks";
// import "tinymce/plugins/visualchars";
// import "tinymce/plugins/fullscreen";
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/media";
// import "tinymce/plugins/template";
import "tinymce/plugins/codesample";
import "tinymce/plugins/table";
import "tinymce/plugins/charmap";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/anchor";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/advlist";
import "tinymce/plugins/lists";
// import "tinymce/plugins/wordcount";
import "tinymce/plugins/help";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/emoticons";
// import "tinymce/plugins/accordion"

// importing plugin resources
import "tinymce/plugins/emoticons/js/emojis";

// Content styles, including inline UI like fake cursors
import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import darkContentCss from "!!raw-loader!tinymce/skins/content/dark/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
import darkContentUiCss from "!!raw-loader!tinymce/skins/ui/oxide-dark/content.min.css";
// Lazy load skin css depending on dark mode
const DarkTheme = lazy(() => import("./dark"));
const LightTheme = lazy(() => import("./light"));

import { useTheme } from "contexts/theme";
import { uploadFiles } from "api";
import { getAttachmentSrc } from "utils";

// all toolbar options:
// undo redo blocks fontfamily fontsize bold italic underline strikethrough align numlist bullist link image table media lineheight outdent indent| forecolor backcolor removeformat charmap emoticons code fullscreen preview save print pagebreak anchor codesample ltr rtl",
const toolbar_groups = {
  moreFormatting: {
    icon: "change-case",
    tooltip: "More Formatting",
    items:
      "underline strikethrough | fontfamily fontsize | lineheight outdent indent",
  },
  insert: {
    icon: "plus",
    tooltip: "Insert",
    items: "image media table | blockquote | charmap emoticons",
  },
};
const toolbar =
  "blocks | bold italic moreFormatting | quickimage | numlist bullist align | insert";
const quickbars_selection_toolbar = "bold italic quicklink";

const images_upload_handler = (blobInfo, progress) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("files", blobInfo.blob(), blobInfo.filename());
    uploadFiles(formData, (e) => {
      progress((e.loaded / e.total) * 100);
    })
      .then(({ data: { data } }) => {
        resolve(getAttachmentSrc(data[0].filename));
      })
      .catch(reject);
  });

export const RichTextEditor = (props) => {
  const { init, ...rest } = props;
  const { darkMode } = useTheme();

  // note that skin and content_css is disabled to avoid the normal
  // loading process and is instead loaded as a string via content_style
  return (
    <>
      <Suspense fallback={null}>
        {darkMode ? <DarkTheme /> : <LightTheme />}
      </Suspense>
      <Editor
        init={{
          ...init,
          skin: false,
          content_css: false,
          branding: false,
          resize: false,
          statusbar: false,
          promotion: false,
          images_upload_handler,
          file_picker_types: "image media",
          menubar: false,
          plugins: [
            // "preview",
            "importcss",
            "searchreplace",
            "autolink",
            "autosave",
            "save",
            "directionality",
            // "code",
            // "visualblocks",
            // "visualchars",
            // "fullscreen",
            "image",
            "link",
            "media",
            // "template",
            "codesample",
            "table",
            "charmap",
            "pagebreak",
            "nonbreaking",
            "anchor",
            "insertdatetime",
            "advlist",
            "lists",
            // "wordcount",
            "quickbars",
            "emoticons",
            // "accordion",
          ],
          toolbar,
          toolbar_groups,
          quickbars_selection_toolbar,
          quickbars_insert_toolbar: false,
          automatic_uploads: false,
          file_picker_callback: (cb, value, meta) => {
            const input = document.createElement("input");
            const accept = `image/*${
              meta.filetype === "media" ? ",video/*" : ""
            }`;
            input.setAttribute("type", "file");
            input.setAttribute("accept", accept);

            input.addEventListener("change", (e) => {
              const file = e.target.files[0];

              const reader = new FileReader();
              reader.addEventListener("load", () => {
                /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
              */
                const id = "blobid" + new Date().getTime();
                const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                const base64 = reader.result.split(",")[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(blobInfo.blobUri(), { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
          height: "100%",
          content_style: [
            darkMode ? darkContentCss : contentCss,
            darkMode ? darkContentUiCss : contentUiCss,
            init?.content_style || "",
          ].join("\n"),
        }}
        {...rest}
      />
    </>
  );
};
