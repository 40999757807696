import React, { useState, useEffect, useRef } from "react";
import withFlowState from "components/hoc/withFlowState";
import Spinner from "components/common/Spinner";
import { getApiEndpoint, postAlertToSlack } from "api";
import {
  CheckCircleIcon,
  XCircleIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { NODE_TYPE } from "utils/constants";
import { Button } from "components/common";
import FlowStep from "../FlowStep";
import TechInfo from "../TechInfo";
import { postFrontendErrorLogs } from "api";
import { useAuthState } from "contexts/auth";

const MAX_NESTING_DEPTH = 14;

const formatResponse = (obj) => {
  const format = ([_key, value], depth = 1) => {
    const key = _key
      .replace("_", " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

    if (typeof value === "object") {
      if (Array.isArray(value))
        return (
          <div key={_key}>
            {key}: {JSON.stringify(value)}
          </div>
        );

      if (depth > MAX_NESTING_DEPTH) return null;
      return (
        <div key={_key}>
          {key}:{" "}
          <div className="pl-4">
            {Object.entries(value).map((arg) => format(arg, depth + 1))}
          </div>
        </div>
      );
    } else {
      return (
        <div key={_key}>
          {key}: {value}
        </div>
      );
    }
  };

  if (typeof obj !== "object") return null;
  return Object.entries(obj).map(format);
};

const ApiStep = (props) => {
  const {
    state,
    updateFlowStateStepData,
    debugMode,
    setApiRunning,
    determineNextStep,
    stepState,
    injectValue,
  } = props;
  const { workflow, instanceState, dryRun, currentNode } = state;

  const {user} = useAuthState();

  const ignoreApiCall = useRef(false);
  const [apis, setApis] = useState(
    stepState?.apis.map((api) => ({
      ...api,
      completed: false,
    })) || [],
  );
  const [idx, setIdx] = useState(0);
  const [techInfo, setTechInfo] = useState(null);
  const [status, setStatus] = useState({
    status: null,
    success: null,
    response: null,
    next: null,
  });

  useEffect(() => {
    // this is to fix next button being pressable when coming back to this node from previous step
    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: NODE_TYPE.API,
      next: null,
    });
    setTechInfo(
      Object.values(instanceState)?.filter(
        (value) => value?.tech_name && value?.tech_phone,
      ),
    );
  }, [currentNode]);

  const postStatusToSlack = async (alertObj) => {
    try {
      // formats object to { message: `<key1>:"<value1>" <key2>:"<value2>" ...`} format
      const payload = Object.entries(alertObj).reduce(
        (obj, [key, value]) => {
          if (obj.message.length > 0) obj.message += " ";
          obj.message += `${key}:"${value}"`;
          return obj;
        },
        { message: "" },
      );
      await postAlertToSlack(payload);
    } catch (error) {
      postFrontendErrorLogs(error);
    }
  };

  const handleApiCall = async () => {
    try {
      const newApis = apis.filter(
        (item) => !item.completed || item.response?.runAgain,
      );
      if (newApis.length > 0) {
        setApis((prevState) =>
          prevState.map((api) => ({ ...api, completed: false, response: {} })),
        );
        setApiRunning(true);
        const api = newApis[0];
        const endpoint = injectValue(api.endpoint);
        const params = Object.entries(api.parameters ?? {}).reduce(
          (obj, [key, value]) => {
            obj[key] = injectValue(value);
            return obj;
          },
          {},
        );
        const args = api.arguments?.map(({ key, value }) => ({
          key: injectValue(key),
          value: injectValue(value),
        }));
        const { status, data } = await getApiEndpoint(endpoint, params, args);
        let runAgain = false;
        if (!data?.success) {
          runAgain = true;
        }
        const apiResponse = { status, data, runAgain };

        setApis([
          {
            ...api,
            // parameters: { ...api.parameters, ip: "127.0.0.1" },
            response: apiResponse,
            completed: true,
          },
        ]);

        const next = determineNextStep({ apiResponse });
        updateFlowStateStepData({
          nodeId: currentNode,
          nodeType: NODE_TYPE.API,
          next: next,
          state: apiResponse,
        });
        setApiRunning(false);
      }
    } catch (error) {
      setApiRunning(false);
      postFrontendErrorLogs(error);

      const api = {
        ...apis[0],
        response: `${error?.response?.status ?? 500} Received`,
        completed: true,
      };

      if (error?.response?.status === 404) {
        api.message =
          "Workflow configuration error: API endpoint does not exist. Unable to proceed";

        const endpoint = injectValue(api.endpoint);
        postStatusToSlack({
          workflow_id: workflow.id,
          status: 404,
          endpoint,
        });
      }

      setApis([api]);
      const apiResponse = { status: error.response.status ?? 500, data: null };
      const next = determineNextStep({ apiResponse });
      updateFlowStateStepData({
        nodeId: currentNode,
        nodeType: NODE_TYPE.API,
        next: next,
        state: apiResponse,
      });
    }
  };

  useEffect(() => {
    // workaround to prevent react strict mode from making duplicate requests
    if (!ignoreApiCall.current) {
      ignoreApiCall.current = true;
      handleApiCall();
    }
  }, [stepState]);

  const onAPIToggle = () => {
    const newResultIndex = (idx + 1) % stepState.results.length;
    setIdx(newResultIndex);

    updateFlowStateStepData({
      nodeId: currentNode,
      nodeType: NODE_TYPE.API,
      next: stepState.results[newResultIndex]?.next,
      state: {
        status: stepState.results[newResultIndex]?.status,
        data: {
          success: stepState.results[newResultIndex]?.success,
          text: stepState.results[newResultIndex]?.response,
        },
      },
    });
    setStatus({
      status: `Status: ${stepState.results[newResultIndex]?.status}`,
      success: `Success: ${stepState.results[newResultIndex]?.success}`,
      response: `Response: ${stepState.results[newResultIndex]?.response}`,
      next: `Next:${stepState.results[newResultIndex]?.next}`,
    });
  };

  return (
    <FlowStep data={stepState}>
      <h1 className="text-lg">{`${stepState.apis[0]?.name}`}</h1>
      <div className="mt-8 space-y-2">
        {apis.map((api, index) => (
          <div className="flex items-center" key={`api_${index}`}>
            <div className="mr-4">
              {!api.completed && <Spinner size="small" />}
              {api.completed && api.response?.data?.success && (
                <CheckCircleIcon className="h-6 w-6 text-green-500" />
              )}
              {api.completed && !api.response?.data?.success && (
                <XCircleIcon className="h-6 w-6 text-red-500 dark:text-red-600" />
              )}
            </div>
            <div className="flex w-full flex-col">
              <div className="flex justify-between">
                <span>
                  {!api.completed && api.name}
                  {api.completed && (api.message ?? api?.response?.data?.success ? "Flow Request Completed - Please click Next to continue" : "Flow Request Failed - Please click Next and we'll work to fix the issue")}
                </span>
                {api.completed &&
                  !api.response?.data?.success &&
                  api.response?.runAgain && (
                    <ArrowPathIcon
                      onClick={handleApiCall}
                      className="h-6 w-6 text-blue-500 dark:text-blue-600"
                    />
                  )}
              </div>
              <div>
                {api.completed &&
                  api?.response?.data?.data &&
                  api?.response?.data?.display &&
                  formatResponse(api.response.data.data)}
              </div>
            </div>
          </div>
        ))}
        {user && user?.username !== process.env.REACT_APP_FIELD_TECH &&
          debugMode && (
            <div className="mt-8 space-y-2">
              <Button onClick={onAPIToggle}>Toggle Results</Button>
              <div>{status?.status}</div>
              <div>{status?.success}</div>
              <div>{status?.response}</div>
              <div>{status?.next}</div>
            </div>
          )}
      </div>
      <TechInfo techInfo={techInfo} />
    </FlowStep>
  );
};

export default withFlowState(ApiStep);
