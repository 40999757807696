import { injectValue } from "utils";
import { LOGICAL_OPS, NODE_TYPE, OPS } from "utils/constants";
import { useAuthState } from "contexts/auth";

export const comparisonOperatorsHash = {
  [OPS.LT]: function (a, b) {
    if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b) === -1;
    } else {
      return a < b;
    }
  },
  [OPS.LTE]: function (a, b) {
    if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b) === -1 || a.localeCompare(b) === 0;
    } else {
      return a <= b;
    }
  },
  [OPS.GT]: function (a, b) {
    if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b) === 1;
    } else {
      return a > b;
    }
  },
  [OPS.GTE]: function (a, b) {
    if (typeof a === "string" && typeof b === "string") {
      return a.localeCompare(b) === 1 || a.localeCompare(b) === 0;
    } else {
      return a >= b;
    }
  },
  [OPS.CONTAIN]: function (a, b) {
    if (typeof a === "string" && typeof b === "string") {
      return new RegExp(b, "i").test(a);
    } else if (Array.isArray(a) && Array.isArray(b)) {
      return b.every((item) => a.includes(item));
    } else {
      return false;
    }
  },
  [OPS.EQUAL]: function (a, b) {
    if (typeof a !== "string" && typeof b !== "string") {
      try {
        return a.toString() === b.toString();
      } catch (error) {}
    }
    return a === b;
  },
  [OPS.NOT_EQUAL]: function (a, b) {
    return a !== b;
  },
};

export const comparisonLogicalOperatorsHash = {
  [LOGICAL_OPS.AND]: function (a, b) {
    return a && b;
  },
  [LOGICAL_OPS.OR]: function (a, b) {
    return a || b;
  },
};

// check if both values share the same type
// if so convert to that type
const parseComparisonValues = (a, b) => {
  // numbers
  if (!isNaN(a) && !isNaN(b)) return [Number(a), Number(b)];

  // arrays
  try {
    const selectedAnswersArray = JSON.parse(a);
    const preSelectedAnswersArray = JSON.parse(b);

    if (
      Array.isArray(selectedAnswersArray) &&
      Array.isArray(preSelectedAnswersArray)
    ) {
      const sortedSelectedAnswers = selectedAnswersArray.sort();
      const sortedPreSelectedAnswers = preSelectedAnswersArray.sort();

      return [sortedSelectedAnswers, sortedPreSelectedAnswers];
    }
  } catch (error) {}

  return [a, b];
};

export const checkIfStatements = (criteria, instanceState, path) => {
  const { logicalOp, rules } = criteria;
  return rules.reduce(
    (accumulator, currentValue) => {
      const [value, comparisonValue] = parseComparisonValues(
        injectValue(currentValue.variable, instanceState, path),
        injectValue(currentValue.value, instanceState, path),
      );
      return comparisonLogicalOperatorsHash[logicalOp](
        accumulator,
        currentValue.rules
          ? checkIfStatements(currentValue, instanceState, path)
          : comparisonOperatorsHash[OPS[currentValue.op]](
              value || false,
              comparisonValue,
            ),
      );
    },
    // set initial comparison value for AND operator to true
    logicalOp === LOGICAL_OPS.AND,
  );
};

export const checkCondition = (condition, func) => {
  if (!condition) {
    func();
  }
};

// This component will render the children components if the user has
// the permsission that was set passed
export const ShowForPermission = ({ permission, children }) => {
  const { user } = useAuthState();
  const isAllowed = permission.includes(user?.roles);

  if (isAllowed) {
    return children; // rendering nested elements
  } else {
    return null;
  }
};

export const isBoolean = (val) => "boolean" === typeof val;

export const removeEmptyProps = (obj) =>
  Object.fromEntries(
    Object.entries(obj || {}).filter(([_, v]) => (isBoolean(v) ? true : v)),
  );

export const isObjectNotEmpty = (obj) => {
  return Object.keys(obj).length > 0;
};

export const convertToCamelCase = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

/**
 * Flattens object,
 * puts a dot between nested keys
 * ```js
 * flattenObject({ "a": 1, "b": { "c": 2 }}) // returns { "a":1, "b.c": 2 }
 * ```
 */
export const flattenObject = (obj, parent) => {
  let res = {};

  for (const [key, value] of Object.entries(obj)) {
    const propName = parent ? parent + "." + key : key;
    const flattened = {};

    if (value instanceof Date) {
      flattened[key] = value.toISOString();
    } else if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      res = { ...res, ...flattenObject(value, propName) };
    } else {
      res[propName] = value;
    }
  }

  return res;
};

export const convertToSnakeCase = (string) => {
  return string
    .replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join("_");
};

export const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      link.dispatchEvent(clickEvt);
      document.body.removeChild(link);
    }
  }
};
