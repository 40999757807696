import { states } from "./forms";
import { TRACKING_PATTERN } from "utils";
import { searchUsers, searchWorkflows } from "utils/queryHelpers";
import { ROLES } from "./constants";

export const formLayouts = {
  workflows: {
    edit: {
      cols: 6,
      fields: {
        name: {
          label: "Name",
          type: "text",
          options: { required: true },
          order: 1,
          xs: 6,
          sm: 4,
        },
        description: {
          label: "Description",
          type: "textArea",
          options: { required: false },
          order: 2,
          xs: 6,
          sm: 4,
        },
        customer_name: {
          label: "Customer Name",
          type: "text",
          options: { required: false },
          order: 5,
          xs: 6,
          sm: 3,
        },
        account_number: {
          label: "Account Number",
          options: { required: false },
          type: "text",
          order: 6,
          xs: 6,
          sm: 3,
        },
        form_id: {
          type: "select",
          label: "Form",
          selectOptions: [],
          options: { required: false },
          order: 6,
          xs: 6,
          sm: 3,
        },
        is_active: {
          label: "Is Active",
          type: "checkbox",
          order: 7,
          xs: 6,
          sm: 3,
        },
        notificationsConfig: {
          type: "array",
          label: "Escalation Settings",
          xs: 6,
          sm: 6,
          fields: {
            message: {
              label: "Escalate Group",
              type: "text",
              xs: 6,
              sm: 6,
              options: {
                required: true,
              },
            },
            channel_id: {
              type: "select",
              label: "Slack Channel",
              selectOptions: [],
              options: {
                required: true,
              },
            },
            message_body: {
              type: "multiSelect",
              label: "Columns",
              placeholder: "Choose a form to view options",
              selectOptions: [],
              options: {
                required: true,
              },
            },
          },
        },
      },
    },
    view: {
      cols: 6,
      fields: {
        name: {
          label: "Name",
          type: "text",
          order: 1,
          xs: 6,
          sm: 3,
        },
        description: {
          label: "Description",
          type: "text",
          order: 2,
          xs: 6,
          sm: 6,
        },
        customer_name: {
          label: "Customer Name",
          type: "text",
          order: 5,
          xs: 6,
          sm: 3,
        },
        account_number: {
          label: "Account Number",
          type: "text",
          order: 7,
          xs: 6,
          sm: 3,
        },
        form_id: {
          label: "Form",
          type: "select",
          selectOptions: [],
          order: 7,
          xs: 6,
          sm: 3,
        },
        is_active: {
          label: "Is Active",
          type: "checkbox",
          order: 7,
          xs: 6,
          sm: 3,
        },
      },
    },
  },
  dia: {
    form: {
      cols: 8,
      fields: {
        workflow_id: {
          type: "select",
          label: "Workflow Name",
          selectOptions: [],
          options: { required: "Workflow Name is required." },
          order: 1,
          start: 1,
          end: 5,
        },
      },
    },
    view: {
      cols: 12,
      fields: {
        workflow_id: {
          type: "select",
          label: "Workflow Name",
          selectOptions: [],
          options: { required: true },
          order: 1,
          start: 1,
          end: 5,
        },
        project_id: {
          type: "text",
          label: "Project ID",
          options: { required: false },
          order: 1,
          start: 5,
          end: 9,
        },
        account_number: {
          label: "Child Account Number",
          type: "text",
          options: {
            required: true,
            pattern: /^[^0][0-9]{6}$|^(0){1}[0-9]{7}$/g,
            maxLength: 8,
          },
          order: 2,
          start: 1,
          end: 5,
        },
        iw_ticket: {
          label: "IW Ticket",
          type: "text",
          options: { required: true, pattern: /^[0-9]{9}$/g, maxLength: 9 },
          order: 3,
          start: 5,
          end: 9,
        },
        customer_name: {
          label: "Customer Name",
          type: "text",
          options: { required: true },
          order: 4,
          start: 1,
          end: 5,
        },
        tracking_number: {
          label: "Tracking Number",
          type: "text",
          options: {
            required: true,
            pattern: TRACKING_PATTERN,
          },
          order: 5,
          start: 5,
          end: 9,
        },
        address_1: {
          label: "Address",
          type: "text",
          options: { required: true },
          order: 6,
          start: 1,
          end: 4,
        },
        address_2: {
          label: "Suite/Bldg",
          type: "text",
          options: {},
          order: 7,
          start: 4,
          end: 5,
        },
        city: {
          label: "City",
          type: "text",
          options: { required: true, pattern: /^[A-Za-z- ]+$/g },
          order: 8,
          start: 5,
          end: 7,
        },
        state: {
          type: "select",
          label: "State",
          selectOptions: states,
          options: { required: true },
          order: 9,
          start: 7,
          end: 8,
        },
        zip_code: {
          label: "Zip Code",
          type: "text",
          options: { required: true, minLength: 5, maxLength: 6 },
          order: 10,
          start: 8,
          end: 9,
        },
        nso_id: {
          type: "text",
          label: "NSO ID",
          options: {
            required: true,
            pattern: /^(CPEGRT)[0-9]{10}$/g,
            maxLength: 16,
          },
          order: 11,
          start: 1,
          end: 5,
        },
        bandwidth: {
          label: "Bandwidth (MBPS)",
          type: "text",
          options: { required: true, maxLength: 5, pattern: /^[0-9]{1,5}$/ },
          order: 12,
          start: 5,
          end: 9,
        },
        dispatch_date: {
          type: "date",
          label: "Dispatch Date",
          options: { required: true, max: "2050-01-01" },
          order: 13,
          start: 1,
          end: 5,
        },
        dispatch_time: {
          type: "time",
          label: "Dispatch Time",
          options: { required: true },
          order: 14,
          start: 5,
          end: 9,
        },
      },
    },
  },
  users: {
    edit: {
      cols: 6,
      fields: {
        username: {
          label: "Username",
          type: "text",
          options: { required: true },
          order: 1,
          xs: 6,
          sm: 3,
        },
        name: {
          label: "Name",
          type: "text",
          options: { required: false },
          order: 2,
          xs: 6,
          sm: 3,
        },
        phone: {
          label: "Phone",
          type: "text",
          options: { required: false },
          order: 3,
          xs: 6,
          sm: 3,
        },
        roles: {
          label: "Roles",
          type: "select",
          selectOptions: Object.values(ROLES).filter(
            (value) => value !== "ALL",
          ),
          options: { required: true },
          order: 4,
          xs: 6,
          sm: 3,
        },
        is_active: {
          label: "Is Active",
          type: "checkbox",
          order: 5,
          xs: 6,
          sm: 3,
        },
      },
    },
    view: {
      cols: 6,
      fields: {
        username: {
          label: "Username",
          type: "text",
          order: 1,
          xs: 6,
          sm: 3,
        },
        name: {
          label: "Name",
          type: "text",
          order: 2,
          xs: 6,
          sm: 3,
        },
        phone: {
          label: "Phone",
          type: "text",
          order: 3,
          xs: 6,
          sm: 3,
        },
        roles: {
          label: "Roles",
          type: "text",
          order: 4,
          xs: 6,
          sm: 3,
        },
        is_active: {
          label: "Is Active",
          type: "checkbox",
          order: 5,
          xs: 6,
          sm: 3,
        },
        is_deleted: {
          label: "Is Deleted",
          type: "checkbox",
          order: 6,
          xs: 6,
          sm: 3,
        },
      },
    },
  },
  reports: {
    view: {
      cols: 12,
      fields: {
        account_number: {
          label: "Child Account",
          type: "text",
          order: 1,
          xs: 6,
          sm: 2,
        },
        iw_ticket: {
          label: "IW Ticket",
          type: "text",
          order: 3,
          xs: 6,
          sm: 2,
        },
        customer_name__like: {
          label: "Customer Name",
          type: "text",
          order: 2,
          xs: 6,
          sm: 2,
        },

        created_by: {
          label: "Submitter",
          type: "autoComplete",
          query: searchUsers,
          selectOptions: [],
          order: 4,
          xs: 6,
          sm: 3,
        },
        Workflow__name__like: {
          label: "Workflow Name",
          type: "autoComplete",
          query: searchWorkflows,
          order: 5,
          xs: 6,
          sm: 3,
        },
        "form_data->>address_1__like": {
          label: "Address",
          type: "text",
          order: 6,
          xs: 6,
          sm: 4,
        },
        "form_data->>city__like": {
          label: "City",
          type: "text",
          order: 7,
          xs: 6,
          sm: 3,
        },
        "form_data->>zip_code": {
          label: "ZIP Code",
          type: "text",
          order: 8,
          xs: 6,
          sm: 3,
        },
        "form_data->>state": {
          label: "State",
          type: "select",
          order: 9,
          xs: 6,
          sm: 2,
          selectOptions: ["", ...states],
        },
        status: {
          label: "Status",
          type: "select",
          order: 10,
          xs: 6,
          sm: 2,
          selectOptions: [
            "",
            "Completed",
            "Escalated",
            "Failure",
            "In Progress",
            "Scheduled",
          ],
        },
        dispatch_date__gte: {
          label: "From",
          type: "date",
          options: { required: false, max: "2050-01-01" },
          order: 11,
          xs: 6,
          sm: 3,
        },
        dispatch_date__lte: {
          label: "To",
          type: "date",
          options: { required: false, max: "2050-01-01" },
          order: 12,
          xs: 6,
          sm: 3,
        },
      },
    },
  },
  settings: {
    view: {
      cols: 12,
      fields: {
        name: {
          label: "Name",
          type: "text",
          order: 2,
          xs: 6,
          sm: 3,
        },
        username: {
          label: "Username",
          type: "text",
          order: 1,
          xs: 6,
          sm: 3,
        },
        phone: {
          label: "Phone",
          type: "text",
          order: 3,
          xs: 6,
          sm: 3,
        },
        is_active: {
          label: "Is Active",
          type: "checkbox",
          order: 4,
          xs: 6,
          sm: 3,
        },
        roles: {
          label: "Roles",
          type: "text",
          order: 4,
          xs: 6,
          sm: 3,
        },
      },
    },
    edit: {
      cols: 12,
      fields: {
        name: {
          label: "Name",
          type: "text",
          order: 2,
          xs: 6,
          sm: 4,
        },
        username: {
          label: "Username",
          type: "text",
          order: 1,
          xs: 6,
          sm: 4,
        },
        phone: {
          label: "Phone",
          type: "text",
          order: 3,
          xs: 6,
          sm: 4,
        },
      },
    },
  },
  forms: {
    list: [""],
    edit: {
      cols: 8,
      fields: {
        workflow_id: {
          type: "select",
          label: "Workflow Name",
          selectOptions: [],
          options: { required: true },
          order: 1,
          start: 1,
          end: 5,
        },
      },
    },
    view: {
      cols: 12,
      fields: {
        name: {
          type: "text",
          label: "Name",
          options: { required: true },
          order: 1,
          start: 1,
          end: 8,
        },
      },
    },
  },
};

export const escalatedTableLayout = [
  {
    title: "IW Ticket",
    key: "workflow_instances_iw_ticket",
  },
  {
    title: "Account",
    key: "workflow_instances_account_number",
  },
  {
    title: "Customer",
    key: "workflow_instances_customer_name",
    render: (row) =>
      row?.workflow_instances_customer_name
        ? row.workflow_instances_customer_name
        : row.workflows_customer_name,
  },
  {
    title: "Address",
    key: "workflow_instances_form_data",
    render: (row) =>
      `${row?.workflow_instances_form_data?.address_1} ${row?.workflow_instances_form_data?.city} ${row?.workflow_instances_form_data?.state}`,
  },
  {
    title: "Submitted",
    key: "workflow_instances_created_by",
  },
  {
    title: "Dispatch Date",
    key: "workflow_instances_dispatch_date",
    render: (row) =>
      new Date(row.workflow_instances_dispatch_date).toLocaleDateString(
        undefined,
        {
          day: "2-digit",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
        },
      ),
  },
  {
    title: "Workflow",
    key: "workflows_name",
  },
  {
    title: "Status",
    key: "workflow_instances_status",
  },
];
