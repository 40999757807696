import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Button from "../Button";

const DeleteConfirmation = ({
  title,
  message,
  onConfirm,
  onCancel = null,
  actionButtonLabel = "Delete",
  cancelButtonLabel = "Cancel",
  defaultStateOpen = false,
  additionalMessage = null,
  disabled = false,
}) => {
  const cancelButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(defaultStateOpen);

  const onCancelHandler = () => {
    setIsOpen(false);
    onCancel && onCancel();
  };
  return (
    <>
      {!defaultStateOpen && (
        <Button
          variant="delete"
          size="small"
          className="w-24"
          onClick={() => setIsOpen(true)}
        >
          Delete
        </Button>
      )}
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={onCancelHandler}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-background-primary px-4 pb-4 pt-5 text-left shadow-xl transition-all dark:bg-background-primary-dark sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-header dark:text-header-dark"
                      >
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{message}</p>
                      </div>
                      {additionalMessage && (
                        <div className="mt-2">
                          <p className="text-sm font-bold text-gray-500">
                            {additionalMessage}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:gap-4 sm:pl-4">
                    <Button
                      variant="delete"
                      size="small"
                      className="w-24"
                      onClick={onConfirm}
                      disabled={disabled}
                    >
                      {actionButtonLabel}
                    </Button>
                    <Button
                      variant="secondary"
                      className="w-24"
                      onClick={onCancelHandler}
                      ref={cancelButtonRef}
                    >
                      {cancelButtonLabel}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DeleteConfirmation;
