import React, { Fragment, useState, useEffect } from "react";
import {
  InstructionsStep,
  ApiStep,
  EscalateStep,
  StartStep,
  InputStep,
  EndStep,
  IfElseStep,
  StartSpecificStep,
  ReferenceFlowStep,
  LoopStep,
} from ".";
import { NODE_TYPE } from "utils/constants";
import withFlowState from "components/hoc/withFlowState";
import WaitStep from "./WaitStep";
import { Button } from "components/common";
import Navigation from "./Navigation";
import AttachmentsModal from "components/common/attachments/AttachmentModal";
import { InfoModal } from "components/common/InfoModal";
import { useAuthState } from 'contexts/auth';
import TimerView from "components/common/TimerView";
import FlowTimer from "./FlowTimer";

const Flow = (props) => {
  const { state, debugMode, setDebugMode, sequence, stepState, workflowInstanceId } = props;
  const { dryRun, workflowNodes, workflow, instanceState, path, flowState } =
    state;

  const {user} = useAuthState();

  const excludeNavNodeTypes = [
    NODE_TYPE.START,
    NODE_TYPE.END,
    NODE_TYPE.ESCALATE,
  ];

  const [displayAttachmentsModal, setDisplayAttachmentsModal] = useState(false);
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(null);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState(false);

  const onAttachmentClickHandler = (index) => {
    if (stepState?.info) {
      setDisplayInfoModal(true);
    } else {
      setCurrentAttachmentIndex(index);
      setDisplayAttachmentsModal(true);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
        if(confirmLeave){
            return null
        }
      
      const message = `Are you sure you want to leave? Your changes may not be saved and you may need to start over.`
      event.returnValue = false
      return message
    }
    
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [confirmLeave])

  return (
    <>
      <div className="relative">
        <FlowTimer workflowInstanceId={workflowInstanceId}/>
        <div className="absolute right-0 top-0">
          {
            // show debug mode button in prod if doing a dry run
            user && user?.username !== process.env.REACT_APP_FIELD_TECH && (
              <div className="mt-4 text-secondary-text dark:text-secondary-text-dark sm:mt-8">
                <Button size="small" onClick={() => setDebugMode(!debugMode)}>
                  {debugMode ? "Debug On" : "Debug Off"}
                </Button>
              </div>
            )
          }
        </div>
      </div>
      <div className="py-4 text-center sm:mb-4">
        <span className="block text-lg font-bold leading-8 tracking-tight text-header dark:text-header-dark sm:text-4xl sm:font-extrabold">
          {workflow.name}
        </span>
        <span className="leading-8 text-secondary-text dark:text-secondary-text-dark">{`${instanceState?.customer_name} - ${instanceState?.city}, ${instanceState?.state_ || instanceState?.state}`}</span>
      </div>
      <Fragment key={sequence}>
        {workflowNodes &&
          Object.keys(workflowNodes).length > 0 &&
          ({
            [NODE_TYPE.RADIO]: (
              <TimerView>
              <InstructionsStep
                onAttachmentClickHandler={onAttachmentClickHandler}
              />
              </TimerView>
            ),
            [NODE_TYPE.CHECKBOX]: (
              <TimerView>
              <InstructionsStep
                onAttachmentClickHandler={onAttachmentClickHandler}
              />
              </TimerView>
            ),
            [NODE_TYPE.INSTRUCTIONAL]: (
              <TimerView>
              <InstructionsStep
                onAttachmentClickHandler={onAttachmentClickHandler}
              />
              </TimerView>
            ),
            [NODE_TYPE.ESCALATE]: <TimerView><EscalateStep /></TimerView>,
            [NODE_TYPE.API]: <TimerView> <ApiStep /> </TimerView>,
            [NODE_TYPE.WAIT]: (
              <TimerView>
              <WaitStep onAttachmentClickHandler={onAttachmentClickHandler} />
              </TimerView>
            ),
            [NODE_TYPE.START]: <TimerView><StartStep /></TimerView>,
            [NODE_TYPE.IF]: <TimerView><IfElseStep /></TimerView>,
            [NODE_TYPE.INPUT]: (
              <TimerView>
              <InputStep onAttachmentClickHandler={onAttachmentClickHandler} />
              </TimerView>
            ),
            [NODE_TYPE.END]: <TimerView><EndStep /></TimerView>,
            [NODE_TYPE.START_SPECIFIC]:<TimerView> <StartSpecificStep /></TimerView>,
            [NODE_TYPE.REFERENCE_FLOW]: <TimerView><ReferenceFlowStep /></TimerView>,
            [NODE_TYPE.LOOP]: <TimerView><LoopStep /></TimerView>,
          }[stepState?.type] || <div>{"Unknown flow step type"}</div>)}
      </Fragment>
      {!excludeNavNodeTypes.includes(stepState?.type) && (
        <Navigation
          isNextEnabled={stepState?.type === NODE_TYPE.ESCALATE}
          onAttachmentClickHandler={onAttachmentClickHandler}
        />
      )}
      {displayAttachmentsModal && (
        <AttachmentsModal
          attachments={stepState?.images}
          openIndex={currentAttachmentIndex}
          onClose={() => setDisplayAttachmentsModal(false)}
        />
      )}
      <InfoModal
        isOpen={displayInfoModal}
        onClose={() => setDisplayInfoModal(false)}
        html={stepState?.info}
      />
      {user && user?.username !== process.env.REACT_APP_FIELD_TECH &&
        debugMode && (
          <div className="mt-4 text-secondary-text dark:text-secondary-text-dark sm:mt-8">
            {/* <div>Flow Path</div>
            <pre>{JSON.stringify(flowPath, null, 4)}</pre> */}
            <div>Flow State</div>
            <pre>{JSON.stringify(flowState, null, 4)}</pre>
            <div>Instance State</div>
            <pre>{JSON.stringify(instanceState, null, 4)}</pre>
            <div>Path</div>
            <pre>{JSON.stringify(path, null, 4)}</pre>
            {/* <pre>{JSON.stringify(workflow, null, 4)}</pre> */}
            {/*<div>Current Step</div>
        <div>{JSON.stringify(currentNode)}</div> 
        <div>Workflow</div>
        <div>{JSON.stringify(workflow)}</div>
        */}
          </div>
        )}
    </>
  );
};

export default withFlowState(Flow);
