const { getUsers, getWorkflows } = require("api");

export const searchUsers = async (value) => {
  const {
    data: { data },
  } = await getUsers({ username__like: value });
  return data.map((user) => ({
    value: user.username,
    label: user.username,
  }));
};

export const searchWorkflows = async (value, mapper = null) => {
  const {
    data: { data },
  } = await getWorkflows({ name__like: value });
  if (mapper) {
    return data.map(mapper);
  } else {
    return data.map(({ name }) => ({
      value: name,
      label: name,
    }));
  }
};
