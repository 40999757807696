import { Button } from "components/common";
import FormInput from "components/common/form/FormInput";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { formLayouts as layouts } from "utils/layouts";
import { getSlackChannels, getWorkflow, getWorkflows } from "api";
import {
  onChangeAutoCompleteHandler,
  onVariableSelectionHandler,
} from "workflow-editor/utils/addNode.autocompleteHandler";

const ReferenceFlowNode = ({
  workflow,
  nodes,
  editedNode = null,
  onSave,
  onClose,
}) => {
  const [referenceFlow, setReferenceFlow] = useState(null);
  const [mainFlowForm] = useState({ ...workflow?.form });
  const [mainFlowVariables, setMainFlowVariables] = useState([]);
  const [referenceFlowVariables, setReferenceFlowVariables] = useState([]);
  const [layout] = useState(layouts.workflows.edit);
  const [dynamicOptions, setDynamicOptions] = useState({});

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors, isValidating },
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    if (mainFlowForm?.form_schema) {
      setMainFlowVariables(
        Object.entries(mainFlowForm.form_schema).map(([key, { label }]) => ({
          value: key,
          label: label,
        })),
      );
      if (!dynamicOptions.reference_flow?.length) {
        fetchWorkflows();
      }
    }
  }, [mainFlowForm]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "reference_flow" && value[name]) {
        getWorkflow(value[name]).then(({ data: { data: workflow } }) => {
          setReferenceFlow(workflow);
          parseInputVariables(workflow);
          const inputVars = parseInputVariables(workflow);
          setReferenceFlowVariables(inputVars);
          fetchSlackChannels().then(() => {
            reset({
              ...getValues(),
              notifications_config: workflow.notifications_config || [],
              ...inputVars.reduce((acc, curr) => {
                acc[curr] = mainFlowVariables.find((x) => x.value === curr)
                  ? "${" + curr + "}"
                  : null;
                return acc;
              }, {}),
            });
          });
        });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch("reference_flow")]);

  const parseInputVariables = (workflow) => {
    let variables = [];
    if (workflow?.flow_tree) {
      workflow.flow_tree.nodes.forEach((node) => {
        if (node.data) {
          variables = [
            ...variables,
            ...(JSON.stringify(node.data)?.match(/\${[^.]*?}/g) || []),
          ];
        }
      });
    }
    return [...new Set(variables)].map((variable) =>
      variable.replace("${", "").replace("}", ""),
    );
  };

  useEffect(() => {
    if (editedNode) {
      reset({ ...editedNode.data });
      if (editedNode.data.reference_flow) {
        getWorkflow(editedNode.data.reference_flow).then(
          ({ data: { data: workflow } }) => {
            setReferenceFlow(workflow);
            const inputVars = parseInputVariables(workflow);
            setReferenceFlowVariables(inputVars);
            fetchSlackChannels().then(() => {
              reset({
                ...editedNode.data,
                notifications_config: workflow.notifications_config || [],
              });
            });
          },
        );
      }
    }
  }, [editedNode]);

  const fetchSlackChannels = async () => {
    try {
      const { data } = await getSlackChannels();
      if (data.success) {
        setDynamicOptions((prevState) => ({
          ...prevState,
          channel_id: [
            { value: null, label: "Select Slack Channel" },
            ...data.data?.channels
              ?.filter(
                (channel) =>
                  !["C0424RUJ35M", "C042KA35AJW"].includes(channel.id),
              )
              .map((channel) => ({
                value: channel.id,
                label: channel.name,
              })),
          ],
        }));
      }
    } catch (error) {}
  };

  const fetchWorkflows = async () => {
    try {
      const { data } = await getWorkflows({
        fields: ["workflows__id", "workflows__name"],
        page: 0,
      });
      setDynamicOptions((prevState) => ({
        ...prevState,
        reference_flow: [
          {
            value: "",
            label: "Choose ...",
          },
          ...data.data
            .filter(({ workflows_id }) => workflow.id !== workflows_id)
            .map((w) => ({
              value: w.workflows_id,
              label: w.workflows_name,
            })),
        ],
      }));
      reset({ ...editedNode.data });
    } catch (error) {}
  };

  const onSubmit = (data) => {
    onSave(data, onClose);
  };

  return (
    <div className="flex h-full flex-1 flex-col divide-y divide-gray-300 dark:divide-gray-300/30">
      <form action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          <div className="flex-1 space-y-4 overflow-y-auto p-4">
            <div className="grid grid-cols-12 gap-2 text-sm">
              <div className="col-start-1 col-end-7">
                <FormInput
                  control={control}
                  register={register}
                  placeholder={"Reference Flow"}
                  name={"reference_flow"}
                  type={"select"}
                  label={"Reference Flow"}
                  options={{ required: true }}
                  error={!!errors?.["reference_flow"]}
                  selectOptions={dynamicOptions?.["reference_flow"] || []}
                />
              </div>
              <div className="col-start-1 col-end-7">
                <FormInput
                  control={control}
                  register={register}
                  placeholder={"Reference Flow Label"}
                  name={"reference_flow_label"}
                  type={"text"}
                  label={"Reference Flow Label"}
                  options={{ required: true }}
                  error={!!errors?.["reference_flow_label"]}
                />
              </div>
              {referenceFlowVariables.length > 0 && (
                <>
                  <div className="col-span-12 mt-2 border-b bg-background-secondary p-2 font-medium dark:border-gray-300/30 dark:bg-background-secondary-dark">
                    Variables
                  </div>
                  {referenceFlowVariables?.map((refInputVar) => (
                    <div className="col-span-3" key={refInputVar}>
                      <FormInput
                        control={control}
                        register={register}
                        isValidating={isValidating}
                        name={refInputVar}
                        type="autoComplete"
                        label={refInputVar}
                        options={{ required: false }}
                        error={!!errors?.[refInputVar]}
                        query={(value) =>
                          onChangeAutoCompleteHandler({
                            value,
                            formSchema: workflow.form?.form_schema,
                            nodes,
                          })
                        }
                        onVariableSelectionHandler={onVariableSelectionHandler}
                      />
                    </div>
                  ))}
                  <div className="col-span-12 mt-2 border-b bg-background-secondary p-2 font-medium dark:border-gray-300/30 dark:bg-background-secondary-dark">
                    Escalation Settings
                  </div>
                  <div className="col-span-12">
                    {referenceFlow.notifications_config?.map((c, idx) => (
                      <div className="flex gap-4" key={idx}>
                        <div>
                          <FormInput
                            control={control}
                            register={register}
                            isValidating={isValidating}
                            name={`notifications_config[${idx}].message`}
                            type={"text"}
                            label={idx === 0 ? "Escalate Group" : ""}
                            options={{ required: false }}
                            error={
                              !!errors?.[`notifications_config[${idx}].message`]
                            }
                            disabled={true}
                            className={
                              "disabled:bg-background-secondary disabled:opacity-50 dark:disabled:bg-background-secondary-dark"
                            }
                          />
                        </div>
                        <div>
                          <FormInput
                            control={control}
                            register={register}
                            isValidating={isValidating}
                            name={`notifications_config[${idx}].channel_id`}
                            type={"select"}
                            label={idx === 0 ? "Slack Channel" : ""}
                            options={{ required: false }}
                            selectOptions={dynamicOptions?.["channel_id"] || []}
                            error={
                              !!errors?.[
                                `notifications_config[${idx}].channel_id`
                              ]
                            }
                          />
                        </div>
                        <div className="flex-1">
                          <FormInput
                            control={control}
                            register={register}
                            isValidating={isValidating}
                            name={`notifications_config[${idx}].message_body`}
                            type={"multiSelect"}
                            label={idx === 0 ? "Columns" : ""}
                            options={{ required: false }}
                            selectOptions={
                              layout?.fields.notificationsConfig.fields
                                .message_body.selectOptions || []
                            }
                            error={
                              !!errors?.[
                                `notifications_config[${idx}].message_body`
                              ]
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-between bg-background-primary px-4 py-3 dark:bg-background-primary-dark sm:px-6">
            <div className="flex gap-4">
              <Button type="submit" className="ml-2" onSubmit={onSubmit}>
                Save
              </Button>
              <Button onClick={onClose} variant="secondary">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReferenceFlowNode;
