import { useOnline } from "hooks/useOnline";
import { LostConnectionIcon } from "./LostConnectionIcon";
import Modal from "../Modal";

const onClose = () => {};
export const NetworkConnectionErrorModal = () => {
  const isOnline = useOnline();
  return (
    <Modal
      isOpen={!isOnline}
      onClose={onClose} // onClose function is required
    >
      <div className="border border-red-600 p-4">
        <div className="flex">
          <LostConnectionIcon />
          <span className="font-semibold">Network Connection Lost!</span>
        </div>
        <div className="mt-2 space-y-4">
          <p>
            If issue persist please call the number below to work with a
            representative.
          </p>
          <p>
            <a
              className="text-blue-500 hover:text-blue-600 dark:text-blue-600 dark:hover:text-blue-500"
              href="tel:8778551146"
            >
              877-855-1146
            </a>{" "}
            <span>Option 2</span>
          </p>
        </div>
      </div>
    </Modal>
  );
};
