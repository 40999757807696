import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import FormInput from "../FormInput";
import Label from "../Label";
import Button from "components/common/Button";
import { classNames } from "utils";

const InputArray = ({
  name,
  label,
  error,
  register,
  onReset,
  control,
  watch,
  isValidating,
  fields = {},
  dynamic = true,
  dynamicOptions,
  options = {},
  query,
  ...rest
}) => {
  const {
    fields: arrayFields,
    append,
    remove,
    swap,
    replace,
  } = useFieldArray({
    control,
    name,
  });

  const defaultValue = Object.keys(fields).reduce((obj, key) => {
    obj[key] = undefined;
    return obj;
  }, {});

  const collapsable = options.collapsed !== undefined;
  const [collapsed, setCollapsed] = useState(!!options.collapsed);

  const [watchedValues, setWatchedValues] = useState([]);
  useEffect(() => {
    if (arrayFields.length === 0) {
      // use replace instead of append as in strict mode component get remounted and effects run twice
      replace([defaultValue]);
    }
  }, [arrayFields]);

  // made this function so that when rows are added it can trigger the useEffect that sets watched values
  // this fixes the allow multiple entries field not rendering when additional rows are added
  const addRow = () => {
    append(defaultValue);
  };

  useEffect(() => {
    if (watch) {
      setWatchedValues(watch(name));
    }
  }, [isValidating, addRow]);

  const renderInput = (fieldName, fieldValue, idx) =>
    (fieldValue || []).includes(watchedValues?.[idx]?.[fieldName]);

  const getSelectOptions = (
    filter,
    options,
    name,
    fields,
    watchedValues,
    idx,
  ) => {
    if (!filter || !watchedValues) return options;

    if (fields[name].filter) {
      const { filterFieldName } = fields[name];
      const option = fields[filterFieldName]?.selectOptions?.find(
        (x) => x.value === watchedValues?.[idx]?.[filterFieldName],
      );
      if (option) {
        const filteredOptions = fields[name].filter(option.type);
        const selectedOption = filteredOptions?.find(
          (x) => x.value === watchedValues?.[idx]?.[name],
        );

        return [
          selectedOption || { value: "", label: "Choose ..." },
          ...filteredOptions.filter(
            (opt) => opt.value !== selectedOption?.value,
          ),
        ];
      }
    }

    return options;
  };
  return (
    <div className="space-y-4">
      {label && (
        <div
          className={classNames(
            "flex gap-4",
            collapsable && "cursor-pointer select-none",
          )}
          onClick={() => collapsable && setCollapsed(!collapsed)}
        >
          <Label name={name} label={label} error={error} options={options} />
          {collapsable && (
            <div className="cursor-pointer">
              {collapsed && <ChevronDownIcon className="h-5 w-5" />}
              {!collapsed && <ChevronUpIcon className="h-5 w-5" />}
            </div>
          )}
        </div>
      )}
      <div className={classNames("space-y-4", collapsed && "hidden")}>
        {/* transition-transform duration-200 ease-in-out */}
        {arrayFields.map((field, idx) => (
          <div key={field.id} className="flex items-center gap-1">
            {fields &&
              Object.entries(fields).map(
                ([
                  key,
                  {
                    type,
                    label: fieldLabel,
                    options,
                    checkIf,
                    filter,
                    placeholder,
                    selectOptions,
                    values,
                    error: flowError,
                  },
                ]) => (
                  <React.Fragment key={key}>
                    {!checkIf ? (
                      <div className="flex-1">
                        <FormInput
                          {...rest}
                          register={register}
                          control={control}
                          label={idx === 0 ? fieldLabel : null}
                          name={`${name}[${idx}].[${key}]`}
                          type={type}
                          placeholder={placeholder ?? fieldLabel}
                          options={options}
                          selectOptions={getSelectOptions(
                            filter,
                            dynamicOptions?.[key] || selectOptions,
                            key,
                            fields,
                            watchedValues,
                            idx,
                          )}
                          values={values}
                          // added in flowError to handle errors for when running input step on the flow
                          error={
                            flowError ? flowError[idx]?.[key]?.message : error
                          }
                          query={query}
                        />
                      </div>
                    ) : renderInput(
                        checkIf.fieldName,
                        checkIf.fieldValue,
                        idx,
                      ) ? (
                      <div className="flex-1">
                        <FormInput
                          register={register}
                          control={control}
                          label={idx === 0 ? fieldLabel : null}
                          name={`${name}[${idx}].[${key}]`}
                          type={type}
                          placeholder={placeholder ?? fieldLabel}
                          options={options}
                          selectOptions={getSelectOptions(
                            filter,
                            dynamicOptions?.[key] || selectOptions,
                            key,
                            fields,
                            watchedValues,
                            idx,
                          )}
                          values={values}
                          // added in flowError to handle errors for when running input step on the flow
                          error={
                            flowError ? flowError[idx]?.[key]?.message : error
                          }
                          query={query}
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                ),
              )}
            {dynamic && (
              <div className="flex flex-col self-stretch justify-center">
                {idx === 0 && <div className="h-5" />}
                <Button
                  variant="secondary"
                  size="small"
                  className="!z-0 mt-1 h-8 w-8"
                  onClick={() => {
                    // call onReset only if it's the last item
                    if (arrayFields.length === 1) onReset?.();
                    remove(idx);
                  }}
                >
                  -
                </Button>
              </div>
            )}
          </div>
        ))}
        {dynamic && (
          <Button
            variant="secondary"
            size="small"
            className="!z-0 h-fit w-8"
            onClick={() => {
              append(defaultValue);
            }}
          >
            +
          </Button>
        )}
      </div>
    </div>
  );
};

export default InputArray;
