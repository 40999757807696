import React, {useEffect, useState} from "react";
import { useFlowState } from "contexts/flow";
import { postFrontendErrorLogs, updateActivationTicket, sendEscalationMessage } from "api";

const TimerView = ({children}) => {
    const [timer, setTimer] = useState(0);
    const [isEscalated, setIsEscalated] = useState(false)

    const {state, workflowInstanceId} = useFlowState();

    async function updateTicketStatus(){
        {
            try{
                await updateActivationTicket({
                    ...state?.instanceState,
                    escalate_message: 'Field tech might be stuck and need help'
                })

                await sendEscalationMessage(workflowInstanceId)
            }catch(error){
                postFrontendErrorLogs(error);
                return false
            }
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
        }, 1000);

        return () => {
            clearInterval(intervalId)
        };
    }, []);

    useEffect(() => {
        if(timer > 900 && !isEscalated){
            updateTicketStatus()
            setIsEscalated(true)
        }
    }, [timer]);

    return <>{children}</>
}

export default TimerView;