import React, { useState } from "react";
import axios from "axios";
import { Button } from "components/common";
import Modal from "./helpModal";
import { updateSurveyCW } from "api";

function CheckListItem({ status, title, clickHandler }) {
    return (
        <li
            className="flex items-center p-4 bg-white rounded-lg shadow-md mb-2 transition-transform duration-200 ease-in-out cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#3b5aa9]"
            onClick={clickHandler}
        >
            <div className="flex-1">
                <div className="flex justify-between items-center">
                    <span className="font-semibold text-lg text-gray-800">{title}</span>
                </div>
                <p className="text-gray-600 mt-1">{status || 'Pending'}</p>
            </div>
            <div className="ml-4 flex-shrink-0">
                {status === 'Changes Needed' && (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#eed202" className="w-6 h-6">
                        <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                    </svg>
                )}
                {status === 'Approved' && (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4BB543" className="w-6 h-6">
                        <path d="M7.493 18.5c-.425 0-.82-.236-.975-.632A7.48 7.48 0 0 1 6 15.125c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75A.75.75 0 0 1 15 2a2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23h-.777ZM2.331 10.727a11.969 11.969 0 0 0-.831 4.398 12 12 0 0 0 .52 3.507C2.28 19.482 3.105 20 3.994 20H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 0 1-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227Z" />
                    </svg>
                )}
            </div>
        </li>
    );
}

function CheckList({ setCurrentStep, currentStep, tasks, specialInstructions, instanceId, code }) {
    const [showInstructions, setShowInstructions] = useState(false);
    const [showModal, setShowModal] = useState(false);

    async function submitApproval() {
        try {
            let { data } = await updateSurveyCW(instanceId);
        } catch (error) {
            console.error('Error submitting approval:', error);
        }
    }

    return (
        <div className="min-h-screen bg-[#e6f0ff] font-poppins">
            <header className="bg-[#3b5aa9] text-white py-4 px-6 flex items-center justify-between">
                <div className="flex items-center">
                    <button onClick={() => setCurrentStep(0)} className="text-white mr-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 15.75l-3.75-3.75m0 0l3.75-3.75m-3.75 3.75h9" />
                        </svg>
                    </button>
                    <span className="text-lg font-semibold">Survey Tasks</span>
                </div>
                <button
                    onClick={() => setShowModal(true)}
                    className="text-white"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
                    </svg>
                </button>
            </header>
            <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
                {specialInstructions && (<div className="relative mb-6">
                    <div
                        className={`p-4 bg-[#3b5aa9] text-white rounded-lg shadow-md cursor-pointer transition-transform transform ${showInstructions ? 'scale-105' : 'scale-100'}`}
                        onClick={() => setShowInstructions(!showInstructions)}
                        onKeyDown={(e) => { if (e.key === 'Enter') setShowInstructions(!showInstructions); }}
                        role="button"
                        tabIndex={0}
                    >
                        <div className="flex items-center justify-between">
                            <p className="text-lg font-semibold">Special Instructions</p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path d="M4.5 9a.75.75 0 0 1 .75-.75h11.5a.75.75 0 0 1 0 1.5H5.25A.75.75 0 0 1 4.5 9Zm.75 4.5a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1-.75-.75Zm6 4.5a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1-.75-.75Z" />
                            </svg>
                        </div>
                        {showInstructions && (
                            <div className="mt-4">
                                <p className="text-sm text-white-700">{specialInstructions}</p>
                            </div>
                        )}
                    </div>
                </div>)}
                {code && (<div className="mx-auto bg-white rounded-lg shadow-md p-2">Sign Out Code: {code}</div>)}
                <ul className="divide-y divide-gray-200 p-2">
                    {tasks?.map((form) => (
                        <CheckListItem
                            key={form?.stepId}
                            clickHandler={() => setCurrentStep(form?.stepId)}
                            status={form?.status}
                            title={form?.title}
                        />
                    ))}
                </ul>
                <Button
                    onClick={() => submitApproval()}
                    className="mt-6 w-full bg-[#3b5aa9] text-white px-6 py-3 rounded-lg border border-[#3b5aa9] transition-transform transform hover:scale-105 active:scale-95"
                >
                    Submit for Review
                </Button>
            </div>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)} />
        </div>
    );
}

export default CheckList;
