import { NODE_TYPE } from "utils/constants";

export const validReferenceRegexp = new RegExp(
  `\\\${((${Object.values(NODE_TYPE).join("|")})-[0-9]+)\\.[^}]+}`,
  "g",
);
export const nodeTypeRegex = new RegExp(
  `^(\\\${)?(?<id>(?<type>${Object.values(NODE_TYPE).join("|")})-[0-9]+)\\.`,
);
const nodeIds = new Set();
const duplicateIds = new Set();

const defaultMapper = (match) => match;
export const matchStrings = (strings, regexp, mapper = defaultMapper) => {
  return strings.reduce((acc, string) => {
    const match = string.match(regexp);
    if (!match) return acc;
    return [...acc, ...match.map(mapper)];
  }, []);
};

/**
 * Returns the referable string values from a node
 * ```js
 * const node = {
 *   id: "radio-1",
 *   type: "radio",
 *   data: {
 *      question: "question",
 *      answers: [{ text: "answer1" }, { text: "answer2" }]
 *   },
 *   ...
 * };
 * // returns ["question", "answer1", "answer2"]
 * getReferableStrings(node)
 * ```
 * @param {Node} node
 * @returns {string[]}
 */
export const getReferableStrings = (node) => {
  switch (node.type) {
    case NODE_TYPE.END:
      return [node.data.message];
    case NODE_TYPE.ESCALATE:
      return [node.data.failure];
    case NODE_TYPE.INPUT:
      return [node.data.heading];
    case NODE_TYPE.LOOP:
      return node.data.source ? [node.data.source] : [];
    case NODE_TYPE.API:
      let strings = [node.data.endpoint];
      Object.values(node.data.parameters || {}).forEach(
        (parameter) => (strings = [...strings, parameter]),
      );
      (node.data.arguments || []).forEach(
        ({ value }) => (strings = [...strings, value]),
      );
      return strings;
    case NODE_TYPE.IF: {
      let strings = [];
      const getVariable = ({ rules, variable }) => {
        if (rules) return rules.forEach((rule) => getVariable(rule));
        strings = [...strings, variable];
      };

      node.data.conditions.rules.forEach(getVariable);
      return strings;
    }
    case NODE_TYPE.RADIO:
    case NODE_TYPE.CHECKBOX: {
      let strings = [node.data.question];
      node.data.answers.forEach((answer) => {
        strings = [...strings, answer.text];
      });
      return strings;
    }
    default:
      return [];
  }
};

const findReference = (string, node) => {
  const match = new RegExp(`\\\${(?<reference>${node}\\..*)}`).exec(string);
  if (!match) return;
  const {
    groups: { reference },
  } = match;
  return reference;
};

export const searchNodesForReference = (nodes, nodeIds = []) => {
  return nodes.reduce((acc, node) => {
    let references = [];
    const strings = getReferableStrings(node);
    nodeIds.forEach((nodeId) => {
      strings.forEach((string) => {
        const reference = findReference(string, nodeId);
        if (reference) references = [...references, reference];
      });
    });
    if (references.length) acc[node.id] = references;
    return acc;
  }, {});
};

/**
 * Return the properties that can be accessed for a node
 * ```js
 * const node = { type: "api", id: "api-1", ... }
 * // returns ["data", "response", "success", "status_code"]
 * getNodeTypeProperties(node)
 * ```
 */
export const getNodeTypeProperties = (node, nodes = []) => {
  switch (node.type) {
    case NODE_TYPE.RADIO:
    case NODE_TYPE.CHECKBOX:
    case NODE_TYPE.ESCALATE:
      return ["answer"];
    case NODE_TYPE.REFERENCE_FLOW:
      return ["success"];
    case NODE_TYPE.API:
      return ["data", "response", "success", "status_code"];
    case NODE_TYPE.LOOP:
      return [
        "item",
        "index",
        "source",
        "data",
        ...nodes
          .filter(({ parentNode }) => parentNode === node.id)
          .map(({ id }) => id),
      ];
    case NODE_TYPE.INPUT:
      return nodes
        .find(({ id }) => id === node.id)
        ?.data.fields?.map(({ name }) => name);
    default:
      return;
  }
};

/**
 * Return the properties of a reference
 * ```js
 * // starting "${" characters are optional, can be used as "api-1.<rest>"
 * const reference = "${api-1.<rest>"
 * // returns ["data", "response", "success", "status_code"]
 * getReferenceProperties(reference)
 * ```
 */
export const getReferenceProperties = (string, nodes = []) => {
  const match = nodeTypeRegex.exec(string);
  if (!match) return;
  const {
    groups: { type, id },
  } = match;
  return getNodeTypeProperties({ type, id }, nodes);
};

/**
 * Traverses the tree starting from a specific node
 *
 * return a list of node ids that are reachable from specified node
 *
 * when mode is set to previous it starts from the node and goes upwards
 * until it reaches the root node (start node)
 * @param {"previous" | "following"} mode
 * @returns {string[]}
 */
export const getReachableNodes = (
  nodeId,
  nodes,
  edgesToNodeIdMap,
  mode = "previous",
) => {
  nodeIds.clear();
  duplicateIds.clear();
  const accessor = {
    previous: "source",
    following: "target",
  };

  const traverseGraph = (nodeId) => {
    const edges = edgesToNodeIdMap[mode]?.[nodeId];
    if (!edges) {
      const node = nodes.find((node) => node.id === nodeId);
      if (!node.parentNode) return;
      return traverseGraph(node.parentNode);
    }
    edges?.forEach((edge) => {
      const id = edge[accessor[mode]];
      if (nodeIds.has(id)) {
        duplicateIds.add(id);
        return;
      }
      nodeIds.add(id);
      return traverseGraph(id);
    });
  };

  traverseGraph(nodeId);
  return { nodeIds: [...nodeIds], duplicateIds: [...duplicateIds] };
};

/**
 * Traverses the tree starting from a specific node
 *
 * return the paths with cycles in the graph
 * @returns {string[][]}
 */
export const getGraphCycles = (
  edgesToNodeIdMap,
  startingNode = "start-1",
  maxDepth = 15,
) => {
  const cycles = [];
  const traverseGraph = (path) => {
    if (
      path.length > maxDepth ||
      !edgesToNodeIdMap.following[path[path.length - 1]]
    )
      return;
    const arr = edgesToNodeIdMap.following[path[path.length - 1]].reduce(
      (acc, edge) => {
        if (
          !acc.some(
            ({ source, target }) =>
              source === edge.source && target === edge.target,
          )
        )
          acc.push(edge);
        return acc;
      },
      [],
    );
    if (arr.length === 0) return;
    for (let index = arr.length - 1; index >= 0; index--) {
      const id = arr[index].target;
      const existingIndex = path.indexOf(id);
      if (existingIndex >= 0) {
        const cycle = path.slice(existingIndex);
        if (
          !cycles.some(
            (c) =>
              c.length === cycle.length &&
              cycle.every((nodeId) => c.includes(nodeId)),
          )
        )
          cycles.push(cycle);
        break;
      }
      traverseGraph([...path, id]);
    }
  };
  traverseGraph([startingNode]);
  return cycles;
};

export const isReferenceReachableFromAllBranches = (
  referenceId,
  nodeId,
  nodesToObj,
  edgesToNodeIdMap,
) => {
  nodeIds.clear();
  const traverseGraph = (nodeId) => {
    if (nodeIds.has(nodeId) || isInGroupNode(nodeId, referenceId, nodesToObj)) {
      return true;
    }
    nodeIds.add(nodeId);
    const edges = edgesToNodeIdMap.previous?.[nodeId];
    if (!edges) {
      const node = nodesToObj[nodeId];
      if (!node.parentNode) return false;
      return traverseGraph(node.parentNode);
    }
    for (let index = edges.length - 1; index >= 0; index--) {
      const edge = edges[index];
      if (edge.source !== referenceId) {
        const result = traverseGraph(edge.source);
        if (!result) return false;
      }
    }
    return true;
  };
  return traverseGraph(nodeId);
};

/**
 * Checks if the source node is in the same group and can reach the target node
 */
export const isInGroupNode = (sourceNodeId, targetNodeId, nodesToObj) => {
  const node = nodesToObj[sourceNodeId];
  if (node?.parentNode) {
    if (node.parentNode === targetNodeId) return true;
    return isInGroupNode(node.parentNode, targetNodeId, nodesToObj);
  }
  return false;
};
