import React, { useState } from 'react';
import Label from '../Label'; 
import { classNames } from 'utils';

const ImageUpload = ({
  name,
  label,
  register,
  error,
  options = {},
  ...rest
}) => {
  const hasError = typeof error === 'boolean' ? error : !!error;
  return (
    <div className="flex flex-col items-start mt-4">
      <label
        htmlFor={`${name}-upload`}
        className={classNames(
          "relative inline-flex items-center cursor-pointer",
          options.readOnly && "opacity-60 cursor-not-allowed"
        )}
      >
        <input
          id={`${name}-upload`}
          type="file"
          accept="image/*"
          className="mt-2 block w-full text-sm file:mr-4 file:rounded-md file:border-0 file:bg-teal-500 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white hover:file:bg-teal-700 focus:outline-none disabled:pointer-events-none disabled:opacity-60"
          {...register(name, options)}
        />
      </label>

      {label && (
        <div className="mt-2 text-sm">
          <Label
            name={`${name}-upload`}
            label={label}
            error={hasError}
            options={options}
          />
        </div>
      )}

      {hasError && (
        <div className="text-red-500 text-sm mt-1">
          {typeof error === 'string' ? error : 'An error occurred'}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
