import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import withFlowState from "components/hoc/withFlowState";
import { NODE_TYPE, WORKFLOW_STATUS } from "utils/constants";
import { Button } from "components/common";
import FlowStep from "../FlowStep";
import { postNotes } from "api";
import Navigation from "../Navigation";
import TechInfo from "../TechInfo";
import { postFrontendErrorLogs } from "api";

const EndStep = (props) => {
  const [techInfo, setTechInfo] = useState(null);
  const [completed, setCompleted] = useState(false);

  const {
    state,
    onNext,
    updateFlowStateStepData,
    accountNumber,
    workflowInstanceId,
    determineNextStep,
    stepState,
    referenceFlow,
    getInstanceState,
    injectValue,
  } = props;
  const { workflow, workflowNodes, flowState, instanceState, currentNode } =
    state;
  const currentState = useMemo(() => getInstanceState(), [getInstanceState]);

  useEffect(() => {
    setTechInfo(
      Object.values(instanceState)?.filter(
        (value) => value?.tech_name && value?.tech_phone,
      ),
    );
    if (referenceFlow) {
      updateFlowStateStepData({
        nodeId: currentNode,
        nodeType: NODE_TYPE.END,
        status: stepState?.status,
        message: injectValue(stepState?.message || ""),
        next: determineNextStep(),
      });
    }
  }, [currentNode, workflowNodes]);

  // for reference flows continue to main flow once updateFlowState is completed
  useEffect(() => {
    if (typeof currentState.success !== "undefined") {
      onNext();
    }
  }, [currentState]);

  const sendTurnUpSummary = async (status) => {
    const escalateData = [];
    let i = 1;
    Object.keys(flowState).forEach((key) => {
      if (flowState[key]?.nodeType === "escalate") {
        if (flowState[key]?.resolution) {
          escalateData.push(
            `Issue ${i}: ${flowState[key].escalate} <br>Resolution ${i}: ${flowState[key].resolution}</br>`,
          );
        } else if (flowState[key]?.issueDetails) {
          escalateData.push(
            `Issue ${i}: ${flowState[key].escalate} <br>Issue Details ${i}: ${flowState[key].issueDetails}</br>`,
          );
        } else {
          escalateData.push(`Issue ${i}: ${flowState[key].escalate}`);
        }
        i += 1;
      }
    });
    const payload = {
      status: status,
      callStatus: `Call Successfull: ${status === "Success" ? "Yes" : "No"}`,
      issue: `Issues: ${escalateData.length > 0 ? "Yes" : "No"}`,
      numIssues: `Number of Issues: ${escalateData.length}`,
      escalations: escalateData,
      workflowName: `Workflow: ${workflow.name}`,
      customerName: `Customer Name: ${instanceState["customer_name"]}`,
      accountNumber: `Account Number: ${accountNumber}`,
      iwTicket: `IW Ticket: ${instanceState["iw_ticket"]}`,
      address:
        `Address: ${instanceState["address_1"]} ${instanceState["city"]} ` +
        `${instanceState["state"]} ${instanceState["zip_code"]}`,
      account_number: accountNumber,
      iw_ticket: instanceState["iw_ticket"],
      id: workflowInstanceId,
    };
    try {
      await postNotes(instanceState.activation_ticket, payload);
    } catch (error) {
      postFrontendErrorLogs(error);
    }
  };
  const onNextHandler = async () => {
    if (!completed) {
      updateFlowStateStepData(
        {
          nodeId: currentNode,
          nodeType: NODE_TYPE.END,
          status: stepState?.status,
          message: injectValue(stepState?.message || ""),
        },
        stepState?.status === "Success"
          ? WORKFLOW_STATUS.COMPLETED
          : WORKFLOW_STATUS.FAILURE,
      );
      sendTurnUpSummary(stepState?.status);
      setCompleted(true);
    } else {
      onNext();
    }
  };

  const status = stepState.status;

  return (
    <>
      <FlowStep data={stepState}>
        {referenceFlow && (
          <>
            <div>Please wait ...</div>
            <div>Continuing to the next step in the main flow ...</div>
          </>
        )}
        {!referenceFlow && !completed && (
          <>
            <h1 className="mb-4 text-lg font-bold">{stepState.status}</h1>
            <div className="space-y-2">
              {status === "Failure" && (
                <div>
                  <span>The turn up will be marked as "Failed" due to: </span>
                  <span className="text-red-500 dark:text-red-600">{`${injectValue(
                    stepState?.message || "",
                  )}`}</span>
                </div>
              )}
              {status === "Success" && (
                <div>
                  <span>The turn up will be marked as "Success", Please click Next to Finish</span>
                </div>
              )}
            </div>
            <div>
              <span>Please click </span>
              <span className="hidden sm:inline">Next</span>
              <span className="inline sm:hidden">Continue</span>
              <span> to complete the turn up</span>
            </div>
          </>
        )}
        {!referenceFlow && completed && (
          <>
            <h1 className="mb-4 text-lg font-bold">{`Turn up completed ${
              status === "Failure" ? "with failure." : "successfully."
            }`}</h1>
          </>
        )}
        <TechInfo techInfo={techInfo} />
      </FlowStep>
      {!referenceFlow && !completed && (
        <div className="flex-shrink-0 overflow-hidden rounded-b-md py-5 sm:py-8">
          {/* desktop */}
          <div className="hidden items-center text-secondary-text dark:text-secondary-text-dark sm:flex">
            <Button
              variant="primary"
              className="ml-3 p-2"
              onClick={onNextHandler}
              disabled={completed}
            >
              Next
            </Button>
          </div>
          {/* mobile */}
          <div className="m-2 flex justify-around text-secondary-text dark:text-secondary-text-dark sm:hidden">
            <Button
              variant="primary"
              onClick={onNextHandler}
              className="flex-grow cursor-default !rounded-full !bg-[#3B82F6] !py-4 text-white disabled:!bg-transparent disabled:text-[#9ca3af] dark:bg-transparent dark:text-white dark:disabled:text-[#9ca3af]"
            >
              <span>Continue</span>
            </Button>
          </div>
        </div>
      )}
      {!referenceFlow && completed && (
        <div className="flex-shrink-0 overflow-hidden rounded-b-md py-5 sm:py-8">
          {/* desktop */}
          <div className="hidden items-center text-secondary-text dark:text-secondary-text-dark sm:flex">
            <Link to={`/flow`} reloadDocument>
              <Button type="button" className="w-full px-4">
                <span>Finish</span>
              </Button>
            </Link>
          </div>
          {/* mobile */}
          <div className="m-2 flex justify-around text-secondary-text dark:text-secondary-text-dark sm:hidden">
            <Link
              to={`/flow`}
              reloadDocument
              className="flex-grow cursor-default !rounded-full !bg-[#3B82F6] !py-4 text-center text-white dark:bg-transparent dark:text-white"
            >
              <span>Finish</span>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default withFlowState(EndStep);
