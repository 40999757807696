import React from "react";
import Label from "../Label";
import CreatableSelect from "react-select/creatable";
import { Controller } from "react-hook-form";
import { classNames } from "utils";
import { isBoolean } from "utils/helpers";

const SelectWrapper = ({
  name,
  label,
  register,
  control,
  error,
  options = {},
  selectOptions = [],
  isMulti,
  placeholder = "Choose...",
  ...rest
}) => {
  const ops = selectOptions.map((option) => {
    if (typeof option === "string") {
      return { value: option, label: option };
    }
    return {
      value: option.value,
      label: option.label,
    };
  });

  const hasError = !!error;
  const customStyles = {
    control: (provided, state) => ({
      ...provided,

      border: hasError ? "1px solid #ef4444" : null,
      "&:hover": {
        border: hasError ? "1px solid #ef4444" : null,
      },
    }),
  };

  const getSelectedValues = (values) => {
    if (values) {
      return ops.filter((option) => {
        if (option.value === undefined) return false;
        if (typeof option.value === "string") {
          return values?.includes(option.value);
        } else {
          return (
            values?.filter((value) => value.key === option.value.key).length > 0
          );
        }
      });
    }
    return null;
  };

  return (
    <>
      {label && (
        <Label name={name} label={label} error={hasError} options={options} />
      )}
      {isMulti ? (
        <Controller
          name={name}
          control={control}
          rules={options}
          render={({ field: { value, onChange, onBlur } }) => {
            return (
              <CreatableSelect
                classNamePrefix="react-select"
                className="mt-1"
                classNames={{
                  control: (state) => "p-0.5",
                }}
                isDisabled={options?.disabled}
                options={ops}
                placeholder={placeholder}
                isMulti={true}
                onChange={(options) =>
                  onChange(options?.map((option) => option.value))
                }
                styles={customStyles}
                onBlur={onBlur}
                value={getSelectedValues(value)}
                closeMenuOnSelect={false}
              />
            );
          }}
        />
      ) : (
        <select
          disabled={options.disabled || options.readOnly}
          name={name}
          {...register(name, { ...options })}
          className={classNames(
            hasError
              ? "border-red-500 dark:border-red-600"
              : "border-gray-300 dark:border-gray-300/30",
            "mt-1 block w-full rounded-md bg-background-primary py-2 pl-3 pr-10 text-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none dark:bg-background-primary-dark sm:text-base",
          )}
          {...rest}
        >
          {ops.map(({ value, label }) => (
            <option key={value} value={value}>
              {label.length > 100 ? label.slice(0, 100) + "..." : label}
            </option>
          ))}
        </select>
      )}
      {!isBoolean(error) && error && (
        <div className="mt-1 text-sm text-red-500 dark:text-red-600">
          {error}
        </div>
      )}
    </>
  );
};

export default SelectWrapper;
