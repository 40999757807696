import React, { useEffect, useState, useContext } from "react";
import {
    getCurrentFlowState,
    postFrontendErrorLogs,
} from "api";
import { DashboardContext } from "./Dashboard";
import Header from "./Header";

function displayName(form_data){
    return `${form_data?.customer_name || ''} - ${form_data?.account_number || ''} - ${form_data?.iw_ticket || ''} -  ${form_data?.serial_number || ''}`
}

function Conversations({ name, wId, isSelected, selected }) {
    return (
      <button onClick={() => selected(wId)}
      className={`flex flex-row items-center hover:bg-gray-100 rounded-xl p-2 ${isSelected ? 'bg-blue-200' : ''}`}>
      <div className="ml-2 text-sm font-semibold">{name}</div>
      </button>
    )
}

const FlowState = () => {
    const {selectedFlow, instances} = useContext(DashboardContext);

    const [workflowInstanceId, setWorkflowInstanceId] = useState(selectedFlow || false);
    const [instanceData, setInstanceData] = useState(false);
    const [isLoading, setLoading] = useState(false);

    async function getFlowState(workflowInstanceID) {
        try{
            const { data } = await getCurrentFlowState(workflowInstanceID);
            setInstanceData(JSON.parse(data?.data) || false);
            setLoading(false)
        }catch(error){
            postFrontendErrorLogs(error)
            setLoading(false)
        }
      }

    useEffect(() => {
        getFlowState(workflowInstanceId);

        const intervalId = setInterval(() => {
            setLoading(true);
            getFlowState(workflowInstanceId);
        }, 90000);


        return () => {
            clearInterval(intervalId);
        };
    }, [workflowInstanceId]);

    function selected(wId){
        setWorkflowInstanceId(wId)
    }

  function getConversationName(wId){
    let flow = instances?.find(({id}) => id === wId)

    return displayName(flow?.form_data)
  }

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="relative">
                    <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-blue-500 animate-spin">
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="flex h-screen antialiased text-gray-800">
            <div className="flex flex-row h-full w-full overflow-x-hidden">
                <div className="flex flex-col py-8 pl-6 pr-2 w-64 bg-white flex-shrink-0">
                    <div className="flex flex-col mt-8 overflow-auto">
                        <div className="flex flex-row items-center justify-between text-xs">
                            <span className="font-bold">Active Install</span>
                            <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full">{instances.length}</span>
                        </div>
                        <div className="flex flex-col space-y-1 mt-4 h-full overflow-y-auto">
                            {instances.length && instances?.map(({ id, wId, form_data }) => {
                                const isSelected = workflowInstanceId === id;
                                return <Conversations key={id} name={displayName(form_data)} isSelected={isSelected} wId={id} selected={selected} />
                            })}
                        </div>
                    </div>
                </div>

                <div className="flex flex-col flex-auto h-full p-6">
                    {workflowInstanceId && (<Header name={getConversationName(workflowInstanceId)}/>)}
                    <div
                        className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4"
                    >
                        <div className="flex flex-col h-full overflow-x-auto mb-4">
                            <div className="flex flex-col h-full">
                            <pre>{!instanceData && (<h3>No Data Available</h3>) || JSON.stringify(instanceData, null, 2)}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlowState;