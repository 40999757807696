import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { formLayouts as layouts } from "utils/layouts";
import { Button } from "components/common";
import { classNames } from "utils";
import FormInput from "components/common/form/FormInput";
import {
  getCurrentUser,
  updateUser,
  getTeammates,
  addTeammate,
  getAllUsers,
  deleteTeammate,
} from "api";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Switch } from "@headlessui/react";
import { useAuthState } from "contexts/auth";
import SelectWrapper from "components/common/form/Select";
import Spinner from "components/common/Spinner";

const MODE = {
  EDIT: "edit",
  VIEW: "view",
};
const Settings = () => {
  const [viewLayout] = useState(layouts.settings.view);
  const [editLayout] = useState(layouts.settings.edit);
  const [user, setUser] = useState(null);
  const [teammates, setTeammates] = useState([]);
  const [users, setUsers] = useState([]);
  const [mode, setMode] = useState(MODE.VIEW);
  const [loading, setLoading] = useState(false);
  const { user: authenticatedUser } = useAuthState();
  const {
    control,
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValidating },
  } = useForm({
    mode: "onBlur",
  });

  const fetchCurrentUser = () => {
    setLoading(true);
    getCurrentUser().then((response) => {
      if (response.data.success) {
        setUser(response.data.data);
      }
      setLoading(false);
    });
  };

  const fetchUsers = () => {
    setLoading(true);
    getAllUsers().then((response) => {
      if (response.data.success) {
        getTeammates(authenticatedUser.id).then((teamResponse) => {
          if (teamResponse.data.success) {
            setTeammates(teamResponse.data.data.sort((a, b) => a.id - b.id));
            const filteredUsers = response.data.data
              ?.map(({ id, name }) => ({
                value: id,
                label: name,
              }))
              .filter(({ value }) => value !== authenticatedUser.id)
              .filter(
                ({ value }) =>
                  !teamResponse.data.data

                    .map(({ teammate_id }) => teammate_id)
                    .includes(value),
              );
            setUsers(filteredUsers);
          }
        });
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchCurrentUser();
    fetchUsers();
  }, []);

  const onAddNewTeammate = () => {
    const { teammate_id } = watch();
    const data = {
      user_id: authenticatedUser.id,
      teammate_id,
      notifications_enabled: true,
    };
    addTeammate(authenticatedUser.id, teammate_id, data).then((response) => {
      if (response.data.success) {
        fetchUsers();
      }
    });
  };

  const onDeleteTeammate = (user_id, teammate_id) => {
    deleteTeammate(user_id, teammate_id).then((response) => {
      if (response.data.success) {
        fetchUsers();
      }
    });
  };

  const onUpdateTeammate = (user_id, teammate_id, notifications_enabled) => {
    const data = {
      user_id,
      teammate_id,
      notifications_enabled,
    };
    addTeammate(authenticatedUser.id, teammate_id, data).then((response) => {
      if (response.data.success) {
        fetchUsers();
      }
    });
  };

  const renderViewControl = (key, field, value = "", onEdit) => {
    return (
      <div
        className={classNames(
          field.xs ? `col-span-${field.xs}` : "",
          field.sm ? `sm:col-span-${field.sm}` : "",
        )}
        key={key}
      >
        <dt className="text-sm font-medium text-gray-500">
          <div className="flex">
            <span>{field.label} </span>
          </div>
        </dt>
        <dd className="mt-1 text-sm text-header dark:text-header-dark">
          {value?.toString() || " - "}
        </dd>
      </div>
    );
  };

  const onSubmit = async (input) => {
    updateUser(input.id, input).then((response) => {
      fetchCurrentUser();
      setMode(MODE.VIEW);
    });
  };

  const onEdit = () => {
    setMode(MODE.EDIT);
    reset(user);
  };

  return (
    <div className="min-h-screen shadow">
      <div className="sticky top-0 z-30 flex items-center gap-5 border-b border-gray-300 bg-background-primary px-4 py-5 dark:border-gray-300/30 dark:bg-background-primary-dark sm:px-7">
        <div className="flex flex-col">
          <h3 className="text-lg font-medium leading-6 text-header dark:text-header-dark">
            {`Settings`}
          </h3>
        </div>
      </div>
      <div className="relative space-y-4 p-2 sm:p-3">
        {loading && (
          <div className="absolute left-0 top-0 z-10 flex h-screen w-full items-center justify-center bg-background-primary bg-opacity-50 dark:bg-background-primary-dark">
            <Spinner />
          </div>
        )}
        {!loading && (
          <div className="p-2 sm:p-3">
            <div className="relative rounded-lg bg-background-primary p-4 dark:bg-background-primary-dark">
              <div className="pb-4 text-xl font-bold text-secondary-text dark:text-secondary-text-dark">
                User Information
              </div>
              {mode === MODE.VIEW && (
                <>
                  <div className="absolute right-4 top-4">
                    <PencilSquareIcon
                      className="h-6 w-6 cursor-pointer text-blue-600"
                      onClick={onEdit}
                    />
                  </div>
                  <dl className="grid grid-cols-6 gap-x-4 gap-y-8">
                    {viewLayout &&
                      user &&
                      Object.keys(viewLayout.fields || {}).map((key) =>
                        renderViewControl(
                          key,
                          viewLayout.fields[key],
                          user[key],
                          null,
                        ),
                      )}
                  </dl>
                </>
              )}
              {mode === MODE.EDIT && (
                <form
                  className="space-y-4"
                  action="#"
                  method="POST"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <dl className="grid grid-cols-12 gap-4  border-b border-b-gray-300 pb-4 dark:border-b-gray-300/30">
                    {editLayout &&
                      Object.entries(editLayout.fields).map(
                        ([
                          key,
                          {
                            type,
                            label,
                            options,
                            selectOptions,
                            values,
                            fields,
                            xs,
                            sm,
                          },
                        ]) => (
                          <div
                            className={classNames(
                              xs ? `col-span-${xs}` : "",
                              sm ? `sm:col-span-${sm}` : "",
                            )}
                            key={key}
                          >
                            <FormInput
                              register={register}
                              control={control}
                              watch={watch}
                              isValidating={isValidating}
                              name={key}
                              type={type}
                              label={label}
                              options={options}
                              values={values}
                              error={!!errors?.[key]}
                              fields={fields}
                              selectOptions={selectOptions}
                            />
                          </div>
                        ),
                      )}
                  </dl>
                  <div className="flex gap-4">
                    <Button type="submit" variant="primary">
                      Update
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => setMode(MODE.VIEW)}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}
        {!loading && (
          <div className="p-2 sm:p-3">
            <div className="relative rounded-lg bg-background-primary p-3 dark:bg-background-primary-dark">
              <div className="pb-4 text-xl font-bold text-secondary-text dark:text-secondary-text-dark">
                Team Members
              </div>
              <dl className="flex flex-col gap-2 text-sm">
                <div className="grid w-full grid-cols-6 border-b py-2 sm:w-2/3">
                  <div className="col-span-2 font-medium">{`Name`}</div>
                  <div className="col-span-2 font-medium">{`Email`}</div>
                  <div className="col-span-2 font-medium">{`Notifications`}</div>
                </div>
                {teammates.map(
                  (
                    { user_id, teammate_id, teammate, notifications_enabled },
                    index,
                  ) => (
                    <div
                      key={index}
                      className="grid w-full grid-cols-6 items-center border-b py-1 sm:w-2/3"
                    >
                      <div className="col-span-2">{teammate.name}</div>
                      <div className="col-span-2">{teammate.username}</div>
                      <div className="col-span-1 flex items-center">
                        <Switch
                          checked={notifications_enabled}
                          onChange={() =>
                            onUpdateTeammate(
                              user_id,
                              teammate_id,
                              !notifications_enabled,
                            )
                          }
                          className={`${
                            notifications_enabled
                              ? "bg-blue-600"
                              : "bg-gray-200 dark:bg-gray-400"
                          } relative inline-flex h-6 w-11 items-center rounded-full`}
                        >
                          <span className="sr-only">Enable notifications</span>
                          <span
                            className={`${
                              notifications_enabled
                                ? "translate-x-6 dark:bg-gray-800"
                                : "translate-x-1 dark:bg-gray-700"
                            } inline-block h-4 w-4 transform rounded-full bg-background-primary`}
                          />
                        </Switch>
                      </div>
                      <div className="col-span-1 flex cursor-pointer items-center justify-end">
                        <TrashIcon
                          className="h-6 w-6 text-red-600"
                          onClick={() => onDeleteTeammate(user_id, teammate_id)}
                        />
                      </div>
                    </div>
                  ),
                )}
                <div className="mt-4 grid w-full grid-cols-6 items-end gap-4 sm:w-2/3">
                  <div className="col-span-3">
                    <SelectWrapper
                      name={`teammate_id`}
                      label={`Add a new team member`}
                      type={"select"}
                      placeholder={"Select Team Member"}
                      register={register}
                      control={control}
                      options={{}}
                      selectOptions={users}
                    />
                  </div>
                  <div className="col-span-3 flex items-center">
                    <Button variant="secondary" onClick={onAddNewTeammate}>
                      Add Team Member
                    </Button>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
