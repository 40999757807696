import * as React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuthState } from "contexts/auth";

const Layout = () => {
  const { user, logout, isTurnupUser } = useAuthState();
  if (user) {
    if (!isTurnupUser) return <Navigate to="/app" replace />;
    logout();
  }

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto p-2" src="/logo.png" alt="Workflow" />
        <img className="mx-auto h-6 w-auto" src="/greenix-logo.png"/>
      </div>
      <Outlet />
    </div>
  );
};

export default Layout;
