import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import withFlowState from "components/hoc/withFlowState";
import { NODE_TYPE } from "utils/constants";
import FlowStep from "../FlowStep";
import FormInput from "components/common/form/FormInput";
import { flattenObject } from "utils/helpers";

const StartSpecificStep = (props) => {
  const {
    state,
    updateFlowStateStepData,
    sequence,
    determineNextStep,
    stepState,
    injectValue,
  } = props;
  const { workflowNodes, flowState, currentNode } = state;

  const referencedNodes = Object.values(stepState?.references || {})
    .flat()
    .reduce((acc, reference) => {
      const nodeId = reference.split(".")[0];
      if (!acc.find((ref) => ref.reference === reference))
        acc.push({ reference, node: workflowNodes[nodeId] });
      return acc;
    }, []);

  const { control, register, setValue, watch, reset, formState } = useForm({
    mode: "onChange",
  });

  const { isValid, isValidating, errors } = formState;

  useEffect(() => {
    reset();
    if (flowState[sequence]?.state) {
      const state = flattenObject(flowState[sequence].state);
      Object.keys(state || {}).forEach((key) => {
        setValue(key, state[key]);
      });
    }
  }, [currentNode, workflowNodes]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      updateFlowStateStepData({
        nodeId: currentNode,
        nodeType: NODE_TYPE.START_SPECIFIC,
        state: { ...value },
      });
    });

    return () => subscription.unsubscribe();
  }, [watch, currentNode]);

  useEffect(() => {
    updateFlowStateStepData({
      next: determineNextStep({ isValid }),
    });
  }, [isValid, isValidating]);

  const renderInput = ({ node, reference }, index) => {
    let type = "text";
    let selectOptions = [];
    let label = reference;
    switch (node.type) {
      case NODE_TYPE.CHECKBOX:
      case NODE_TYPE.ESCALATE:
      case NODE_TYPE.RADIO:
        type = node.type === NODE_TYPE.CHECKBOX ? "multiSelect" : "select";
        selectOptions = node.answers?.map((answer) => answer.text) || [];
        label = (
          <>
            <p className="mb-1">{reference}</p>
            <span>{node.question || node.failure}</span>
          </>
        );
        break;
      case NODE_TYPE.INPUT:
        const field = node.fields.find(
          ({ name }) => name === reference.split(".")[1],
        );
        if (field) {
          type = field.type;
          selectOptions = field.selectTypeOptions?.split("\n") || [];
        }
        break;
      default:
        break;
    }
    return (
      <FormInput
        key={`${sequence}_${currentNode}_${index}`}
        register={register}
        control={control}
        name={reference}
        type={type}
        label={label}
        options={{ required: true }}
        selectOptions={selectOptions}
        error={errors?.[reference]?.message}
      />
    );
  };

  return (
    <FlowStep data={stepState}>
      <h1 className="text-bold mb-8 text-lg">
        {injectValue(stepState?.heading || "")}
      </h1>
      <form className="space-y-4">
        <div className="flex flex-col items-center">
          <div className="w-1/2 space-y-4">
            {referencedNodes.map(renderInput)}
          </div>
        </div>
      </form>
    </FlowStep>
  );
};

export default withFlowState(StartSpecificStep);
